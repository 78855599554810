import { filter } from 'lodash';
import React, { useEffect, useState, createRef } from 'react';
import _ from 'lodash';
import {
  Checkbox,
  Table,
  Button,
  Header,
  Pagination,
  Label,
  Icon,
  Input,
  Dropdown,
  Grid,
  Menu,
  DropdownMenu,
  DropdownItem,
} from 'semantic-ui-react';
import { NavLink } from 'react-router-dom/cjs/react-router-dom.min';

import {
  getAllLocations,
  getUsersByRole,
  getAllTeams,
  humanize,
  toggle,
  IMAGES_URL,
  getAllCustomers,
  getAllParentLocations,
  getActiveUsersByRole,
} from '../../config/functions';
import { selectTranslations } from '../../config/i18n/slice';
import { useDispatch, useSelector } from 'react-redux';
import {
  DownloadIcon,
  PdfIcon,
  SearchIcon,
  ShareIcon,
  WhiteDownChevron,
} from '../../../Assets/Icons/svg';
import WorkOrderSearchDropdown from '../../components/WorkOrder/WorkOrderSearchDropdown';
import { DropdownToggle, UncontrolledDropdown } from 'reactstrap';
import CategoryDropdown from '../../components/common/CategoryDropdown';
import { LetteredAvatarImageUrl } from '../../components/common/userProfilePlaceholder';
import moment from 'moment';
import DownloadExcelFileWithData from '../../../services/DownloadExcelFileWithData';
import DownloadPdfFileWithData from '../../../services/DownloadPdfFileWithData';
import {
  fetchAllTeamsData,
  fetchTeamsWithFilter,
} from '../../../redux/reducers/teamsReducer';
import { toast } from 'react-toastify';

const Filters = ({
  data,
  searchTitle,
  setDataByFilter,
  hasStatus,
  fileData,
  filterOptions = [],
  setFilterOptions,
  setSearchTitle,
  debounceValue,
  setloader,
  sortData,
  currentUserId,userRole
}) => {
  const t = useSelector(selectTranslations);
  const role = useSelector((state) => {
    if (state?.auth?.user?.role?.id) {
      return state?.auth?.user?.role?.type;
    } else {
      return null;
    }
  });
  const organisationId = useSelector((state) => {
    if (state?.auth?.user?.organisation) {
      return state.auth.user.organisation.id;
    } else {
      return null;
    }
  });
  const selectedLanguage = useSelector((state) => {
    if (state?.i18n?.lang) {
      return state.i18n.lang;
    } else {
      return 'en';
    }
  });


  // const currentUserId = useSelector((state) => {
  //   if (state?.auth?.user?.id) {
  //     return state.auth.user.id;
  //   } else {
  //     return null;
  //   }
  // });


  const [filtersTrigger, setFiltersTrigger] = useState({
    requestedByType: 'supervisors',
    requestedBy: {
      supervisors: {
        initOptions: [],
        options: [],
      },
      customers: {
        initOptions: [],
        options: [],
      },
    },
    locations: {
      initOptions: [],
      options: [],
    },
    superior: {
      initOptions: [],
      options: [],
    },
    customers: {
      initOptions: [],
      options: [],
    },
    teams: {
      initOptions: [],
      options: [],
    },
    dueDate: {
      options: [
        { key: 0, text: 'Any Date', value: 'any_day' },
        { key: 1, text: 'Today', value: 'today' },
        { key: 2, text: 'Tomorrow', value: 'tomorrow' },
        { key: 3, text: 'Next 7 Days', value: 'next_7_days' },
        { key: 4, text: 'Next 30 Days', value: 'next_30_days' },
        { key: 5, text: 'This Month', value: 'this_month' },
        { key: 6, text: 'This Quarter', value: 'this_quarter' },
        { key: 7, text: 'This Year', value: 'this_year' },
        { key: 8, text: 'Yesterday', value: 'yesterday' },
        { key: 9, text: 'Last Week', value: 'last_week' },
        { key: 10, text: 'Last Month', value: 'last_month' },
        { key: 11, text: 'Last Quarter', value: 'last_quarter' },
        { key: 12, text: 'Last Year', value: 'last_year' },
        { key: 13, text: 'Past Due', value: 'past_due' },
        { key: 14, text: 'Unscheduled', value: 'unscheduled' },
      ],
    },
    priority: {
      options: [
        { key: 0, text: 'None', value: 'none', color: null },
        { key: 1, text: 'Low', value: 'low', color: 'blue' },
        { key: 2, text: 'Medium', value: 'medium', color: 'orange' },
        { key: 3, text: 'High', value: 'high', color: 'red' },
      ],
    },

    status: {
      options: [
        { key: 1, text: 'Declined', value: 'declined', color: 'red' },
        { key: 2, text: 'Approved', value: 'approved', color: 'green' },
      ],
    },
  });

  const { allTeamsData } = useSelector((state) => ({
    allTeamsData: state.teams.allTeamsData,
  }));

  const [filterSearch, setFilterSearch] = useState({
    teams: '',
    superior: '',
    customer: '',
    location: '',
    requestedBy: '',
  });

  const dispatch = useDispatch();

  const [newData, setNewData] = useState(data);

  let _newData = newData;
  useEffect(() => {
    (async function() {
      setloader(true);

      await dispatch(
        fetchTeamsWithFilter({
          organisationId,
          searchName: debounceValue,
          page: '1',
          pageSize: '25',
          customer: filterOptions.customers,
          superior: filterOptions.superior,
          team: filterOptions.teams,
          location: filterOptions.locations,
          sort: sortData,
         userRole, currentUserId 
        })
      );

      setloader(false);
    })();

    // if (searchTitle.length > 0) {
    //   const re = new RegExp(_.escapeRegExp(searchTitle), 'i');
    //   const isMatch = (result) => re.test(result.attributes.name);

    //   _newData =
    //     searchTitle.length > 0 ? _.filter(_newData, isMatch) : _newData;
    // }

    // if (filterOptions.superior.length > 0)
    //   _newData = _newData.filter((el) => {
    //     return filterOptions.superior.includes(
    //       `${el.attributes?.superior?.data?.id}`
    //     );
    //   });

    // if (filterOptions.teams.length > 0)
    //   _newData = _newData.filter((el) => {
    //     return filterOptions.teams.includes(`${el?.id}`);
    //   });

    // if (filterOptions.customers.length > 0)
    //   _newData = _newData.filter((el) => {
    //     return el?.attributes?.customers?.data?.some((item) =>
    //       filterOptions.customers.includes(`${item.id}`)
    //     );
    //   });

    // if (filterOptions.locations.length > 0)
    //   _newData = _newData.filter((el) => {
    //     return el?.attributes?.locations?.data?.some((item) =>
    //       filterOptions.locations.includes(`${item.id}`)
    //     );
    //   });

    // if (_newData.length > 0) {
    //   setDataByFilter(_newData);
    // } else {
    //   setDataByFilter([]);
    // }
  }, [filterOptions, debounceValue]);

  useEffect(() => {
    (async function() {
      // const fetchData = await getAllCustomers(organisationId);

      let the_options_teams = [];
      let the_supervisor = [];
      // let the_options_jobTitle = [];
      let the_options_customer = [];
      let the_options_location = [];

      const fetchData = await getActiveUsersByRole(
        ['admin', 'limited_admin'],
        organisationId
      );
      // console.log();
      if (fetchData?.data) {
        fetchData.data.map((el) => {
          the_supervisor.push({
            key: `${el.id}`,
            value: `${el.id}`,
            text: el.firstName + ' ' + el.lastName,
            image: {
              avatar: true,
              src: el?.profileImage?.url
                ? IMAGES_URL + el?.profileImage?.url
                : LetteredAvatarImageUrl(el.firstName + ' ' + el.lastName),
              style: { width: '20px', height: '20px' },
            },
          });
        });
      }

      // const fetchTeamsData = await getAllTeams(organisationId);

      // console.log();
      // if (fetchTeamsData.response) {
      //   fetchTeamsData.response.map((el) => {
      //     the_options_teams.push({
      //       key: `${el.id}`,
      //       value: `${el.id}`,
      //       text: el.attributes.name,
      //       image: {
      //         avatar: true,
      //         src: LetteredAvatarImageUrl(el.attributes.name),
      //         style: { width: '20px', height: '20px' },
      //       },
      //     });
      //   });
      // }

      const fetchCustomerData = await getAllCustomers(organisationId,'',1,99999);
      if (fetchCustomerData?.response) {
        fetchCustomerData.response.forEach((el) => {
          the_options_customer.push({
            key: `${el.id}`,
            value: `${el.id}`,
            text: el.attributes.name,
            image: {
              avatar: true,
              src: el.attributes.logo.data
                ? IMAGES_URL + el.attributes.logo.data.attributes.url
                : LetteredAvatarImageUrl(el.attributes.name + ' '),
              style: { width: '20px', height: '20px' },
            },
          });
        });
      }

      const fetchDataLocation = await getAllLocations(
       
        organisationId,
       
      );

      if (fetchDataLocation) {
        fetchDataLocation.forEach((el) => {
       
            the_options_location.push({
              key: `${el.id}`,
              value: `${el.id}`,
              text: `${el.attributes.name}`,
            });
          
        });
      }

      // const fetchJobTitleData = await getAllJobTitles(organisationId);

      // if (fetchJobTitleData) {
      //   fetchJobTitleData.map((el) => {
      //     the_options_jobTitle.push({
      //       key: `${el.id}`,
      //       value: `${el.id}`,
      //       text: el.attributes.title,
      //     });
      //   });
      // }

      setFiltersTrigger({
        ...filtersTrigger,
        superior: {
          ...filtersTrigger.superior,
          initOptions: the_supervisor,
          options: the_supervisor,
        },
        teams: {
          ...filtersTrigger.teams,
          initOptions: the_options_teams,
          options: the_options_teams,
        },
        customers: {
          ...filtersTrigger.customers,
          initOptions: the_options_customer,
          options: the_options_customer,
        },
        locations: {
          ...filtersTrigger.locations,
          initOptions: the_options_location,
          options: the_options_location,
        },
      });

      // if (fetchData) {
      //   fetchData.forEach((el) => {
      //     the_options_customer.push({
      //       key: `${el.id}`,
      //       value: `${el.id}`,
      //       text: el.attributes.name,
      //       image: {
      //         avatar: true,
      //         src: el.attributes.logo.data
      //           ? IMAGES_URL +
      //             el.attributes.logo.data.attributes.formats.thumbnail.url
      //           : LetteredAvatarImageUrl(el.attributes.name + ' '),
      //         style: { width: '20px', height: '20px' },
      //       },
      //     });
      //   });
      // }

      // const fetchTeamsData = await getAllTeams(organisationId);

      // if (fetchTeamsData) {
      //   fetchTeamsData.map((el) => {
      //     the_options_teams.push({
      //       key: el.id,
      //       value: `${el.id}`,
      //       text: el.attributes.name,
      //       image: {
      //         avatar: true,
      //         src: LetteredAvatarImageUrl(`${el.attributes.name}`),
      //         style: { width: '16px', height: '16px' },
      //       },
      //     });
      //   });
      // }

      // getAllParentLocations,
      // const fetchDataLocation = await getAllParentLocations(organisationId);

      // if (fetchDataLocation) {
      //   fetchDataLocation.forEach((el) => {
      //     the_options_location.push({
      //       key: `${el.id}`,
      //       value: `${el.id}`,
      //       text: `${el.attributes.name}`,
      //     });
      //   });
      // }

      // const fetchDataPeople = await getUsersByRole(
      //   [
      //     'user',
      //     'supervisor',
      //     'view_only',
      //     'admin',
      //     'requester',
      //     'super_admin',
      //   ],
      //   organisationId
      // );

      // if (fetchDataPeople) {
      //   fetchDataPeople.map((el) => {
      //     the_options_user.push({
      //       key: `${el.id}`,
      //       value: `${el.id}`,
      //       text: el.firstName + ' ' + el.lastName,
      //       image: {
      //         avatar: true,
      //         src: el?.profileImage?.url
      //           ? IMAGES_URL + el?.profileImage?.url
      //           : LetteredAvatarImageUrl(el.firstName + ' ' + el.lastName),
      //         style: { width: '20px', height: '20px' },
      //       },
      //     });
      //   });
      // }
    })();
  }, []);

  useEffect(() => {
    const re = new RegExp(_.escapeRegExp(filterSearch.superior), 'i');
    const isMatch = (result) => re.test(result.text);

    setFiltersTrigger({
      ...filtersTrigger,
      superior: {
        ...filtersTrigger.superior,
        options:
          filterSearch.superior.length > 0
            ? _.filter(filtersTrigger.superior.initOptions, isMatch)
            : filtersTrigger.superior.initOptions,
      },
    });
  }, [filterSearch.superior]);

  useEffect(() => {
    const re = new RegExp(_.escapeRegExp(filterSearch.teams), 'i');
    const isMatch = (result) => {
      re.test(result.text);
    };

    let data = filtersTrigger.teams.initOptions.filter((test) =>
      re.test(test.text)
    );

    setFiltersTrigger({
      ...filtersTrigger,
      teams: {
        ...filtersTrigger.teams,
        options:
          filterSearch.teams.length > 0
            ? data
            : filtersTrigger.teams.initOptions,
      },
    });
  }, [filterSearch.teams]);

  useEffect(() => {
    const re = new RegExp(_.escapeRegExp(filterSearch.customer), 'i');
    // console.log(re, 'res');
    const isMatch = (result) => {
      let ss = re.test(result.text);
      // console.log(ss, ' i m ss');
    };

    // console.log(
    //   _.filter(filtersTrigger.customers.initOptions, isMatch),
    //   ' i m csds'
    // );

    let data = filtersTrigger.customers.initOptions.filter((test) =>
      re.test(test.text)
    );

    setFiltersTrigger({
      ...filtersTrigger,
      customers: {
        ...filtersTrigger.customers,
        options:
          filterSearch.customer.length > 0
            ? data
            : filtersTrigger.customers.initOptions,
      },
    });
  }, [filterSearch.customer]);

  useEffect(() => {
    const re = new RegExp(_.escapeRegExp(filterSearch.location), 'i');
    // console.log(re, 'res');
    const isMatch = (result) => {
      let ss = re.test(result.text);
      // console.log(ss, ' i m ss');
    };

    let data = filtersTrigger.locations.initOptions.filter((test) =>
      re.test(test.text)
    );

    setFiltersTrigger({
      ...filtersTrigger,
      locations: {
        ...filtersTrigger.locations,
        options:
          filterSearch.location.length > 0
            ? data
            : filtersTrigger.locations.initOptions,
      },
    });
  }, [filterSearch.location]);

  const handleSearchAssigneesChange = (e) => {
    setFilterSearch({
      ...filterSearch,
      customer: e.target.value,
    });
  };
  const handleSearchLocationChange = (e) => {
    setFilterSearch({
      ...filterSearch,
      location: e.target.value,
    });
  };

  const handleSearchTeamsChange = (e) => {
    setFilterSearch({
      ...filterSearch,
      teams: e.target.value,
    });
  };

  const handleSearchSupervisorChange = (e) => {
    setFilterSearch({
      ...filterSearch,
      superior: e.target.value,
    });
  };

  fileData = allTeamsData;

  const headers = [
    { label: t.assets.table.ID, key: 'id' },
    { label: t.people.table.name, key: 'name' },
    // { label: t.assets.form.description, key: 'description' },
    { label: t.teams.team_info_form.superior, key: 'superior' },
    { label: t.people.work_info_form.locations, key: 'location' },
    { label: t.teams.team_info_form.customers, key: 'customer' },
    { label: t.teams.team_info_form.team_members, key: 'team_members' },
    // { label: t.common.created_by, key: 'createdBy' },
  ];

  const downloadXLS = async () => {
    const hasValues = Object.values(filterOptions).some(
      (option) => option.length > 0
    );

    if (!hasValues && searchTitle == '') {
      let mydata = await dispatch(fetchAllTeamsData({ organisationId, userRole, currentUserId }));

      if (mydata.payload.response) {
        fileData = mydata.payload.response;
      } else {
        toast.error(t.common.something_wrong);
      }
    }
    let csvData = [];

    for (let i = 0; i < fileData.length; i++) {
      let teams_data = [];

      teams_data.id = fileData[i].id;
      teams_data.name = fileData[i].attributes.name || t.common.na;

      teams_data.description = fileData[i]?.attributes?.description
        ? fileData[i].attributes.description.replace(/\s+/g, ' ').trim()
        : t.common.na;
      teams_data.superior = fileData[i]?.attributes?.superior?.data
        ? fileData[i]?.attributes.superior?.data?.attributes?.firstName +
          ' ' +
          fileData[i]?.attributes.superior?.data?.attributes?.lastName
        : t.common.na;

      let locationsData = fileData[i].attributes.locations?.data;
      let locationsString = '';

      if (locationsData?.length > 0) {
        locationsString = locationsData
          .map((each) => each.attributes?.name)
          .join(', ');
      } else {
        locationsString = t.common.na;
      }

      teams_data.locations = locationsString;

      let assigneePeople = fileData[i].attributes?.customers?.data;
      let assignString = '';

      if (assigneePeople?.length > 0) {
        assignString = assigneePeople
          .map((people) => people?.attributes?.name)
          .join(', ');
      } else {
        assignString = t.common.na;
      }
      teams_data.customer = assignString;

      let teamsMember = fileData[i].attributes?.users?.data;
      let teamsMemberString = '';
      if (teamsMember?.length > 0) {
        teamsMemberString = teamsMember
          .map(
            (people) =>
              `${people?.attributes?.firstName || ''} ${people.attributes
                .lastName || ''}`
          )
          .join(', ');
      } else {
        teamsMemberString = t.common.na;
      }
      teams_data.teamMember = teamsMemberString;

      teams_data.createdBy = fileData[i]?.attributes?.createdByUser?.data
        ? fileData[i]?.attributes?.createdByUser.data?.attributes?.firstName +
          ' ' +
          fileData[i]?.attributes?.createdByUser?.data?.attributes?.lastName
        : t.common.na;

      csvData.push([
        teams_data.id,
        teams_data.name,
        // teams_data.description,
        teams_data.superior,
        teams_data.locations,
        teams_data.customer,
        teams_data.teamMember,
        // teams_data.createdBy,
      ]);
    }
    let csvColumnWidths = [
      { wch: 5 }, // ID column
      { wch: 20 }, // Title column
      { wch: 25 }, // Description column
      { wch: 25 }, // Category column
      { wch: 35 }, // Procedure Items column
      { wch: 35 }, // Created By column
      { wch: 35 }, // Created At column
      { wch: 25 }, // Updated At column
    ];
    DownloadExcelFileWithData(headers, csvData, csvColumnWidths, t.teams.title);
  };

  const generatePDF = async () => {
    const hasValues = Object.values(filterOptions).some(
      (option) => option.length > 0
    );

    if (!hasValues && searchTitle == '') {
      let mydata = await dispatch(fetchAllTeamsData({ organisationId, userRole, currentUserId }));

      if (mydata.payload.response) {
        fileData = mydata.payload.response;
      } else {
        toast.error(t.common.something_wrong);
      }
    }
    let csvData = [];

    for (let i = 0; i < fileData.length; i++) {
      let teams_data = [];

      teams_data.id = fileData[i].id;
      teams_data.name = fileData[i].attributes.name || t.common.na;

      teams_data.description = fileData[i]?.attributes?.description
        ? fileData[i].attributes.description.replace(/\s+/g, ' ').trim()
        : t.common.na;
      teams_data.superior = fileData[i]?.attributes?.superior?.data
        ? fileData[i]?.attributes.superior?.data?.attributes?.firstName +
          ' ' +
          fileData[i]?.attributes.superior?.data?.attributes?.lastName
        : t.common.na;

      let locationsData = fileData[i].attributes.locations?.data;
      let locationsString = '';

      if (locationsData?.length > 0) {
        locationsString = locationsData
          .map((each) => each.attributes?.name)
          .join(', ');
      } else {
        locationsString = t.common.na;
      }

      teams_data.locations = locationsString;

      let assigneePeople = fileData[i].attributes?.customers?.data;
      let assignString = '';

      if (assigneePeople?.length > 0) {
        assignString = assigneePeople
          .map((people) => people?.attributes?.name)
          .join(', ');
      } else {
        assignString = t.common.na;
      }
      teams_data.customer = assignString;

      let teamsMember = fileData[i].attributes?.users?.data;
      let teamsMemberString = '';
      if (teamsMember?.length > 0) {
        teamsMemberString = teamsMember
          .map(
            (people) =>
              `${people?.attributes?.firstName || ''} ${people.attributes
                .lastName || ''}`
          )
          .join(', ');
      } else {
        teamsMemberString = t.common.na;
      }
      teams_data.teamMember = teamsMemberString;

      teams_data.createdBy = fileData[i]?.attributes?.createdByUser?.data
        ? fileData[i]?.attributes?.createdByUser.data?.attributes?.firstName +
          ' ' +
          fileData[i]?.attributes?.createdByUser?.data?.attributes?.lastName
        : t.common.na;

      csvData.push([
        teams_data.id,
        teams_data.name,
        // teams_data.description,
        teams_data.superior,
        teams_data.locations,
        teams_data.customer,
        teams_data.teamMember,
        // teams_data.createdBy,
      ]);
    }
    const columnWidths = [, 30, 30];

    // const columnWidths = [
    //   10,
    //   20,
    //   ,
    //   20,
    //   50,
    //   20,
    //   22,
    //   15,
    //   20,
    //   ,
    //   ,
    //   25,
    //   ,
    //   ,
    //   ,
    //   45,
    //   20,
    // ];
    DownloadPdfFileWithData(headers, csvData, t.teams.title, columnWidths, 'A3');
  };

  return (
    <>
      <div className="filters">
        <div className="text-right mb-5 pb-5 d-flex justify-content-between align-items-center views">
          <div className="list-view-option">
            <p className={`single-view ${role == "requester" ? "d-none" : ""}`}>
              <NavLink to="/people">{t.people.title}</NavLink>
            </p>
            <p className="single-view">
              <NavLink to="/teams">{t.people.filter.teams}</NavLink>
            </p>
          </div>

          {data?.length > 0 ? (
            <div className="table-searchbar d-flex justify-content-end">
              <div className="work-order-upload">
                <span className="search-svg">
                  <SearchIcon />
                </span>
                <Input
                  icon={{ name: 'search' }}
                  placeholder={t.teams.filter.search}
                  value={searchTitle}
                  onChange={(e) => setSearchTitle(e.target.value)}
                />
              </div>
            </div>
          ) : null}
        </div>
      </div>
      {data?.length > 0 ? (
        <div className="filters-wrapper justify-content-between">
          <div className="flexbox table-right-dropdown d-flex ">
            {/* <PriorityDropdown
            title={t.people.filter.role}
            options={filtersTrigger.role.options}
            pill={false}
            selectedValues={filterOptions.role}
            onChange={(e) => {
              if (e.target.checked) {
                setFilterOptions({
                  ...filterOptions,
                  role: e.target.value ? [e.target.value] : [],
                });
              } else {
                setFilterOptions({
                  ...filterOptions,
                  role: [],
                });
              }
            }}
            icon={false}
            image={false}
          /> */}

            {/* Role */}
            {/* <Dropdown
              text={rolePlaceholder}
              icon="user"
              style={{
                marginRight: '10px',
                display: 'flex',
                alignItems: 'center',
              }}
              floating
              labeled
              button
              basic
              multiple
              className="icon"
              open={openRole}
              onOpen={() => {
                closeAllFilters();
                setOpenRole(true);
              }}
            >
              <Dropdown.Menu>
                <Dropdown.Header content={t.people.filter.select_role} />
                <Dropdown.Divider />

                {filtersTrigger.role.options.map((option) => (
                  <Dropdown.Item
                    key={option.key}
                    text={option.text}
                    selected={filterOptions.role.includes(option.value)}
                    onClick={() => {
                      const newArray = toggle(filterOptions.role, option.value);
                      setFilterOptions({
                        ...filterOptions,
                        role: newArray,
                      });
                    }}
                  />
                ))}
              </Dropdown.Menu>
            </Dropdown> */}

            {/* <WorkOrderSearchDropdown
              title={t.teams.add}
              options={filtersTrigger.teams.options}
              selectedValues={filterOptions.teams}
              searchValue={filterSearch.teams}
              handleSearchValue={handleSearchTeamsChange}
              onClick={(value) => {
                let newArray = toggle(filterOptions.teams, value.value);

                setFilterOptions({
                  ...filterOptions,
                  teams: newArray,
                });

                setFilterSearch({
                  ...filterSearch,
                  teams: '',
                });
              }}
            /> */}

            {/* supervisor */}
            <WorkOrderSearchDropdown
              title={t.people.table.supervisor}
              options={filtersTrigger.superior.options}
              selectedValues={filterOptions.superior}
              searchValue={filterSearch.superior}
              handleSearchValue={handleSearchSupervisorChange}
              onClick={(value) => {
                let newArray = toggle(filterOptions.superior, value.value);

                setFilterOptions({
                  ...filterOptions,
                  superior: newArray,
                });

                setFilterSearch({
                  ...filterSearch,
                  superior: '',
                });
              }}
            />

            {/* <Dropdown
            icon="bullhorn"
            floating
            labeled
            button
            basic
            multiple
            className="icon"
            text={supervisorsPlaceholder}
            open={openSupervisor}
            style={{ marginRight: '10px' }}
            onOpen={() => {
              closeAllFilters();
              setOpenSupervisor(true);
              fetchSupervisorsOptions();
            }}
          >
            <Dropdown.Menu>
              <Input
                autoComplete="new-password"
                icon="search"
                iconPosition="left"
                className="search"
                value={filterSearch.supervisors}
                onChange={handleSearchSupervisorChange}
              />
              <Dropdown.Divider />
              <Dropdown.Header content={t.people.filter.select_supervisors} />
              <Dropdown.Menu scrolling>
                {filtersTrigger.supervisors.options.map((option) => (
                  <Dropdown.Item
                    key={option.key}
                    {...option}
                    selected={filterOptions.supervisors.includes(option.value)}
                    onClick={() => {
                      const newArray = toggle(
                        filterOptions.supervisors,
                        option.value
                      );

                      setFilterOptions({
                        ...filterOptions,
                        supervisors: newArray,
                      });

                      setFilterSearch({
                        ...filterSearch,
                        supervisors: '',
                      });
                    }}
                  />
                ))}
              </Dropdown.Menu>
            </Dropdown.Menu>
          </Dropdown> */}

            {/* teams */}
            {/* <Dropdown
            icon="users"
            floating
            labeled
            button
            basic
            multiple
            className="icon"
            text={teamsPlaceholder}
            open={openTeams}
            style={{ marginRight: '10px' }}
            onOpen={() => {
              closeAllFilters();
              setOpenTeams(true);
              fetchTeamsOptions();
            }}
          >
            <Dropdown.Menu>
              <Input
                autoComplete="new-password"
                icon="search"
                iconPosition="left"
                className="search"
                value={filterSearch.teams}
                onChange={handleSearchTeamsChange}
              />
              <Dropdown.Divider />
              <Dropdown.Header content={t.people.filter.select_teams} />
              <Dropdown.Menu scrolling>
                {filtersTrigger.teams.options.map((option) => (
                  <Dropdown.Item
                    key={option.key}
                    {...option}
                    selected={filterOptions.teams.includes(option.value)}
                    onClick={() => {
                      const newArray = toggle(
                        filterOptions.teams,
                        option.value
                      );

                      setFilterOptions({
                        ...filterOptions,
                        teams: newArray,
                      });

                      setFilterSearch({
                        ...filterSearch,
                        teams: '',
                      });
                    }}
                  />
                ))}
              </Dropdown.Menu>
            </Dropdown.Menu>
          </Dropdown> */}

            {/* Rate */}
            {/* <Popup
            key={1}
            on="click"
            pinned
            open={openRate}
            position="bottom right"
            id="ratePopup"
            onClick={(e) => e.stopPropagation()}
            trigger={
              <Label
                basic
                onClick={() => setOpenRate(!openRate)}
                className="popup-filter"
              >
                {selectedLanguage === 'en' ? (
                  <Icon name="dollar sign" color="#878c90" />
                ) : null}{' '}
                {t.people.filter.rate}
                {selectedLanguage === 'ar' ? (
                  <Icon name="dollar sign" color="#878c90" />
                ) : null}
              </Label>
            }
            className={`${selectedLanguage == 'ar' ? 'asset-filter-rtl' : ''}`}
          >
            <div
              className="flexbox"
              style={{ minWidth: '200px' }}
              id="ratePopup"
            >
              <Form.Field id="ratePopup" style={{ width: '100%' }}>
                <label className="label-item" id="ratePopup">
                  {t.people.filter.from}
                </label>
                <Input
                  id="ratePopup"
                  autoComplete="new-password"
                  fluid
                  type="number"
                  placeholder={t.people.filter.from}
                  value={filterOptions.rate.from}
                  onChange={(e) =>
                    setFilterOptions({
                      ...filterOptions,
                      rate: {
                        ...filterOptions.rate,
                        from: e.target.value,
                      },
                    })
                  }
                />
              </Form.Field>
              <Form.Field
                id="ratePopup"
                style={{ width: '100%', marginLeft: 2 }}
              >
                <label id="ratePopup" className="label-item">
                  {t.people.filter.to}
                </label>
                <Input
                  id="ratePopup"
                  autoComplete="new-password"
                  fluid
                  type="number"
                  placeholder={t.people.filter.to}
                  value={filterOptions.rate.to}
                  onChange={(e) =>
                    setFilterOptions({
                      ...filterOptions,
                      rate: {
                        ...filterOptions.rate,
                        to: e.target.value,
                      },
                    })
                  }
                />
              </Form.Field>
            </div>
            <div className="filter-button-container">
              <Button
                color="black"
                id="ratePopup"
                onClick={(e) => {
                  setFilterOptions({
                    ...filterOptions,
                    rate: {
                      from: null,
                      to: null,
                    },
                  });

                  // console.log(filterOptions);
                }}
              >
                {t.people.filter.reset}
              </Button>
            </div>
          </Popup> */}

            {/* <PriorityDropdown
            title={t.people.filter.status}
            options={filtersTrigger.status.options}
            pill={false}
            selectedValues={filterOptions.status}
            onChange={(e) => {
              if (e.target.checked) {
                setFilterOptions({
                  ...filterOptions,
                  status: e.target.value ? [e.target.value] : [],
                });
              } else {
                setFilterOptions({
                  ...filterOptions,
                  status: [],
                });
              }
            }}
            icon={false}
            image={false}
          /> */}
            {/* Contract End */}
            {/* <Popup
            key={2}
            on="click"
            pinned
            open={openContractEnd}
            position="bottom right"
            id="contractEndPopup"
            onClick={(e) => e.stopPropagation()}
            trigger={
              <Label
                basic
                onClick={() => setOpenContractEnd(!openContractEnd)}
                className="popup-filter"
              >
                {selectedLanguage == 'en' ? (
                  <Icon name="calendar" color="#878c90" />
                ) : null}
                {t.people.filter.contract_expiry_date}
                {selectedLanguage == 'ar' ? (
                  <Icon name="calendar" color="#878c90" />
                ) : null}
              </Label>
            }
            className={`${selectedLanguage == 'ar' ? 'asset-filter-rtl' : ''}`}
          >
            <div
              className="flexbox"
              style={{ minWidth: '300px' }}
              id="contractEndPopup"
            >
              <Form.Field id="contractEndPopup" style={{ width: '100%' }}>
                <label className="label-item" id="contractEndPopup">
                  {t.people.filter.from}
                </label>
                <Input
                  id="contractEndPopup"
                  autoComplete="new-password"
                  fluid
                  type="date"
                  placeholder={t.people.filter.from}
                  value={filterOptions.contractEnd.from}
                  onChange={(e) =>
                    setFilterOptions({
                      ...filterOptions,
                      contractEnd: {
                        ...filterOptions.contractEnd,
                        from: e.target.value,
                      },
                    })
                  }
                />
              </Form.Field>
              <Form.Field
                id="contractEndPopup"
                style={{ width: '100%', marginLeft: 2 }}
              >
                <label id="contractEndPopup" className="label-item">
                  {t.people.filter.to}
                </label>
                <Input
                  id="contractEndPopup"
                  autoComplete="new-password"
                  fluid
                  type="date"
                  placeholder={t.people.filter.to}
                  value={filterOptions.contractEnd.to}
                  onChange={(e) =>
                    setFilterOptions({
                      ...filterOptions,
                      contractEnd: {
                        ...filterOptions.contractEnd,
                        to: e.target.value,
                      },
                    })
                  }
                />
              </Form.Field>
            </div>
            <div className="filter-button-container">
              <Button
                color="black"
                id="contractEndPopup"
                onClick={(e) => {
                  setFilterOptions({
                    ...filterOptions,
                    contractEnd: {
                      from: null,
                      to: null,
                    },
                  });

                  // console.log(filterOptions);
                }}
              >
                {t.people.filter.reset}
              </Button>
            </div>
          </Popup> */}

            {/* Status */}
            {/* <Dropdown
            text={statusPlaceholder}
            icon="question circle"
            floating
            labeled
            button
            basic
            multiple
            className="icon"
            style={{ display: 'flex', alignItems: 'center' }}
            open={openStatus}
            onOpen={() => {
              closeAllFilters();
              setOpenStatus(true);
            }}
          >
            <Dropdown.Menu>
              <Dropdown.Header content={t.people.filter.select_status} />
              <Dropdown.Divider />

              {filtersTrigger.status.options.map((option) => (
                <Dropdown.Item
                  key={option.key}
                  label={{ color: option.color, empty: true, circular: true }}
                  text={option.text}
                  selected={filterOptions.status == option.value}
                  onClick={() => {
                    setFilterOptions({
                      ...filterOptions,
                      status:
                        filterOptions.status == option.value
                          ? null
                          : option.value,
                    });
                  }}
                />
              ))}
            </Dropdown.Menu>
          </Dropdown> */}

            <CategoryDropdown
              title={t.teams.team_info_form.customer}
              issearch={true}
              options={filtersTrigger.customers.options}
              searchValue={filterSearch.customer}
              handleSearchValue={handleSearchAssigneesChange}
              selectedValues={filterOptions.customers}
              onChange={(e) => {
                if (e.target.checked) {
                  setFilterOptions({
                    ...filterOptions,
                    customers: [...filterOptions.customers, e.target.value],
                  });
                  // setFilterSearch({
                  //   ...filterSearch,
                  //   customer: '',
                  // });
                } else {
                  let filteredLocations = filterOptions.customers.filter(
                    (el) => el !== e.target.value
                  );
                  setFilterOptions({
                    ...filterOptions,
                    customers: filteredLocations,
                  });
                  // setFilterSearch({
                  //   ...filterSearch,
                  //   customer: '',
                  // });
                }
              }}
              icon={false}
              image={true}
            />

            <CategoryDropdown
              issearch={true}
              title={t.teams.team_info_form.location}
              options={filtersTrigger.locations.options}
              selectedValues={filterOptions.locations}
              searchValue={filterSearch.location}
              handleSearchValue={handleSearchLocationChange}
              onChange={(e) => {
                if (e.target.checked) {
                  setFilterOptions({
                    ...filterOptions,
                    locations: [...filterOptions.locations, e.target.value],
                  });
                } else {
                  let filteredLocations = filterOptions.locations.filter(
                    (el) => el !== e.target.value
                  );
                  setFilterOptions({
                    ...filterOptions,
                    locations: filteredLocations,
                  });
                }
              }}
              icon={false}
              image={false}
            />
          </div>

          <div className="custom-menu-dropdown ">
            <UncontrolledDropdown className="mr-2" direction="down">
              <DropdownToggle color="primary" className="option-toggle">
                {t.common.page_action} <WhiteDownChevron />{' '}
              </DropdownToggle>
              <DropdownMenu className={selectedLanguage == 'ar' ? 'rtl' : ''}>
                {/* {role !== 'view_only' ? (
                  <>
                    <DropdownItem>
                      <ShareIcon /> {t.common.share}
                    </DropdownItem>
                    <DropdownItem className="divider" />
                  </>
                ) : null} */}
                <DropdownItem className="divider" />
                <DropdownItem onClick={() => downloadXLS()}>
                  <DownloadIcon /> {t.common.download_sheet}
                </DropdownItem>
                {/* <DropdownItem className="divider" />
                <DropdownItem onClick={() => generatePDF()}>
                  <PdfIcon /> {t.common.download_pdf}
                </DropdownItem> */}
                {/* <DropdownItem onClick={() => setAssetUploadModal(true)}>
                <UploadIcon /> Upload
              </DropdownItem> */}
              </DropdownMenu>
            </UncontrolledDropdown>
          </div>
        </div>
      ) : null}
    </>
  );
};

export default Filters;
