import React, { useEffect, useState } from 'react';
import { Dimmer, Loader } from 'semantic-ui-react';

import {
  createNewPurchaseOrder,
  createNewSet,
  getActiveUsersByRole,
  getAllAreaLocations,
  getAllVendros,
  uploadFile,
} from '../../config/functions';

import { useDispatch, useSelector } from 'react-redux';
import { useCancelModal } from '../../../hooks/Messages/useCancelModal';

import { selectTranslations } from '../../config/i18n/slice';
import { useHistory } from 'react-router';

import { toast } from 'react-toastify';

import { CloseIcon } from '../../../Assets/Icons/svg';
import ToolTip from 'react-power-tooltip';

import { fetchSetsInitData } from '../../../redux/reducers/setsReducer';
import { cloneDeep } from 'lodash';
import useNewCancelModal from '../../../hooks/Messages/useNewCncelModal';
import PurchaseForm from './purchaseForm';
import { LetteredAvatarImageUrl } from '../../components/common/userProfilePlaceholder';
import { fetchProcedureInitData } from '../../../redux/reducers/procedureReducer';
import { fetchPOInitData } from '../../../redux/reducers/purchaseOrderReducer';
import { Resizable } from 're-resizable';
import { FormatPaint } from '@material-ui/icons';
import { Spinner } from 'reactstrap';

function Add({
  toggled,
  untoggle,
  untoggleModal,
  setFilterOptions,
  setDueDates,
  setSelectedDates,
  setSearchTitle,
  POData = [],
  untogglePrevModal,
  fromPart = false,
  modalWidth,
  setModalWidth
}) {
  const t = useSelector(selectTranslations);
  const organisationId = useSelector((state) => {
    if (state?.auth?.user?.organisation) {
      return state.auth.user.organisation.id;
    } else {
      return null;
    }
  });
  const [selectedTablePartData, setSelectedTablePartData] = useState(POData);
  const [prevPartsData, setPrevPartsData] = useState([]);
  const [allPartsData, setAllPartsData] = useState([]);

  const { setNewCancelModal, NewCancelModal } = useNewCancelModal();


  const [attachments, setAttachments] = useState({
    warrantyFile: [],
  });
  const [options, setOptions] = useState({
    locations: [],
    area: [],
    vendor: [],
    people: [],
  });

  const [toggleAsideModal, setToggleAsideModal] = useState(false);
  const selectedLanguage = useSelector((state) => {
    if (state?.i18n?.lang) {
      return state.i18n.lang;
    } else {
      return 'en';
    }
  });

  const currentUserId = useSelector((state) => {
    if (state?.auth?.user?.id) {
      return state.auth.user.id;
    } else {
      return null;
    }
  });

  const [modalAddWidth, setModalAddWidth] = useState(
    fromPart ? modalWidth :   Math.max(window.innerWidth / 2, 514)
  );

  const { initData } = useSelector((state) => ({
    initData: state.purchaseOrder.POInitData,
  }));

  const history = useHistory();

/*
 * The function fetches vendor data, processes it, and sets the options for vendors.
 */
  const fetchVendorsData = async () => {
    const fetchData = await getAllVendros(organisationId);
    if (fetchData.response) {
      let the_options = fetchData.response.map((el) => {
        const the_image = el.attributes.logo.data
          ? el.attributes.logo.data.attributes.url
          : LetteredAvatarImageUrl(el.attributes.name);
        return {
          key: el.id,
          value: el.id,
          text: el.attributes.name,
          image: { avatar: true, src: the_image, size: 'tiny' },
          address: el?.attributes?.billingInformation?.address,
        };
      });
      setOptions({
        ...options,
        vendor: the_options,
      });
    }
  };

/*
 * The fetchPeopleData function fetches active user data by role and maps it to create options for a dropdown menu with user information and profile images.
 */
  const fetchPeopleData = async () => {
    const fetchData = await getActiveUsersByRole(
      ['limited_admin', 'admin', 'user'],
      organisationId
    );
    if (fetchData.data) {
      let the_options = fetchData.data.map((el) => {
        const the_image = el.profileImage?.url
          ?  el.profileImage?.url
          : LetteredAvatarImageUrl(el?.firstName + ' ' + el.lastName);
        return {
          key: el.id,
          value: el.id,
          text:
            el.firstName +
            ' ' +
            el.lastName +
            ' (' +
            t.people.roles[el.role.name] +
            ')',
          // description: `${teams_string_array.join(' , ')}`,
          image: { avatar: true, src: the_image, size: 'tiny' },
        };
      });
      setOptions((prevOptions) => ({
        ...prevOptions,
        people: the_options,
      }));

      return the_options;
    }
  };

/*
 * The fetchLocations function retrieves area locations data and updates the options state with thefetched information.
 */
  const fetchLocations = async () => {
    let the_options_location = [];
    const fetchDataLocation = await getAllAreaLocations(organisationId);

    if (fetchDataLocation) {
      fetchDataLocation.forEach((el) => {
        the_options_location.push({
          key: `${el.id}`,
          value: el.id,
          text: `${el.attributes.name}`,
          area: el?.attributes?.areas?.data || [],
          address: el?.attributes?.address || '',
        });
      });
    }

    setOptions({
      ...options,
      locations: the_options_location,
    });
  };

/*
 * The fetchArea function retrieves area options based on a selected location ID and updates the options state with the filtered area options that are designated as stores.
 */
  const fetchArea = async (id, index) => {
    let currentSelectedLocation = options.locations.find(
      (location) => location.value == id
    );

    let the_options_area = [];
    if (currentSelectedLocation) {
      currentSelectedLocation.area.forEach((el) => {
        if (el.attributes.isStore)
          the_options_area.push({
            key: `${el.id}`,
            value: el.id,
            text: `${el.attributes.name}`,
          });
      });
    }

    setOptions((prev) => {
      return {
        ...prev,

        area: the_options_area,
      };
    });

    // setOptions({
    //   ...options,
    //   area: areaOptions,
    // });
  };

  useEffect(() => {
    if (toggled) {
      fetchLocations();
    }
  }, [toggled]);

  const [partsData, setPartsData] = useState([]);
  const [initPartsData, setInitPartsData] = useState([]);
  const [initSetsData, setInitSetsData] = useState([]);
  const [initialData, setInitialData] = useState({
    details: {
      title: '',
      poNumber: '',
      vendor: '',
      address: '',
      people: [],
      dueDate: null,
      additionalInformation: '',
    },
    orderItems: {
      items: POData?.length > 0 ? POData : [],
      taxes: null,
      taxAmt: null,
      shipAmt: null,
      otherAmt: null,
      shippingCost: null,
      otherCost: null,
      subTotal: POData?.length > 0 ? POData[0]?.totalCost : null,
      totalCost: POData?.length > 0 ? POData[0]?.totalCost : null,
      shippingCostInPercentage: false,
      taxesInPercentage: false,
      otherCostInPercentage: false,
    },

    shippingAddress: {
      companyName: '',
      area: '',
      contactPhoneNumber: null,
      address: '',
      state: '',
      zipcode: null,
    },
    billingAddress: {
      companyName: '',
      contactPhoneNumber: null,
      address: '',
      sameAsShippingAddress: true,
    },
  });

/*
 * The function `uploadFilessHandler` filters and maps files that have been changed before uploading them, returning the upload response or false if no files are changed.
 */
  const uploadFilessHandler = async (files = []) => {
    // console.log(files);
    let theFiles = files.filter((el) => el.isChanged);
    theFiles = theFiles.map((el) => el.file);
    if (theFiles.length > 0) {
      let reponse = await uploadFile(theFiles);
      return reponse;
    } else {
      return false;
    }
  };

  const [setsData, setSetsData] = useState([]);

  const [initChange, setInitChange] = useState(false);

  const { setCancelModal, CancelModal } = useCancelModal();
  const [showCancleTooltip, setCancleTooltip] = useState(false);
  const [buttonStatus, setButtonStatus] = useState(0);
  const [cancelModal, toggleCancelModal] = useState(false);

  const dispatch = useDispatch();

  const userRole = useSelector((state) => {
    if (state?.auth?.user?.role?.id) {
      return state?.auth?.user?.role?.type;
    } else {
      return null;
    }
  });

  // useEffect(() => {
  //   setInitialData({
  //     name: '',
  //     parts: [],
  //     sets: [],
  //   })

  // }, [])

/*
 * The `handleAddSet` function processes and submits a new purchase order with validation checks and error handling.
 */
  const handleAddSet = async (values, resetForm) => {
    let addedSetsData = cloneDeep(values);
    const numParts = addedSetsData.orderItems.items;
    setButtonStatus(1);
    if (numParts.length >= 1) {
      const transformedItemsArray = addedSetsData.orderItems.items.map(
        (item) => ({
          part: item.id,
          qtyToOrder: Number(item.quantity),
          unitCost: Number(item.unitCost),
          totalCost: Number(item.totalCost),
          fillToMax: item?.fillMax ? item?.fillMax : false,
          // totalCost: item.totalCost,
        })
      );

      addedSetsData.orderItems.items = transformedItemsArray;

     
      // addedSetsData.details.poNumber = addedSetsData.details.poNumber || null;

      const transformedData = {
        details: addedSetsData.details,
        orderItems: addedSetsData.orderItems,
        shippingAddress: addedSetsData.shippingAddress,
        billingAddress: addedSetsData.billingAddress,
      };

      const inventoryFilesReponse =
        attachments.warrantyFile && attachments?.warrantyFile?.length
          ? await uploadFilessHandler(attachments.warrantyFile)
          : null;

      let files =
        inventoryFilesReponse?.status == 200
          ? inventoryFilesReponse.data.map((el) => el.id)
          : null;

      const response = await createNewPurchaseOrder(
        transformedData.details,
        transformedData.orderItems,
        transformedData.shippingAddress,
        transformedData.billingAddress,
        organisationId,
        currentUserId,
        files
      );

      if (response.status == 200) {
        resetForm();

        if(fromPart){
          setModalWidth(Math.max(window.innerWidth / 2, 514));
          setModalAddWidth(Math.max(window.innerWidth / 2, 514));
        }else{

          setModalAddWidth(Math.max(window.innerWidth / 2, 514));
        }
        setButtonStatus(0);

        // setRefresh(!refresh);

        // setFilterOptions({
        //   vendors: [],
        //   totalCost: {
        //     from: null,
        //     to: null,
        //   },
        //   status: [],
        //   submissionDate: [],
        //   dueDate: [],
        // });
        if (fromPart) {
          untoggleModal();
          untogglePrevModal();
          return history.push(`/parts?id=${POData[0]?.countId}`);
        }
        setSelectedDates([]);
        setDueDates([]);
        untoggle();
        toast.success(t.po.form.add_success);

        if (initData?.length == 0) {
          const fetchData = await dispatch(fetchPOInitData(organisationId));
        }

        // toggleSuccessModal(true);
      } else if (response.status == 400) {
        setButtonStatus(0);
        if (response.error && response.error.message) {
          toast.error(response.error.message);
          // untoggle();
        } else {
          toast.error(t.common.something_wrong);

          if (fromPart) {
            untoggleModal();
          } else {
            untoggle();
          }
        }
      } else {
        setButtonStatus(0);
        toast.error(t.common.something_wrong);
      }
    } else {
      setButtonStatus(0);
      toast.error(t.po.form.add_valiadtion);
    }
    setButtonStatus(0);
  };

/*
 * The `cancelHandler` function resets initial data, adjusts modal width, toggles modals, and sets flags to false.
 */
  const cancelHandler = () => {
    setInitialData({
      name: '',
      parts: [],
      sets: [],
    });

    if(fromPart){
      
      setModalWidth(Math.max(window.innerWidth / 2, 514));
      setModalAddWidth(Math.max(window.innerWidth / 2, 514))
    }else{
      setModalAddWidth(Math.max(window.innerWidth / 2, 514));
    }
    if (fromPart) {
      untoggleModal();
    } else {
      untoggle();
    }
    setNewCancelModal(false);
    setInitChange(false);
  };

  return (
    <>
      <div
        style={{ maxWidth: fromPart ? `${modalWidth}px` : `${modalAddWidth}px` }}
        clearing
        className={
          toggled && !toggleAsideModal
            ? 'aside-modal po active procedure'
            : 'aside-modal po procedure'
        }
      >
        {false ? (
          <>
            {' '}
            <div className="modal-header">
              <div className="flexbox justify-between">
                <div className="name">{t.po.add}</div>
                <div>
                  <span
                    className="c-pointer"
                    style={{ position: 'relative' }}
                    onMouseOver={() => setCancleTooltip(true)}
                    onMouseLeave={() => setCancleTooltip(false)}
                    onClick={() =>
                      initChange ? setNewCancelModal(true) : cancelHandler()
                    }
                  >
                    <CloseIcon />
                    <ToolTip
                      color="#fff"
                      backgroundColor="#172B4D"
                      position={
                        selectedLanguage == 'en'
                          ? 'bottom center'
                          : 'bottom center'
                      }
                      arrowAlign="center"
                      show={showCancleTooltip}
                      textBoxWidth="36px"
                      padding="5px 5px 5px 7px"
                      borderRadius="5px"
                      static
                    >
                      <span className="work-order-preview-tooltip">
                        {t.common.close}
                      </span>
                    </ToolTip>
                  </span>
                </div>
              </div>
            </div>
            
            {initialData && (
              <PurchaseForm
                initialFormValues={initialData}
                selectedTablePartData={selectedTablePartData}
                setSelectedTablePartData={setSelectedTablePartData}
                allPartsData={allPartsData}
                setAllPartsData={setAllPartsData}
                setPrevPartsData={setPrevPartsData}
                prevPartsData={prevPartsData}
                initChange={initChange}
                setInitChange={setInitChange}
                buttonStatus={buttonStatus}
                cancelHandler={cancelHandler}
                setNewCancelModal={setNewCancelModal}
                handleAddSet={handleAddSet}
                setOptions={setOptions}
                fetchArea={fetchArea}
                fetchLocations={fetchLocations}
                fetchVendorsData={fetchVendorsData}
                fetchPeopleData={fetchPeopleData}
                options={options}
                setPartsData={setPartsData}
                partsData={partsData}
                setInitPartsData={setInitPartsData}
                initPartsData={initPartsData}
                setInitSetsData={setInitSetsData}
                initSetsData={initSetsData}
                setSetsData={setSetsData}
                setsData={setsData}
                setAttachments={setAttachments}
                attachments={attachments}
                setToggleAsideModal={setToggleAsideModal}
                toggleAsideModal={toggleAsideModal}
              />
            )}
          </>
        ) : (
          <Resizable
            size={{ width:fromPart ? modalWidth : modalAddWidth, height: 'auto' }}
            minWidth={'550px'}
            onResize={(e, direction, ref, d) => {
              if (fromPart) {
                setModalWidth(ref.offsetWidth);
                setModalAddWidth(ref.offsetWidth);
              } else {
                setModalAddWidth(ref.offsetWidth);
              }
            }}
          >
            <div className="modal-header">
              <div className="flexbox justify-between">
                <div className="name">{t.po.add}</div>
                <div>
                  <span
                    className="c-pointer"
                    style={{ position: 'relative' }}
                    onMouseOver={() => setCancleTooltip(true)}
                    onMouseLeave={() => setCancleTooltip(false)}
                    onClick={() =>
                      initChange ? setNewCancelModal(true) : cancelHandler()
                    }
                  >
                    <CloseIcon />
                    <ToolTip
                      color="#fff"
                      backgroundColor="#172B4D"
                      position={
                        selectedLanguage == 'en'
                          ? 'bottom center'
                          : 'bottom center'
                      }
                      arrowAlign="center"
                      show={showCancleTooltip}
                      textBoxWidth="36px"
                      padding="5px 5px 5px 7px"
                      borderRadius="5px"
                      static
                    >
                      <span className="work-order-preview-tooltip">
                        {t.common.close}
                      </span>
                    </ToolTip>
                  </span>
                </div>
              </div>
            </div>

            {initialData && (
              <PurchaseForm
                initialFormValues={initialData}
                selectedTablePartData={selectedTablePartData}
                setSelectedTablePartData={setSelectedTablePartData}
                allPartsData={allPartsData}
                setAllPartsData={setAllPartsData}
                setPrevPartsData={setPrevPartsData}
                prevPartsData={prevPartsData}
                initChange={initChange}
                setInitChange={setInitChange}
                buttonStatus={buttonStatus}
                cancelHandler={cancelHandler}
                setNewCancelModal={setNewCancelModal}
                handleAddSet={handleAddSet}
                setOptions={setOptions}
                fetchArea={fetchArea}
                fetchLocations={fetchLocations}
                fetchVendorsData={fetchVendorsData}
                fetchPeopleData={fetchPeopleData}
                options={options}
                setPartsData={setPartsData}
                partsData={partsData}
                setInitPartsData={setInitPartsData}
                initPartsData={initPartsData}
                setInitSetsData={setInitSetsData}
                initSetsData={initSetsData}
                setSetsData={setSetsData}
                setsData={setsData}
                setAttachments={setAttachments}
                attachments={attachments}
                setToggleAsideModal={setToggleAsideModal}
                toggleAsideModal={toggleAsideModal}
              />
            )}
          </Resizable>
        )}
      </div>

      <div
        className={
          toggled && !toggleAsideModal
            ? 'aside-modal-background active'
            : 'aside-modal-background'
        }
        onClick={() => (initChange ? setNewCancelModal(true) : cancelHandler())}
      ></div>

<NewCancelModal
        yesCallback={() => {
          cancelHandler();
        }}
        title={t.parts.form.changes}
      >
              <p style={{lineHeight:"24px"}}> {t.common.cancel_2statement}</p>
      </NewCancelModal>
    </>
  );
}

export default Add;
