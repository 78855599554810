import { filter } from 'lodash';
import React, { useEffect, useState, createRef, useRef } from 'react';
import _ from 'lodash';
import {
  Checkbox,
  Table,
  Button,
  Header,
  Pagination,
  Label,
  Icon,
  Input,
  Dropdown,
  Grid,
  Menu,
  DropdownMenu,
  DropdownItem,
  Popup,
  Form,
  Modal,
} from 'semantic-ui-react';

import calendar_icon from  '../../../Assets/Icons/calender-icon.svg';
import { DropdownToggle, UncontrolledDropdown } from 'reactstrap';
import { Link } from 'react-router-dom';
import QrScanner from 'qr-scanner';

import {
  getAllLocations,
  getActiveUsersByRole,
  getAllTeams,
  humanize,
  toggle,
  IMAGES_URL,
  getAllCustomers,
  getAllCategories,
  getAllParentLocations,
  getUsersByRole,
} from '../../config/functions';
import UserProfilePlaceholder, {
  LetteredAvatarImageUrl,
} from '../../components/common/userProfilePlaceholder';
import {
  CategoryElectrical,
  CategoryInspection,
  CategoryMechanical,
  CategoryRefrigeration,
  CategorySafety,
  CatgoryPreventive,
  Close,
  CloseIcon,
  CorrectiveIcon,
  Damage,
  DeleteIcon,
  DownChevron,
  DownloadIcon,
  OperationProcedure,
  PdfIcon,
  PlannedIcon,
  Project,
  ShareIcon,
  UnPlannedIcon,
  UploadIcon,
  WhiteDownChevron,
} from '../../../Assets/Icons/svg';
import { cloneDeep } from 'lodash';

import md5 from 'md5';

import moment from 'moment';

import { useSelector } from 'react-redux';
import { selectTranslations } from '../../config/i18n/slice';
import { SearchIcon } from '../../../Assets/Icons/svg';
import CategoryDropdown from '../../components/common/CategoryDropdown';
import DatePicker from 'react-multi-date-picker';
import DownloadExcelFileWithData from '../../../services/DownloadExcelFileWithData';
import DownloadPdfFileWithData from '../../../services/DownloadPdfFileWithData';
import { NavLink } from 'react-router-dom/cjs/react-router-dom.min';
import { toast } from 'react-toastify';
import QRCode from 'react-qr-code';
import PriorityDropdown from '../../components/common/PriorityDropdown';
import WorkOrderStatusDropdown from '../../components/WorkOrder/WorkOrderStatusDropdown';
import WorkOrderSearchDropdown from '../../components/WorkOrder/WorkOrderSearchDropdown';
import useDeleteResultModal from '../../../hooks/Messages/useDeleteResultModal';

const Filters = ({
  data,
  setDataByFilter,
  refresh,
  setRefresh,
  fileData = [],
  roleData,allIds=[]
}) => {
  const t = useSelector(selectTranslations);
  const lang = useSelector((state) => state.i18n.lang);
  const { setDeleteResultModal, DeleteResultModal } = useDeleteResultModal();
  const organisationId = useSelector((state) => {
    if (state?.auth?.user?.organisation) {
      return state.auth.user.organisation.id;
    } else {
      return null;
    }
  });
  const selectedLanguage = useSelector((state) => {
    if (state?.i18n?.lang) {
      return state.i18n.lang;
    } else {
      return 'en';
    }
  });

  const role = useSelector((state) => {
    if (state?.auth?.user?.role?.id) {
      return state?.auth?.user?.role?.type;
    } else {
      return null;
    }
  });

  const toggleDeleteSuccessModal = () => {
    setOpenQRmodal(true);
    setSelectedFile({});
    setFilterOptions({
      ...filterOptions,
      serialNumber: '',
    });
  };

  const [selectedDates, setSelectedDates] = useState([]);

  const fileInputRef = useRef(null);

  const [filtersTrigger, setFiltersTrigger] = useState({
    assigneeType: 'people',
    assignees: {
      people: {
        initOptions: [],
        options: [],
      },
      teams: {
        initOptions: [],
        options: [],
      },
    },
    people: {
      initOptions: [],
      options: [],
    },

    teams: {
      initOptions: [],
      options: [],
    },

    locations: {
      initOptions: [],
      options: [],
    },
    customers: {
      initOptions: [],
      options: [],
    },
    createdByUser: {
      initOptions: [],
      options: [],
    },
    dueDate: {
      options: [
        { key: 0, text: t.workOrders.due_date_status.any_date, value: 0 },
        { key: 1, text: t.workOrders.due_date_status.today, value: 1 },
        { key: 2, text: t.workOrders.due_date_status.tomorrow, value: 2 },
        { key: 3, text: t.workOrders.due_date_status.next_7_day, value: 3 },
        { key: 4, text: t.workOrders.due_date_status.next_30_day, value: 4 },
        { key: 5, text: t.workOrders.due_date_status.this_month, value: 5 },
        { key: 7, text: t.workOrders.due_date_status.this_year, value: 6 },
        { key: 8, text: t.workOrders.due_date_status.yesterday, value: 7 },
        { key: 9, text: t.workOrders.due_date_status.last_week, value: 8 },
        { key: 10, text: t.workOrders.due_date_status.last_month, value: 9 },
        { key: 12, text: t.workOrders.due_date_status.last_year, value: 10 },
        { key: 13, text: t.workOrders.due_date_status.past_due, value: 11 },
      ],
    },
    status: {
      options: [
        {
          key: 1,
          text: t.common.all,
          value: '',
          color: '',
        },
        {
          key: 1,
          text: t.workOrders.work_order_status.open,
          value: 'open',
          color: 'blue',
        },
        {
          key: 3,
          text: t.workOrders.work_order_status.inProgress,
          value: 'inProgress',
          color: 'yellow',
        },
        {
          key: 2,
          text: t.workOrders.work_order_status.onHold,
          value: 'onHold',
          color: 'red',
        },
        {
          key: 0,
          text: t.workOrders.work_order_status.inReview,
          value: 'inReview',
          color: 'black',
        },
        {
          key: 4,
          text: t.workOrders.work_order_status.completed,
          value: 'completed',
          color: 'green',
        },
        {
          key: 5,
          text: t.workOrders.work_order_status.cancel,
          value: 'cancelled',
          color: 'darkgrey',
        },
      ],
    },

    categories: {
      options: [
        {
          key: 0,
          value: 'damage',
          text: t.workOrders.form.category_options[0],
          icon:    <Damage />
        },
        {
          key: 2,
          value: 'corrective action Downtime',
          text: t.workOrders.form.category_options[1],
          icon:   <CorrectiveIcon/>
        },
        {
          key: 4,
          value: 'planned Downtime',
          text: t.workOrders.form.category_options[2],
          icon:    <PlannedIcon/>
        },
        {
          key: 10,
          value: 'unplanned Downtime',
          text: t.workOrders.form.category_options[3],
          icon:   <UnPlannedIcon/>
        },
        {
          key: 11,
          value: 'inspection',
          text: t.workOrders.form.category_options[4],
          icon: <CategoryInspection />,
        },
        {
          key: 1,
          value: 'electrical',
          text: t.workOrders.form.category_options[5],
          icon: <CategoryElectrical />,
        },

        {
          key: 3,
          value: 'mechanical',
          text: t.workOrders.form.category_options[6],
          icon: <CategoryMechanical />,
        },

        {
          key: 6,
          value: 'HVAC',
          text: t.workOrders.work_order_category.HVAC,
          icon: <CategoryRefrigeration />,
        },
        {
          key: 16,
          value: 'preventive maintenance',
          text: t.workOrders.form.category_options[8],
          icon:  <CatgoryPreventive />,
        },
        {
          key: 17,
          value: 'project',
          text: t.workOrders.form.category_options[9],
          icon:    <Project />,
        },
        {
          key: 7,
          value: 'safety',
          text: t.workOrders.form.category_options[10],
          icon: <CategorySafety />,
        },
        {
          key: 27,
          value: 'sop',
          text: t.workOrders.form.category_options[11],
          icon: <OperationProcedure />,
        },
      ],
    },
    priority: {
      options: [
        {
          key: 0,
          text: t.common.all,
          value: '',
          color: null,
        },
        {
          key: 1,
          text: t.workOrders.priority_status.low,
          value: 'low',
          color: 'blue',
        },
        {
          key: 2,
          text: t.workOrders.priority_status.medium,
          value: 'medium',
          color: 'orange',
        },
        {
          key: 3,
          text: t.workOrders.priority_status.high,
          value: 'high',
          color: 'red',
        },
      ],
    },
  });

  const [filterOptions, setFilterOptions] = useState({
    serialNumber: '',
    assignees: {
      people: [],
      teams: [],
    },
    people: [],
    teams: [],
    customers: [],
    locations: [],
    createdByUser: [],
    dueDate: {
      value: 0,
      text: 'Any Date',
    },
    status: [],
    priority: [],
    categories: [],
  });

  const [selectedWeek, setSelectedWeek] = useState({
    start: new Date(
      new Date().getFullYear(),
      new Date().getMonth(),
      new Date().getDate() - 7
    ),
    end: new Date(
      new Date().getFullYear(),
      new Date().getMonth(),
      new Date().getDate()
    ),
  });
  const [currentView, setCurrentView] = useState('dayGridMonth');

  const [filterSearch, setFilterSearch] = useState({
    location: '',
    assignee: '',
    createdByUser: '',
    teams: '',
  });

  const calendarRef = createRef();

  const [searchTitle, setSearchTitle] = useState('');
  const [openBarcode, setOpenBarcode] = useState(false);

  const [selectedFile, setSelectedFile] = useState({});

  const [newData, setNewData] = useState(data);

  const [openAssigneeType, setOpenAssigneeType] = useState(false);
  const [openAssignees, setOpenAssignees] = useState(false);
  const [openLocations, setOpenLocations] = useState(false);
  const [openDueDate, setOpenDueDate] = useState(false);
  const [openStatus, setOpenStatus] = useState(false);
  const [openPriority, setOpenPriority] = useState(false);
  const [openCreatedBy, setOpenCreatedBy] = useState(false);
  const [selectedQrcode, setSelectedQrcode] = useState(null);
  const [openQRmodal, setOpenQRmodal] = useState(false);
  const [deleteErrorMessage, setDeleteErrorMessage] = useState(false);
  const [resetKey, setResetKey] = useState(0);

  const closeAllFilters = () => {
    setOpenAssigneeType(false);
    setOpenAssignees(false);
    setOpenLocations(false);
    // setOpenBarcode(false);
    setOpenDueDate(false);
    setOpenStatus(false);
    setOpenPriority(false);
    setOpenCreatedBy(false);
  };

  /* headers for excel and pdf file */
  const headers = [
    { label: t.workOrders.table.ID, key: 'id' },
    { label: t.workOrders.table.title, key: 'title' },
    // { label: t.assets.form.description, key: 'description' },
    { label: t.workOrders.table.status, key: 'status' },
    { label: t.workOrders.table.priority, key: 'priority' },
    { label: t.workOrders.table.assignees, key: 'assignees' },
    { label: t.workOrders.table.category, key: 'category' },
    // { label: t.workOrders.form.code, key: 'code' },
    { label: t.workOrders.table.customer, key: 'customer' },
    { label: t.workOrders.table.location, key: 'location' },
    // { label: t.workOrders.form.parts, key: 'parts' },
    // { label: t.workOrders.table.procedure, key: 'procedure' },
    // { label: t.workOrders.details_view.estimation_time, key: 'estimationTime' },
    { label: t.workOrders.table.dueDate, key: 'dueDate' },
    { label: t.workOrders.table.createdByUser, key: 'created By' },
    { label: t.workOrders.form.progress, key: 'progress' },
    // { label: t.workOrders.table.createdAt, key: 'created At' },
  ];

  const categoryOptions = [
    {
      value: 'damage',
      label: t.workOrders.form.category_options[0],
    },
    {
      value: 'corrective action Downtime',
      label: t.workOrders.form.category_options[1],
    },
    {
      value: 'planned Downtime',
      label: t.workOrders.form.category_options[2],
    },
    {
      value: 'unplanned Downtime',
      label: t.workOrders.form.category_options[3],
    },
    {
      value: 'inspection',
      label: t.workOrders.form.category_options[4],
    },
    {
      value: 'electrical',
      label: t.workOrders.form.category_options[5],
    },
    {
      value: 'mechanical',
      label: t.workOrders.work_order_category.Mechanical,
    },
    {
      value: 'HVAC',
      label: t.workOrders.work_order_category.HVAC,
    },
    {
      value: 'preventive maintenance',
      label: t.workOrders.form.category_options[8],
    },
    {
      value: 'project',
      label: t.workOrders.form.category_options[9],
    },
    {
      value: 'safety',
      label: t.workOrders.work_order_category.Safety,
    },
    {
      value: 'sop',
      label: t.workOrders.form.category_options[11],
    },
  ];
  const codeOptions = [
    {
      value: 'sop',
      label: t.workOrders.work_order_category.Sop,
    },

    {
      value: 'project',
      label: t.workOrders.work_order_category.Project,
    },
    {
      value: 'preventive',
      label: t.workOrders.work_order_category.Preventive,
    },
    {
      value: 'inspection',
      label: t.workOrders.work_order_category.Inspection,
    },
    {
      value: 'damage',
      label: t.workOrders.work_order_category.Damage,
    },
  ];
  const statusOptions = [
    {
      value: 'open',
      color: 'blue',
      label: t.workOrders.work_order_status.open,
    },
    {
      value: 'cancelled',
      color: 'darkgrey',
      label: t.workOrders.work_order_status.cancel,
    },
    {
      value: 'inProgress',
      color: 'yellow',
      label: t.workOrders.work_order_status.inProgress,
    },
    {
      value: 'onHold',
      color: 'red',
      label: t.workOrders.work_order_status.onHold,
    },
    {
      value: 'inReview',
      color: 'black',
      label: t.workOrders.work_order_status.inReview,
    },
    {
      value: 'completed',
      color: 'green',
      label: t.workOrders.work_order_status.completed,
    },
  ];

  /* The function `downloadXLS` fetches WO data, formats it, and downloads it as an Excel file. */
  const downloadXLS = () => {
    let csvData = [];

    const sortedCsvData = fileData.sort((a, b) => {
      const startDate = moment(a.attributes.createdAt).format('DD-MM-YYYY LT');
      const endDate = moment(b.attributes.createdAt).format('DD-MM-YYYY LT');

      const start = moment(startDate, 'DD-MM-YYYY h:mm A').startOf('day');
      const end = moment(endDate, 'DD-MM-YYYY h:mm A').startOf('day');

      const dateDifferenceInDays = end.diff(start, 'days');
      if (dateDifferenceInDays == 0) {
        return a.attributes.category.localeCompare(b.attributes.category);
      }
      return dateDifferenceInDays;
    });

    for (let i = 0; i < sortedCsvData.length; i++) {
      let workOrder_data = [];

      workOrder_data.id = sortedCsvData[i].id;
      workOrder_data.title = sortedCsvData[i].attributes.title || t.common.na;
      workOrder_data.description = sortedCsvData[i]?.attributes?.description
        ? sortedCsvData[i].attributes.description
        : t.common.na;
      const workOrderStatus = statusOptions.find(
        (status) => status.value === sortedCsvData[i].attributes.status
      );
      workOrder_data.status = workOrderStatus.label || t.common.na;

      workOrder_data.priority =
        humanize(sortedCsvData[i].attributes.priority) || t.common.na;
      let assigneePeople = sortedCsvData[i].attributes?.people?.data;
      let assignString = '';

      if (assigneePeople?.length > 0) {
        assignString = assigneePeople
          .map(
            (people) =>
              `${people?.attributes?.firstName || ''} ${people.attributes
                .lastName || ''}`
          )
          .join(', ');
      } else {
        assignString = t.common.na;
      }

      workOrder_data.assignee = assignString;
      const procedureCompletion =
        sortedCsvData[i]?.attributes?.report?.data?.attributes
          ?.procedureCompletion;

      workOrder_data.progress =
        procedureCompletion !== undefined &&
        procedureCompletion !== null &&
        procedureCompletion !== 0
          ? `${procedureCompletion}%`
          : '0%';

      const categoryStatus = categoryOptions.find(
        (one) => one.value == sortedCsvData[i].attributes.category
      );
      workOrder_data.category = categoryStatus?.label || t.common.na;
      const codeStatus = codeOptions?.find(
        (one) => one.value == sortedCsvData[i].attributes.code
      );
      workOrder_data.code = codeStatus?.label || t.common.na;
      workOrder_data.customer = sortedCsvData[i].attributes?.customer?.data
        ?.attributes?.name
        ? sortedCsvData[i].attributes?.customer?.data?.attributes?.name
        : t.common.na;
      workOrder_data.location = sortedCsvData[i].attributes?.location?.data
        ?.attributes?.name
        ? sortedCsvData[i].attributes?.location?.data?.attributes?.name
        : t.common.na;

      workOrder_data.procedure = sortedCsvData[i].attributes?.procedure?.data
        ?.attributes?.title
        ? sortedCsvData[i].attributes?.procedure?.data?.attributes?.title
        : t.common.na;
      let partsData = sortedCsvData[i].attributes?.parts?.data;
      let partsString = '';

      if (partsData?.length > 0) {
        partsString = partsData.map((each) => each.attributes?.name).join(', ');
      } else {
        partsString = t.common.na;
      }

      workOrder_data.parts = partsString;

      workOrder_data.estimationTime = sortedCsvData[i].attributes
        ?.estimationTime
        ? `${sortedCsvData[i].attributes?.estimationTime}` + ' Hour'
        : t.common.na;

      workOrder_data.dueDate =
        moment(sortedCsvData[i].attributes.dueDate).format('DD-MM-YYYY LT') ||
        t.common.na;

      workOrder_data.createdByUser =
        sortedCsvData[i]?.attributes?.createdByUser?.data?.attributes
          ?.firstName || t.common.na;
      workOrder_data.createdAt =
        moment(sortedCsvData[i].attributes.createdAt).format('DD-MM-YYYY LT') ||
        t.common.na;

      csvData.push([
        workOrder_data.id,
        workOrder_data.title,
        // workOrder_data.description,
        workOrder_data.status,
        workOrder_data.priority,
        workOrder_data.assignee,
        workOrder_data.category,
        // workOrder_data.code,
        workOrder_data.customer,
        workOrder_data.location,
        // workOrder_data.parts,
        // workOrder_data.procedure,
        // workOrder_data.estimationTime,
        workOrder_data.dueDate,
        workOrder_data.createdByUser,
        // workOrder_data.createdAt,
        workOrder_data.progress,
      ]);
    }

    let csvColumnWidths = [
      { wch: 5 }, // ID column
      { wch: 20 }, // Title column
      { wch: 20 }, // Title column
      { wch: 35 }, // Description column
      { wch: 25 }, // Category column
      { wch: 15 }, // Procedure Items column
      { wch: 15 }, // Created By column
      { wch: 20 }, // Created At column
      { wch: 20 }, // Updated At column
      { wch: 20 }, // Updated At column
      { wch: 20 }, // Updated At column
      { wch: 20 }, // Updated At column
      { wch: 20 }, // Updated At column
      { wch: 20 }, // Updated At column
      { wch: 20 }, // Updated At column
      { wch: 20 }, // Updated At column
    ];
    DownloadExcelFileWithData(
      headers,
      csvData,
      csvColumnWidths,
      t.workOrders.title
    );
  };

  /*
   * The function `generatePDF` fetches WO data, formats it, and then downloads a PDF file with the data.
   */
  const generatePDF = () => {
    let csvData = [];
    for (let i = 0; i < fileData.length; i++) {
      let workOrder_data = {};

      workOrder_data.id = fileData[i].id;
      workOrder_data.title = fileData[i].attributes.title || t.common.na;
      const workOrderStatus = statusOptions.find(
        (status) => status.value === fileData[i].attributes.status
      );
      workOrder_data.description = fileData[i]?.attributes?.description
        ? fileData[i].attributes.description
        : t.common.na;
      workOrder_data.status = workOrderStatus?.label || t.common.na;

      workOrder_data.priority =
        humanize(fileData[i].attributes.priority) || t.common.na;
      let assigneePeople = fileData[i].attributes?.people?.data;
      let assignString = '';

      if (assigneePeople?.length > 0) {
        assignString = assigneePeople
          .map(
            (people) =>
              `${people?.attributes?.firstName || ''} ${people.attributes
                .lastName || ''}`
          )
          .join(', ');
      } else {
        assignString = t.common.na;
      }
      workOrder_data.assignee = assignString;
      const procedureCompletion =
        fileData[i]?.attributes?.report?.data?.attributes?.procedureCompletion;

      workOrder_data.progress =
        procedureCompletion !== undefined &&
        procedureCompletion !== null &&
        procedureCompletion !== 0
          ? `${procedureCompletion}%`
          : '0%';

      const categoryStatus = categoryOptions?.find(
        (one) => one.value == fileData[i].attributes.category
      );
      workOrder_data.category = categoryStatus?.label || t.common.na;

      const codeStatus = codeOptions?.find(
        (one) => one.value == fileData[i].attributes.code
      );
      workOrder_data.code = codeStatus?.label || t.common.na;
      workOrder_data.customer = fileData[i].attributes?.customer?.data
        ?.attributes?.name
        ? fileData[i].attributes?.customer?.data?.attributes?.name
        : t.common.na;
      workOrder_data.location = fileData[i].attributes?.location?.data
        ?.attributes?.name
        ? fileData[i].attributes?.location?.data?.attributes?.name
        : t.common.na;

      workOrder_data.procedure = fileData[i].attributes?.procedure?.data
        ?.attributes?.title
        ? fileData[i].attributes?.procedure?.data?.attributes?.title
        : t.common.na;
      let partsData = fileData[i].attributes?.parts?.data;
      let partsString = '';

      if (partsData?.length > 0) {
        partsString = partsData.map((each) => each.attributes?.name).join(', ');
      } else {
        partsString = t.common.na;
      }

      workOrder_data.parts = partsString;

      workOrder_data.estimationTime = fileData[i].attributes?.estimationTime
        ? `${fileData[i].attributes?.estimationTime}` + ' Hour'
        : t.common.na;

      workOrder_data.dueDate =
        moment(fileData[i].attributes.dueDate).format('DD-MM-YYYY LT') ||
        t.common.na;

      workOrder_data.createdByUser =
        fileData[i]?.attributes?.createdByUser?.data?.attributes?.firstName ||
        t.common.na;

      workOrder_data.createdAt =
        moment(fileData[i].attributes.createdAt).format('DD-MM-YYYY LT') ||
        t.common.na;

      csvData.push([
        workOrder_data.id,
        workOrder_data.title,
        // workOrder_data.description,
        workOrder_data.status,
        workOrder_data.priority,
        workOrder_data.assignee,
        workOrder_data.category,
        // workOrder_data.code,
        workOrder_data.customer,
        workOrder_data.location,
        // workOrder_data.parts,
        // workOrder_data.procedure,
        // workOrder_data.estimationTime,
        workOrder_data.dueDate,
        workOrder_data.createdByUser,
        // workOrder_data.createdAt,
        workOrder_data.progress,
      ]);
    }
    const sortedCsvData = csvData.sort((a, b) => {
      const startDate = moment(a.createdAt, 'DD-MM-YYYY h:mm A').startOf('day');
      const endDate = moment(b.createdAt, 'DD-MM-YYYY h:mm A').startOf('day');

      const dateDifferenceInDays = endDate.diff(startDate, 'days');
      if (dateDifferenceInDays == 0) {
        return a.category.localeCompare(b.category);
      }
      return dateDifferenceInDays;
    });

    const columnWidths = [10, 25, 20, 15, 50, 22, 25, 25, 25, 25, 25];
    DownloadPdfFileWithData(
      headers,
      sortedCsvData,
      t.workOrders.title,
      columnWidths,
      'A3'
    );
  };

  /**
   * The `handleQRCode` function takes a file, scans it for a QR code, extracts a numeric value from the QRcode result, and sets the id in the filter options.
   */
  const handleQRCode = (setFilterSearch, file) => {
    setSelectedQrcode(file);
    QrScanner.scanImage(file)
      .then((result) => {
        const numericPart = result.match(/\d+/)[0];
        setFilterOptions({
          ...filterOptions,
          serialNumber: numericPart,
        });
        setOpenQRmodal(false);
      })
      .catch((err) => {
        setFilterOptions({
          ...filterOptions,
          serialNumber: '',
        });
        setOpenQRmodal(false);
        setDeleteResultModal(true);

        setSelectedQrcode(null);
      });
  };

  const fetchLocationsOptions = async () => {
    closeAllFilters();

    setOpenLocations(true);
    let the_options = [];

    if (filtersTrigger.locations.initOptions.length < 1) {
      const fetchData = await getAllLocations(organisationId);
      if (fetchData) {
        the_options = fetchData.map((el) => {
          return {
            key: el.id,
            value: el.id,
            text: el.attributes.name,
          };
        });

        setFiltersTrigger({
          ...filtersTrigger,
          locations: {
            ...filtersTrigger.locations,
            initOptions: the_options,
            options: the_options,
          },
        });
      } else {
        setFiltersTrigger({
          ...filtersTrigger,
          locations: {
            ...filtersTrigger.locations,
          },
        });
      }
    }
  };

  useEffect(() => {
    const re = new RegExp(_.escapeRegExp(filterSearch.teams), 'i');
    const isMatch = (result) => {
      re.test(result.text);
    };

    let data = filtersTrigger.teams.initOptions.filter((test) =>
      re.test(test.text)
    );

    setFiltersTrigger({
      ...filtersTrigger,
      teams: {
        ...filtersTrigger.teams,
        options:
          filterSearch.teams.length > 0
            ? data
            : filtersTrigger.teams.initOptions,
      },
    });
  }, [filterSearch.teams]);

  /*
   * The function fetches active users by role and prepares options for filtering people based on their names and profile images.
   */
  const fetchPeopleOptions = async () => {
    const fetchData = await getActiveUsersByRole(
      ['user', 'supervisor', 'view_only', 'admin', 'requester', 'super_admin'],
      organisationId
    );

    if (fetchData.data) {
      let the_options = fetchData.data.map((el) => {
        return {
          key: el.id,
          value: el.id,
          text: el.firstName + ' ' + el.lastName,
          type: 'people',
          image: {
            avatar: true,
            // src: `http://gravatar.com/avatar/${md5(
            //   el.firstName + ' ' + el.lastName
            // )}?d=identicon`,
            src: el?.profileImage?.url
              ? `${IMAGES_URL}${el?.profileImage?.url}`
              : LetteredAvatarImageUrl(`${el.firstName} ${el.lastName}`),
            style: { width: '20px', height: '20px' },
          },
        };
      });
      setFiltersTrigger({
        ...filtersTrigger,
        assignees: {
          teams: {
            initOptions: [],
            options: [],
          },
          people: {
            initOptions: the_options,
            options: the_options,
          },
        },
        createdByUser: {
          initOptions: the_options,
          options: the_options,
        },
      });
    }
  };

  /*
   * The function fetchTeamsOptions fetches team options data and prepares it for display.
   */
  const fetchTeamsOptions = async () => {
    const fetchData = await getAllTeams(organisationId);

    if (fetchData.response) {
      let the_options = fetchData.response.map((el) => {
        return {
          key: el.id,
          value: el.id,
          text: el.attributes.name,
          type: 'team',
          image: {
            avatar: true,
            src: LetteredAvatarImageUrl(`${el.attributes.name}`),
            style: { width: '16px', height: '16px' },
          },
        };
      });
      setFiltersTrigger({
        ...filtersTrigger,
        assignees: {
          teams: {
            initOptions: the_options,
            options: the_options,
          },
          people: {
            initOptions: [],
            options: [],
          },
        },
      });
    }
  };

  const handleAssigneeTypeChange = (value) => {
    setFiltersTrigger({
      ...filtersTrigger,
      assigneeType: value,
    });
    if (value == 'people') {
      fetchPeopleOptions();
    }
    if (value == 'teams') {
      fetchTeamsOptions();
    }
  };

  /*
   * The `handleChange` function takes an array of dates, converts them to ISO format, and sets the selected dates.
   */
  const handleChange = (value) => {
    if (value && value.length) {
      const dateValues = value.map((date) => date.valueOf());

      const isoDates = dateValues.map((timestamp) =>
        timestamp ? new Date(timestamp).toISOString() : ''
      );
      setSelectedDates(isoDates);
    }
  };

  const handleSearchLocationChange = (e) => {
    setFilterSearch({
      ...filterSearch,
      location: e.target.value,
    });
  };

  const handleSearchTeamsChange = (e) => {
    setFilterSearch({
      ...filterSearch,
      teams: e.target.value,
    });
  };

  const handleDeselect = () => {
    // Deselect by setting an empty array
    setSelectedDates([]);
  };

  const handleSearchAssigneesChange = (e) => {
    setFilterSearch({
      ...filterSearch,
      assignee: e.target.value,
    });
  };

  const handleSearchCreatedByChange = (e) => {
    setFilterSearch({
      ...filterSearch,
      createdByUser: e.target.value,
    });
  };

  /* Fetch filters data */
  useEffect(() => {
    (async function() {
      const fetchData = await getAllCustomers(organisationId,'',1,99999);
      let the_options_customer = [];
      let the_options_location = [];
      let the_options_teams = [];
      let the_options_user = [];
      let the_options_people = [];
      let the_created_user = [];

      if (fetchData?.response) {
        fetchData.response.forEach((el) => {
          the_options_customer.push({
            key: `${el.id}`,
            value: `${el.id}`,
            text: el.attributes.name,
            image: {
              avatar: true,
              src: el.attributes.logo.data
                ? IMAGES_URL + el.attributes.logo.data.attributes.url
                : LetteredAvatarImageUrl(el.attributes.name + ' '),
              style: { width: '20px', height: '20px' },
            },
          });
        });
      }

      const fetchTeamsData = await getAllTeams(organisationId,'',1,99999);

      if (fetchTeamsData?.response) {
        fetchTeamsData.response.map((el) => {
          the_options_teams.push({
            key: el.id,
            value: `${el.id}`,
            text: el.attributes.name,
            image: {
              avatar: true,
              src: LetteredAvatarImageUrl(`${el.attributes.name}`),
              style: { width: '16px', height: '16px' },
            },
          });
        });
      }

      // getAllParentLocations,
      const fetchDataLocation = await getAllLocations(
        organisationId,
        '',
        1,
        99999
      );

      if (fetchDataLocation) {
        fetchDataLocation.forEach((el) => {
          // if (roleData !== "super_admin" ) {
          //   if(allIds.includes(el.id)){
          //     the_options_location.push({
          //       key: `${el.id}`,
          //       value: `${el.id}`,
          //       text: `${el.attributes.name}`,
          //     });
          //   }
          
          // }else{
            the_options_location.push({
              key: `${el.id}`,
              value: `${el.id}`,
              text: `${el.attributes.name}`,
            });
          // }
        });
      }

      const fetchDataPeople = await getUsersByRole(
        [
          'user',
          'supervisor',
          'view_only',
          'admin',
          'requester',
          'super_admin',
          'limited_admin',
        ],
        organisationId,'',1,99999
      );


      if (fetchDataPeople.response) {
        fetchDataPeople.response.map((el) => {
          the_options_user.push({
            key: `${el.id}`,
            value: `${el.id}`,
            text: el.firstName + ' ' + el.lastName,
            image: {
              avatar: true,
              src: el?.profileImage?.url
                ? IMAGES_URL + el?.profileImage?.url
                : LetteredAvatarImageUrl(el.firstName + ' ' + el.lastName),
              style: { width: '20px', height: '20px' },
            },
          });
        });
      }

      setFiltersTrigger({
        ...filtersTrigger,
        customers: {
          ...filtersTrigger.customers,
          initOptions: the_options_customer,
          options: the_options_customer,
        },
        locations: {
          ...filtersTrigger.locations,
          initOptions: the_options_location,
          options: the_options_location,
        },
        createdByUser: {
          ...filtersTrigger.createdByUser,
          initOptions: the_options_people,
          options: the_options_people,
        },
        teams: {
          ...filtersTrigger.teams,
          initOptions: the_options_teams,
          options: the_options_teams,
        },
        people: {
          ...filtersTrigger.people,
          initOptions: the_options_user,
          options: the_options_user,
        },
      });
    })();
  }, []);

  useEffect(() => {
    const re = new RegExp(_.escapeRegExp(filterSearch.location), 'i');
    const isMatch = (result) => re.test(result.text);

    setFiltersTrigger({
      ...filtersTrigger,
      locations: {
        initOptions: filtersTrigger.locations.initOptions,
        options:
          filterSearch.location.length > 0
            ? _.filter(filtersTrigger.locations.initOptions, isMatch)
            : filtersTrigger.locations.initOptions,
      },
    });
    document.body.addEventListener('click', closeAllFilters);
  }, [filterSearch.location]);

  /*
   * The function `handleBrowseButtonClick` triggers a click event on a file input element
   */
  const handleBrowseButtonClick = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  useEffect(() => {
    const re = new RegExp(_.escapeRegExp(filterSearch.assignee), 'i');
    const isMatch = (result) => re.test(result.text);

    setFiltersTrigger({
      ...filtersTrigger,
      people: {
        ...filtersTrigger.people,
        options:
          filterSearch.assignee.length > 0
            ? _.filter(filtersTrigger.people.initOptions, isMatch)
            : filtersTrigger.people.initOptions,
      },
    });
  }, [filterSearch.assignee]);

  useEffect(() => {
    const re = new RegExp(_.escapeRegExp(filterSearch.createdByUser), 'i');
    const isMatch = (result) => re.test(result.text);

    setFiltersTrigger({
      ...filtersTrigger,
      createdByUser: {
        ...filtersTrigger.createdByUser,
        options:
          filterSearch.createdByUser.length > 0
            ? _.filter(filtersTrigger.createdByUser.initOptions, isMatch)
            : filtersTrigger.createdByUser.initOptions,
      },
    });
  }, [filterSearch.createdByUser]);

  let _newOrders = cloneDeep(data);

  useEffect(() => {
    if (filterOptions.assignees.people.length > 0)
      _newOrders = _newOrders.filter((el) => {
        return el.attributes.people.data.some((item) =>
          filterOptions.assignees.people.includes(item.id)
        );
      });

    if (selectedDates.length == 2) {
      if (selectedDates[0] == selectedDates[1]) {
        _newOrders = _newOrders.filter((el) => {
          var toDate = moment(selectedDates[1]).format('YYYY-MM-DD') || '';
          var fromDate = moment(selectedDates[0]).format('YYYY-MM-DD') || '';
          var createdAt =
            moment(el.attributes.dueDate).format('YYYY-MM-DD') || '';

          return createdAt >= fromDate && createdAt <= toDate;
        });
      } else {
        _newOrders = _newOrders.filter((el) => {
          var toDate = moment(selectedDates[1]).format('YYYY-MM-DD') || '';
          var fromDate = moment(selectedDates[0]).format('YYYY-MM-DD') || '';
          var createdAt =
            moment(el.attributes.dueDate).format('YYYY-MM-DD') || '';

          return createdAt >= fromDate && createdAt <= toDate;
        });
      }
    } else if (selectedDates.length == 1) {
      _newOrders = _newOrders.filter((el) => {
        var fromDate = moment(selectedDates[0]).format('YYYY-MM-DD') || '';

        return el.attributes.dueDate >= fromDate;
      });
    }

    if (filterOptions.teams.length > 0)
      _newOrders = _newOrders.filter((el) => {
        return el?.attributes?.teams?.data?.some((item) =>
          filterOptions.teams.includes(`${item.id}`)
        );
      });

    if (filterOptions.serialNumber.length > 0)
      _newOrders = _newOrders.filter((el) => {
        return filterOptions.serialNumber == el.id;
      });

    if (filterOptions.locations.length > 0)
      _newOrders = _newOrders.filter((el) => {
        return filterOptions.locations.includes(
          `${el.attributes?.location?.data?.id}`
        );
      });

    if (filterOptions.people.length > 0)
      _newOrders = _newOrders.filter((el) => {
        return el?.attributes?.people?.data?.some((item) =>
          filterOptions.people.includes(`${item.id}`)
        );
      });
    if (filterOptions.customers.length > 0)
      _newOrders = _newOrders.filter((el) => {
        return filterOptions.customers.includes(
          `${el.attributes?.customer?.data?.id}`
        );
      });

    if (filterOptions.priority.length > 0)
      _newOrders = _newOrders.filter((el) => {
        return filterOptions.priority.includes(el.attributes.priority);
      });

    if (filterOptions.categories.length > 0)
      _newOrders = _newOrders.filter((el) => {
        return filterOptions.categories.includes(el.attributes.category);
      });

    if (filterOptions.status.length > 0)
      _newOrders = _newOrders.filter((el) => {
        return filterOptions.status.includes(el.attributes.status);
      });

    if (filterOptions.createdByUser.length > 0)
      _newOrders = _newOrders.filter((el) => {
        return filterOptions.createdByUser.includes(
          `${el.attributes.createdByUser?.data?.id}`
        );
      });

    if (searchTitle.length > 0) {
      const re = new RegExp(_.escapeRegExp(searchTitle), 'i');
      const isMatch = (result) => {
        if (re.test(result.attributes.title) || re.test(result.id)) {
          return true;
        } else if (result.attributes?.customer?.data?.attributes?.name) {
          return re.test(result.attributes?.customer?.data?.attributes?.name);
        } else if (result.attributes?.createdByUser?.data?.attributes?.name) {
          return re.test(
            result.attributes?.createdByUser?.data?.attributes?.name
          );
        }
      };

      _newOrders =
        searchTitle.length > 0 ? _.filter(_newOrders, isMatch) : _newOrders;
    }

    if (_newOrders.length > 0) {
      setDataByFilter(_newOrders);
    } else {
      setDataByFilter([]);
    }
  }, [filterOptions, searchTitle, selectedDates, selectedFile]);

  const assigneeOptions =
    filtersTrigger.assigneeType == 'people'
      ? filtersTrigger.assignees.people.options
      : filtersTrigger.assignees.teams.options;

  let assigneesPlaceholder = t.workOrders.filter.assignees;
  if (
    !(
      filterOptions.assignees.people.length > 0 ||
      filterOptions.assignees.teams.length > 0
    )
  ) {
    assigneesPlaceholder = t.workOrders.filter.assignees;
  } else {
    assigneesPlaceholder =
      filterOptions.assignees.people.length > 0
        ? filterOptions.assignees.people.length + ' People'
        : '';

    assigneesPlaceholder +=
      filterOptions.assignees.teams.length > 0
        ? ' ' + filterOptions.assignees.teams.length + ' Teams'
        : '';
  }

  let createdByPlaceholder = t.workOrders.filter.createdByUser;
  if (!(filterOptions.createdByUser.length > 0)) {
    createdByPlaceholder = t.workOrders.filter.createdByUser;
  } else {
    createdByPlaceholder =
      filterOptions.createdByUser.length > 0
        ? filterOptions.createdByUser.length + ' People'
        : '';
  }

  const changeView = (view) => {
    if (calendarRef.current) {
      calendarRef.current.getApi().changeView(view);
      setCurrentView(view);
      if (view === 'timeGridWeek' && selectedWeek.start) {
        calendarRef.current.getApi().gotoDate(selectedWeek.start);
      }
    }
  };

  return (
    <>
      <div className="filters">
        <div className="text-right mb-5 pb-5 d-flex justify-content-between align-items-center views flex-wrap-ans">
          <div className="list-view-option">
            <p className="single-view">
              <NavLink to="/work-orders" className="white-space-no">
                {t.workOrders.tabs.first}
              </NavLink>
            </p>
            <p className="single-view">
              <NavLink to="/work-orders-kanban-view">
                {t.workOrders.kanbar}
              </NavLink>
            </p>
            <p className="single-view">
              <NavLink to="/work-orders-calendar">
                {t.workOrders.tabs.second}
              </NavLink>
            </p>
          </div>

          <div className="table-searchbar d-flex flex-wrap-ans">
            <button
              className={`upload-btn mr-2 mb-2-css ${
                lang == 'ar' ? 'ml-2' : ''
              }`}
              onClick={() => setOpenQRmodal(true)}
            >
              {t.workOrders.upload_QR}
            </button>
            <div className="work-order-upload">
              <span className="search-svg">
                <SearchIcon />
              </span>
              <Input
                icon={{ name: 'search' }}
                placeholder={t.workOrders.filter.search}
                value={searchTitle}
                onChange={(e) => setSearchTitle(e.target.value)}
              />
            </div>
          </div>
        </div>
      </div>

      <div className="filters-wrapper justify-content-between align-items-start">
        <div className="flexbox table-right-dropdown">
          <WorkOrderSearchDropdown
            title={t.workRequests.form.people}
            options={filtersTrigger.people.options}
            selectedValues={filterOptions.people}
            searchValue={filterSearch.assignee}
            handleSearchValue={handleSearchAssigneesChange}
            onClick={(value) => {
              let newArray = toggle(filterOptions.people, value.value);

              setFilterOptions({
                ...filterOptions,
                people: newArray,
              });

              setFilterSearch({
                ...filterSearch,
                assignee: '',
              });
            }}
          />

          <WorkOrderSearchDropdown
            title={t.teams.add}
            options={filtersTrigger.teams.options}
            selectedValues={filterOptions.teams}
            searchValue={filterSearch.teams}
            handleSearchValue={handleSearchTeamsChange}
            onClick={(value) => {
              let newArray = toggle(filterOptions.teams, value.value);

              setFilterOptions({
                ...filterOptions,
                teams: newArray,
              });

              setFilterSearch({
                ...filterSearch,
                teams: '',
              });
            }}
          />

          <PriorityDropdown
            title={t.workOrders.table.status}
            options={filtersTrigger.status.options}
            selectedValues={filterOptions.status}
            pill={false}
            onChange={(e) => {
              if (e.target.checked) {
                setFilterOptions({
                  ...filterOptions,
                  status: e.target.value ? [e.target.value] : [],
                });
              } else {
                setFilterOptions({
                  ...filterOptions,
                  status: [],
                });
              }
            }}
            icon={false}
            image={false}
            label={true}
          />

          <PriorityDropdown
            title={t.workOrders.table.priority}
            options={filtersTrigger.priority.options}
            pill={true}
            selectedValues={filterOptions.priority}
            onChange={(e) => {
              if (e.target.checked) {
                setFilterOptions({
                  ...filterOptions,
                  priority: e.target.value ? [e.target.value] : [],
                });
              } else {
                setFilterOptions({
                  ...filterOptions,
                  priority: [],
                });
              }
            }}
            icon={false}
            image={false}
          />

          <CategoryDropdown
            title={t.workOrders.table.category}
            options={filtersTrigger.categories.options}
            selectedValues={filterOptions.categories}
            onChange={(e) => {
              if (e.target.checked) {
                setFilterOptions({
                  ...filterOptions,
                  categories: [...filterOptions.categories, e.target.value],
                });
              } else {
                let filteredLocations = filterOptions.categories.filter(
                  (el) => el !== e.target.value
                );
                setFilterOptions({
                  ...filterOptions,
                  categories: filteredLocations,
                });
              }
            }}
          />

          <CategoryDropdown
            title={t.workOrders.table.customer}
            options={filtersTrigger.customers.options}
            selectedValues={filterOptions.customers}
            onChange={(e) => {
              if (e.target.checked) {
                setFilterOptions({
                  ...filterOptions,
                  customers: [...filterOptions.customers, e.target.value],
                });
              } else {
                let filteredLocations = filterOptions.customers.filter(
                  (el) => el !== e.target.value
                );
                setFilterOptions({
                  ...filterOptions,
                  customers: filteredLocations,
                });
              }
            }}
            icon={false}
            image={false}
          />
          <CategoryDropdown
            title={t.workOrders.table.location}
            options={filtersTrigger.locations.options}
            selectedValues={filterOptions.locations}
            onChange={(e) => {
              if (e.target.checked) {
                setFilterOptions({
                  ...filterOptions,
                  locations: [...filterOptions.locations, e.target.value],
                });
              } else {
                let filteredLocations = filterOptions.locations.filter(
                  (el) => el !== e.target.value
                );
                setFilterOptions({
                  ...filterOptions,
                  locations: filteredLocations,
                });
              }
            }}
            icon={false}
            image={false}
          />

          <div className="wo-date-picker" key={resetKey}>
            <DatePicker
              style={
                selectedDates.length <= 1
                  ? { width: '125px' }
                  : { width: '186px' }
              }
              value={selectedDates}
              onChange={handleChange}
              showOtherDays={true}
              range
              showMonthDropdown
              showYearDropdown
              placeholder={t.workOrders.filter.date}
              className="active my-class"
              weekDays={[
                t.workOrders.details_view.sun,
                t.workOrders.details_view.mon,
                t.workOrders.details_view.tue,
                t.workOrders.details_view.wed,
                t.workOrders.details_view.thu,
                t.workOrders.details_view.fri,
                t.workOrders.details_view.sat,
              ]}
              months={[
                t.common.january,
                t.common.february,
                t.common.march,
                t.common.april,
                t.common.may,
                t.common.june,
                t.common.july,
                t.common.august,
                t.common.september,
                t.common.october,
                t.common.november,
                t.common.december,
              ]}
              format="DD/MM/YY"
            >
              <button
                onClick={() => {
                  setSelectedDates([]);
                  setResetKey((pre) => !pre);
                }}
                className="btn-datepicker blue"
              >
                {t.common.reset}
              </button>
            </DatePicker>
            <img
              className="date-picker-image procedure"
              style={{ left: '-33px' }}
              src={calendar_icon}
            />
          </div>
        </div>
        <div className="custom-menu-dropdown">
          <UncontrolledDropdown className="mr-2" direction="down">
            <DropdownToggle color="primary" className="option-toggle">
              {t.common.page_action} <WhiteDownChevron />{' '}
            </DropdownToggle>
            <DropdownMenu className={selectedLanguage == 'ar' ? 'rtl' : ''}>
              {/* {role !== 'view_only' ? (
                <>
                  <DropdownItem>
                    <ShareIcon /> {t.common.share}
                  </DropdownItem>
                  <DropdownItem className="divider" />
                </>
              ) : null} */}
              <DropdownItem className="divider" />
              <DropdownItem onClick={() => downloadXLS()}>
                <DownloadIcon /> {t.common.download_sheet}
              </DropdownItem>
              {/* <DropdownItem className="divider" />
              <DropdownItem onClick={() => generatePDF()}>
                <PdfIcon /> {t.common.download_pdf}
              </DropdownItem> */}
            </DropdownMenu>
          </UncontrolledDropdown>
        </div>
      </div>

      {/* open QRcode modal*/}
      <Modal
        className="assets-modal qr-modal"
        size="tiny"
        open={openQRmodal}
        onClose={() => setOpenQRmodal(false)}
      >
        <div className="header-container">
          <span className="assets-header"> {t.workOrders.upload_QR}</span>
          <div>
            <span
              className="c-pointer text-right"
              onClick={() => setOpenQRmodal(false)}
            >
              <CloseIcon />
            </span>
          </div>
        </div>
        <Modal.Content>
          <div className="modal-input d-block">
            {selectedFile?.name ? (
              <>
                <label
                  className={`text-label d-flex align-items-center ${
                    selectedLanguage == 'en' ? 'ml-2' : 'mr-2'
                  }`}
                >
                  <div>
                    <QRCode
                      id="QRCode"
                      title="WorkOrder QR Code"
                      value={true}
                      size={80}
                      disabled
                    />
                  </div>

                  <div className={`${lang == 'ar' ? 'mr-3' : 'ml-3'}`}>
                    <span className="file-label">{selectedFile?.name}</span>
                    <span
                      className={`${lang == 'ar' ? 'mr-3' : 'ml-3'}`}
                      onClick={() => {
                        setSelectedFile({});
                        setFilterOptions({
                          ...filterOptions,
                          serialNumber: '',
                        });
                      }}
                    >
                      <DeleteIcon />
                    </span>
                  </div>
                </label>
              </>
            ) : null}
            <input
              id="barcodePopup"
              type="file"
              hidden
              className="file-input"
              ref={fileInputRef}
              fluid
              accept="image/*"
              onChange={(e) => {
                if (e.target.files && e.target.files.length) {
                  setSelectedFile(e.target.files[0]);
                }
              }}
            />
            <div>
              <button
                onClick={handleBrowseButtonClick}
                className="browse-btn mt-2"
              >
                {t.assets.browse_btn}
              </button>
            </div>
          </div>
        </Modal.Content>

        <div
          className={`modal-button-container ${
            selectedLanguage == 'en' ? 'mr-6' : 'ml-10'
          }`}
        >
          <button
            onClick={() => setOpenQRmodal(false)}
            className="modal-form-action-button"
          >
            {t.common.cancel}
          </button>

          <button
            onClick={() => handleQRCode(setFilterOptions, selectedFile)}
            className="modal-form-action-button blue"
          >
            {t.common.view}
          </button>
        </div>
      </Modal>

      {/* delete result modal*/}
      <DeleteResultModal
        yesCallback={() => {
          toggleDeleteSuccessModal();
        }}
        closeCallback={() => {
          setOpenQRmodal(false);
          setSelectedFile({});
          setFilterOptions({
            ...filterOptions,
            serialNumber: '',
          });
        }}
        deleteErrorMessage={true}
        btnName={t.workOrders.search_again}
      >
        <div className="mb-2">
          <Close />
        </div>
        <p className="status-modal-popup-message">
          {t.workOrders.no_related_QR_msg}
        </p>
      </DeleteResultModal>
    </>
  );
};

export default Filters;
