import React, { useEffect, useState, createRef } from 'react';
import {
  Image,
  Table,
  Button,
  Header,
  Icon,
  Placeholder,
  Loader,
} from 'semantic-ui-react';

import Moment from 'react-moment';

import md5 from 'md5';

import { NavLink } from 'react-router-dom';

import { Country, State, City } from 'country-state-city';

import { useLocation } from 'react-router';
import { toAbsoluteUrl, checkIsActive } from '../../../_metronic/_helpers';

import { IMAGES_URL, getAllVendros, humanize } from '../../config/functions';

import Add from './add';
import Edit from './edit';

import Filters from './filters';
import Preview from './preview';
import { useDispatch, useSelector } from 'react-redux';
import { usePermissionsSimplified } from '../../../hooks/usePermissionsSimplified';
import UserProfilePlaceholder, {
  LetteredAvatarImageUrl,
} from '../../components/common/userProfilePlaceholder';
import { selectTranslations } from '../../config/i18n/slice';
import useGetCurrentTimeAccZone from '../../../hooks/useGetCurrentTimeAccZone';
import moment from 'moment';
import DateConvert from '../../../utilFunctions/DateFormat';
import {
  ArrowLeft,
  ArrowRight,
  CloseIcon,
  CustomerIcon,
  ProcedurePlusIcon,
  SortIcon,
  VendorBlueIcon,
} from '../../../Assets/Icons/svg';
import { useHistory } from 'react-router';
import DataTable from 'react-data-table-component';
import useDisplayFormattedText from '../../../hooks/useDisplayFormattedText';
import ToolTip from 'react-power-tooltip';
import Pagination from '../../components/common/CommonPagination';
import {
  fetchAllVendorData,
  fetchVendorInitData,
  fetchVendorWithFilter,
} from '../../../redux/reducers/vendorReducer';
import { useDebounce } from 'use-debounce';
import EmptyFilter from './emptyFilter';
import Animation from '../../components/common/CustomLoader';

export const Vendors = () => {
  const { userPermissions } = usePermissionsSimplified();
  const t = useSelector(selectTranslations);
  const dispatch = useDispatch();

  const organisationId = useSelector((state) => {
    if (state?.auth?.user?.organisation) {
      return state.auth.user.organisation.id;
    } else {
      return null;
    }
  });
  const selectedLanguage = useSelector((state) => {
    if (state?.i18n?.lang) {
      return state.i18n.lang;
    } else {
      return 'en';
    }
  });

  const {
    data,
    allVendorData,
    isLoading: loading,
    error,
    initData,
    page,
    pageSize,
    total,
    initLoading,
  } = useSelector((state) => ({
    data: state.vendor.vendorData,
    allVendorData: state.vendor.allVendorData,
    isLoading: state.vendor.isLoading,
    error: state.vendor.error,
    initData: state.vendor.vendorInitData,
    page: state.vendor.page,
    pageSize: state.vendor.pageSize,
    total: state.vendor.total,
    initLoading: state.vendor.initLoading,
  }));

  const [currentPage, setCurrentPage] = useState(1);
  const [showTooltip, setShowTooltip] = useState(false);
  const [modalWidth, setModalWidth] = useState(
    Math.max(window.innerWidth / 2, 514)
  );
  const [loader, setloader] = useState(false);
  const [searchTitle, setSearchTitle] = useState('');
  const [debounceValue] = useDebounce(searchTitle, 500);
  const [sortData, setSortData] = useState([]);
  const [selectedStartDates, setSelectedStartDates] = useState([]);
  const [selectedEndDates, setSelectedEndDates] = useState([]);
  // const [pageSize, setPageSize] = useState(25);
  const [toggleAsideModal, setToggleAsideModal] = useState(false);
  const { currentDate, organisationTimezone } = useGetCurrentTimeAccZone();
  const [toggleEditModal, setToggleEditModal] = useState(false);
  const [togglePreviewModal, setTogglePreviewModal] = useState(false);
  const [targetedData, setTargetedData] = useState({});
  const [fetching, setFetchLoading] = useState(true);
  const [refresh, setRefresh] = useState(true);
  // const [data, setData] = useState([]);
  // const [initData, setInitData] = useState([]);

  const [filterOptions, setFilterOptions] = useState({
    countries: '',
    locations: [],
    cities: [],
    createdAt: {
      value: 0,
      text: t.vendors.filter.created_at,
    },
    updatedAt: {
      value: 0,
      text: t.vendors.filter.updated_at,
    },
    dueDate: {
      value: 'any_day',
      text: 'Any Date',
    },
  });
  const curentUrl = useLocation();
  const queryParams = new URLSearchParams(curentUrl.search);
  const partId = queryParams.get('id');
  const history = useHistory();

  useEffect(() => {
    const fetchData = async () => {
      const fetchVendorData = await dispatch(
        fetchVendorInitData(organisationId)
      );

      if (fetchVendorData) {
        if (partId) {
          const fetchAllData = await dispatch(
            fetchAllVendorData(organisationId)
          );

          let selectedPart = fetchAllData.payload.response.find(
            (each) => each.attributes?.countId  == partId
          );
          if (selectedPart) {
            setTargetedData(selectedPart);
            setTogglePreviewModal(true);
          }
        }
        // setData(fetchData);
        // setInitData(fetchData);
        setFetchLoading(false);
      }
    };

    fetchData();
  }, [refresh, partId]);

  const { DisplayFormattedText } = useDisplayFormattedText();

  const location = useLocation();
  const getMenuItemActive = (url, hasSubmenu = false) => {
    return checkIsActive(location, url);
  };

  const rowStyle = { cursor: 'pointer' };
  const customStyles = {
    // rows: {
    //   style: {
    //     height: '72px',
    //     // override the row height
    //   },
    // },
    headCells: {
      style: {
        paddingTop: '11px', // override the cell padding for head cells
        // paddingBottom: '4px',
      },
    },
    cells: {
      style: {
        paddingLeft: '16px', // override the cell padding for data cells
        paddingRight: '16px',
        paddingTop: '19px',
        paddingBottom: '19px',
      },
    },
  };

  const columns = [
    {
      width: selectedLanguage === 'en' ? '133px' : '150px',
      name: t.assets.table.ID,
      sortField: 'countNumId',
      sortable: true,
      cell: (row) => <span onClick={()=>{
        setTargetedData(row);
        setTogglePreviewModal(true);
      }} className="id-text-style">{row?.attributes?.countId}</span>,
    },
    {
      name: t.vendors.table.name,
      sortField: 'name',
      sortable: true,
      minWidth: '180px',
      cell: (row) => {
        if (row?.attributes?.logo?.data) {
          // return <span>{role?.text || humanize(row.role.type)}</span>;
          return (
            <div onClick={()=>{
              setTargetedData(row);
              setTogglePreviewModal(true);
            }}  className="d-flex align-items-center">
              <div className="text-center">
                <Image
                  avatar
                  title={row?.attributes?.name}
                  src={`${IMAGES_URL}${row?.attributes?.logo?.data.attributes.url}`}
                  style={
                    selectedLanguage === 'en'
                      ? { width: '34px', height: '34px', right: '6px' }
                      : { width: '34px', height: '34px', right: '-6px' }
                  }

                  // style={
                  //   index != 0
                  //     ? {
                  //         marginLeft: '-6px',
                  //         width: '34px',
                  //         height: '34px',
                  //       }
                  //     : { width: '34px', height: '34px' }
                  // }
                />
              </div>
              <div
                className="username-container ml-1"
                // onClick={() => history.push(`/people?id=${row?.id}`)}
              >
                <p className="user-text">
                  {DisplayFormattedText(humanize(row?.attributes?.name), 8)}
                </p>
              </div>
            </div>
          );
        } else {
          return (
            <div onClick={()=>{
              setTargetedData(row);
              setTogglePreviewModal(true);
            }}  className="d-flex align-items-center">
              <div className="text-center">
                <UserProfilePlaceholder
                  name={row?.attributes?.name}
                  width={'34px'}
                  height={'34px'}
                  right={selectedLanguage === 'en' ? '6px' : '-6px'}
                />
              </div>
              <div
                className="username-container ml-1"
                // onClick={() => history.push(`/people?id=${row?.id}`)}
              >
                <p className="user-text">
                  {DisplayFormattedText(humanize(row?.attributes?.name), 8)}
                </p>
              </div>
            </div>
          );
        }
      },
    },

    {
      sortField: 'phone',
      minWidth: '160px',
      name: t.customers.table.phone,

      sortable: true,
      cell: (row) =>
        row.attributes?.phone
          ? `${selectedLanguage == 'en' ? '+' : ''}${row.attributes?.phone}${
              selectedLanguage == 'ar' ? '+' : ''
            }`
          : t.common.na,
    },

    {
      minWidth: '150px',
      sortField: 'email',
      name: t.customers.table.email,
      // selector: (row) =>
      //   row?.attributes?.email ? row?.attributes?.email?.length : 0,
      sortable: true,
      cell: (row) =>
        row?.attributes?.email
          ? <span onClick={()=>{
            setTargetedData(row);
            setTogglePreviewModal(true);
          }} >{DisplayFormattedText(row?.attributes?.email, 10)}</span>
          : t.common.na,
    },

    {
      name: t.workOrders.table.location,
      sortField: 'location.name',
      minWidth: '150px',

      sortable: true,
      cell: (row) => (
        <span  onClick={()=>{
          setTargetedData(row);
          setTogglePreviewModal(true);
        }}  className="">
          {DisplayFormattedText(
            row?.attributes?.location?.data?.attributes.name,
            12
          ) || t.common.na}
        </span>
      ),
    },

    {
      sortField: 'serviceType',
      name: t.customers.table.service_type,
      // selector: (row) => row?.attributes?.serviceType,
      sortable: true,
      minWidth: '165px',
      cell: (row) =>
        row?.attributes?.serviceType
          ?  DisplayFormattedText(row?.attributes?.serviceType,16)
          : t.common.na,
    },

    {
      name: t.vendors.form.start_dt,
      minWidth: '170px',
      sortField: 'startDate',
      // selector: (row) =>
      //   row?.attributes?.startDate ? row.attributes.startDate : '',
      sortable: true,
      // minWidth: '156px',
      cell: (row) => {
        if (row?.attributes?.startDate) {
          return (
            <div onClick={()=>{
              setTargetedData(row);
              setTogglePreviewModal(true);
            }} >
              <div>
                <Moment format="DD.MM.YYYY" date={row?.attributes?.startDate} />
              </div>
              <div className="time-text-style text-left">
                <Moment format="h a" date={row?.attributes?.startDate} />
              </div>
            </div>
          );
        } else {
          return t.common.na; // Or you can return some default content or placeholder
        }
      },
    },
    {
      name: t.vendors.form.end_dt,
      minWidth: '170px',
      sortField: 'endDate',
      // selector: (row) =>
      //   row?.attributes?.endDate ? row.attributes.endDate : '',
      sortable: true,
      // minWidth: '156px',
      cell: (row) => {
        if (row?.attributes?.endDate) {
          return (
            <div onClick={()=>{
              setTargetedData(row);
              setTogglePreviewModal(true);
            }} >
              <div>
                <Moment format="DD.MM.YYYY" date={row?.attributes?.endDate} />
              </div>
              <div className="time-text-style text-left">
                <Moment format="h a" date={row?.attributes?.endDate} />
              </div>
            </div>
          );
        } else {
          return t.common.na; // Or you can return some default content or placeholder
        }
      },
    },
    {
      minWidth: '110px',
      name: t.assets.table.status,
      selector: (row) => row?.attributes?.status,
      // sortable: true,
      cell: (row) => (
        <span onClick={()=>{
          setTargetedData(row);
          setTogglePreviewModal(true);
        }} 
          className={`pill ${
            row?.attributes?.status === 'inactive'
              ? ' inactive-grey '
              : 'active-green'
          }`}
        >
          {row?.attributes?.status === 'inactive'
            ? t.assets.inActive
            : t.assets.active}
        </span>
      ),
    },
  ];

  const handleSetDataByFilter = (newData) => {
    // setData(newData);
  };

  const handleSort = async (column, sortDirection) => {
    setloader(true);

    // Assuming the selector represents the field to sort
    const sort = [`${column.sortField}:${sortDirection}`];
    setSortData(sort);
    const sortedData = await dispatch(
      fetchVendorWithFilter({
        organisationId,
        searchName: debounceValue,
        page: page,
        pageSize: pageSize,
        location: filterOptions.locations,
        startDate: selectedStartDates,
        endDate: selectedEndDates,
        sort,
      })
    );

    setloader(false);

    // Update state or data variable with sortedData
    // setData(sortedData); // Assuming you have state or variable named 'data'
  };

  const handleRemoveSorting = async () => {
    setloader(true);

    // Assuming the select or represents the field to sort
    const sort = undefined;
    setSortData(sort);
    const sortedData = await dispatch(
      fetchVendorWithFilter({
        organisationId,
        searchName: debounceValue,
        page: page,
        pageSize: pageSize,
        location: filterOptions.locations,
        startDate: selectedStartDates,
        endDate: selectedEndDates,
        sort,
      })
    );

    setloader(false);
  };

  const getPaginatedData = (dataToPaginate) => {
    const startIndex = (currentPage - 1) * pageSize;
    const endIndex = startIndex + pageSize;
    return dataToPaginate.slice(startIndex, endIndex);
  };

  const totalFilteredPages = Math.ceil(data.length / pageSize);

  return (
    <div className="new-listing-container">
      <div className="table-header">
        <div className="header-actions">
          <Header className="procedure-title" as="h2">
            {' '}
            <CustomerIcon />
            {t.aside.customersVendors}
          </Header>
          <div></div>
          {/* <Button.Group basic>
          <NavLink to="/customers">
            <Button
              color={getMenuItemActive('/customers', false) ? 'teal' : ''}
            >
              {t.customers.customers}
            </Button>
          </NavLink>
          <NavLink to="/vendors">
            <Button color={getMenuItemActive('/vendors', false) ? 'teal' : ''}>
              {t.vendors.vendors}
            </Button>
          </NavLink>
        </Button.Group> */}
          {userPermissions?.vendor?.create && initData.length > 0 ? (
            <Button
              icon="add"
              primary
              onClick={() => {
                setToggleAsideModal(true);
              }}
            >
              {t.vendors.add_vendor}
              {/* <ProcedurePlusIcon /> */}
            </Button>
          ) : null}
        </div>
      </div>
      {/* <Header as="h2">{t.vendors.vendors}</Header>
      <div className="header-actions">
        <Button.Group basic>
          <NavLink to="/customers">
            <Button
              color={getMenuItemActive('/customers', false) ? 'teal' : ''}
            >
              {t.customers.customers}
            </Button>
          </NavLink>
          <NavLink to="/vendors">
            <Button color={getMenuItemActive('/vendors', false) ? 'teal' : ''}>
              {t.vendors.vendors}
            </Button>
          </NavLink>
        </Button.Group>
        {userPermissions?.vendor?.create ? (
          <Button
            icon="add"
            content={t.vendors.vendor}
            primary
            onClick={() => {
              setToggleAsideModal(true);
            }}
          />
        ) : null}
      </div> */}
      {initLoading ? (
         <div className='d-flex justify-content-center align-items-center' style={{minHeight:"500px"}}>
         <Animation/>
         {/* <Placeholder fluid>
           <Placeholder.Line />
           <Placeholder.Line />
           <Placeholder.Line />
           <Placeholder.Line />
           <Placeholder.Line />
         </Placeholder> */}
       </div>
        // <div>
        //   <Placeholder fluid>
        //     <Placeholder.Line />
        //     <Placeholder.Line />
        //     <Placeholder.Line />
        //     <Placeholder.Line />
        //     <Placeholder.Line />
        //   </Placeholder>
        // </div>
      ) : (
        <div className="new-listing-filter">
          {initData.length > 0 ? (
            <>
              <Filters
                data={initData}
                fileData={data}
                setDataByFilter={handleSetDataByFilter}
                hasStatus={false}
                setFilterOptions={setFilterOptions}
                filterOptions={filterOptions}
                debounceValue={debounceValue}
                setSearchTitle={setSearchTitle}
                searchTitle={searchTitle}
                setloader={setloader}
                loader={loader}
                setSelectedEndDates={setSelectedEndDates}
                setSelectedStartDates={setSelectedStartDates}
                selectedEndDates={selectedEndDates}
                selectedStartDates={selectedStartDates}
                setSortData={setSortData}
                sortData={sortData}
              />

              {error ? (
                <div className="mt-8 mb-8 ">
                 <Animation/>
                </div>
              ) : (
                <DataTable
                  className="datatable-div"
                  columns={columns}
                  onSort={handleSort}
                  fixedHeader
                  data={data}
                  customStyles={customStyles}
                  noDataComponent={
                    initData.length > 0 ? (
                      <div className="no-data-container">
                        <div className="">
                          <p className="no-data-text">
                            {t.common.no_result_found}
                          </p>
                        </div>
                      </div>
                    ) : null
                  }
                  direction={selectedLanguage === 'en' ? 'ltr' : 'rtl'}
                  onRowClicked={(row) => {
                    setTargetedData(row);
                    setTogglePreviewModal(true);
                  }}
                  sortIcon={
                    sortData ? (
                      <div>
                        <span>
                          <SortIcon />
                        </span>
                        <span
                          onClick={(e) => {
                            e.stopPropagation();
                            handleRemoveSorting();
                          }}
                        >
                          <CloseIcon />
                        </span>
                      </div>
                    ) : (
                      <span></span>
                    )
                  }
                  persistTableHead={true}
                  pointerOnHover={true}
                  highlightOnHover={true}
                  progressPending={loader}
                  progressComponent={
                    <div className="mt-8 mb-8 ">
                       { t.common.loading}
                    </div>
                  }
                />
              )}

              <div className="d-flex justify-content-end">
                <Pagination
                  ofString={t.common.of}
                  total={total}
                  prevItem={
                    selectedLanguage === 'en' ? <ArrowLeft /> : <ArrowRight />
                  }
                  nextItem={
                    selectedLanguage === 'en' ? <ArrowRight /> : <ArrowLeft />
                  }
                  page={page}
                  limit={pageSize}
                  handleNext={async () => {
                    setloader(true);
                    await dispatch(
                      fetchVendorWithFilter({
                        organisationId,
                        searchName: debounceValue,
                        page: page + 1,
                        pageSize: pageSize,
                        location: filterOptions.locations,
                        startDate: selectedStartDates,
                        endDate: selectedEndDates,
                        sort: sortData,
                      })
                    );
                    setloader(false);
                  }}
                  handlePrevious={async () => {
                    setloader(true);
                    await dispatch(
                      fetchVendorWithFilter({
                        organisationId,
                        searchName: debounceValue,
                        page: page - 1,
                        pageSize: pageSize,
                        location: filterOptions.locations,
                        startDate: selectedStartDates,
                        endDate: selectedEndDates,
                        sort: sortData,
                      })
                    );
                    setloader(false);
                  }}
                />
              </div>
              {/* <Table selectable>
                <Table.Header>
                  <Table.Row>
                    <Table.HeaderCell>{t.vendors.table.id}</Table.HeaderCell>
                    <Table.HeaderCell>{t.vendors.table.name}</Table.HeaderCell>
                    <Table.HeaderCell>{t.vendors.table.phone}</Table.HeaderCell>
                    <Table.HeaderCell>{t.vendors.table.email}</Table.HeaderCell>
                    <Table.HeaderCell>
                      {t.vendors.table.website}
                    </Table.HeaderCell>
                    <Table.HeaderCell>
                      {t.vendors.table.country}
                    </Table.HeaderCell>
                    <Table.HeaderCell>{t.vendors.table.city}</Table.HeaderCell>
                    <Table.HeaderCell>
                      {t.vendors.table.service_type}
                    </Table.HeaderCell>
                    <Table.HeaderCell>
                      {t.vendors.table.hourly_rate}
                    </Table.HeaderCell>
                    <Table.HeaderCell>
                      {t.vendors.table.created_by}
                    </Table.HeaderCell>
                    <Table.HeaderCell>
                      {t.vendors.table.created_at}
                    </Table.HeaderCell>
                    <Table.HeaderCell>
                      {t.vendors.table.updated_at}
                    </Table.HeaderCell>
                  </Table.Row>
                </Table.Header>

                <Table.Body>
                  {getPaginatedData(data).map((el) => {
                    let nameIMG = el?.attributes?.logo.data ? (
                      <div>
                        <Image
                          avatar
                          title={el?.attributes?.name}
                          src={
                            IMAGES_URL +
                            el?.attributes?.logo.data.attributes.url
                          }
                        />
                        <span style={{ marginLeft: '4px' }}>
                          {el?.attributes?.name}
                        </span>
                      </div>
                    ) : (
                      <div>
                        <Image
                          avatar
                          title={el?.attributes?.name}
                          src={LetteredAvatarImageUrl(el?.attributes?.name)}
                        />
                        <span style={{ marginLeft: '4px' }}>
                          {el?.attributes?.name}
                        </span>
                      </div>
                    );

                    let createdByUser = el?.attributes?.createdByUser?.data
                      ?.attributes ? (
                      <div>
                        <Image
                          avatar
                          title={
                            el?.attributes?.createdByUser?.data?.attributes
                              ?.firstName +
                            ' ' +
                            el?.attributes?.createdByUser?.data?.attributes
                              ?.lastName
                          }
                          src={
                            el?.attributes?.createdByUser?.data?.attributes
                              ?.profileImage?.data?.attributes?.url
                              ? IMAGES_URL +
                                el?.attributes?.createdByUser?.data?.attributes
                                  ?.profileImage?.data?.attributes?.url
                              : LetteredAvatarImageUrl(
                                  el?.attributes?.createdByUser?.data
                                    ?.attributes?.firstName +
                                    ' ' +
                                    el?.attributes?.createdByUser?.data
                                      ?.attributes?.lastName
                                )
                          }
                          style={{ width: '20px', height: '20px' }}
                        />
                        <span style={{ marginLeft: '4px' }}>
                          {
                            el?.attributes?.createdByUser?.data?.attributes
                              ?.firstName
                          }
                        </span>
                      </div>
                    ) : (
                      t.common.na
                    );

                    return (
                      <Table.Row
                        style={rowStyle}
                        key={el.id}
                        id={el.id}
                        onClick={() => {
                          setTargetedData(el);
                          setTogglePreviewModal(true);
                        }}
                      >
                        <Table.Cell>{el.id}</Table.Cell>
                        <Table.Cell>{nameIMG}</Table.Cell>
                        <Table.Cell dir="ltr">
                          {el?.attributes?.phone
                            ? `+${el?.attributes?.phone}`
                            : t.common.na}
                        </Table.Cell>
                        <Table.Cell title={el?.attributes?.email}>
                          {el?.attributes?.email
                            ? el?.attributes?.email.substring(0, 5) + '...'
                            : t.common.na}
                        </Table.Cell>
                        <Table.Cell>
                          {el?.attributes?.website || t.common.na}
                        </Table.Cell>{' '}
                        <Table.Cell>
                          {el?.attributes?.billingInformation?.country
                            ? Country.getCountryByCode(
                                el?.attributes?.billingInformation?.country
                              ).name
                            : t.common.na}
                        </Table.Cell>
                        <Table.Cell>
                          {el?.attributes?.billingInformation?.city
                            ? State.getStateByCodeAndCountry(
                                el?.attributes?.billingInformation?.city,
                                el?.attributes?.billingInformation?.country
                              ).name
                            : t.common.na}
                        </Table.Cell>
                        <Table.Cell>
                          {el?.attributes?.serviceType
                            ? el?.attributes?.serviceType
                            : t.common.na}
                        </Table.Cell>
                        <Table.Cell>
                          {el?.attributes?.billingInformation?.rate
                            ? el?.attributes?.billingInformation?.rate +
                              el?.attributes?.billingInformation?.currency
                            : t.common.na}
                        </Table.Cell>
                        <Table.Cell>{createdByUser}</Table.Cell>
                        <Table.Cell>
                          {
                            <Moment
                              format="DD-MM-YYYY LT"
                              date={el?.attributes?.createdAt}
                            />
                          }
                        </Table.Cell>
                        <Table.Cell>
                          {
                            <Moment
                              format="DD-MM-YYYY LT"
                              date={el?.attributes?.updatedAt}
                            />
                          }
                        </Table.Cell>
                      </Table.Row>
                    );
                  })}
                </Table.Body>
              </Table> */}
            </>
          ) : (
            <div>
              <EmptyFilter />
              {/* <Filters
                data={initData}
                fileData={data}
                setDataByFilter={handleSetDataByFilter}
                hasStatus={false}
                setFilterOptions={setFilterOptions}
                filterOptions={filterOptions}
                debounceValue={debounceValue}
                setSearchTitle={setSearchTitle}
                searchTitle={searchTitle}
                setloader={setloader}
                loader={loader}
                setSelectedEndDates={setSelectedEndDates}
                setSelectedStartDates={setSelectedStartDates}
                selectedEndDates={selectedEndDates}
                selectedStartDates={selectedStartDates}
                setSortData={setSortData}
                sortData={sortData}
              /> */}
              <div className="no-data-container">
                <div className="no-work-orders pb-15">
                  <VendorBlueIcon />
                  <p className="no-data-text">
                    {t.common.there_no}{' '}
                    <span
                      className="link-text"
                      style={{ position: 'relative', cursor: 'pointer' }}
                      onMouseOver={() => setShowTooltip(true)}
                      onMouseLeave={() => setShowTooltip(false)}
                    >
                      {/* <ToolTip
                        color="#fff"
                        backgroundColor="#172B4D"
                        position="top left"
                        show={showTooltip}
                        textBoxWidth="50px"
                        arrowAlign="start"
                        fontSize="14px"
                        fontWeight="400"
                        fontFamily={selectedLanguage == 'ar' ? "Noto Kufi Arabic" : "Roboto"}
                        padding="0px 0px 0px 8px"
                        borderRadius="5px"
                        static
                        moveRight="80px"
                        moveUp="-1px"
                      >
                        <span className="work-order-tooltip">
                          {t.vendors.how_to_add_vendor}
                        </span>
                      </ToolTip> */}

                      <ToolTip
                        color="#fff"
                        backgroundColor="#172B4D"
                        position="top left"
                        show={showTooltip}
                        textBoxWidth={
                          selectedLanguage == 'ar' ? '75px' : '55px'
                        }
                        arrowAlign="start"
                        fontSize="14px"
                        fontWeight="400"
                        fontFamily={
                          selectedLanguage == 'ar'
                            ? 'Noto Kufi Arabic'
                            : 'Roboto'
                        }
                        padding="0px 0px 0px 8px"
                        borderRadius="5px"
                        static
                        moveRight="80px"
                        moveUp="-1px"
                      >
                        <span className="work-order-tooltip">
                          {t.vendors.how_to_add_vendor}
                        </span>
                      </ToolTip>
                      {' ' + t.vendors.title_small + ' '}
                    </span>{' '}
                    {t.common.added}
                  </p>
                  {userPermissions?.vendor?.create ? (
                    <button
                      className="work-order-button-transparent"
                      onClick={() => {
                        setToggleAsideModal(true);
                      }}
                    >
                      {t.vendors.no_vendor_added}
                    </button>
                  ) : null}
                </div>
              </div>
            </div>
          )}
        </div>
      )}

      <Preview
        setModalWidth={setModalWidth}
        modalWidth={modalWidth}
        toggled={togglePreviewModal}
        untoggle={() => {
          setTogglePreviewModal(false);
          setModalWidth(Math.max(window.innerWidth / 2, 514));
          setTargetedData();
        }}
        edit={() => {
          setTogglePreviewModal(false);
          setToggleEditModal(true);
        }}
        targetedData={targetedData}
        refresh={refresh}
        setRefresh={setRefresh}
      />

      <Edit
        targetedData={targetedData}
        toggled={toggleEditModal}
        untoggle={() => {
          setModalWidth(Math.max(window.innerWidth / 2, 514));
          setToggleEditModal(false);
          setTargetedData();
        }}
        refresh={refresh}
        setRefresh={setRefresh}
        setFilterOptions={setFilterOptions}
        setSearchTitle={setSearchTitle}
      />

      <Add
        toggled={toggleAsideModal}
        untoggle={() => {
          setToggleAsideModal(false);
          setModalWidth(Math.max(window.innerWidth / 2, 514));
        }}
        refresh={refresh}
        setRefresh={setRefresh}
        setFilterOptions={setFilterOptions}
        setSearchTitle={setSearchTitle}
      />
    </div>
  );
};
