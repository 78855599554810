export default {
  title: 'Teams',
  add: 'Team',
  add_team: 'Add Team',
  next_btn: 'Next',
  filter: {
    search: 'Search...',
    people: 'People',
  },
  team_info_form: {
    add_team: 'Add team name',
    team_name: 'Team Name',
    description: 'Description',
    description_placeholder: 'Add description',
    team_superior: 'Team Supervisor',
    add_team_superior: 'Add team supervisor',
    superior: 'Supervisor',
    team_members: 'Team Members',
    add_team_members: 'Add team members',
    member: 'Member',
    members: 'members',
    customer: 'Customer',
    customers: 'Customers',
    customer_placeholder: 'Add customer',
    location: 'Location',
    location_placeholder: 'Add location',
  },

  team_preview: {
    supervisor: 'Supervisor',
    locations: 'Locations',
    edit_team: 'Edit Team',
  },

  members_of_teams: 'member of this team',
  invalid_phone: 'Invalid phone',
  result_not_found: 'Results not found',
  no_teams_found: "Looks like you don't have Teams yet.",
  add_team_message: 'Press the ' + ' button to add your first Team.',
  something_wrong: 'Something went wrong',
  delete_teams: ' Are you sure you want to delete this team?',
  team_connected:
    'Unable to delete team . Linked with other forms',
  team_deleted: 'Team deleted successfully',
  security: 'Security',
  modified_success: 'modified successfully!',
  invalid_email: 'Invalid email',
  no_people_statement: "Looks like you don't have People yet.",
  press_to_add_people: 'Press the ' + ' button to add your first Person.',
  add_teams: 'start adding people & teams',
};
