import React, { useState, useEffect, createRef } from 'react';
import {
  Popup,
  Header,
  Modal,
  List,
  Dropdown,
  Grid,
  Input,
  Form,
  Button,
  Divider,
  Icon,
  Checkbox,
  TextArea,
  Label,
  Radio,
} from 'semantic-ui-react';
import * as Yup from 'yup';
import calendar_icon from '../../../Assets/Icons/calender-icon.svg';
import clock_icon from '../../../Assets/Icons/clock.svg';
import DatePicker from 'react-multi-date-picker';
import { useCancelModal } from '../../../hooks/Messages/useCancelModal';

import moment from 'moment';
import { format } from 'date-fns';
import {
  getActiveUsersByRole,
  IMAGES_URL,
  getAllAssetsByCustomer,
  getAllTeams,
  uploadFile,
  urlToFile,
  getAllLocations,
  updateWorkOrder,
  updateWorkRequestStatus,
  getAllCustomers,
  getAllProcedures,
  getAllPartsByAssets,
  handleKeyDown,
  getAllAssets,
  getAllAreaByLocationId,
  getAllParts,
  getAllSets,
} from '../../config/functions';

import AddCustomerModal from './popups/add-customer';
import AddLocationModal from './popups/add-location';
import AddPeopleModal from './popups/add-people';
import AddProcedureModal from './popups/add-procedure';
import AddAssetModal from './popups/add-asset';
import AddPartModal from './popups/add-part';
import AddTeamModal from './popups/add-team';
import { useSuccessModal } from '../../../hooks/Messages/useSuccessModal';

import md5 from 'md5';

import { useSelector } from 'react-redux';

import { selectTranslations } from '../../config/i18n/slice';
import { LetteredAvatarImageUrl } from '../../components/common/userProfilePlaceholder';
import ar from 'date-fns/locale/ar-SA';
import enGB from 'date-fns/locale/en-GB/index.js';
import 'react-datepicker/dist/react-datepicker.css';
import { toast } from 'react-toastify';
import Asterisk from '../../components/common/Asterisk';
import {
  AddIconWithCircle,
  AddImage,
  DeleteIcon,
  TextSnippet,
  CategoryInspection,
  CategoryMechanical,
  CloseIcon,
  CopyIcon,
  Damage,
  Electrical,
  HeadingIcon,
  OperationProcedure,
  Preventive,
  Project,
  Refrigeration,
  Safety,
  ClockIcon,
  PriceIcon,
  CalenderGreyIcon,
  CatgoryPreventive,
  CorrectiveIcon,
  PlannedIcon,
  UnPlannedIcon,
} from '../../../Assets/Icons/svg';
import { useDropzone } from 'react-dropzone';
import { scrollToError } from '../../../utilFunctions/scrollToError';
import ToolTip from 'react-power-tooltip';
import PartsDropdown from '../../components/common/PartsDropdown';
import { Resizable } from 're-resizable';
import useNewCancelModal from '../../../hooks/Messages/useNewCncelModal';

/*
 * The FileUpload component in JavaScript allows users to upload files with customizable drag and drop functionality.
 */
const FileUpload = ({
  onFileChange,
  name = '',
  attachments = {},
  setAttachments = () => {},
}) => {
  const { getRootProps, getInputProps } = useDropzone({
    onDrop: (acceptedFiles) => {
      onFileChange(acceptedFiles, name);
    },
  });
  const t = useSelector(selectTranslations);
  //TO DO : Customize and Style this Drag and Drop to Upload box as you want🧑‍💻😊
  return (
    <div {...getRootProps()}>
      <input {...getInputProps()} />
      {name === 'image' ? (
        attachments?.image?.name ? (
          <div className="image-dropzone text-center mb-5">
            <label>
              {attachments?.image?.name}
              <span
                className="ml-3 mr-3 c-pointer"
                onClick={(e) => {
                  e.stopPropagation();
                  setAttachments({
                    ...attachments,
                    image: null,
                  });
                }}
              >
                <DeleteIcon />
              </span>
            </label>
          </div>
        ) : (
          <div className="image-dropzone text-center mb-5">
            <div>
              <p className="p1">
                <span className="mr-2 ml-2">
                  <AddImage />
                </span>
                <span className="mt-1">{t.workOrders.drag_img}</span>
                <span className="mt-1" style={{ color: '#0C66E4' }}>
                  {' '}
                  {t.workOrders.upload_a_file}
                </span>{' '}
              </p>
            </div>
          </div>
        )
      ) : attachments?.file?.name ? (
        <div className="warrenty-file-dropzone text-center">
          <label>
            {attachments?.file?.name}
            <span
              className="ml-3 mr-3 c-pointer"
              onClick={(e) => {
                e.stopPropagation();
                setAttachments({
                  ...attachments,
                  file: null,
                });
              }}
            >
              <DeleteIcon />
            </span>
          </label>
        </div>
      ) : (
        <div className="warrenty-file-dropzone d-flex align-items-center justify-content-between">
          <div className="d-flex ml-4 mr-4">
            <TextSnippet />
            <div className="pl-4 pr-4">
              <p className="p1 m-0">{t.workOrders.form.file}</p>
              <p className="p2 m-0">{t.workOrders.form.attach_file}</p>
            </div>
          </div>
          <div>
            <AddIconWithCircle />
          </div>
        </div>
      )}
    </div>
  );
};
function Edit({
  targetedData,
  toggled,
  untoggle,
  dueDateParam,
  orderTypeParam,
  request,
  setRefresh,
  refresh,
  seriesEdit,
  setSeriesEdit,
  setFilterOptions,
  setSelectedDates,
  setSearchTitle,
  roleData,allIds
}) {
  const t = useSelector(selectTranslations);
  const organisationId = useSelector((state) => {
    if (state?.auth?.user?.organisation) {
      return state.auth.user.organisation.id;
    } else {
      return null;
    }
  });
  const organisationCurrency = useSelector((state) => {
    if (state?.auth?.user?.organisation) {
      return state.auth.user.organisation.currency;
    } else {
      return 'JOD';
    }
  });
  const selectedLanguage = useSelector((state) => {
    if (state?.i18n?.lang) {
      return state.i18n.lang;
    } else {
      return 'en';
    }
  });
  const [initChange, setInitChange] = useState(false);
  const [showCancleTooltip, setCancleTooltip] = useState(false);
  const [fetchingPartsData, setFetchingPartsData] = useState(true);
  const { setSuccessModal, SuccessModal } = useSuccessModal();
  const { setNewCancelModal, NewCancelModal } = useNewCancelModal();
  const [buttonStatus, setButtonStatus] = useState(0);
  const [cancelModal, toggleCancelModal] = useState(false);
  const [successModal, toggleSuccessModal] = useState(false);
  const [fetchingCustomer, setFetchingCustomer] = useState(true);
  const [fetchingLocation, setFetchingLocation] = useState(true);
  const [fetchingPeople, setFetchingPeople] = useState(true);
  const [fetchingTeams, setFetchingTeams] = useState(true);
  const [fetchingAssets, setFetchingAssets] = useState(true);
  const [fetchingParts, setFetchingParts] = useState(true);
  const [fetchingProcedures, setFetchingProcedures] = useState(true);
  const [fetchingWeeks, setFetchingWeeks] = useState(true);
  const [fetchingMonths, setFetchingMonths] = useState(true);
  const [fetchingMonthsDays, setFetchingMonthsDays] = useState(true);
  const [fetchingYears, setFetchingYears] = useState(true);
  const [responseData, setResponseData] = useState({});

  // on to go popups
  const [addCustomerModal, toggleAddCustomerModal] = useState(false);
  const [addLocationModal, toggleAddLocationModal] = useState(false);
  const [addPeopleModal, toggleAddPeopleModal] = useState(false);
  const [addTeamModal, toggleAddTeamModal] = useState(false);
  const [addProcedureModal, toggleAddProcedureModal] = useState(false);
  const [addAssetModal, toggleAddAssetModal] = useState(false);
  const [addPartModal, toggleAddPartModal] = useState(false);

  const [customerText, setCustomerText] = useState('');
  const [locationText, setLocationText] = useState('');
  const [peopleText, setPeopleText] = useState('');
  const [teamText, setTeamText] = useState('');
  const [procedureText, setProcedureText] = useState('');
  const [assetText, setAssetText] = useState('');
  const [partText, setPartText] = useState('');
  const [attachments, setAttachments] = useState({
    image: null,
    file: null,
  });

  let allQtyOptions = [
    {
      key: 0,
      value: 'pieces',
      text: t.parts.qty_unit.piece,
    },
    {
      key: 1,
      value: 'items',
      text: t.parts.qty_unit.items,
    },
    {
      key: 2,
      value: 'units',
      text: t.parts.qty_unit.unit,
    },
    {
      key: 3,
      value: 'liters (L)',
      text: selectedLanguage == 'en' ? 'l' : 'لتر',
    },
    {
      key: 4,
      value: 'gallons (gal)',
      text: selectedLanguage == 'en' ? 'gal' : 'جالون',
    },
    {
      key: 5,
      value: 'cubic meters (m³)',
      text: selectedLanguage == 'en' ? 'm³' : 'م³',
    },
    {
      key: 6,
      value: 'cubic feet (ft³)',
      text: selectedLanguage == 'en' ? 'ft³' : 'ق³',
    },
    {
      key: 7,
      value: 'barrels',
      text: t.parts.vol_unit.barrels,
    },
    {
      key: 8,
      value: 'kilograms (kg)',
      text: selectedLanguage == 'en' ? 'kg' : 'كجم',
    },
    {
      key: 9,
      value: 'pounds (lb)',
      text: selectedLanguage == 'en' ? 'lb' : 'رطل',
    },

    {
      key: 10,
      value: 'tons',
      text: t.parts.mass_unit.tons,
    },
    {
      key: 11,
      value: 'metric tonnes',
      text: t.parts.mass_unit.m_tons,
    },
  ];

  const [options, setOptions] = useState({
    customers: [],
    locations: [],
    people: [],
    teams: [],
    assets: [],
    parts: [],
    area: [],
    partsData: [],
    sets: [],
    procedures: [],
    weeks: [],
    months: [],
    monthsDays: [],
    repeatEvery: [
      {
        key: 0,
        value: 'daily',
        text: t.workOrders.details_view.daily,
      },

      {
        key: 1,
        value: 'weekly',
        text: t.workOrders.details_view.weekly,
      },
      {
        key: 2,
        value: 'monthly',
        text: t.workOrders.details_view.monthly,
      },
      {
        key: 1,
        value: 'yearly',
        text: t.workOrders.details_view.yearly,
      },
    ],
    priority: [
      {
        key: 0,
        value: 'low',
        text: t.workOrders.form.priority_options[1],
      },

      {
        key: 1,
        value: 'medium',
        text: t.workOrders.form.priority_options[2],
      },
      {
        key: 2,
        value: 'high',
        text: t.workOrders.form.priority_options[3],
      },
    ],
    years: [],
    orderTypes: [
      {
        key: 0,
        value: 'reactive',
        text: t.workOrders.form.order_type_1,
      },
      {
        key: 1,
        value: 'scheduled',
        text: t.workOrders.form.order_type_2,
      },
    ],
    categories: [
      {
        key: 0,
        value: 'damage',
        text: (
          <span>
            <span className="ml-2 mr-2">
              <Damage />
            </span>
            {t.workOrders.form.category_options[0]}
          </span>
        ),
      },
      {
        key: 1,
        value: 'corrective action Downtime',
        text: (
          <span>
            <span className="ml-2 mr-2"><CorrectiveIcon/></span>
            {t.workOrders.form.category_options[1]}
          </span>
        ),
      },
      {
        key: 2,
        value: 'planned Downtime',
        text: (
          <span>
            <span className="ml-2 mr-2"><PlannedIcon/></span>
            {t.workOrders.form.category_options[2]}
          </span>
        ),
      },
      {
        key: 3,
        value: 'unplanned Downtime',
        text: (
          <span>
            <span className="ml-2 mr-2"><UnPlannedIcon/></span>
            {t.workOrders.form.category_options[3]}
          </span>
        ),
      },
      {
        key: 4,
        value: 'inspection',
        text: (
          <span>
            <span className="ml-2 mr-2">
              <CategoryInspection />
            </span>
            {t.workOrders.form.category_options[4]}
          </span>
        ),
      },
      {
        key: 5,
        value: 'electrical',
        text: (
          <span>
            <span className="ml-2 mr-2">
              <Electrical />
            </span>
            {t.workOrders.form.category_options[5]}
          </span>
        ),
      },

      {
        key: 6,
        value: 'mechanical',
        text: (
          <span>
            <span className="ml-2 mr-2">
              <CategoryMechanical />
            </span>
            {t.workOrders.form.category_options[6]}
          </span>
        ),
      },

      {
        key: 7,
        value: 'HVAC',
        text: (
          <span>
            <span className="ml-2 mr-2">
              <Refrigeration />
            </span>
            {t.workOrders.work_order_category.HVAC}
          </span>
        ),
      },
      {
        key: 8,
        value: 'preventive maintenance',
        text: (
          <span>
            <span className="ml-2 mr-2">
              <CatgoryPreventive />
            </span>
            {t.workOrders.form.category_options[8]}
          </span>
        ),
      },
      {
        key: 9,
        value: 'project',
        text: (
          <span>
            <span className="ml-2 mr-2">
              <Project />
            </span>
            {t.workOrders.form.category_options[9]}
          </span>
        ),
      },
      {
        key: 10,
        value: 'safety',
        text: (
          <span>
            <span className="ml-2 mr-2">
              <Safety />
            </span>
            {t.workOrders.form.category_options[10]}
          </span>
        ),
      },

      {
        key: 11,
        value: 'sop',
        text: (
          <span>
            <span className="ml-2 mr-2">
              <OperationProcedure />
            </span>
            {t.workOrders.form.category_options[11]}
          </span>
        ),
      },
    ],
  });

  const [data, setData] = useState({
    title: '',
    description: '',
    customer: '',
    location: '',
    orderType: 'reactive',
    dueDate: format(new Date(), 'yyyy-MM-dd'),
    includeDueTime: false,
    dueTime: '08:00',
    repeatEvery: 'daily',
    daysRepeat: {
      sat: true,
      sun: true,
      mon: true,
      tue: true,
      wed: true,
      thu: true,
      fri: true,
    },
    repeatEveryWeek: 1,
    repeatEveryMonth: 1,
    repeatMonthDay: 'd1',
    repeatEveryYear: 1,
    endRepeat: 10,
    estimationTime: 0,
    laborCost: 0,
    priority: 'low',

    category: '',
    people: [],
    teams: [],
    assets: [],
    parts: [],
    area: [],
    partsData: [],
    sets: [],
    procedure: '',
    endDate: format(new Date(), 'yyyy-MM-dd'),
    endType: 'on',
    qrcode: '',
  });

  const areAnyDaysSelected = Object?.values(data?.daysRepeat).some(
    (day) => day
  );

  const HandleUnitType = (data) => {
    let unitType;
    if (data) {
      unitType = allQtyOptions.find((el) => el.value == data);
    }
    return data ? unitType?.text : null;
  };

  const [validation, setValidation] = useState({
    title: {
      error: false,
      pattern: (title) => title != '',
      skipped: false,
      message: t.common.required_field,
    },
    category: {
      error: true,
      pattern: (category) => category != '',
      skipped: false,
      message: t.common.required_field,
    },

    daysRepeat: {
      // pattern: (customer) => customer != '',
      skipped: false,
      message: t.common.required_field,
      error: data?.orderType === 'scheduled' ? !areAnyDaysSelected : false,
    },
    // customer: {
    //   error: false,
    //   pattern: (customer) => customer != '',
    //   skipped: false,
    //   message: t.common.required_field,
    // },
    endRepeat: {
      error:
        data?.orderType === 'scheduled' &&
        data?.endType === 'after' &&
        data?.endRepeat == '',
      pattern: (endRepeat) => endRepeat !== '',
      skipped: false,
      message: t.common.required_field,
    },
    endDate: {
      error:
        data?.orderType === 'scheduled' &&
        data?.endType === 'on' &&
         data?.endDate == null,
      pattern: (endRepeat) => endRepeat !== '',
      skipped: false,
      message: t.common.required_field,
    },
    location: {
      error: false,
      pattern: (location) => location != '',
      skipped: false,
      message: t.common.required_field,
    },
    // orderType: {
    //   error: false,
    //   pattern: (orderType) =>
    //     orderType != '' &&
    //     options.orderTypes.some((el) => el.value == orderType),
    //   skipped: false,
    //   message: t.common.required_field,
    // },
    // dueDate: {
    //   error: false,
    //   pattern: (date) =>
    //     moment(date).format('YYYY-MM-DD') === moment(date).format('YYYY-MM-DD'),
    //   skipped: false,
    //   message: 'Invalid date',
    // },
    // dueTime: {
    //   error: false,
    //   pattern: (time) =>
    //     moment(time).format('HH:mm') === moment(time).format('HH:mm') &&
    //     time != '',
    //   skipped: false,
    //   message: 'Invalid time',
    // },

    procedure: {
      error: data.procedure != '' ? false : true,
      pattern: (procedure) => procedure != '',
      skipped: false,
      message: t.common.required_field,
    },
  });

  const [validForm, setValidForm] = useState(false);

  const fetchParts = async (id, areaId) => {
    let the_options_parts = [];
    let the_options_sets = [];
    const response = await getAllParts(
      organisationId,
      '',
      1,
      99999,
      [],
      [],
      [],
      [],
      [id],
      [areaId]
    );

    if (response?.response?.length > 0) {
      response.response.forEach((el) => {
        // Find the inventory entry that matches the id and areaId
        const matchedInventory = el.attributes.inventory.find(
          (inv) => inv.location.data.id == id && inv.area.data.id == areaId
        );

        // Use the matched inventory's quantity or default to 0 if no match is found
        const quantity = matchedInventory ? matchedInventory.quantity : 0;

        the_options_parts.push({
          key: `${el.id}`,
          value: `${el.id}`,
          text: `${el.attributes.name}`,
          description: `${
            quantity !== 0
              ? `${t.parts.table.qty_in_stock}: ${quantity} ${HandleUnitType(
                  el?.attributes?.measuringUnit
                )}`
              : t.parts.status.out_of_stock
          }`,
          disabled: quantity == 0 ? true : false,
        });
      });
    }
    const setResponse = await getAllSets(
      organisationId,
      '',
      1,
      99999,
      [],
      id,
      areaId
    );

    const inventoryData = filterInventoryData(
      setResponse?.response,
      id,
      areaId
    );

    setOptions((prev) => {
      return {
        ...prev,

        partsData: the_options_parts,
        sets: inventoryData,
      };
    });
  };

  const fetchArea = async (id) => {
    const response = await getAllAreaByLocationId(organisationId, id);

    let the_options_area = [];
    if (response.length > 0) {
      response.forEach((el) => {
        the_options_area.push({
          key: el.id,
          value: `${el.id}`,
          text: `${el.attributes.name}`,
        });
      });
    }

    setOptions((prev) => {
      return {
        ...prev,
        partsData: [],
        sets: [],
        area: the_options_area,
      };
    });
  };

  const resetForm = () => {
    setData({
      title: '',
      description: '',
      customer: '',
      location: '',
      orderType: 'reactive',
      dueDate: format(new Date(), 'yyyy-MM-dd'),
      includeDueTime: false,
      dueTime: '08:00',
      repeatEvery: 'daily',
      daysRepeat: {
        sat: true,
        sun: true,
        mon: true,
        tue: true,
        wed: true,
        thu: true,
        fri: true,
      },
      repeatEveryWeek: 1,
      repeatEveryMonth: 1,
      repeatMonthDay: 'd1',
      repeatEveryYear: 1,
      endRepeat: 10,
      estimationTime: 0,
      laborCost: 0,
      priority: 'low',

      category: '',
      people: [],
      teams: [],
      assets: [],
      parts: [],
      procedure: '',
      endDate: format(new Date(), 'yyyy-MM-dd'),
      endType: 'on',
      qrcode: '',
      area: [],
      partsData: [],
      sets: [],
    });

    setAttachments({
      image: null,
      file: null,
    });

    setFetchingPartsData(true);

    setOptions({
      customers: [],
      locations: [],
      people: [],
      teams: [],
      assets: [],
      parts: [],
      area: [],
      partsData: [],
      sets: [],
      procedures: [],
      priority: [
        {
          key: 0,
          value: 'low',
          text: t.workOrders.form.priority_options[1],
        },

        {
          key: 1,
          value: 'medium',
          text: t.workOrders.form.priority_options[2],
        },
        {
          key: 2,
          value: 'high',
          text: t.workOrders.form.priority_options[3],
        },
      ],
      repeatEvery: [
        {
          key: 0,
          value: 'daily',
          text: t.workOrders.details_view.daily,
        },

        {
          key: 1,
          value: 'weekly',
          text: t.workOrders.details_view.weekly,
        },
        {
          key: 2,
          value: 'monthly',
          text: t.workOrders.details_view.monthly,
        },
        {
          key: 1,
          value: 'yearly',
          text: t.workOrders.details_view.yearly,
        },
      ],
      weeks: [],
      months: [],
      monthsDays: [],
      years: [],
      orderTypes: [
        {
          key: 0,
          value: 'reactive',
          text: t.workOrders.form.order_type_1,
        },
        {
          key: 1,
          value: 'scheduled',
          text: t.workOrders.form.order_type_2,
        },
      ],
      categories: [
        {
          key: 0,
          value: 'damage',
          text: (
            <span>
              <span className="ml-2 mr-2">
                <Damage />
              </span>
              {t.workOrders.form.category_options[0]}
            </span>
          ),
        },
        {
          key: 1,
          value: 'corrective action Downtime',
          text: (
            <span>
              <span className="ml-2 mr-2">
                <CorrectiveIcon />
              </span>
              {t.workOrders.form.category_options[1]}
            </span>
          ),
        },
        {
          key: 2,
          value: 'planned Downtime',
          text: (
            <span>
              <span className="ml-2 mr-2">
                <PlannedIcon />
              </span>
              {t.workOrders.form.category_options[2]}
            </span>
          ),
        },
        {
          key: 3,
          value: 'unplanned Downtime',
          text: (
            <span>
              <span className="ml-2 mr-2">
                <UnPlannedIcon />
              </span>
              {t.workOrders.form.category_options[3]}
            </span>
          ),
        },
        {
          key: 4,
          value: 'inspection',
          text: (
            <span>
              <span className="ml-2 mr-2">
                <CategoryInspection />
              </span>
              {t.workOrders.form.category_options[4]}
            </span>
          ),
        },
        {
          key: 5,
          value: 'electrical',
          text: (
            <span>
              <span className="ml-2 mr-2">
                <Electrical />
              </span>
              {t.workOrders.form.category_options[5]}
            </span>
          ),
        },

        {
          key: 6,
          value: 'mechanical',
          text: (
            <span>
              <span className="ml-2 mr-2">
                <CategoryMechanical />
              </span>
              {t.workOrders.form.category_options[6]}
            </span>
          ),
        },

        {
          key: 7,
          value: 'HVAC',
          text: (
            <span>
              <span className="ml-2 mr-2">
                <Refrigeration />
              </span>
              {t.workOrders.work_order_category.HVAC}
            </span>
          ),
        },
        {
          key: 8,
          value: 'preventive maintenance',
          text: (
            <span>
              <span className="ml-2 mr-2">
                <CatgoryPreventive />
              </span>
              {t.workOrders.form.category_options[8]}
            </span>
          ),
        },
        {
          key: 9,
          value: 'project',
          text: (
            <span>
              <span className="ml-2 mr-2">
                <Project />
              </span>
              {t.workOrders.form.category_options[9]}
            </span>
          ),
        },
        {
          key: 10,
          value: 'safety',
          text: (
            <span>
              <span className="ml-2 mr-2">
                <Safety />
              </span>
              {t.workOrders.form.category_options[10]}
            </span>
          ),
        },

        {
          key: 11,
          value: 'sop',
          text: (
            <span>
              <span className="ml-2 mr-2">
                <OperationProcedure />
              </span>
              {t.workOrders.form.category_options[11]}
            </span>
          ),
        },
      ],
    });

    setFetchingCustomer(true);
    setFetchingLocation(true);
    setFetchingPeople(true);
    setFetchingTeams(true);
    setFetchingAssets(true);
    setFetchingParts(true);
    setFetchingProcedures(true);
    setFetchingWeeks(true);
    setFetchingMonths(true);
    setFetchingMonthsDays(true);
    setFetchingYears(true);

    setValidation({
      title: {
        error: false,
        pattern: (title) => title != '',
        skipped: false,
        message: t.common.required_field,
      },
      category: {
        error: false,
        pattern: (category) => category != '',
        skipped: false,
        message: t.common.required_field,
      },
      daysRepeat: {
        // pattern: (customer) => customer != '',
        skipped: false,
        message: t.common.required_field,
        error: data?.orderType === 'scheduled' ? !areAnyDaysSelected : false,
      },

      endRepeat: {
        error:
          data?.orderType === 'scheduled' &&
          data?.endType === 'after' &&
          data?.endRepeat == '',
        pattern: (endRepeat) => endRepeat !== '',
        skipped: false,
        message: t.common.required_field,
      },
      endDate: {
        error:
          data?.orderType === 'scheduled' &&
          data?.endType === 'on' &&
           data?.endDate == null,
        pattern: (endRepeat) => endRepeat !== '',
        skipped: false,
        message: t.common.required_field,
      },

      // customer: {
      //   error: false,
      //   pattern: (customer) => customer != '',
      //   skipped: false,
      //   message: t.common.required_field,
      // },
      location: {
        error: false,
        pattern: (location) => location != '',
        skipped: false,
        message: t.common.required_field,
      },
      // orderType: {
      //   error: false,
      //   pattern: (orderType) =>
      //     orderType != '' &&
      //     options.orderTypes.some((el) => el.value == orderType),
      //   skipped: false,
      //   message: t.common.required_field,
      // },
      // dueDate: {
      //   error: false,
      //   pattern: (date) =>
      //     moment(date).format('YYYY-MM-DD') ===
      //     moment(date).format('YYYY-MM-DD'),
      //   skipped: false,
      //   message: 'Invalid date',
      // },
      // dueTime: {
      //   error: false,
      //   pattern: (time) =>
      //     moment(time).format('HH:mm') === moment(time).format('HH:mm') &&
      //     time != '',
      //   skipped: false,
      //   message: 'Invalid time',
      // },
      procedure: {
        error: false,
        pattern: (procedure) => procedure != '',
        skipped: false,
        message: t.common.required_field,
      },
    });

    setModalWidth(Math.max(window.innerWidth / 2, 514));

    setValidForm(false);

    setInitChange(false);
  };

  useEffect(() => {
    if (toggled) {
      fetchOptions();

      if (targetedData) {
        setData({
          title: targetedData.attributes.title,
          description: targetedData.attributes.description,
          customer: targetedData.attributes.customer.data?.id,
          location: targetedData.attributes.location.data?.id,
          orderType: targetedData.attributes.orderType,
          dueDate: format(
            new Date(targetedData.attributes.dueDate),
            'yyyy-MM-dd'
          ),
          endDate: targetedData?.attributes?.endDate
            ? format(new Date(targetedData.attributes.endDate), 'yyyy-MM-dd')
            : format(new Date(), 'yyyy-MM-dd'),
          endType: targetedData?.attributes?.endType
            ? targetedData?.attributes?.endType
            : 'on',
          includeDueTime: targetedData.attributes.includeDueTime,
          dueTime: format(
            new Date(
              targetedData.attributes.dueDate +
                'T' +
                `${targetedData.attributes.dueTime || '08:00:00'}`
            ),
            'kk:mm'
          ),
          repeatEvery: targetedData.attributes.repeatEvery,
          daysRepeat: {
            sat: targetedData.attributes.daysRepeat?.sat,
            sun: targetedData.attributes.daysRepeat?.sun,
            mon: targetedData.attributes.daysRepeat?.mon,
            tue: targetedData.attributes.daysRepeat?.tue,
            wed: targetedData.attributes.daysRepeat?.wed,
            thu: targetedData.attributes.daysRepeat?.thu,
            fri: targetedData.attributes.daysRepeat?.fri,
          },
          repeatEveryWeek: targetedData.attributes.repeatEveryWeek,
          repeatEveryMonth: targetedData.attributes.repeatEveryMonth,
          repeatMonthDay: targetedData.attributes.repeatMonthDay,
          repeatEveryYear: targetedData.attributes.repeatEveryYear,
          endRepeat: targetedData.attributes.endRepeat,

          estimationTime: targetedData.attributes.estimationTime
            ? targetedData.attributes.estimationTime
            : 0,
          laborCost: targetedData.attributes.laborCost
            ? targetedData.attributes.laborCost
            : 0,
          priority: targetedData.attributes.priority
            ? targetedData.attributes.priority
            : 'low',
          category: targetedData.attributes.category
            ? targetedData.attributes.category
            : '',

          qrcode: targetedData.attributes.id,
          people:
            targetedData.attributes.people.data?.length > 0
              ? targetedData.attributes.people.data.map((el_user) => el_user.id)
              : [],
          teams:
            targetedData.attributes.teams.data?.length > 0
              ? targetedData.attributes.teams.data.map((el_team) => el_team.id)
              : [],
          assets:
            targetedData.attributes.assets.data?.length > 0
              ? targetedData.attributes.assets.data.map(
                  (el_asset) => el_asset.id
                )
              : [],
          parts:
            targetedData.attributes.parts.data?.length > 0
              ? targetedData.attributes.parts.data.map((el_part) => el_part.id)
              : [],
          partsData:
            targetedData.attributes.parts.data?.length > 0
              ? targetedData.attributes.parts.data.map((el_part) =>
                  String(el_part.id)
                )
              : [],
          sets:
            targetedData.attributes.sets.data?.length > 0
              ? targetedData.attributes.sets.data.map((el_part) =>
                  String(el_part.id)
                )
              : [],
          area: targetedData?.attributes?.area?.data?.id
            ? String(targetedData?.attributes?.area?.data?.id)
            : null,
          procedure: targetedData.attributes.procedure.data?.id
            ? targetedData.attributes.procedure.data?.id
            : '',
          status: targetedData.attributes.status,
        });
       
        setAttachments({
          ...attachments,
          image: targetedData?.attributes.image?.data
            ? {
                id: targetedData?.attributes.image?.data?.id,
                file: urlToFile(
                  targetedData?.attributes.image?.data?.attributes.url,
                  targetedData?.attributes.image?.data?.attributes.name
                ),
                name: targetedData?.attributes.image?.data?.attributes.name,
                type: targetedData?.attributes.image?.data?.attributes.mime,
                cancelPopup: false,
                isChanged: false,
              }
            : null,
          file: targetedData?.attributes.file?.data
            ? {
                id: targetedData?.attributes.file?.data?.id,
                file: urlToFile(
                  targetedData?.attributes.file?.data?.attributes.url,
                  targetedData?.attributes.file?.data?.attributes.name
                ),
                name: targetedData?.attributes.file?.data?.attributes.name,
                type: targetedData?.attributes.file?.data?.attributes.mime,
                cancelPopup: false,
                isChanged: false,
              }
            : null,
        });

        
      }
      // }
    }
  }, [targetedData, toggled]);

  const filterInventoryData = (response, locationId, areaId) => {
    let filteredInventory = [];

    response.forEach((item) => {
      let matchCount = 0;
      let setMatchCount = 0;

      // Iterate through each part's inventory
      item?.attributes?.parts?.length > 0 &&
        item.attributes.parts.forEach((part) => {
          // Check each inventory item for the selected location match
          part?.part?.data?.attributes?.inventory &&
            part.part.data.attributes.inventory.forEach((inv) => {
              if (
                inv.location.data.id === locationId &&
                inv.area.data.id === areaId &&
                inv.quantity !== 0
              ) {
                matchCount++;
              }
            });
        });

      // If both parts' inventories match the selected location, push to filteredInventory
      if(item?.attributes?.parts?.length !== 0){
        if (
          matchCount >= 2 &&
          !filteredInventory.some((inv) => inv.key == item.id)
        ) {
          filteredInventory.push({
            key: `${item.id}`,
            value: `${item.id}`,
            text: item?.attributes?.name,
            description: '',
            disabled: false,
          });
        } else if (!filteredInventory.some((inv) => inv.key == item.id)) {
          filteredInventory.push({
            key: `${item.id}`,
            value: `${item.id}`,
            text: item?.attributes?.name,
            description: t?.parts?.status.out_of_stock,
            disabled: true,
          });
        }
  
      }
     
      item?.attributes?.sets?.length > 0 &&
        item.attributes.sets.forEach((set) => {
          set?.set?.data?.attributes?.parts &&
            set.set.data.attributes.parts.forEach((part_single) => {
              // Check each inventory item for the selected location match
              part_single?.part?.data?.attributes?.inventory &&
                part_single.part.data.attributes.inventory.forEach((inv) => {
                  if (
                    inv.location.data.id === locationId &&
                    inv.area.data.id === areaId &&
                    inv.quantity !== 0
                  ) {
                    setMatchCount++;
                  }
                });
            });
          if (setMatchCount >= 2) {
            if (
              item?.attributes?.parts?.length == 0 &&
              !filteredInventory.some((inv) => inv.key == item.id)
            ) {
            
              filteredInventory.push({
                key: `${item.id}`,
                value: `${item.id}`,
                text: item?.attributes?.name,
                description: '',
                disabled: false,
              });
            }

            if (
              !filteredInventory.some((inv) => inv.key == set?.set?.data?.id)
            ) {
            
              filteredInventory.push({
                key: `${set?.set?.data?.id}`,
                value: `${set?.set?.data?.id}`,
                text: set?.set?.data?.attributes?.name,
                description: '',
                disabled: false,
              });
            }
          } else {
            if (
              item?.attributes?.parts?.length === 0 &&
              !filteredInventory.some((inv) => inv.key == item.id)
            ) {
              filteredInventory.push({
                key: `${item.id}`,
                value: `${item.id}`,
                text: item?.attributes?.name,
                description: t.parts.status.out_of_stock,
                disabled: true,
              });
            }

            if (
              !filteredInventory.some((inv) => inv.key == set?.set?.data?.id)
            ) {
              filteredInventory.push({
                key: `${set?.set?.data?.id}`,
                value: `${set?.set?.data?.id}`,
                text: set?.set?.data?.attributes?.name,
                description: t.parts.status.out_of_stock,
                disabled: true,
              });
            }
          }
        });
    });

    return filteredInventory;
  };

  useEffect(() => {
    if (data?.location) {
      if (!fetchingPartsData) {
        setData({
          ...data,
          area: '',
          partsData: [],
          sets: [],
        });
      }

      fetchArea(data?.location);
    }
  }, [data?.location]);

  useEffect(() => {
    if (data?.location && data?.area) {
      if (!fetchingPartsData) {
        setData({
          ...data,
          partsData: [],
          sets: [],
        });
        setOptions((prev) => {
          return {
            ...prev,
            partsData: [],
            sets: [],
          };
        });
      }

      setFetchingPartsData(false);
      fetchParts(data?.location, data?.area);
    }
  }, [data?.area]);

  //Fetch all options
  const fetchOptions = async () => {
    const [
      customers,
      locations,
      people,
      teams,
      assets,
      // parts,
      procedures,
      weeks,
      months,
      monthDays,
      years,
      // sets,
      // area,
      // partsData,
      // sets,
    ] = await Promise.all([
      getAllCustomers(organisationId,'',1,99999),
      getAllLocations(organisationId),
      getActiveUsersByRole(
        ['user', 'supervisor', 'limited_admin', 'admin', 'requester'],
        organisationId
      ),
      getAllTeams(organisationId,'',1,99999),
      getAllAssets(organisationId,'',1,9999),
      // getAllPartsByAssets(
      //   targetedData?.attributes.assets.data?.map((asset) => asset.id),
      //   organisationId
      // ),
      getAllProcedures(false, organisationId),
      fetchWeekOptions(),
      fetchMonthOptions(),
      fetchMonthDaysOptions(),
      fetchYearsOptions(),
      // getAllSets(organisationId),
      // getAllAreaByLocationId(
      //   organisationId,
      //   targetedData.attributes.location.data?.id
      // ),
      // getAllParts(
      //   organisationId,
      //   '',
      //   1,
      //   99999,
      //   [],
      //   [],
      //   [],
      //   [],
      //   [targetedData.attributes.location.data?.id],
      //   [targetedData.attributes.area.data?.id]
      // ),
      // getAllSets(
      //   organisationId,
      //   '',
      //   1,
      //   99999,
      //   [],
      //   targetedData.attributes.location.data?.id,
      //   targetedData.attributes.area.data?.id
      // ),
    ]);

    let customers_options = [];
    if (customers.response) {
      customers_options = customers.response.map((el) => {
        const the_image = el.attributes.logo.data
          ? IMAGES_URL + el.attributes.logo.data.attributes.url
          : LetteredAvatarImageUrl(el.attributes.name);
        return {
          key: el.id,
          value: el.id,
          text: el.attributes.name,
          image: { avatar: true, src: the_image, size: 'tiny' },
          locationId: el?.attributes?.location?.data?.id || '',
        };
      });

      setFetchingCustomer(false);
    }

    let the_options = [];
    if (locations?.length > 0) {
      locations.forEach((el) => {
        // if(roleData !== "super_admin"){
        //   if(allIds.includes(el.id)){
        //     let people = el.attributes?.users?.data?.length
        //     ? el.attributes?.users?.data.map((one) => one.id)
        //     : [];
        //   let teams = el.attributes?.teams?.data?.length
        //     ? el.attributes?.teams?.data.map((one) => one.id)
        //     : [];

        //     the_options.push({
        //       key: el.id,
        //       value: el.id,
        //       text: el.attributes.name,
        //       people,
        //       teams,
        //     })
  
        //   // return {
        //   //   key: el.id,
        //   //   value: el.id,
        //   //   text: el.attributes.name,
        //   //   people,
        //   //   teams,
        //   //   // image: { avatar: true, src: the_image, size: 'tiny' },
        //   // };
        //   }
        // }
        // else{
          let people = el.attributes?.users?.data?.length
          ? el.attributes?.users?.data.map((one) => one.id)
          : [];
        let teams = el.attributes?.teams?.data?.length
          ? el.attributes?.teams?.data.map((one) => one.id)
          : [];

          the_options.push({
            key: el.id,
            value: el.id,
            text: el.attributes.name,
            people,
            teams,
          })
        // }
      
      });
      setFetchingLocation(false);
    }

    let people_options = [];
    if (people.data) {
      people_options = people.data.map((el) => {
        const the_image = el.profileImage?.url
          ? IMAGES_URL + el.profileImage?.url
          : LetteredAvatarImageUrl(el?.firstName + ' ' + el.lastName);
        let teams_string_array = el?.teams?.map((one) => one.name);
        return {
          key: el.id,
          value: el.id,
          text:
            el.firstName +
            ' ' +
            el.lastName +
            ' (' +
            t.people.roles[el.role.name] +
            ')',
          description: `${teams_string_array.join(' , ')}`,
          image: { avatar: true, src: the_image, size: 'tiny' },
        };
      });

      setFetchingPeople(false);
    }

    let teams_options = [];
    if (teams?.response) {
      teams_options = teams.response.map((el) => {
        const the_image = LetteredAvatarImageUrl(el.attributes.name);

        return {
          key: el.id,
          value: el.id,
          text: el.attributes.name,
          image: { avatar: true, src: the_image, size: 'tiny' },
        };
      });

      setFetchingTeams(false);
    }

    let assets_options = [];
    let inventoryData = [];
    if (assets?.response) {
      assets_options = assets.response.map((el) => {
        // const the_image = el.attributes.image.data
        //   ? IMAGES_URL + el.attributes.image?.data.attributes.url
        //   : `http://gravatar.com/avatar/${md5(
        //       el?.attributes.name
        //     )}?d=identicon`;
        return {
          key: el.id,
          value: el.id,
          text: el.attributes.name,
          // image: { avatar: true, src: the_image, size: 'tiny' },
        };
      });

      setFetchingAssets(false);
    }

    // if (sets?.response) {
    //   inventoryData = filterInventoryData(
    //     sets?.response
    //     // id,
    //     // areaId
    //   );
    //   // setOptions((prev) => {
    //   //   return {
    //   //     ...prev,

    //   //     sets: inventoryData,
    //   //   };
    //   // });
    // }

    // let the_options_area = [];
    // if (area.length > 0) {
    //   area.forEach((el) => {
    //     the_options_area.push({
    //       key: `${el.id}`,
    //       value: `${el.id}`,
    //       text: `${el.attributes.name}`,
    //     });
    //   });
    // }

    // let the_options_parts = [];
    // if (partsData?.response?.length > 0) {
    //   partsData.response.forEach((el) => {
    //     the_options_parts.push({
    //       key: `${el.id}`,
    //       value: `${el.id}`,
    //       text: `${el.attributes.name}`,
    //       description: `${
    //         el.attributes.status !== 'outOfStock'
    //           ? ` ${t.parts.table.qty_in_stock}: ${el?.attributes?.qtyInStock} ${el?.attributes?.measuringUnit}`
    //           : t.parts.status.out_of_stock
    //       } `,
    //       disabled: el.attributes.status == 'outOfStock' ? true : false,
    //     });
    //   });
    // }

    // const inventoryData = filterInventoryData(
    //   sets?.response,
    //   targetedData?.attributes?.location?.data?.id,
    //   targetedData?.attributes?.area?.data?.id,
    // );

    // let parts_options = [];
    // if (parts) {
    //   parts_options = parts.map((el) => {
    //     const quantity = el.attributes.quantity ? el.attributes.quantity : 0;
    //     // const the_image = el.attributes.image.data
    //     //   ? IMAGES_URL + el.attributes.image.data?.attributes.url
    //     //   : `http://gravatar.com/avatar/${md5(
    //     //       el?.attributes.name
    //     //     )}?d=identicon`;
    //     return {
    //       key: el.id,
    //       value: el.id,
    //       text: el.attributes.name + '(' + quantity + ')',
    //       // image: { avatar: true, src: the_image, size: 'tiny' },
    //     };
    //   });

    //   setFetchingParts(false);
    // }

    let procedures_options = [];
    if (procedures?.response) {
      procedures_options = procedures.response.map((el) => {
        return {
          key: el.id,
          value: el.id,
          text: el.attributes.title,
        };
      });

      setFetchingProcedures(false);
    }

    if (weeks) {
      setFetchingWeeks(false);
    }

    if (months) {
      setFetchingMonths(false);
    }

    if (monthDays) {
      setFetchingMonthsDays(false);
    }

    if (years) {
      setFetchingYears(false);
    }

    setOptions((prev) => {
      return {
        ...prev,
        customers: customers.response?.length > 0 ? customers_options : [],
        locations: locations?.length > 0 ? the_options : [],
        people: people.data?.length > 0 ? people_options : [],
        teams: teams?.response?.length > 0 ? teams_options : [],
        assets: assets.response?.length > 0 ? assets_options : [],
        // parts: parts?.length > 0 ? parts_options : [],
        procedures: procedures.response?.length > 0 ? procedures_options : [],

        weeks: weeks,
        months: months,
        monthDays: monthDays,
        years: years,
        // sets: inventoryData,
      };
    });

    // setOptions({
    //   ...options,
    //   customers: customers.response?.length > 0 ? customers_options : [],
    //   locations: locations?.length > 0 ? locations_options : [],
    //   people: people.data?.length > 0 ? people_options : [],
    //   teams: teams.response?.length > 0 ? teams_options : [],
    //   assets: assets.response?.length > 0 ? assets_options : [],
    //   // parts: parts?.length > 0 ? parts_options : [],
    //   procedures: procedures.response?.length > 0 ? procedures_options : [],

    //   weeks: weeks,
    //   months: months,
    //   monthDays: monthDays,
    //   years: years,
    //   // area: area.length > 0 ? the_options_area : [],
    //   // partsData: partsData?.response.length > 0 ? the_options_parts : [],
    //   // sets:inventoryData,
    // });
  };

  const onFileChange = (files, name) => {
    setInitChange(true);
    var randomnumber = Math.floor(Math.random() * (0 - 100 + 1)) + 100;
    setAttachments({
      ...attachments,
      [name]: {
        file: files[0],
        id: randomnumber,
        name: files[0].name,
        type: files[0].type,
        cancelPopup: false,
        isChanged: true,
      },
    });
  };

  useEffect(() => {
    if (dueDateParam)
      setData({
        ...data,
        dueDate: dueDateParam
          ? format(new Date(dueDateParam), 'yyyy-MM-dd')
          : format(new Date(), 'yyyy-MM-dd'),
      });
  }, [dueDateParam]);

  /*
   * The function `fetchWeekOptions` generates an array of objects representing week options
   */
  const fetchWeekOptions = () => {
    let weeks = [];

    for (let i = 1; i < 21; i++) {
      const obj = {
        key: i,
        value: i,
        text: i,
      };

      weeks.push(obj);
    }

    return weeks;
  };

  /*
   * The function `fetchMonthOptions` generates an array of objects representing months from 1 to 12.
   */
  const fetchMonthOptions = () => {
    let months = [];

    for (let i = 1; i < 25; i++) {
      const obj = {
        key: i,
        value: i,
        text: i,
      };

      months.push(obj);
    }
    return months;
  };

  /*
   * The function `fetchMonthDaysOptions` generates an array of objects representing options for selecting days in a month, with special handling for the suffixes 'st', 'nd', 'rd', and 'th'.
   */
  const fetchMonthDaysOptions = () => {
    let monthsDays = [];

    for (let i = 1; i < 32; i++) {
      let day_th = 'th';
      if (i == 1) day_th = 'st';
      if (i == 2) day_th = 'nd';
      if (i == 3) day_th = 'rd';

      const obj = {
        key: i,
        value: 'd' + i,
        text: i > 28 ? i + day_th + ' (or last)' : i + day_th,
      };

      monthsDays.push(obj);
    }

    setOptions({
      ...options,
      monthsDays: monthsDays,
    });
  };

  /*
   * The function `fetchYearsOptions` generates an array of objects representing years
   */
  const fetchYearsOptions = () => {
    let years = [];

    for (let i = 1; i < 11; i++) {
      const obj = {
        key: i,
        value: i,
        text: i,
      };

      years.push(obj);
    }

    return years;
  };

  const fetchCustomerOptions = async () => {
    const fetchData =  await getAllCustomers(organisationId,'',1,99999);

    if (fetchData.response) {
      let the_options = fetchData.response.map((el) => {
        const the_image = el.attributes.logo.data
          ? IMAGES_URL + el.attributes.logo.data.attributes.url
          : LetteredAvatarImageUrl(el.attributes.name);
        return {
          key: el.id,
          value: el.id,
          text: el.attributes.name,
          image: { avatar: true, src: the_image, size: 'tiny' },
          locationId: el?.attributes?.location?.data?.id || '',
        };
      });
      setOptions({
        ...options,
        customers: the_options,
      });

      return the_options;
    }
  };

  const fetchLocationsOptions = async (customerID) => {
    const fetchData = await getAllLocations(organisationId);
    let the_options = [];
    if (fetchData) {
       fetchData.forEach((el) => {
      
         
  
          // return {
          //   key: el.id,
          //   value: el.id,
          //   text: el.attributes.name,
          //   people,
          //   teams,
          //   // image: { avatar: true, src: the_image, size: 'tiny' },
          // };
          
     
          let people = el.attributes?.users?.data?.length
          ? el.attributes?.users?.data.map((one) => one.id)
          : [];
        let teams = el.attributes?.teams?.data?.length
          ? el.attributes?.teams?.data.map((one) => one.id)
          : [];

          the_options.push({
            key: el.id,
            value: el.id,
            text: el.attributes.name,
            people,
            teams,
          })
        
      
      });
      setOptions((prev) => {
        return {
          ...prev,
          locations: the_options,
        };
      });
      return the_options;
    }
  };

  const fetchPeopleOptions = async () => {
    const fetchData = await getActiveUsersByRole(
      ['user', 'supervisor', 'limited_admin', 'admin', 'requester'],
      organisationId
    );

    if (fetchData.data) {
      let the_options = fetchData.data.map((el) => {
        const the_image = el.profileImage?.url
          ? IMAGES_URL + el.profileImage?.url
          : LetteredAvatarImageUrl(el?.firstName + ' ' + el.lastName);
        let teams_string_array = el?.teams?.map((one) => one.name);
        return {
          key: el.id,
          value: el.id,
          text:
            el.firstName +
            ' ' +
            el.lastName +
            ' (' +
            t.people.roles[el.role.name] +
            ')',
          description: `${teams_string_array.join(' , ')}`,
          image: { avatar: true, src: the_image, size: 'tiny' },
        };
      });
      setOptions({
        ...options,
        people: the_options,
      });

      return the_options;
    }
  };

  const fetchTeamsOptions = async () => {
    const fetchData = await getAllTeams(organisationId,'',1,99999);

    if (fetchData.response) {
      let the_options = fetchData.response.map((el) => {
        const the_image = LetteredAvatarImageUrl(el.attributes.name);

        return {
          key: el.id,
          value: el.id,
          text: el.attributes.name,
          image: { avatar: true, src: the_image, size: 'tiny' },
        };
      });
      setOptions({
        ...options,
        teams: the_options,
      });

      return the_options;
    }
  };

  const fetchAssetsOptions = async (customerID) => {
    const fetchData = await getAllAssets(organisationId,'',1,9999);
    if (fetchData) {
      let the_options = fetchData.map((el) => {
        return {
          key: el.id,
          value: el.id,
          text: el.attributes.name,
          // image: { avatar: true, src: the_image, size: 'tiny' },
        };
      });

      setOptions({
        ...options,
        assets: the_options,
        // locations,
      });

      return the_options;
    }
  };

  // const fetchPartsOptions = async (assetsID) => {
  //   const fetchData = await getAllPartsByAssets(assetsID, organisationId);

  //   if (fetchData) {
  //     let the_options = fetchData.map((el) => {
  //       const quantity = el.attributes.quantity ? el.attributes.quantity : 0;
  //       // const the_image = el.attributes.image.data
  //       //   ? IMAGES_URL + el.attributes.image.data?.attributes.url
  //       //   : `http://gravatar.com/avatar/${md5(
  //       //       el?.attributes.name
  //       //     )}?d=identicon`;
  //       return {
  //         key: el.id,
  //         value: el.id,
  //         text: el.attributes.name + '(' + quantity + ')',
  //         description: el.attributes.asset.data?.attributes.name,
  //         // image: { avatar: true, src: the_image, size: 'tiny' },
  //       };
  //     });

  //     setOptions({
  //       ...options,
  //       parts: the_options,
  //     });

  //     return the_options;
  //   }
  // };

  const fetchProceduresOptions = async () => {
    const fetchData = await getAllProcedures(false.valueOf, organisationId);

    if (fetchData.response) {
      let the_options = fetchData.response.map((el) => {
        return {
          key: el.id,
          value: el.id,
          text: el.attributes.title,
        };
      });

      setOptions({
        ...options,
        procedures: the_options,
      });

      return the_options;
    }
  };

  /*
   * The `onValuesChange` function in JavaScript handles changes in form field values, updating state based on the type of field (boolean or dropdown) and performing validation.
   */
  const onValuesChange = (name) => (event, value) => {
    setInitChange(true);
    const boolean_fields = ['includeDueTime'];
    const dropdown_fields = [
      'customer',
      'location',
      'orderType',
      'category',
      'code',
      'people',
      'teams',
      'assets',
      'parts',
      'partsData',
      'area',
      'sets',

      'procedure',
      'repeatEveryWeek',
      'repeatEveryMonth',
      'repeatMonthDay',
      'repeatEveryYear',
      'endType',
    ];

    if (!boolean_fields.includes(name) || !dropdown_fields.includes(name))
      setData({
        ...data,
        [name]: event.target.value,
      });

    if (boolean_fields.includes(name)) {
      setData({
        ...data,
        [name]: value.checked,
      });
    }

    if (dropdown_fields.includes(name)) {
      setData({
        ...data,
        [name]: value.value,
      });
    }
  };

  /*
   * The `cancelHandler` function resets form fields, hides modals, and updates validation status for various form fields.
   */
  const cancelHandler = () => {
    resetForm();
    setModalWidth(Math.max(window.innerWidth / 2, 514));
    untoggle();
    toggleCancelModal(false);
    setNewCancelModal(false);
    setInitChange(false);
    setValidation({
      title: {
        error: false,
        pattern: (title) => title != '',
        skipped: false,
        message: t.common.required_field,
      },
      category: {
        error: false,
        pattern: (category) => category != '',
        skipped: false,
        message: t.common.required_field,
      },

      // customer: {
      //   error: false,
      //   pattern: (customer) => customer != '',
      //   skipped: false,
      //   message: t.common.required_field,
      // },
      location: {
        error: false,
        pattern: (location) => location != '',
        skipped: false,
        message: t.common.required_field,
      },

      procedure: {
        error: false,
        pattern: (procedure) => procedure != '',
        skipped: false,
        message: t.common.required_field,
      },

      endDate: {
        error:
          data?.orderType === 'scheduled' &&
          data?.endType === 'on' &&
           data?.endDate == null,
        pattern: (endRepeat) => endRepeat !== '',
        skipped: false,
        message: t.common.required_field,
      },

      endRepeat: {
        error:
          data?.orderType === 'scheduled' &&
          data?.endType === 'after' &&
          data?.endRepeat === '',
        pattern: (endRepeat) => endRepeat !== '',
        skipped: false,
        message: t.common.required_field,
      },
    });
  };

  const onFilesChange = (e) => {
    let newFiles = [];
    Array.from(e.target.files).forEach((file) => {
      var randomnumber = Math.floor(Math.random() * (0 - 100 + 1)) + 100;
      newFiles.push({
        file: file,
        id: randomnumber,
        name: file.name,
        type: file.type,
        cancelPopup: false,
        isChanged: true,
      });
    });

    newFiles = attachments.concat(newFiles);

    // console.log('new files', newFiles);

    setAttachments(newFiles);
  };

  const approveOrder = async (orderID) => {
    const approveOrderResponse = await updateWorkRequestStatus(
      request.id,
      'approved',
      orderID,
      ''
    );

    return approveOrderResponse;
  };

  /*
   * The `validationHandler` function updates validation properties based on data inputs and checks for any errors in the data.
   */
  const validationHandler = async () => {
    setValidation({
      ...validation,
      title: {
        ...validation.title,
        error: validation.title.pattern(data.title) ? false : true,
        skipped: true,
      },
      category: {
        ...validation.category,
        error: validation.category.pattern(data.category) ? false : true,
        skipped: true,
      },

      // customer: {
      //   ...validation.customer,
      //   error: validation.customer.pattern(data.customer) ? false : true,
      //   skipped: true,
      // },
      location: {
        ...validation.location,
        error: validation.location.pattern(data.location) ? false : true,
        skipped: true,
      },
      endRepeat: {
        ...validation.endRepeat,
        error:
          data?.orderType === 'scheduled' &&
          data?.endType === 'after' &&
          data?.endRepeat === '',
        skipped: true,
      },

      endDate: {
        ...validation.endDate,
        error:
          data?.orderType === 'scheduled' &&
          data?.endType === 'on' &&
           data?.endDate == null,
        skipped: true,
       
      },

      daysRepeat: {
        ...validation.daysRepeat,
        error: data?.orderType === 'scheduled' ? !areAnyDaysSelected : false,
      },
      // orderType: {
      //   ...validation.orderType,
      //   error: validation.orderType.pattern(data.orderType) ? false : true,
      //   skipped: true,
      // },
      // dueDate: {
      //   ...validation.dueDate,
      //   error: validation.dueDate.pattern(data.dueDate) ? false : true,
      //   skipped: true,
      // },
      // dueTime: {
      //   ...validation.dueTime,
      //   error: validation.dueTime.pattern(data.dueTime) ? false : true,
      //   skipped: true,
      // },
      procedure: {
        ...validation.procedure,
        error: validation.procedure.pattern(data.procedure) ? false : true,
        skipped: true,
      },
    });
  };

  useEffect(() => {
    const validationToArray = Object.entries(validation);
    const cond = validationToArray.some((el) => el[1].error && el[1].skipped);

    if (initChange) setValidForm(!cond);
  }, [validation, initChange]);

  useEffect(() => {
    validationHandler();
  }, [data]);

  const updateWorkOrderHandler = async () => {
    const validationToArray = Object.entries(validation);
    const errorsInData = validationToArray.some((el) => el[1].error);
    if (!errorsInData) {
      setButtonStatus(1);
      const assetImageReponse =
        attachments.image && attachments.image.isChanged
          ? await uploadFile([attachments.image.file])
          : null;

      const workOrderFile =
        attachments.file && attachments.file.isChanged
          ? await uploadFile([attachments.file.file])
          : null;

      await updateAsset2Handler(
        assetImageReponse?.status == 200
          ? assetImageReponse.data[0].id
          : attachments?.image?.id
          ? attachments?.image.id
          : null,
        workOrderFile?.status == 200
          ? workOrderFile.data[0].id
          : attachments?.file?.id
          ? attachments?.file.id
          : null
      );
    } else {
      scrollToError();
    }
  };

  const updateAsset2Handler = async (image, file) => {
    const response = await updateWorkOrder(
      Number(targetedData?.id),
      String(data.title),
      String(data.description),

      data.customer ? data.customer : null,
      data.location,
      data.orderType,
      format(new Date(data.dueDate), 'yyyy-MM-dd'),
      data.includeDueTime,
      format(new Date(data.dueDate + 'T' + data.dueTime), 'kk:mm:ss.SSS'),
      data.repeatEvery,
      data.daysRepeat,
      data.repeatEveryWeek,
      data.repeatEveryMonth,
      data.repeatMonthDay,
      data.repeatEveryYear,
      data.endRepeat,
      Number(data.estimationTime),
      Number(data.laborCost),
      data.priority,
      data.category,
      data.people,
      data.teams,
      data.assets,
      data?.partsData?.length > 0 ? data?.partsData : [],
      data.procedure ? data.procedure : null,
      image,
      file,
      data.status,
      data.endType === 'on'
      ? data.endDate
        ? format(new Date(data.endDate), 'yyyy-MM-dd')
        : null
      : null,
      data.endType,
      data.qrcode ? true : false,
      targetedData?.attributes?.orderType == 'reactive' &&
        data?.orderType == 'scheduled'
        ? true
        : seriesEdit,
      organisationId,
      data?.sets?.length > 0 ? data.sets : [],
      data?.area ? Number(data.area) : null
    );

    if (response && response.status == 200) {
      if (request) {
        const approveResponse = await approveOrder(response.data.id);
        if (approveResponse.status == 200) {
          setButtonStatus(0);
          untoggle();
          resetForm();
          setResponseData({
            id: response.data.id,
            name: response.data.title,
            qrcode: response.data.qrcode,
          });

          toggleSuccessModal(true);
          const currentPathname = window.location.pathname;
          setRefresh(!refresh);
          if (currentPathname == '/work-orders') {
            setSearchTitle('');
            setSelectedDates([]);
            setFilterOptions({
              serialNumber: '',
              assignees: {
                people: [],
                teams: [],
              },
              people: [],
              teams: [],
              customers: [],
              locations: [],
              createdByUser: [],
              dueDate: {
                value: 0,
                text: 'Any Date',
              },
              status: [],
              priority: [],
              categories: [],
            });
          }
          toast.success(t.workOrders.form.updated.msg);
        }
      } else {
        untoggle();
        resetForm();
        setResponseData({
          id: response.data.id,
          name: response.data.title,
        });
        setSeriesEdit(false);
        toggleSuccessModal(true);
        const currentPathname = window.location.pathname;

        setRefresh(!refresh);
        if (currentPathname == '/work-orders') {
          setSearchTitle('');
          setSelectedDates([]);
          setFilterOptions({
            serialNumber: '',
            assignees: {
              people: [],
              teams: [],
            },
            people: [],
            teams: [],
            customers: [],
            locations: [],
            createdByUser: [],
            dueDate: {
              value: 0,
              text: 'Any Date',
            },
            status: [],
            priority: [],
            categories: [],
          });
        }
        toast.success(t.workOrders.form.updated.msg);
      }
    } else if (response.status == 400) {
      if (response.error && response.error.message) {
        if(response.error.message == "Insufficient quantity available for the part added in this scheduled WO"){
          toast.error(t.workOrders.part_error);
        }else if(response.error.message == "Insufficient quantity available for the sets added in this scheduled WO"){
          toast.error(t.workOrders.set_error);
        }else{

          toast.error(response.error.message);
        }
      } else {
        toast.error(t.common.something_wrong);
      }
    } else {
      toast.error(t.common.something_wrong);
      untoggle();
    }
  };

  // add popups
  const handleAddCustomer = (e, { value }) => {
    setCustomerText(value);

    toggleAddCustomerModal(true);
  };

  const returnAddCustomer = (id) => {
    fetchCustomerOptions().then((resp_data) => {
      if (resp_data.map((option) => option.value).includes(id)) {
        setData({
          ...data,
          customer: id,
        });
      }
    });
  };

  const handleAddProcedure = (e, { value }) => {
    setProcedureText(value);
    toggleAddProcedureModal(true);
  };

  const returnAddProcedure = (id) => {
    fetchProceduresOptions().then((resp_data) => {
      if (resp_data.response.map((option) => option.value).includes(id)) {
        setData({
          ...data,
          procedure: id,
        });
      }
    });
  };

  const handleAddLocation = (e, { value }) => {
    setLocationText(value);
    toggleAddLocationModal(true);
  };

  const returnAddLocation = (id) => {
    fetchLocationsOptions(data.customer).then((resp_data) => {
      if (resp_data.map((option) => option.value).includes(id)) {
        setData({
          ...data,
          location: id,
        });
      }
    });
  };

  const handleAddTeam = (e, { value }) => {
    setTeamText(value);
    toggleAddTeamModal(true);
  };

  const returnAddTeam = (id) => {
    fetchTeamsOptions().then((resp_data) => {
      if (resp_data.map((option) => option.value).includes(id)) {
        const picked_before = data.teams;

        picked_before.push(id);
        setData({
          ...data,
          teams: picked_before,
        });
      }
    });
  };

  const handleAddPeople = (e, { value }) => {
    setPeopleText(value);
    toggleAddPeopleModal(true);
  };

  const returnAddPeople = (id) => {
    fetchPeopleOptions().then((resp_data) => {
      if (resp_data.map((option) => option.value).includes(id)) {
        const picked_before = data.people;

        picked_before.push(id);
        setData({
          ...data,
          people: picked_before,
        });
      }
    });
  };

  const handleAddAsset = (e, { value }) => {
    setAssetText(value);
    toggleAddAssetModal(true);
  };

  const returnAddAsset = (id) => {
    fetchAssetsOptions().then((resp_data) => {
      if (resp_data.map((option) => option.value).includes(id)) {
        const picked_before = data.assets;

        picked_before.push(id);
        setData({
          ...data,
          assets: picked_before,
        });
      }
    });
  };

  const handleAddPart = (e, { value }) => {
    setPartText(value);
    toggleAddPartModal(true);
  };

  const [modalWidth, setModalWidth] = useState(
    Math.max(window.innerWidth / 2, 514)
  );

  // const returnAddPart = (id) => {
  //   fetchPartsOptions().then((resp_data) => {
  //     if (resp_data.map((option) => option.value).includes(id)) {
  //       const picked_before = data.parts;

  //       picked_before.push(id);
  //       setData({
  //         ...data,
  //         parts: picked_before,
  //       });
  //     }
  //   });
  // };

  return (
    <>
      <div
        style={{ maxWidth: `${modalWidth}px` }}
        clearing={true}
        className={
          toggled ? 'aside-modal active procedure' : 'aside-modal procedure'
        }
      >
        <Resizable
          size={{ width: modalWidth, height: 'auto' }}
          minWidth={'514px'}
          onResize={(e, direction, ref, d) => {
            setModalWidth(ref.offsetWidth);
          }}
        >
          <div className="modal-header">
            <div className="flexbox justify-between">
              <div className="name">
                {t.workOrders.form.form_title.edit} #
                {targetedData?.attributes?.countId}
              </div>
              <div>
                <span
                  className="c-pointer"
                  style={{ position: 'relative' }}
                  onMouseOver={() => setCancleTooltip(true)}
                  onMouseLeave={() => setCancleTooltip(false)}
                  onClick={() =>
                    initChange ? setNewCancelModal(true) : cancelHandler()
                  }
                >
                  <CloseIcon />
                  <ToolTip
                    color="#fff"
                    backgroundColor="#172B4D"
                    position={
                      selectedLanguage == 'en'
                        ? 'bottom center'
                        : 'bottom center'
                    }
                    show={showCancleTooltip}
                    textBoxWidth="36px"
                    arrowAlign="center"
                    padding="5px 5px 5px 7px"
                    borderRadius="5px"
                    static
                  >
                    <span className="work-order-preview-tooltip">
                      {t.common.close}
                    </span>
                  </ToolTip>
                </span>
              </div>
            </div>
          </div>
          <div style={{ overflowX: 'hidden' }} className="body">
            <Form.Field>
              <label className="label-item">
                {t.workOrders.table.title}
                <Asterisk color="#172B4D" />
              </label>
              <Input
                autoComplete="new-password"
                fluid
                placeholder={t.workOrders.form.title_name}
                disabled={request?.title ? true : false}
                value={data.title}
                onChange={onValuesChange('title')}
                error={validation.title.error && validation.title.skipped}
                onBlur={(e) => {
                  if (validation.title.pattern(data.title)) {
                    setValidation({
                      ...validation,
                      title: {
                        ...validation.title,
                        error: false,
                        skipped: true,
                      },
                    });
                  } else {
                    setValidation({
                      ...validation,
                      title: {
                        ...validation.title,
                        error: true,
                        skipped: true,
                      },
                    });
                  }
                }}
              />
              {validation.title.error && validation.title.skipped ? (
                <div className="label-error">{validation.title.message}</div>
              ) : (
                ''
              )}
            </Form.Field>

            <Form.Field>
              <FileUpload
                onFileChange={onFileChange}
                name="image"
                attachments={attachments}
                setAttachments={setAttachments}
              />
            </Form.Field>

            <Form style={{ marginBottom: '20px' }}>
              <Form.Field>
                <label className="label-item">
                  {t.workOrders.form.description}
                </label>
                <TextArea
                  autoComplete="new-password"
                  value={data.description}
                  onChange={onValuesChange('description')}
                  rows={3}
                  fluid
                  placeholder={t.workOrders.form.description_text}
                />
              </Form.Field>
            </Form>

            <Form.Field>
              <label className="label-item">
                {t.workOrders.form.procedure} <Asterisk color="#172B4D" />
              </label>
              <Dropdown
                placeholder={t.workOrders.form.procedure_placeholder}
                fluid
                selection
                noResultsMessage={t.common.no_results_found}
                search
                //allowAdditions
                onAddItem={handleAddProcedure}
                options={options.procedures}
                value={data.procedure}
                onChange={onValuesChange('procedure')}
                onOpen={fetchProceduresOptions}
                error={
                  validation.procedure.error && validation.procedure.skipped
                }
                onBlur={(e) => {
                  if (validation.procedure.pattern(data.procedure)) {
                    setValidation({
                      ...validation,
                      procedure: {
                        ...validation.procedure,
                        error: false,
                        skipped: true,
                      },
                    });
                  } else {
                    setValidation({
                      ...validation,
                      procedure: {
                        ...validation.procedure,
                        error: true,
                        skipped: true,
                      },
                    });
                  }
                }}
              />
              {validation.procedure.error && validation.procedure.skipped ? (
                <div className="label-error">
                  {validation.procedure.message}
                </div>
              ) : (
                ''
              )}
            </Form.Field>

            <Form.Field>
              <label className="label-item">
                {t.workOrders.form.order_type}
              </label>
              <Dropdown
                placeholder={t.workOrders.form.add_order_type}
                fluid
                noResultsMessage={t.common.no_results_found}
                selection
                disabled={targetedData?.attributes?.orderType == 'scheduled'}
                options={options.orderTypes}
                value={data.orderType}
                onChange={onValuesChange('orderType')}
                // error={validation.orderType.error && validation.orderType.skipped}
                // onBlur={(e) => {
                //   if (validation.orderType.pattern(data.orderType)) {
                //     setValidation({
                //       ...validation,
                //       orderType: {
                //         ...validation.orderType,
                //         error: false,
                //         skipped: true,
                //       },
                //     });
                //   } else {
                //     setValidation({
                //       ...validation,
                //       orderType: {
                //         ...validation.orderType,
                //         error: true,
                //         skipped: true,
                //       },
                //     });
                //   }
                // }}
              />
              {/* {validation.orderType.error && validation.orderType.skipped ? (
              <div className="label-error">{validation.orderType.message}</div>
            ) : (
              ''
            )} */}
            </Form.Field>

            <Form.Field>
              <label className="label-item">
                {data.orderType === 'scheduled'
                  ? t.workOrders.details_view.start_date
                  : t.workOrders.table.dueDate}
              </label>
              {/* <Input
                  autoComplete="new-password"
                  type="date"
                  fluid
                  disabled={dueDateParam || request?.dueDate ? true : false}
                  placeholder={t.workOrders.form.due_date}
                  value={data.dueDate}
                  onChange={onValuesChange('dueDate')}
                 
                />
                 */}
              {/* <DatePicker
                  selected={data.dueDate ? new Date(data.dueDate) : ''}
                  disabled={dueDateParam || request?.dueDate ? true : false}
                  showIcon={true}
                  locale={selectedLanguage}
                  icon="fa fa-calendar"
                  placeholder={t.workOrders.form.due_date}
                  className="date-input-picker"
                  onChange={(date) =>
                    setData({
                      ...data,
                      dueDate: format(date, 'yyyy-MM-dd'),
                    })
                  }
                  dateFormat="yyyy-MM-dd"
                /> */}
              <div className="change-date-time-inll position-relative">
                <DatePicker
                  key={'ssd'}
                  minDate={new Date().setHours(0, 0, 0, 0)}
                  style={{ width: '100%' }}
                  // maxDate={
                  //   !seriesEdit && data?.endType == 'on'
                  //     ? new Date(data.endDate)
                  //     : ''
                  // }
                  disabled={
                    seriesEdit &&
                    targetedData?.attributes?.orderType == 'scheduled'
                  }
                  value={data.dueDate ? new Date(data.dueDate) : new Date()}
                  onChange={(value) => {
                    if (value && value.valueOf()) {
                      if (!seriesEdit) {
                        setData({
                          ...data,
                          dueDate: format(value.valueOf(), 'yyyy-MM-dd'),
                        });
                      } else {
                        setData({
                          ...data,
                          dueDate: format(new Date(), 'yyyy-MM-dd'),
                          // endDate: format(value.valueOf(), 'yyyy-MM-dd'),
                        });
                      }
                    } else {
                      if (!seriesEdit) {
                        setData({
                          ...data,
                          dueDate: format(new Date(), 'yyyy-MM-dd'),
                        });
                      } else {
                        setData({
                          ...data,
                          dueDate: format(value.valueOf(), 'yyyy-MM-dd'),
                          endDate: format(value.valueOf(), 'yyyy-MM-dd'),
                        });
                      }
                    }
                  }}
                  highlightToday={false}
                  showMonthDropdown
                  showYearDropdown
                  placeholder={t.workOrders.form.add_due_date}
                  className={'active'}
                  // locale={lang == 'ar' ? arabic_ar : ''}
                  weekDays={[
                    t.workOrders.details_view.sun,
                    t.workOrders.details_view.mon,
                    t.workOrders.details_view.tue,
                    t.workOrders.details_view.wed,
                    t.workOrders.details_view.thu,
                    t.workOrders.details_view.fri,
                    t.workOrders.details_view.sat,
                  ]}
                  months={[
                    t.common.january,
                    t.common.february,
                    t.common.march,
                    t.common.april,
                    t.common.may,
                    t.common.june,
                    t.common.july,
                    t.common.august,
                    t.common.september,
                    t.common.october,
                    t.common.november,
                    t.common.december,
                  ]}
                  format="DD.MM.YYYY"
                />

                <img
                  className="position-absolute"
                  style={
                    selectedLanguage == 'en'
                      ? { top: '8px', right: '7px' }
                      : { top: '8px', left: '7px' }
                  }
                  src={calendar_icon}
                />
              </div>
              {/* <span className='mr-3'><CalenderGreyIcon/></span> */}
              {/* </div> */}

              <Checkbox
                className=""
                label={
                  data.orderType === 'scheduled'
                    ? t.workOrders.form.include_start_time
                    : t.workOrders.form.include_due_time
                }
                checked={data.includeDueTime}
                onChange={onValuesChange('includeDueTime')}
                // style={{marginBottom:"15px"}}
              />
            </Form.Field>
            {/* </Grid.Column>
            <Grid.Column> */}
            <Form.Field>
              <label className="label-item mt-3">
                {data.orderType === 'scheduled'
                  ? t.workOrders.details_view.start_time
                  : t.workOrders.form.due_time}
              </label>
              <div className="position-relative">
                <Input
                  autoComplete="new-password"
                  type="time"
                  fluid
                  placeholder={t.workOrders.form.add_due_time}
                  disabled={!data.includeDueTime}
                  value={data.dueTime}
                  onChange={onValuesChange('dueTime')}
                  // error={
                  //   validation.dueTime.error &&
                  //   validation.dueTime.skipped &&
                  //   data.includeDueTime
                  // }
                  // onBlur={(e) => {
                  //   if (validation.dueTime.pattern(data.dueTime)) {
                  //     setValidation({
                  //       ...validation,
                  //       dueTime: {
                  //         ...validation.dueTime,
                  //         error: false,
                  //         skipped: true,
                  //       },
                  //     });
                  //   } else {
                  //     setValidation({
                  //       ...validation,
                  //       dueTime: {
                  //         ...validation.dueTime,
                  //         error: true,
                  //         skipped: true,
                  //       },
                  //     });
                  //   }
                  // }}
                >
                  <input />
                  {/* <Label style={{ height: '100%' }}>Hours</Label> */}
                  {/* <span className='mr-3'> <ClockIcon/></span> */}
                </Input>
                <img
                  className="position-absolute"
                  style={
                    selectedLanguage == 'en'
                      ? { top: '8px', right: '7px' }
                      : { top: '8px', left: '7px' }
                  }
                  src={clock_icon}
                />
              </div>
              {/* {validation.dueTime.error &&
                validation.dueTime.skipped &&
                data.includeDueTime ? (
                  <div className="label-error">
                    {validation.dueTime.message}
                  </div>
                ) : (
                  ''
                )} */}
            </Form.Field>

            {data.orderType != 'reactive' ? (
              <>
                <label className="label-item">
                  <label className="label-item">
                    {t.workOrders.details_view.repeat_every}
                  </label>
                </label>
                <div
                  className={
                    data.repeatEvery != 'daily'
                      ? 'repeat-container-flex'
                      : 'repeat-container'
                  }
                >
                  <Form.Field>
                    <div className="d-flex gap-4">
                      <Dropdown
                        placeholder="Repeat Everly"
                        className="repeat-everly"
                        fluid
                        selection
                        noResultsMessage={t.common.no_results_found}
                        disabled={
                          !seriesEdit &&
                          targetedData?.attributes?.orderType == 'scheduled'
                        }
                        options={options.repeatEvery}
                        value={data.repeatEvery}
                        onChange={(e, value) => {
                          // onValuesChange('customer')
                          // console.log(value, e);
                          let findPriority = value.options.find(
                            (one) => one.value == value.value
                          );
                          if (findPriority.value == 'daily') {
                            setData({
                              ...data,
                              repeatEvery: 'daily',
                              daysRepeat: {
                                sat: true,
                                sun: true,
                                mon: true,
                                tue: true,
                                wed: true,
                                thu: true,
                                fri: true,
                              },
                            });
                          } else if (findPriority.value == 'weekly') {
                            setData({
                              ...data,
                              repeatEvery: 'weekly',
                              daysRepeat: {
                                sat: false,
                                sun: true,
                                mon: false,
                                tue: false,
                                wed: false,
                                thu: false,
                                fri: false,
                              },
                            });
                          } else if (findPriority.value == 'monthly') {
                            setData({ ...data, repeatEvery: 'monthly' });
                          } else if (findPriority.value == 'yearly') {
                            setData({ ...data, repeatEvery: 'yearly' });
                          }
                        }}
                      />
                    </div>
                  </Form.Field>
                  {/* <Form.Field>
                <label className="label-item">
                  {t.workOrders.details_view.repeat_on}
                </label>
                <Button.Group basic fluid>
                  <Button
                    onClick={() => {
                      setData({
                        ...data,
                        repeatEvery: 'daily',
                        daysRepeat: {
                          sat: true,
                          sun: true,
                          mon: true,
                          tue: true,
                          wed: true,
                          thu: true,
                          fri: true,
                        },
                      });
                    }}
                    className={data.repeatEvery == 'daily' ? 'active' : ''}
                  >
                    {t.workOrders.details_view.daily}
                  </Button>

                  <Button
                    onClick={() => {
                      setData({
                        ...data,
                        repeatEvery: 'weekly',
                        daysRepeat: {
                          sat: false,
                          sun: true,
                          mon: false,
                          tue: false,
                          wed: false,
                          thu: false,
                          fri: false,
                        },
                      });
                    }}
                    className={data.repeatEvery == 'weekly' ? 'active' : ''}
                  >
                    {t.workOrders.details_view.weekly}
                  </Button>

                  <Button
                    onClick={() => {
                      setData({ ...data, repeatEvery: 'monthly' });
                    }}
                    className={data.repeatEvery == 'monthly' ? 'active' : ''}
                  >
                    {t.workOrders.details_view.monthly}
                  </Button>

                  <Button
                    onClick={() => {
                      setData({ ...data, repeatEvery: 'yearly' });
                    }}
                    className={data.repeatEvery == 'yearly' ? 'active' : ''}
                  >
                    {t.workOrders.details_view.yearly}
                  </Button>
                </Button.Group>
              </Form.Field> */}
                  {data.repeatEvery != 'daily' ? (
                    data.repeatEvery == 'weekly' ? (
                      <>
                        <Form.Field>
                          <div className="flexbox align-center">
                            {t.workOrders.details_view.every_day}
                            <Dropdown
                              style={{ margin: '0 5px' }}
                              inline
                              search
                              noResultsMessage={t.common.no_results_found}
                              disabled={
                                !seriesEdit &&
                                targetedData?.attributes?.orderType ==
                                  'scheduled'
                              }
                              options={options.weeks}
                              className="week-dropdown"
                              value={data.repeatEveryWeek}
                              placeholder={t.workOrders.details_view.select_no}
                              onChange={onValuesChange('repeatEveryWeek')}
                              onOpen={fetchWeekOptions}
                            />
                            {t.workOrders.details_view.week_on}
                          </div>
                        </Form.Field>
                      </>
                    ) : data.repeatEvery == 'monthly' ? (
                      <Form.Field>
                        <div className="flexbox align-center">
                          {t.workOrders.details_view.every_day}
                          <Dropdown
                            style={{ margin: '0 5px' }}
                            inline
                            noResultsMessage={t.common.no_results_found}
                            search
                            disabled={
                              !seriesEdit &&
                              targetedData?.attributes?.orderType == 'scheduled'
                            }
                            className="week-dropdown"
                            options={options.months}
                            value={data.repeatEveryMonth}
                            placeholder={t.workOrders.details_view.select_no}
                            onChange={onValuesChange('repeatEveryMonth')}
                            onOpen={fetchMonthOptions}
                          />
                          {t.workOrders.details_view.month_on}
                          <Dropdown
                            style={{ margin: '0 5px' }}
                            inline
                            search
                            noResultsMessage={t.common.no_results_found}
                            disabled={
                              !seriesEdit &&
                              targetedData?.attributes?.orderType == 'scheduled'
                            }
                            className="week-dropdown"
                            options={options.monthsDays}
                            value={data.repeatMonthDay}
                            placeholder={t.workOrders.details_view.select_day}
                            onChange={onValuesChange('repeatMonthDay')}
                            onOpen={fetchMonthDaysOptions}
                          />
                        </div>
                      </Form.Field>
                    ) : data.repeatEvery == 'yearly' ? (
                      <Form.Field>
                        <div className="flexbox align-center">
                          {t.workOrders.details_view.every_day}
                          <Dropdown
                            style={{ margin: '0 5px' }}
                            inline
                            noResultsMessage={t.common.no_results_found}
                            disabled={
                              !seriesEdit &&
                              targetedData?.attributes?.orderType == 'scheduled'
                            }
                            search
                            className="week-dropdown"
                            options={options.years}
                            value={data.repeatEveryYear}
                            placeholder={t.workOrders.details_view.select_no}
                            onChange={onValuesChange('repeatEveryYear')}
                            onOpen={fetchYearsOptions}
                          />
                          {t.workOrders.details_view.year}
                        </div>
                      </Form.Field>
                    ) : (
                      ''
                    )
                  ) : (
                    <Form.Field>
                      <Button.Group basic fluid className="basic-btn">
                        <Button
                          disabled={
                            !seriesEdit &&
                            targetedData?.attributes?.orderType == 'scheduled'
                          }
                          onClick={() => {
                            setData({
                              ...data,
                              daysRepeat: {
                                ...data.daysRepeat,
                                sun: !data.daysRepeat.sun,
                              },
                            });
                          }}
                          className={
                            data.daysRepeat.sun ? 'day-btn active' : 'day-btn'
                          }
                        >
                          {t.workOrders.details_view.sun}
                        </Button>

                        <Button
                          disabled={
                            !seriesEdit &&
                            targetedData?.attributes?.orderType == 'scheduled'
                          }
                          onClick={() => {
                            setData({
                              ...data,
                              daysRepeat: {
                                ...data.daysRepeat,
                                mon: !data.daysRepeat.mon,
                              },
                            });
                          }}
                          className={
                            data.daysRepeat.mon ? 'day-btn active' : 'day-btn'
                          }
                        >
                          {t.workOrders.details_view.mon}
                        </Button>

                        <Button
                          disabled={
                            !seriesEdit &&
                            targetedData?.attributes?.orderType == 'scheduled'
                          }
                          onClick={() => {
                            setData({
                              ...data,
                              daysRepeat: {
                                ...data.daysRepeat,
                                tue: !data.daysRepeat.tue,
                              },
                            });
                          }}
                          className={
                            data.daysRepeat.tue ? 'day-btn active' : 'day-btn'
                          }
                        >
                          {t.workOrders.details_view.tue}
                        </Button>

                        <Button
                          disabled={
                            !seriesEdit &&
                            targetedData?.attributes?.orderType == 'scheduled'
                          }
                          onClick={() => {
                            setData({
                              ...data,
                              daysRepeat: {
                                ...data.daysRepeat,
                                wed: !data.daysRepeat.wed,
                              },
                            });
                          }}
                          className={
                            data.daysRepeat.wed ? 'day-btn active' : 'day-btn'
                          }
                        >
                          {t.workOrders.details_view.wed}
                        </Button>

                        <Button
                          disabled={
                            !seriesEdit &&
                            targetedData?.attributes?.orderType == 'scheduled'
                          }
                          onClick={() => {
                            setData({
                              ...data,
                              daysRepeat: {
                                ...data.daysRepeat,
                                thu: !data.daysRepeat.thu,
                              },
                            });
                          }}
                          className={
                            data.daysRepeat.thu ? 'day-btn active' : 'day-btn'
                          }
                        >
                          {t.workOrders.details_view.thu}
                        </Button>

                        <Button
                          disabled={
                            !seriesEdit &&
                            targetedData?.attributes?.orderType == 'scheduled'
                          }
                          onClick={() => {
                            setData({
                              ...data,
                              daysRepeat: {
                                ...data.daysRepeat,
                                fri: !data.daysRepeat.fri,
                              },
                            });
                          }}
                          className={
                            data.daysRepeat.fri ? 'day-btn active' : 'day-btn'
                          }
                        >
                          {t.workOrders.details_view.fri}
                        </Button>

                        <Button
                          disabled={
                            !seriesEdit &&
                            targetedData?.attributes?.orderType == 'scheduled'
                          }
                          onClick={() => {
                            setData({
                              ...data,
                              daysRepeat: {
                                ...data.daysRepeat,
                                sat: !data.daysRepeat.sat,
                              },
                            });
                          }}
                          className={
                            data.daysRepeat.sat ? 'day-btn active' : 'day-btn'
                          }
                        >
                          {t.workOrders.details_view.sat}
                        </Button>
                      </Button.Group>
                      {validation.daysRepeat.error ? (
                        <div className="label-error">
                          {t.common.required_field}
                        </div>
                      ) : (
                        ''
                      )}
                    </Form.Field>
                  )}
                </div>
                {data.repeatEvery == 'weekly' ? (
                  <div className="weekly-btns">
                    {/* <Form.Field>
                      <div className="flexbox align-center">
                        Every
                        <Dropdown
                          style={{ margin: '0 5px' }}
                          inline
                          search
                          options={options.weeks}
                          className="week-dropdown"
                          value={data.repeatEveryWeek}
                          placeholder={'Select number'}
                          onChange={onValuesChange('repeatEveryWeek')}
                          onOpen={fetchWeekOptions}
                        />
                        Week on
                      </div>
                    </Form.Field> */}

                    <Form.Field>
                      <Button.Group basic fluid className="basic-btn">
                        <Button
                          disabled={
                            !seriesEdit &&
                            targetedData?.attributes?.orderType == 'scheduled'
                          }
                          onClick={() => {
                            setData({
                              ...data,
                              daysRepeat: {
                                ...data.daysRepeat,
                                sun: !data.daysRepeat.sun,
                              },
                            });
                          }}
                          className={
                            data.daysRepeat.sun ? 'day-btn active' : 'day-btn'
                          }
                        >
                          {t.workOrders.details_view.sun}
                        </Button>

                        <Button
                          disabled={
                            !seriesEdit &&
                            targetedData?.attributes?.orderType == 'scheduled'
                          }
                          onClick={() => {
                            setData({
                              ...data,
                              daysRepeat: {
                                ...data.daysRepeat,
                                mon: !data.daysRepeat.mon,
                              },
                            });
                          }}
                          className={
                            data.daysRepeat.mon ? 'day-btn active' : 'day-btn'
                          }
                        >
                          {t.workOrders.details_view.mon}
                        </Button>

                        <Button
                          disabled={
                            !seriesEdit &&
                            targetedData?.attributes?.orderType == 'scheduled'
                          }
                          onClick={() => {
                            setData({
                              ...data,
                              daysRepeat: {
                                ...data.daysRepeat,
                                tue: !data.daysRepeat.tue,
                              },
                            });
                          }}
                          className={
                            data.daysRepeat.tue ? 'day-btn active' : 'day-btn'
                          }
                        >
                          {t.workOrders.details_view.tue}
                        </Button>

                        <Button
                          disabled={
                            !seriesEdit &&
                            targetedData?.attributes?.orderType == 'scheduled'
                          }
                          onClick={() => {
                            setData({
                              ...data,
                              daysRepeat: {
                                ...data.daysRepeat,
                                wed: !data.daysRepeat.wed,
                              },
                            });
                          }}
                          className={
                            data.daysRepeat.wed ? 'day-btn active' : 'day-btn'
                          }
                        >
                          {t.workOrders.details_view.wed}
                        </Button>

                        <Button
                          disabled={
                            !seriesEdit &&
                            targetedData?.attributes?.orderType == 'scheduled'
                          }
                          onClick={() => {
                            setData({
                              ...data,
                              daysRepeat: {
                                ...data.daysRepeat,
                                thu: !data.daysRepeat.thu,
                              },
                            });
                          }}
                          className={
                            data.daysRepeat.thu ? 'day-btn active' : 'day-btn'
                          }
                        >
                          {t.workOrders.details_view.thu}
                        </Button>

                        <Button
                          disabled={
                            !seriesEdit &&
                            targetedData?.attributes?.orderType == 'scheduled'
                          }
                          onClick={() => {
                            setData({
                              ...data,
                              daysRepeat: {
                                ...data.daysRepeat,
                                fri: !data.daysRepeat.fri,
                              },
                            });
                          }}
                          className={
                            data.daysRepeat.fri ? 'day-btn active' : 'day-btn'
                          }
                        >
                          {t.workOrders.details_view.fri}
                        </Button>

                        <Button
                          disabled={
                            !seriesEdit &&
                            targetedData?.attributes?.orderType == 'scheduled'
                          }
                          onClick={() => {
                            setData({
                              ...data,
                              daysRepeat: {
                                ...data.daysRepeat,
                                sat: !data.daysRepeat.sat,
                              },
                            });
                          }}
                          className={
                            data.daysRepeat.sat ? 'day-btn active' : 'day-btn'
                          }
                        >
                          {t.workOrders.details_view.sat}
                        </Button>
                      </Button.Group>
                      {validation.daysRepeat.error ? (
                        <div className="label-error">
                          {t.common.required_field}
                        </div>
                      ) : (
                        ''
                      )}
                    </Form.Field>
                  </div>
                ) : null}
                <Form.Field>
                  <label className="label-item">
                    {t.workOrders.details_view.ends}
                  </label>

                  <Form>
                    {/* <Form.Field>
                  {/* <Form.Field>
          On
        </Form.Field> */}
                    <div className="d-flex align-items-center side-dropdown gap-5">
                      <div>
                        <Radio
                          label={t.workOrders.details_view.on}
                          name="radioGroup"
                          value="on"
                          disabled={
                            !seriesEdit &&
                            targetedData?.attributes?.orderType == 'scheduled'
                          }
                          checked={data.endType === 'on'}
                          onChange={onValuesChange('endType')}
                        />
                      </div>
                      {data.endType === 'on' ? (
                        <div className="mt-4 mb-5">
                          {/* <DatePicker
                          selected={data.endDate ? new Date(data.endDate) : ''}
                          showIcon={true}
                          locale={selectedLanguage}
                          icon="fa fa-calendar"
                          placeholder={t.workOrders.form.end_date}
                          className="date-input-picker workorder-date-input"
                          onChange={(date) =>
                            setData({
                              ...data,
                              endDate: format(date, 'yyyy-MM-dd'),
                            })
                          }
                          dateFormat="yyyy-MM-dd"
                          // minDate={new Date()}
                          disabled={data.endType !== 'on'}
                        /> */}
                          <div className="">
                            <DatePicker
                              value={
                                data.endDate ? new Date(data.endDate) : null
                              }
                              minDate={new Date(data.dueDate)}
                              disabled={
                                (!data?.dueDate && data.endType !== 'on') ||
                                (!seriesEdit &&
                                  targetedData?.attributes?.orderType ==
                                    'scheduled')
                              }
                              onChange={(value) => {
                                if (value && value.valueOf() && data.dueDate) {
                                  setData({
                                    ...data,
                                    endDate: format(
                                      value.valueOf(),
                                      'yyyy-MM-dd'
                                    ),
                                  });
                                  setValidation({
                                    ...validation,
                                    endDate: {
                                      ...validation.endDate,
                                      error: false,
                                      skipped: true,
                                    },
                                  });
                                } else {
                                  setData({
                                    ...data,
                                    endDate: null,
                                  });

                                  setValidation({
                                    ...validation,
                                    endDate: {
                                      ...validation.endDate,
                                      error: true,
                                      skipped: true,
                                    },
                                  });
                                }
                              }}
                              showMonthDropdown
                              showYearDropdown
                              placeholder={t.vendors.form.add_end_date}
                              className={'active'}
                              // locale={lang == 'ar' ? arabic_ar : ''}
                              weekDays={[
                                t.workOrders.details_view.sun,
                                t.workOrders.details_view.mon,
                                t.workOrders.details_view.tue,
                                t.workOrders.details_view.wed,
                                t.workOrders.details_view.thu,
                                t.workOrders.details_view.fri,
                                t.workOrders.details_view.sat,
                              ]}
                              months={[
                                t.common.january,
                                t.common.february,
                                t.common.march,
                                t.common.april,
                                t.common.may,
                                t.common.june,
                                t.common.july,
                                t.common.august,
                                t.common.september,
                                t.common.october,
                                t.common.november,
                                t.common.december,
                              ]}
                              format="DD.MM.YYYY"
                            ></DatePicker>

                            <img
                              className="date-picker-image"
                              style={{ top: '7px' }}
                              src={calendar_icon}
                            />
                          </div>
                          {validation?.endDate?.error &&
                              validation?.endDate?.skipped ? (
                                <div className="label-error">
                                  {validation?.endDate?.message}
                                </div>
                              ) : (
                                ''
                              )}
                        </div>
                      ) : null}
                    </div>
                    <div className="d-flex align-items-center side-dropdown ">
                      <div>
                        <Radio
                          label={t.workOrders.details_view.after}
                          name="radioGroup"
                          value="after"
                          disabled={
                            !seriesEdit &&
                            targetedData?.attributes?.orderType == 'scheduled'
                          }
                          checked={data.endType === 'after'}
                          onChange={onValuesChange('endType')}
                        />
                      </div>
                      {data.endType === 'after' ? (
                        <div className="mt-4 mb-5">
                          <Input
                            autoComplete="new-password"
                            className="work-input"
                            type="number"
                            onKeyDown={(evt) => handleKeyDown(evt)}
                            min="0"
                            placeholder="Enter number of orders"
                            value={data.endRepeat}
                            onChange={onValuesChange('endRepeat')}
                            error={
                              validation.endRepeat.error &&
                              validation.endRepeat.skipped
                            }
                            onBlur={(e) => {
                              if (
                                validation.endRepeat.pattern(data.endRepeat)
                              ) {
                                setValidation({
                                  ...validation,
                                  endRepeat: {
                                    ...validation.endRepeat,
                                    error: false,
                                    skipped: true,
                                  },
                                });
                              } else {
                                setValidation({
                                  ...validation,
                                  endRepeat: {
                                    ...validation.endRepeat,
                                    error: true,
                                    skipped: true,
                                  },
                                });
                              }
                            }}
                            disabled={
                              // data.endType !== 'after' &&
                              !seriesEdit &&
                              targetedData?.attributes?.orderType == 'scheduled'
                            }
                          >
                            {/* <Label style={{ height: '100%' }}>Orders</Label> */}
                          </Input>
                          {validation?.endRepeat?.error &&
                              validation?.endRepeat?.skipped ? (
                                <div className="label-error">
                                  {validation?.endRepeat?.message}
                                </div>
                              ) : (
                                ''
                              )}
                        </div>
                      ) : null}
                    </div>
                  </Form>
                </Form.Field>
              </>
            ) : (
              ''
            )}

            <Form.Field>
              <label className="label-item">
                {' '}
                {t.workOrders.details_view.estimation_time}
              </label>

              <Input
                autoComplete="new-password"
                type="number"
                onKeyDown={(evt) => handleKeyDown(evt)}
                min="0"
                fluid
                placeholder={t.workOrders.form.time_placeholder}
                value={data.estimationTime}
                onChange={onValuesChange('estimationTime')}
              >
                <input />
                {/* <Label style={{ height: '100%' }}>Hours</Label> */}
                <span
                  className={`mr-2 p-1 d-flex align-items-center justify-content-center ${
                    selectedLanguage == 'en' ? 'ml-5' : 'mr-5'
                  }`}
                  // onClick={() => {
                  //   setOpenJobTitleAddModal(true);
                  // }}
                  style={{
                    padding: '5px',
                    backgroundColor: '#E9F2FF',
                    color: '#0C66E4',
                  }}
                >
                  {t.common.hrs}
                </span>
              </Input>
            </Form.Field>

            <Form.Field>
              <label className="label-item">
                {t.workOrders.form.labor_cost}
              </label>

              <Input
                autoComplete="new-password"
                type="number"
                onKeyDown={(evt) => handleKeyDown(evt)}
                min="0"
                fluid
                placeholder={t.workOrders.form.add_labor_cost}
                value={data.laborCost}
                onChange={onValuesChange('laborCost')}
              >
                <input />
                <span
                  className={`mr-2 p-1 d-flex align-items-center justify-content-center ${
                    selectedLanguage == 'en' ? 'ml-5' : 'mr-5'
                  }`}
                  // onClick={() => {
                  //   setOpenJobTitleAddModal(true);
                  // }}
                  style={{ backgroundColor: '#E9F2FF', color: '#0C66E4' }}
                >
                  {organisationCurrency}
                </span>
              </Input>
            </Form.Field>

            <Form.Field>
              <label className="label-item">
                {t.workOrders.table.location}
                <Asterisk color="#172B4D" />
              </label>
              <Dropdown
                placeholder={t.workOrders.form.location_placeholder}
                fluid
                selection
                //allowAdditions
                search
                noResultsMessage={t.common.no_results_found}
                onAddItem={handleAddLocation}
                // disabled={!data.customer}
                options={options.locations}
                value={data.location}
                onChange={onValuesChange('location')}
                onOpen={fetchLocationsOptions}
                error={
                  validation.location.error &&
                  validation.location.skipped &&
                  data.customer != ''
                }
                onBlur={(e) => {
                  if (validation.location.pattern(data.location)) {
                    setValidation({
                      ...validation,
                      location: {
                        ...validation.location,
                        error: false,
                        skipped: true,
                      },
                    });
                  } else {
                    setValidation({
                      ...validation,
                      location: {
                        ...validation.location,
                        error: true,
                        skipped: true,
                      },
                    });
                  }
                }}
              />
              {validation.location.error && validation.location.skipped ? (
                <div className="label-error">{validation.location.message}</div>
              ) : (
                ''
              )}
            </Form.Field>

            <Form.Field>
              <label className="label-item">{t.parts.filter.area}</label>
              <Dropdown
                placeholder={t.parts.form.add_area}
                fluid
                selection
                noResultsMessage={t.common.no_results_found}
                search
                // onAddItem={handleAddLocation}
                options={options.area}
                // onOpen={fetchLocationsOptions}
                value={data.area}
                onChange={onValuesChange('area')}
                // error={
                //   validation.location.error &&
                //   validation.location.skipped &&
                //   data.customer != ''
                // }
                // onBlur={(e) => {
                //   if (validation.location.pattern(data.location)) {
                //     setValidation({
                //       ...validation,
                //       location: {
                //         ...validation.location,
                //         error: false,
                //         skipped: true,
                //       },
                //     });
                //   } else {
                //     setValidation({
                //       ...validation,
                //       location: {
                //         ...validation.location,
                //         error: true,
                //         skipped: true,
                //       },
                //     });
                //   }
                // }}
              />
              {/* {validation.location.error && validation.location.skipped ? (
              <div className="label-error">{validation.location.message}</div>
            ) : (
              ''
            )} */}
            </Form.Field>

            <Form.Field>
              <label className="label-item">{t.workOrders.form.teams}</label>
              <Dropdown
                placeholder={t.workOrders.form.teams_placeholder}
                fluid
                selection
                noResultsMessage={t.common.no_results_found}
                search
                multiple
                //allowAdditions
                onAddItem={handleAddTeam}
                options={options.teams}
                value={data.teams}
                onChange={onValuesChange('teams')}
                onOpen={fetchTeamsOptions}
              />
            </Form.Field>

            <Form.Field>
              <label className="label-item">{t.workOrders.form.people}</label>
              <Dropdown
                placeholder={t.workOrders.form.people_placeholder}
                fluid
                selection
                noResultsMessage={t.common.no_results_found}
                search
                //allowAdditions
                multiple
                onAddItem={handleAddPeople}
                options={options.people}
                value={data.people}
                onChange={onValuesChange('people')}
                onOpen={fetchPeopleOptions}
              />
            </Form.Field>

            <Form.Field>
              <label className="label-item">
                {' '}
                {t.workOrders.table.customer}
              </label>
              <Dropdown
                placeholder={t.workOrders.form.customer_placeholder}
                //allowAdditions
                search
                fluid
                selection
                noResultsMessage={t.common.no_results_found}
                onAddItem={handleAddCustomer}
                options={options.customers}
                value={data.customer}
                onChange={(e, value) => {
                  // onValuesChange('customer')
                  let findCustomer = value.options.find(
                    (one) => one.value == value.value
                  );
                  if (findCustomer) {
                    setData({
                      ...data,
                      customer: value.value,
                    });
                  }
                }}
                onOpen={fetchCustomerOptions}
                // error={validation.customer.error && validation.customer.skipped}
                // onBlur={(e) => {
                //   if (validation.customer.pattern(data.customer)) {
                //     setValidation({
                //       ...validation,
                //       customer: {
                //         ...validation.customer,
                //         error: false,
                //         skipped: true,
                //       },
                //     });
                //   } else {
                //     setValidation({
                //       ...validation,
                //       customer: {
                //         ...validation.customer,
                //         error: true,
                //         skipped: true,
                //       },
                //     });
                //   }
                // }}
              />
              {/* {validation.customer.error && validation.customer.skipped ? (
              <div className="label-error">{validation.customer.message}</div>
            ) : (
              ''
            )} */}
            </Form.Field>

          

         

            <Form.Field>
              <label className="label-item">{t.workOrders.form.assets}</label>
              <Dropdown
                placeholder={t.workOrders.form.assets_placeholder}
                fluid
                selection
                search
                multiple
                //allowAdditions
                onAddItem={handleAddAsset}
                options={options.assets}
                noResultsMessage={t.common.no_results_found}
                value={data.assets}
                onChange={onValuesChange('assets')}
              />
            </Form.Field>

            <label className="label-item">{t.parts.tab.first}</label>
            <div className="wrapper-parts-dropdown">
              <PartsDropdown
                title={t.workOrders.form.select_part}
                options={options.partsData}
                // disabled={!data?.location || !data.area}
                selectedValues={data.partsData}
                onChange={(e) => {
                  if (e.target.checked) {
                    setData({
                      ...data,
                      partsData: [...data.partsData, e.target.value],
                    });
                  } else {
                    let filteredLocations = data.partsData.filter(
                      (el) => el !== e.target.value
                    );
                    setData({
                      ...data,
                      partsData: filteredLocations,
                    });
                  }
                }}
                icon={false}
                image={false}
                // onRemovePart={handleRemovePart}
              />
            </div>
            <label className="label-item mt-5">{t.parts.tab.second}</label>
            <div className="wrapper-parts-dropdown">
              <PartsDropdown
                title={t.workOrders.form.select_set}
                options={options.sets}
                // disabled={!data?.location || !data.area}
                selectedValues={data.sets}
                onChange={(e) => {
                  if (e.target.checked) {
                    setData({
                      ...data,
                      sets: [...data.sets, e.target.value],
                    });
                  } else {
                    let filteredLocations = data.sets.filter(
                      (el) => el !== e.target.value
                    );
                    setData({
                      ...data,
                      sets: filteredLocations,
                    });
                  }
                }}
                icon={false}
                image={false}
              />
            </div>

            {/* <Grid columns="equal">
            <Grid.Column> */}

            {/* </Grid.Column>
          </Grid> */}

            {/* <Form.Field>
            <label className="label-item">{t.workOrders.form.parts}</label>
            <Dropdown
              placeholder={t.workOrders.form.parts_placeholder}
              fluid
              selection
              search
              multiple
              //allowAdditions
              onAddItem={handleAddPart}
              options={options.parts}
              value={data.parts}
              onChange={onValuesChange('parts')}
            />
          </Form.Field> */}

            <Form.Field>
              <label className="label-item mt-5">
                {' '}
                <label className="label-item">
                  {t.workOrders.table.priority}
                </label>
              </label>
              <Dropdown
                placeholder={t.workOrders.table.add_priority}
                fluid
                selection
                options={options.priority}
                value={data.priority}
                noResultsMessage={t.common.no_results_found}
                onChange={(e, value) => {
                  // onValuesChange('customer')
                  // console.log(value, e);
                  let findPriority = value.options.find(
                    (one) => one.value == value.value
                  );
                  if (findPriority) {
                    setData({
                      ...data,
                      priority: value.value,
                    });
                  }
                }}
              />
            </Form.Field>

            {/* {console.log('Data', data)} */}

            {/* Location  */}

            <Form.Field>
              <label className="label-item">
                {t.workOrders.table.category} <Asterisk color="#172B4D" />
              </label>
              <Dropdown
                placeholder={t.workOrders.table.category}
                fluid
                selection
                search
                options={options.categories}
                noResultsMessage={t.common.no_results_found}
                value={data.category}
                error={validation.category.error && validation.category.skipped}
                onChange={onValuesChange('category')}
                onBlur={(e) => {
                  if (validation.category.pattern(data.category)) {
                    setValidation({
                      ...validation,
                      category: {
                        ...validation.category,
                        error: false,
                        skipped: true,
                      },
                    });
                  } else {
                    setValidation({
                      ...validation,
                      category: {
                        ...validation.category,
                        error: true,
                        skipped: true,
                      },
                    });
                  }
                }}
              />
              {validation.category.error && validation.category.skipped ? (
                <div className="label-error">{validation.category.message}</div>
              ) : (
                ''
              )}
            </Form.Field>

            {/* <Grid columns="equal" style={{ marginBottom: '10px' }}>
              <Grid.Column>
                <Form.Field>
                  <label className="label-item">
                    {t.workOrders.form.code} <Asterisk color="#172B4D" />
                  </label>
                  <Dropdown
                    placeholder={t.workOrders.form.add_code}
                    error={validation.code.error && validation.code.skipped}
                    fluid
                    selection
                    search
                    noResultsMessage={t.common.no_results_found}
                    options={options.code}
                    value={data.code}
                    onChange={onValuesChange('code')}
                    onBlur={(e) => {
                      if (validation.code.pattern(data.code)) {
                        setValidation({
                          ...validation,
                          code: {
                            ...validation.code,
                            error: false,
                            skipped: true,
                          },
                        });
                      } else {
                        setValidation({
                          ...validation,
                          code: {
                            ...validation.code,
                            error: true,
                            skipped: true,
                          },
                        });
                      }
                    }}
                  />
                  {validation.code.error && validation.code.skipped ? (
                    <div className="label-error">{validation.code.message}</div>
                  ) : (
                    ''
                  )}
                </Form.Field>
              </Grid.Column>
            </Grid> */}

            <Form.Field>
              <FileUpload
                onFileChange={onFileChange}
                name="file"
                attachments={attachments}
                setAttachments={setAttachments}
              />
            </Form.Field>
            <Divider />
            <div className="form-button-container">
              <button
                onClick={() =>
                  initChange
                    ? setNewCancelModal(initChange && true)
                    : cancelHandler()
                }
                className="form-action-button"
              >
                {t.common.cancel}
              </button>

              <button
                onClick={updateWorkOrderHandler}
                className="form-action-button blue"
              >
                {t.common.edit}
              </button>
            </div>
          </div>
        </Resizable>
      </div>
      <div
        className={
          toggled ? 'aside-modal-background active' : 'aside-modal-background'
        }
        onClick={() => (initChange ? setNewCancelModal(true) : cancelHandler())}
      ></div>

      {/* success modal */}
      <SuccessModal
        closeCallback={() => {
          const currentPathname = window.location.pathname;
          setRefresh(!refresh);
          if (currentPathname == '/work-orders') {
            setSearchTitle('');
            setSelectedDates([]);
            setFilterOptions({
              serialNumber: '',
              assignees: {
                people: [],
                teams: [],
              },
              people: [],
              teams: [],
              customers: [],
              locations: [],
              createdByUser: [],
              dueDate: {
                value: 0,
                text: 'Any Date',
              },
              status: [],
              priority: [],
              categories: [],
            });
          }
        }}
      >
        <span>{t.workOrders.form.updated.msg}</span>
      </SuccessModal>

      {/* cancel modal */}
      <NewCancelModal
        yesCallback={() => {
          cancelHandler();
        }}
        title={t.parts.form.changes}
      >
        <p style={{ lineHeight: '24px' }}> {t.common.cancel_2statement}</p>
      </NewCancelModal>

      {/* on the go popups */}
      <AddCustomerModal
        toggled={addCustomerModal}
        untoggle={() => {
          toggleAddCustomerModal(false);
        }}
        returnAddCustomer={returnAddCustomer}
        title={customerText}
      />

      <AddLocationModal
        toggled={addLocationModal}
        untoggle={() => {
          toggleAddLocationModal(false);
        }}
        customer={data.customer}
        returnAddLocation={returnAddLocation}
        title={locationText}
      />

      <AddPeopleModal
        toggled={addPeopleModal}
        untoggle={() => {
          toggleAddPeopleModal(false);
        }}
        returnAddPeople={returnAddPeople}
        title={peopleText}
      />

      <AddTeamModal
        toggled={addTeamModal}
        untoggle={() => {
          toggleAddTeamModal(false);
        }}
        returnAddTeam={returnAddTeam}
        title={teamText}
      />

      <AddProcedureModal
        toggled={addProcedureModal}
        untoggle={() => {
          toggleAddProcedureModal(false);
        }}
        returnAddProcedure={returnAddProcedure}
        title={procedureText}
      />

      <AddAssetModal
        toggled={addAssetModal}
        untoggle={() => {
          toggleAddAssetModal(false);
        }}
        returnAddAsset={returnAddAsset}
        title={assetText}
      />

      {/* <AddPartModal
        toggled={addPartModal}
        untoggle={() => {
          toggleAddPartModal(false);
        }}
        returnAddPart={returnAddPart}
        title={partText}
      /> */}
    </>
  );
}

export default Edit;
