import React, { useState, useEffect, createRef } from 'react';
import {
  Segment,
  Header,
  Popup,
  TextArea,
  Dropdown,
  Modal,
  Input,
  Form,
  Button,
  Divider,
  Icon,
  Dimmer,
  Loader,
} from 'semantic-ui-react';

import { toAbsoluteUrl } from '../../../_metronic/_helpers';
import {
  getUsersByRole,
  getAllCustomers,
  getAllLocations,
  updateTeam,
  IMAGES_URL,
  humanize,
  getActiveUsersByRole,
} from '../../config/functions';

import md5 from 'md5';
import { useDispatch, useSelector } from 'react-redux';

import { selectTranslations } from '../../config/i18n/slice';
import { useCancelModal } from '../../../hooks/Messages/useCancelModal';
import AddCustomerModal from './popups/add-customer';
import AddLocationModal from './popups/add-location';
import { LetteredAvatarImageUrl } from '../../components/common/userProfilePlaceholder';
import { toast } from 'react-toastify';
import { scrollToError } from '../../../utilFunctions/scrollToError';
import Asterisk from '../../components/common/Asterisk';
import { CloseIcon } from '../../../Assets/Icons/svg';
import ToolTip from 'react-power-tooltip';
import { Resizable } from 're-resizable';
import useNewCancelModal from '../../../hooks/Messages/useNewCncelModal';

function Edit({
  targetedData,
  toggled,
  untoggle,
  refresh,
  setRefresh,
  setFilterOptions,
  filterOptions,
  setSearchTitle,
  setSortData,
  userRole,
  allIds

}) {
  const t = useSelector(selectTranslations);
  const organisationId = useSelector((state) => {
    if (state?.auth?.user?.organisation) {
      return state.auth.user.organisation.id;
    } else {
      return null;
    }
  });

  const [initChange, setInitChange] = useState(false);
  const { setNewCancelModal, NewCancelModal } = useNewCancelModal();
  const [showCancleTooltip, setCancleTooltip] = useState(false);
  const [buttonStatus, setButtonStatus] = useState(0);
  const [cancelModal, toggleCancelModal] = useState(false);
  const [successModal, setSuccessModal] = useState(false);
  const [responseData, setResponseData] = useState({});

  const [addCustomerModal, toggleAddCustomerModal] = useState(false);
  const [addLocationModal, toggleAddLocationModal] = useState(false);
  const [customerText, setCustomerText] = useState('');
  const [locationText, setLocationText] = useState('');
  const [modalWidth, setModalWidth] = useState(
    Math.max(window.innerWidth / 2, 514)
  );
  const dispatch = useDispatch();

  const [fetchingSuperiors, setFetchingSuperiors] = useState(true);
  const [fetchingMembers, setFetchingMembers] = useState(true);
  const [fetchingCustomer, setFetchingCustomer] = useState(true);
  const [fetchingLocations, setFetchingLocations] = useState(true);

  const [options, setOptions] = useState({
    supervisors: [],
    users: [],
    locations: [],
    customers: [],
  });

  const [data, setData] = useState({
    name: '',
    description: '',
    users: [],
    customer: [],
    locations: [],
    superior: '',
  });

  const [validation, setValidation] = useState({
    name: {
      error: false,
      pattern: (name) => name != '',
      skipped: false,
      message: t.common.required_field,
    },
    users: {
      error: false,
      pattern: (users) => users.length > 0,
      skipped: false,
      message: t.common.required_field,
    },

    superior: {
      error: false,
      pattern: (superior) => true,
      skipped: false,
      message: t.common.required_field,
    },

    customer: {
      error: false,
      pattern: (customer) => customer != '',
      skipped: false,
      message: t.common.required_field,
    },
    locations: {
      error: true,
      pattern: (locations) => true,
      skipped: false,
      message: t.common.required_field,
    },
  });

  const [validForm, setValidForm] = useState(false);

  useEffect(() => {
    if (toggled && targetedData) {
      fetchOptions();
      // if (
      //   [
      //     fetchingMembers,
      //     fetchingSuperiors,
      //     fetchingCustomer,
      //     fetchingLocations,
      //   ].every((v) => v !== false)
      // ) {
      if (targetedData) {
        setData({
          ...data,
          name: targetedData?.attributes.name,
          description: targetedData?.attributes.description,
          superior: targetedData?.attributes?.superior?.data?.id,
          customer:
            targetedData?.attributes?.customers?.data?.length > 0
              ? targetedData?.attributes.customers.data.map(
                  (el_loc) => el_loc.id
                )
              : [],
          locations:
            targetedData?.attributes?.locations?.data?.length > 0
              ? targetedData?.attributes.locations.data.map(
                  (el_loc) => el_loc.id
                )
              : [],
          users:
            targetedData?.attributes.users.data?.length > 0
              ? targetedData?.attributes.users.data.map((el_user) => el_user.id)
              : [],
        });
      }
      // }
    }
  }, [targetedData, toggled]);

  const fetchOptions = async () => {
    const [customers, locations, supervisors, users] = await Promise.all([
      getAllCustomers(organisationId,'',1,99999),
      getAllLocations(organisationId),
      getActiveUsersByRole(
        ['admin', 'limited_admin', 'supervisor']
      
      ),
      getActiveUsersByRole(
        [
          'user',
          'supervisor',
          'view_only',
          'admin',
          'requester',
          'limited_admin',
        ]
    
      ),
    ]);

    let customers_options = [];
    if (customers.response) {
      customers_options = customers.response.map((el) => {
        const the_image = el.attributes.logo.data
          ? IMAGES_URL + el.attributes.logo.data.attributes.url
          : LetteredAvatarImageUrl(el.attributes.name);
        return {
          key: el.id,
          value: el.id,
          text: el.attributes.name,
          image: { avatar: true, src: the_image, size: 'tiny' },
        };
      });

      setFetchingCustomer(false);
    }

    let locations_options = [];
    if (locations) {
      locations.forEach((el) => {
        // const the_image = el.attributes.customer.data
        //   ? el.attributes.customer.data?.attributes.logo.data
        //     ? IMAGES_URL +
        //       el.attributes.customer.data?.attributes.logo.data?.attributes.url
        //     : `http://gravatar.com/avatar/${md5(
        //         el?.attributes.customer.data?.attributes.name
        //       )}?d=identicon`
        //   : 'N/A';
     
            locations_options.push({
              key: el.id,
              value: el.id,
              text: el.attributes.name,
            })
          
      });

      setFetchingLocations(false);
    }

    let supervisors_options = [];
    if (supervisors.data) {
      supervisors_options = supervisors.data.map((el) => {
        const the_image = el?.profileImage?.url
          ? IMAGES_URL + el?.profileImage?.url
          : LetteredAvatarImageUrl(el?.firstName + ' ' + el?.lastName);
        return {
          key: el.id,
          value: el.id,
          text:
            el.firstName +
            ' ' +
            el.lastName +
            ' (' +
            t.people.roles[el.role.name] +
            ')',
          image: { avatar: true, src: the_image, size: 'tiny' },
        };
      });

      setFetchingSuperiors(false);
    }

    let users_options = [];
    if (users.data) {
      users_options = users.data.map((el) => {
        const the_image = el?.profileImage?.url
          ? IMAGES_URL + el?.profileImage?.url
          : LetteredAvatarImageUrl(el?.firstName + ' ' + el?.lastName);
        return {
          key: el.id,
          value: el.id,
          text:
            el.firstName +
            ' ' +
            el.lastName +
            ' (' +
            t.people.roles[el.role.name] +
            ')',
          // description: humanize(el.role.name),
          image: { avatar: true, src: the_image, size: 'tiny' },
        };
      });

      setFetchingMembers(false);
    }

    setOptions({
      ...options,
      supervisors: supervisors.data?.length > 0 ? supervisors_options : [],
      users: users.data?.length > 0 ? users_options : [],
      customers: customers.response?.length > 0 ? customers_options : [],
      locations: locations?.length > 0 ? locations_options : [],
    });
  };

  const resetForm = () => {
    setData({
      ...data,
      name: '',
      description: '',
      users: [],
      customer: [],
      locations: [],
      superior: '',
    });
    setModalWidth(Math.max(window.innerWidth / 2, 514));

    setValidation({
      name: {
        error: false,
        pattern: (name) => name != '',
        skipped: false,
        message: t.common.required_field,
      },
      users: {
        error: false,
        pattern: (users) => users.length > 0,
        skipped: false,
        message: t.common.required_field,
      },

      superior: {
        error: false,
        pattern: (superior) => true,
        skipped: false,
        message: t.common.required_field,
      },

      customer: {
        error: false,
        pattern: (customer) => customer != '',
        skipped: false,
        message: t.common.required_field,
      },
      locations: {
        error: true,
        pattern: (locations) => true,
        skipped: false,
        message: t.common.required_field,
      },
    });

    setFetchingSuperiors(true);
    setFetchingMembers(true);
    setFetchingCustomer(true);
    setFetchingLocations(true);
    setInitChange(false);
  };

  const fetchCustomerOptions = async () => {
    const fetchData = await getAllCustomers();

    if (fetchData.response) {
      let the_options = fetchData.response.map((el) => {
        const the_image = el.attributes.logo.data
          ? IMAGES_URL + el.attributes.logo.data.attributes.url
          : LetteredAvatarImageUrl(el.attributes.name);
        return {
          key: el.id,
          value: el.id,
          text: el.attributes.name,
          image: { avatar: true, src: the_image, size: 'tiny' },
        };
      });
      setOptions({
        ...options,
        customers: the_options,
      });

      return the_options;
    }
  };

  const fetchLocationsOptions = async (customerID) => {
    let the_options = []
    // setOptions({
    //   ...options,
    //   locations: [],
    // });
    const fetchData = await getAllLocations(organisationId);
    if (fetchData) {
      fetchData.forEach((el) => {
      
           the_options.push({
             key: el.id,
             value: el.id,
             text: el.attributes.name,
           })
         
         //   const the_image = `http://gravatar.com/avatar/${md5(
         //     el?.attributes.name
         //   )}?d=identicon`;
 
       });
       setOptions({
         ...options,
         locations: the_options,
       });
 
       return the_options;
     }
  };

  // useEffect(() => {
  //   if (data.customer && initChange) fetchLocationsOptions(data.customer);
  // }, [data.customer]);

  // add popups
  // add popups
  const handleAddCustomer = (e, { value }) => {
    setCustomerText(value);

    toggleAddCustomerModal(true);
  };

  const returnAddCustomer = (id) => {
    fetchCustomerOptions().then((resp_data) => {
      if (resp_data.map((option) => option.value).includes(id)) {
        setData({
          ...data,
          customer: id,
        });
      }
    });
  };
  const handleAddLocation = (e, { value }) => {
    setLocationText(value);
    toggleAddLocationModal(true);
  };

  const returnAddLocation = (id) => {
    fetchLocationsOptions(data.customer).then((resp_data) => {
      if (resp_data.map((option) => option.value).includes(id)) {
        setData({
          ...data,
          locations: [...data.locations, id],
        });
      }
    });
  };

  const selectedLanguage = useSelector((state) => {
    if (state?.i18n?.lang) {
      return state.i18n.lang;
    } else {
      return 'en';
    }
  });

  const onValuesChange = (name) => (event, value) => {
    setInitChange(true);
    let currentFieldValue = '';
    const dropdown_fields = ['users', 'superior', 'customer', 'locations'];

    if (!dropdown_fields.includes(name)) {
      currentFieldValue = event.target.value;
      setData({
        ...data,
        [name]: event.target.value,
      });
    }

    if (dropdown_fields.includes(name)) {
      currentFieldValue = value.value;
      setData({
        ...data,
        [name]: value.value,
      });
    }

    let fieldValidation = validation[name];
    if (fieldValidation) {
      // console.log(
      //   fieldValidation.pattern(currentFieldValue) ? false : true,
      //   currentFieldValue
      // );
      let currentValidation = {
        ...validation,
        [name]: {
          ...fieldValidation,
          error: fieldValidation.pattern(currentFieldValue) ? false : true,
          skipped: true,
        },
      };
      // console.log('current', currentValidation);
      setValidation(currentValidation);
    }
  };

  const validationHandler = () => {
    let currentValidation = {
      ...validation,
      name: {
        ...validation.name,
        error: validation.name.pattern(data.name) ? false : true,
        skipped: true,
      },
      users: {
        ...validation.users,
        error: validation.users.pattern(data.users) ? false : true,
        skipped: true,
      },
      superior: {
        ...validation.superior,
        error: validation.superior.pattern(data.superior) ? false : true,
        skipped: true,
      },
      customer: {
        ...validation.customer,
        error: validation.customer.pattern(data.customer) ? false : true,
        skipped: true,
      },
      locations: {
        ...validation.locations,
        error: validation.locations.pattern(data.locations) ? false : true,
        skipped: true,
      },
    };
    setValidation(currentValidation);
    const validationToArray = Object.entries(currentValidation);
    const cond = validationToArray.some((el) => el[1].error);
    return cond;
  };

  useEffect(() => {
    const validationToArray = Object.entries(validation);
    const cond = validationToArray.some((el) => el[1].error && el[1].skipped);
    //

    if (initChange) setValidForm(!cond);
  }, [validation, initChange]);

  const updateTeamHandler = async () => {
    let errorsInData = validationHandler();

    if (!errorsInData) {
      setButtonStatus(1);

      const filtered_locations_per_customer = data.locations.filter((loc) =>
        options.locations.map((in_loc) => in_loc.value).includes(loc)
      );

      // console.log(filtered_locations_per_customer);
      const response = await updateTeam(
        targetedData?.id,
        data.name,
        data.description,
        data.users,
        data.superior,
        filtered_locations_per_customer,
        organisationId,
        data?.customer
      );

      if (response.status == 200) {
        setButtonStatus(0);
        untoggle();
        resetForm();

        setResponseData({
          id: response.data.id,
          name: response.data.name,
        });
        setFilterOptions({
          requestedBy: {
            supervisors: [],
            customers: [],
          },
          locations: [],
          customers: [],
          teams: [],
          superior: [],

          priority: [],
          status: [],
        });
        setSortData([]);
        setSearchTitle('');
        toast.success(
          t.teams.add +
          
            ' ' +
            t.common.updated_success
        );
      } else if (response.status == 400) {
        if (response.error && response.error.message) {
          toast.error(response.error.message);
          // untoggle();
        } else {
          toast.error(t.common.something_wrong);
          untoggle();
          resetForm();
        }
      }
      setButtonStatus(0);
    } else {
      scrollToError();
    }
  };

  const cancelHandler = () => {
    resetForm();
    setModalWidth(Math.max(window.innerWidth / 2, 514));
    untoggle();
    setNewCancelModal(false);
    setInitChange(false);
  };

  return (
    <>
      <div
        clearing
        style={{ maxWidth: `${modalWidth}px` }}
        className={
          toggled ? 'aside-modal active procedure' : 'aside-modal procedure'
        }
      >
        <Resizable
          size={{ width: modalWidth, height: 'auto' }}
          minWidth={'514px'}
          onResize={(e, direction, ref, d) => {
            setModalWidth(ref.offsetWidth);
          }}
        >
          <div className="modal-header">
            <div className="flexbox justify-between">
              <div className="name">
                {t.teams.team_preview.edit_team} #{targetedData?.attributes?.countId}
              </div>
              <div>
                <span
                  className=" c-pointer"
                  style={{ position: 'relative' }}
                  onMouseOver={() => setCancleTooltip(true)}
                  onMouseLeave={() => setCancleTooltip(false)}
                  onClick={() =>
                    initChange ? setNewCancelModal(true) : cancelHandler()
                  }
                >
                  <CloseIcon />
                  <ToolTip
                    color="#fff"
                    backgroundColor="#172B4D"
                    position={
                      selectedLanguage == 'en'
                        ? 'bottom center'
                        : 'bottom center'
                    }
                    show={showCancleTooltip}
                    textBoxWidth="36px"
                    arrowAlign="center"
                    padding="5px 5px 5px 7px"
                    borderRadius="5px"
                    static
                  >
                    <span className="work-order-preview-tooltip">
                      {t.common.close}
                    </span>
                  </ToolTip>
                </span>
              </div>
            </div>
          </div>
          {buttonStatus == 1 ? (
            <Dimmer active inverted>
              <Loader inverted content={t.common.loading} />
            </Dimmer>
          ) : (
            ''
          )}
          <div className="body" style={{ overflow: 'visible' }}>
            <Form.Field>
              <label className="label-item">
                {t.teams.team_info_form.team_name}
                <Asterisk color="#172B4D" />
              </label>
              <Input
                autoComplete="new-password"
                fluid
                placeholder={t.teams.team_info_form.add_team}
                value={data.name}
                onChange={onValuesChange('name')}
                error={validation.name.error && validation.name.skipped}
                onBlur={(e) => {
                  if (validation.name.pattern(data.name)) {
                    setValidation({
                      ...validation,
                      name: {
                        ...validation.name,
                        error: false,
                        skipped: true,
                      },
                    });
                  } else {
                    setValidation({
                      ...validation,
                      name: {
                        ...validation.name,
                        error: true,
                        skipped: true,
                      },
                    });
                  }
                }}
              />
              {validation.name.error && validation.name.skipped ? (
                <div className="label-error">{validation.name.message}</div>
              ) : (
                ''
              )}
            </Form.Field>

            <Form style={{ marginBottom: '20px' }}>
              <Form.Field>
                <label className="label-item">
                  {t.teams.team_info_form.description}
                </label>
                <TextArea
                  autoComplete="new-password"
                  value={data.description}
                  onChange={onValuesChange('description')}
                  rows={4}
                  fluid
                  placeholder={t.teams.team_info_form.description_placeholder}
                />
              </Form.Field>
            </Form>

            <Form.Field>
              <label className="label-item">
                {t.teams.team_info_form.team_superior}
                {/* <Icon name="asterisk" color="red" size="small" /> */}
              </label>
              <Dropdown
                placeholder={t.teams.team_info_form.add_team_superior}
                fluid
                search
                selection
                noResultsMessage={t.common.no_results_found}
                options={options.supervisors}
                value={data.superior}
                onChange={onValuesChange('superior')}
                error={validation.superior.error && validation.superior.skipped}
                onBlur={(e) => {
                  if (validation.superior.pattern(data.superior)) {
                    setValidation({
                      ...validation,
                      superior: {
                        ...validation.superior,
                        error: false,
                        skipped: true,
                      },
                    });
                  } else {
                    setValidation({
                      ...validation,
                      superior: {
                        ...validation.superior,
                        error: true,
                        skipped: true,
                      },
                    });
                  }
                }}
              />
              {validation.superior.error && validation.superior.skipped ? (
                <div className="label-error">{validation.superior.message}</div>
              ) : (
                ''
              )}
            </Form.Field>

            <Form.Field>
              <label className="label-item">
                {t.teams.team_info_form.team_members}
                <Asterisk color="#172B4D" />
              </label>
              <Dropdown
                placeholder={t.teams.team_info_form.add_team_members}
                fluid
                search
                selection
                noResultsMessage={t.common.no_results_found}
                multiple
                options={options.users}
                value={data.users}
                onChange={onValuesChange('users')}
                error={validation.users.error && validation.users.skipped}
                onBlur={(e) => {
                  if (validation.users.pattern(data.users)) {
                    setValidation({
                      ...validation,
                      users: {
                        ...validation.users,
                        error: false,
                        skipped: true,
                      },
                    });
                  } else {
                    setValidation({
                      ...validation,
                      users: {
                        ...validation.users,
                        error: true,
                        skipped: true,
                      },
                    });
                  }
                }}
              />
              {validation.users.error && validation.users.skipped ? (
                <div className="label-error" style={{ marginTop: '-15px' }}>
                  {validation.users.message}
                </div>
              ) : (
                ''
              )}
            </Form.Field>

            <Form.Field>
              <label className="label-item">
                {t.teams.team_info_form.customer}
                <Asterisk color="#172B4D" />
              </label>
              <Dropdown
                placeholder={t.teams.team_info_form.customer_placeholder}
                //allowAdditions
                search
                fluid
                multiple
                noResultsMessage={t.common.no_results_found}
                selection
                onAddItem={handleAddCustomer}
                options={options.customers}
                value={data.customer}
                onChange={onValuesChange('customer')}
                error={validation.customer.error && validation.customer.skipped}
                onBlur={(e) => {
                  if (validation.customer.pattern(data.customer)) {
                    setValidation({
                      ...validation,
                      customer: {
                        ...validation.customer,
                        error: false,
                        skipped: true,
                      },
                    });
                  } else {
                    setValidation({
                      ...validation,
                      customer: {
                        ...validation.customer,
                        error: true,
                        skipped: true,
                      },
                    });
                  }
                }}
              />
              {validation.customer.error && validation.customer.skipped ? (
                <div className="label-error" style={{ marginTop: '-15px' }}>
                  {validation.customer.message}
                </div>
              ) : (
                ''
              )}
            </Form.Field>
            {/* Location  */}
            <Form.Field>
              <label className="label-item">
                {t.workOrders.form.location}
                {/* <Icon name="asterisk" color="red" size="small" /> */}
              </label>
              <Dropdown
                placeholder={t.teams.team_info_form.location_placeholder}
                fluid
                selection
                //allowAdditions
                multiple
                search
                noResultsMessage={t.common.no_results_found}
                onOpen={fetchLocationsOptions}
               
                disabled={!data.customer}
                options={options.locations}
                value={data.locations}
                onChange={onValuesChange('locations')}
                error={
                  validation.locations.error && validation.locations.skipped
                }
                onBlur={(e) => {
                  if (validation.locations.pattern(data.locations)) {
                    setValidation({
                      ...validation,
                      locations: {
                        ...validation.locations,
                        error: false,
                        skipped: true,
                      },
                    });
                  } else {
                    setValidation({
                      ...validation,
                      locations: {
                        ...validation.locations,
                        error: true,
                        skipped: true,
                      },
                    });
                  }
                }}
              />
              {validation.locations.error && validation.locations.skipped ? (
                <div className="label-error">
                  {validation.locations.message}
                </div>
              ) : (
                ''
              )}
            </Form.Field>
            <div className="form-button-container mt-10">
              <button
                // content={t.procedures.form.cancel_button}
                // basic
                onClick={() =>
                  initChange ? setNewCancelModal(true) : cancelHandler()
                }
                className="form-action-button"
              >
                {t.assets.form.cancel}
              </button>

              <button
                // content={t.procedures.form.submit.add}
                // primary
                onClick={updateTeamHandler}
                // loading={buttonStatus == 1}
                className="form-action-button blue"
                disabled={buttonStatus == 1}
              >
                {t.common.edit}
              </button>
            </div>
          </div>
        </Resizable>
      </div>
      <div
        className={
          toggled ? 'aside-modal-background active' : 'aside-modal-background'
        }
        onClick={() => (initChange ? setNewCancelModal(true) : cancelHandler())}
      ></div>
      {/* <Modal
        size="tiny"
        open={cancelModal && initChange}
        onClose={() => toggleCancelModal(false)}
      >
        <Modal.Content className="cancel-text">
          <p>{t.common.cancel_statement}</p>
        </Modal.Content>
        <Modal.Actions>
          <Button color="black" onClick={() => toggleCancelModal(false)}>
            {t.common.keep}
          </Button>
          <Button negative onClick={() => cancelHandler()}>
            {t.common.yes_cancel}
          </Button>
        </Modal.Actions>
      </Modal> */}

<NewCancelModal
        yesCallback={() => {
          cancelHandler();
        }}
        title={t.parts.form.changes}
      >
        <p style={{ lineHeight: '24px' }}> {t.common.cancel_2statement}</p>
      </NewCancelModal>


      {/* on the go popups */}
      <AddCustomerModal
        toggled={addCustomerModal}
        untoggle={() => {
          toggleAddCustomerModal(false);
        }}
        returnAddCustomer={returnAddCustomer}
        title={customerText}
      />

      <AddLocationModal
        toggled={addLocationModal}
        untoggle={() => {
          toggleAddLocationModal(false);
        }}
        customer={data.customer}
        returnAddLocation={returnAddLocation}
        title={locationText}
      />
    </>
  );
}

export default Edit;
