import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Button, Modal, Input, Label } from 'semantic-ui-react';
import { selectTranslations } from '../../../config/i18n/slice';
import DataTable from 'react-data-table-component';
import { ArrowLeft, ArrowRight, CloseIcon } from '../../../../Assets/Icons/svg';
import Pagination from '../../common/Pagination';

function AssetsBulkUploadResult({
  isOpen = false,
  setModalOpenState = () => {},
  data = [],
  totalCount = {},
}) {
  const t = useSelector(selectTranslations);
  const selectedLanguage = useSelector((state) => {
    if (state?.i18n?.lang) {
      return state.i18n.lang;
    } else {
      return 'en';
    }
  });
  const [pageSize, setPageSize] = useState(20);
  const [currentPage, setCurrentPage] = useState(1);

  const errorsAndAlerts = totalCount?.updatedData
    ? totalCount?.updatedData.filter(
        (e) =>
          e.importStatus !== 'success' ||
          (e.importStatus === 'success' && e.alert.length > 0)
      )
    : [];
  const customStyles = {
    headCells: {
      style: {
        backgroundColor: '#E9F2FF',
        paddingTop: '8px', // override the cell padding for head cells
      },
    },
    // cells: {
    //   style: {
    //     paddingTop: '20px',
    //     paddingBottom: '8px',
    //   },
    // },
  };

  const getPaginatedData = (dataToPaginate) => {
    const startIndex = (currentPage - 1) * pageSize;
    const endIndex = startIndex + pageSize;
    return dataToPaginate.slice(startIndex, endIndex);
  };
  /**
   * Table Fields
   */
  const columns = [
    {
      name: t.assets.asset_name_data,
      selector: (row) => row.name,
      sortable: true,
      minWidth: '400px',
      cell: (row) => <span className="id-text-style">{row?.name}</span>,
    },
    {
      name: t.categories.status,
      selector: (row) => row.status,
      sortable: true,
      cell: (row) =>
        row?.importStatus && row.importStatus == 'failed' ? (
          <span className="text-dark-red">{t.categories.failed}</span>
        ) : (
          <span className="text-dark-yellow">{t.categories.warn}</span>
        ),
    },
    {
      name: t.assets.message,
      selector: (row) => row?.error,
      minWidth: '400px',
      sortable: true,
      cell: (row) =>
        row?.importStatus && row.importStatus == 'failed' ? (
          row?.error?.length > 0 &&  Array.isArray(row.error)  ? (

            <div className="error-list ml-3 mr-3">
          <ul className='mb-0 mt-0'>
      {row.error.map((err, index) => (
        <li key={index} className="id-text-style mt-1 mb-1">
       {/* {t.parts.errors[err]} */}
       {t.assets.errors[err]}
        </li>
      ))}</ul>
    </div>
            // <span className="id-text-style">{t.assets.errors[row?.error]}</span>
          ) : (
            // ? t.assets.errors[row?.error]
            ''
          )
        ) : row?.alert.length > 0  && Array.isArray(row.alert) ? (
          <div className="error-list ml-3 mr-3">
            <ul className='mb-0 mt-0'>
      {row.alert.map((err, index) => (
        <li key={index} className="id-text-style mt-1 mb-1">
       {/* {t.parts.errors[err]} */}
       {t.assets.errors[err]}
        </li>
      ))}</ul>
    </div>
          // <span className="id-text-style">{t.assets.errors[row?.alert]}</span>
        ) : (
          // ? t.assets.errors[row?.error]
          ''
        ),  
    },
  ];

  return (
    <Modal
      size="large"
      open={isOpen}
      onClose={() => setModalOpenState(false)}
      // className={selectedLanguage === 'en' ? '' : 'category-bulk-result-modal'}
      className="assets-modal"
      style={{ height: '730px' }}
    >
      <div className="header-container">
        <span className="assets-header">{t.assets.asset_bulk_upload_title}</span>
        <div>
          <span
            className="c-pointer text-right"
            onClick={() => setModalOpenState(false)}
          >
            <CloseIcon />
          </span>
        </div>
      </div>
      {/* <Modal.Header
        className={selectedLanguage === 'en' ? 'text-left' : 'text-right'}
      >
        {t.assets.title}
      </Modal.Header> */}
      <Modal.Content
        className="scroll-data"
        style={{ height: '688px', overflow: 'auto' }}
      >
        <div className="mb-7">
          <span className="new-grey-btn ml-0 mr-0">
            {t.assets.imported_asset} &nbsp; &nbsp; &nbsp;
            {totalCount?.importedAsset ? totalCount?.importedAsset : 0}{' '}
          </span>

          <span className="new-grey-btn green ml-7 mr-7">
            {t.assets.success_import} &nbsp; &nbsp; &nbsp;
            {totalCount?.successAsset ? totalCount?.successAsset : 0}{' '}
          </span>
          <span className="new-grey-btn red ml-0 mr-0">
            {t.assets.failed_import} &nbsp; &nbsp; &nbsp;
            {totalCount?.failedAsset ? totalCount?.failedAsset : 0}{' '}
          </span>
        </div>

        <div className="bulk-upload-table">
          <DataTable
            customStyles={customStyles}
            columns={columns}
            // data={datas}
            data={getPaginatedData(errorsAndAlerts)}
            // fixedHeader
            noDataComponent={t.common.no_result_found}
            direction={selectedLanguage === 'en' ? 'ltr' : 'rtl'}
          />
        </div>

        <div className="d-flex justify-content-end">
          <Pagination
            ofString={t.common.of}
            total={errorsAndAlerts.length}
            prevItem={
              selectedLanguage === 'en' ? <ArrowLeft /> : <ArrowRight />
            }
            nextItem={
              selectedLanguage === 'en' ? <ArrowRight /> : <ArrowLeft />
            }
            page={currentPage}
            limit={pageSize}
            handleNext={() => {
              setCurrentPage(currentPage + 1);
            }}
            handlePrevious={() => {
              setCurrentPage(currentPage - 1);
            }}
            parts={true}
          />
        </div>
      </Modal.Content>
      <Modal.Actions
        className={selectedLanguage === 'en' ? 'text-left' : 'text-right'}
      >
        <Button onClick={() => setModalOpenState(false)}>{t.common.ok}</Button>
      </Modal.Actions>
    </Modal>
  );
}

export default AssetsBulkUploadResult;
