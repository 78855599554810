import React, { useEffect, useState } from 'react';
import { Button, Header, Placeholder } from 'semantic-ui-react';
import Add from './add';

import moment from 'moment';

import { useLocation } from 'react-router';

import { getAllWorkOrders } from '../../config/functions';

import Edit from './edit';
import Preview from './preview/index';
import Filters from './filters';

import { useSelector } from 'react-redux';
import { selectTranslations } from '../../config/i18n/slice';
import { usePermissionsSimplified } from '../../../hooks/usePermissionsSimplified';
import { useHistory } from 'react-router-dom';
import {
  ProcedurePlusIcon,
  WorkOrderIcon,
  WorkOrderKanbarHeaderBlue,
  WorkOrderKanbarHeaderGreen,
  WorkOrderKanbarHeaderGrey,
  WorkOrderKanbarHeaderRed,
  WorkOrderKanbarHeaderYellow,
} from '../../../Assets/Icons/svg';
import KanbarHeader from './kanbarHeader';
import Animation from '../../components/common/CustomLoader';
const KanbarView = () => {
  const { userPermissions } = usePermissionsSimplified();
  const history = useHistory();

  const [modalWidth, setModalWidth] = useState(Math.max(window.innerWidth / 2, 514));

  const t = useSelector(selectTranslations);
  const organisationId = useSelector((state) => {
    if (state?.auth?.user?.organisation) {
      return state.auth.user.organisation.id;
    } else {
      return null;
    }
  });
  const currentUserLocations = useSelector((state) => {
    if (state?.auth?.user?.id) {
      return state.auth.user.locations;
    } else {
      return null;
    }
  });

  const role = useSelector((state) => {
    if (state?.auth?.user?.id) {
      return state.auth.user.role.type;
    } else {
      return null;
    }
  });

  const allIds = [];

  currentUserLocations?.length &&  currentUserLocations.forEach(location => {
  allIds.push(location?.id);
  location.subLocations.forEach(subLocation => {
    allIds.push(subLocation?.id);
  });
});
  const curentUrl = useLocation();
  const [seriesEdit, setSeriesEdit] = useState(false);

  const queryParams = new URLSearchParams(curentUrl.search);
  const workOrderId = queryParams.get('id');

  const [toggleAsideModal, setToggleAsideModal] = useState(false);
  const [fetching, setFetchLoading] = useState(true);
  const [data, setData] = useState([]);
  const [initData, setInitData] = useState([]);
  const [targetedData, setTargetedData] = useState();
  const [togglePreview, setTogglePreviw] = useState(false);
  const [toggleEditModal, setToggleEditModal] = useState(false);

  const [refresh, setRefresh] = useState(false);

  

  /* The above code is a React useEffect hook that fetches work orders data asynchronously using the
`getAllWorkOrders` function with the `organisationId` parameter. It then updates the state variables
`initData`, `data`, `fetchLoading`, `targetedData`, and `togglePreview` based on the fetched data
and conditions. The useEffect hook runs whenever the `refresh` or `workOrderId` dependencies change. */
  useEffect(() => {
    const fetchData = async () => {
      const fetchData = await getAllWorkOrders(organisationId, '', 1, 99999);

      if (fetchData.response) {
        setInitData(fetchData.response);
        setData(fetchData.response);
        setFetchLoading(false);
        if (workOrderId) {
          let selectedWorkOrder = fetchData.find(
            (each) => each?.attributes?.countId == workOrderId
          );
          if (selectedWorkOrder) {
            setTargetedData(selectedWorkOrder);
            setTogglePreviw(true);
          }
        }
      }
    };

    fetchData();
  }, [refresh, workOrderId]);

  const statusOptions = [
    {
      value: 'open',
      color: 'blue',
      label: t.workOrders.work_order_status.open,
      svg: <WorkOrderKanbarHeaderBlue />,
    },
    {
      value: 'inProgress',
      color: 'yellow',
      label: t.workOrders.work_order_status.inProgress,
      svg: <WorkOrderKanbarHeaderYellow />,
    },
    // {
    //   value: 'cancelled',
    //   color: 'red',
    //   label: t.workOrders.work_order_status.cancel,
    //   svg: <WorkOrderKanbarHeaderRed />,
    // },
    {
      value: 'onHold',
      color: 'red',
      label: t.workOrders.work_order_status.onHold,
      svg: <WorkOrderKanbarHeaderRed />,
    },
    {
      value: 'inReview',
      color: 'black',
      label: t.workOrders.work_order_status.inReview,
      svg: <WorkOrderKanbarHeaderGrey />,
    },
    {
      value: 'completed',
      color: 'green',
      label: t.workOrders.work_order_status.completed,
      svg: <WorkOrderKanbarHeaderGreen />,
    },
  ];
  const categoryOptions = [
    {
      value: 'damage',
      label: t.workOrders.form.category_options[0],
    },
    {
      value: 'corrective action Downtime',
      label: t.workOrders.form.category_options[1],
    },
    {
      value: 'planned Downtime',
      label: t.workOrders.form.category_options[2],
    },
    {
      value: 'unplanned Downtime',
      label: t.workOrders.form.category_options[3],
    },
    {
      value: 'inspection',
      label: t.workOrders.form.category_options[4],
    },
    {
      value: 'electrical',
      label: t.workOrders.form.category_options[5],
    },
    {
      value: 'mechanical',
      label: t.workOrders.work_order_category.Mechanical,
    },
    {
      value: 'HVAC',
      label: t.workOrders.work_order_category.HVAC,
    },
    {
      value: 'preventive maintenance',
      label: t.workOrders.form.category_options[8],
    },
    {
      value: 'project',
      label: t.workOrders.form.category_options[9],
    },
    {
      value: 'safety',
      label: t.workOrders.work_order_category.Safety,
    },
    {
      value: 'sop',
      label: t.workOrders.form.category_options[11],
    },
  ];

  const handleSetDataByFilter = (newData) => {
    setData(newData);
  };

  const isPastDue = (date) => {
    var date = moment(date).format('YYYY-MM-DD');
    var now = moment().format('YYYY-MM-DD');

    return now > date;
  };

  let statusFilteredData = {
    open: [],
    inProgress: [],
    onHold: [],
    inReview: [],
    completed: [],
  };

  data.map((one) => {
    switch (one?.attributes?.status) {
      case 'open':
        statusFilteredData.open.push(one);
        break;
      case 'inProgress':
        statusFilteredData.inProgress.push(one);
        break;
      case 'onHold':
        statusFilteredData.onHold.push(one);
        break;
      case 'inReview':
        statusFilteredData.inReview.push(one);
        break;
      case 'completed':
        statusFilteredData.completed.push(one);
        break;
    }
  });

  return (
    <div className="new-listing-container">
      <div className="table-header">
        <div className="header-actions">
          <Header className="procedure-title" as="h2">
            {' '}
            <WorkOrderIcon />
            {t.workOrders.title}
          </Header>
          <div></div>

          {userPermissions?.['work-order']?.create ? (
            <Button
              icon="add"
              primary
              onClick={() => {
                setToggleAsideModal(true);
              }}
            >
              {t.workOrders.create_WO}
              {/* <ProcedurePlusIcon /> */}
            </Button>
          ) : null}
        </div>
      </div>
      {fetching ? (
         <div className='d-flex justify-content-center align-items-center' style={{minHeight:"500px"}}>
         <Animation/>
         {/* <Placeholder fluid>
           <Placeholder.Line />
           <Placeholder.Line />
           <Placeholder.Line />
           <Placeholder.Line />
           <Placeholder.Line />
         </Placeholder> */}
       </div>
        // <div>
        //   <Placeholder fluid>
        //     <Placeholder.Line />
        //     <Placeholder.Line />
        //     <Placeholder.Line />
        //     <Placeholder.Line />
        //     <Placeholder.Line />
        //   </Placeholder>
        // </div>
      ) : (
        <div>
          {initData.length > 0 ? (
            <div className="new-listing-filter">
              <>
                <Filters
                  data={initData}
                  setDataByFilter={handleSetDataByFilter}
                  hasStatus={false}
                  fileData={data}
                  setRefresh={setRefresh}
                  refresh={refresh}
                  allIds={allIds}
                  roleData = {role}
                />

                <div
                  className="kanbar-view-container d-flex"
                  style={{ maxHeight: '800px' }}
                >
                  {statusOptions.map((one) => {
                    return (
                      <KanbarHeader
                        color={one.color}
                        name={one.label}
                        svg={one.svg}
                        data={statusFilteredData[one.value]}
                        orderClick={(order) => {
                          setTargetedData(order);
                          setTogglePreviw(true);
                        }}
                      />
                    );
                  })}
                </div>
              </>
            </div>
          ) : (
            <div style={{ textAlign: 'center' }}>
              <Header as="h2">{t.workOrders.no_work_orders_available}</Header>
              <Header as="h3">{t.workOrders.press_to_add_work_orders}</Header>
            </div>
          )}
        </div>
      )}

      {/*  WO add modal */}
      <Add
       allIds={allIds}
       roleData = {role}
        toggled={toggleAsideModal}
        untoggle={() => {
          setToggleAsideModal(false);
          setModalWidth(Math.max(window.innerWidth / 2, 514));
        }}
        setRefresh={setRefresh}
        setModalWidth={setModalWidth}
        modalWidth={modalWidth}
        refresh={refresh}
      />

      {/*  WO edit modal */}
      <Edit
       roleData={role}
       allIds={allIds}
        toggled={toggleEditModal}
        setSeriesEdit={setSeriesEdit}
        seriesEdit={seriesEdit}
        untoggle={() => {
          setToggleEditModal(false);
          setModalWidth(Math.max(window.innerWidth / 2, 514));
          setTargetedData();
          history.push('work-orders-kanban-view');
        }}
        setRefresh={setRefresh}
        refresh={refresh}
        targetedData={targetedData}
      />

      {/*  WO view modal */}
      <Preview
        targetedData={targetedData}
        setModalWidth={setModalWidth}

        modalWidth={modalWidth}
        edit={() => {
          setTogglePreviw(false);
          setToggleEditModal(true);
        }}
        toggled={togglePreview}
        untoggle={() => {
       
          setTargetedData();
          setTogglePreviw(false);
          setModalWidth(Math.max(window.innerWidth / 2, 514));
          history.push('work-orders-kanban-view');
        }}
        negative={isPastDue(
          targetedData?.attributes?.dueDate +
            'T' +
            targetedData?.attributes?.dueTime
        )}
        setRefresh={setRefresh}
        refresh={refresh}
        setSeriesEdit={setSeriesEdit}
        seriesEdit={seriesEdit}
      />
    </div>
  );
};

export default KanbarView;
