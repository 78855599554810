import React from 'react';
import { useBarcode } from 'next-barcode';
import QRCode from 'react-qr-code';

const truncateText = (text, maxLength) => {
  if (text?.length <= maxLength) {
    return text;
  }
  return text?.substring(0, maxLength) + '...';
};
class QRComponentToPrint extends React.Component {
  downloadThisQR = () => {
    const { data, isQRcode } = this.props;

    let svg = null;

    if (isQRcode) {
      svg = document.getElementById('QRCodeDownloadableAsset' + data.id);
    } else {
      var div = document.getElementById('QRCodeDownloadableAsset' + data.id);
      // Get the first SVG element inside the div
      svg = div.querySelector('svg');
    }

    const svgData = new XMLSerializer().serializeToString(svg);

    // Create a div element to hold the SVG and the name paragraph
    const container = document.createElement('div');

    // Append the SVG
    container.appendChild(svg.cloneNode(true));

    // Create a paragraph element for the name
    const nameParagraph = document.createElement('p');
    nameParagraph.innerText = data.attributes.name;

    // Append the name paragraph
    container.appendChild(nameParagraph);

    const canvas = document.createElement('canvas');
    const ctx = canvas.getContext('2d');
    const img = new Image();

    let heighgData;

      if (String(data.attributes.serialNumber).length > 6) {
        heighgData = 70
      
      } else if (String(data.attributes.serialNumber).length > 3) {
        heighgData = 90
     
      } else {
        heighgData = 100
      }


    const padding = isQRcode ? 100 : heighgData; // Set the desired padding

    img.onload = () => {
      

      canvas.width = 595; // Increase canvas width to accommodate the padding
      canvas.height =isQRcode ?  700 : 600;

      // Set background color to white
      ctx.fillStyle = '#fff';
      ctx.fillRect(0, 0, canvas.width, canvas.height); // Fill the canvas with white background

      // Create a new SVG with a white background and padding
      const whiteBackgroundSvg = `<svg xmlns="http://www.w3.org/2000/svg" width="${img.width +
        3 * padding}" height="${img.height +
        3 *
          padding}" style="background-color: white;"><rect width="100%" height="100%" fill="white" /><g transform="translate(${padding},${padding}) scale(2)">${svgData}</g></svg>`;

      const whiteBackgroundImg = new Image();
      whiteBackgroundImg.src = `data:image/svg+xml;base64,${btoa(
        whiteBackgroundSvg
      )}`;

      whiteBackgroundImg.onload = () => {
        const textWidth = ctx.measureText(data.attributes.name).width;
        const textWidthSerial = ctx.measureText(data.attributes.serialNumber)
          .width;
        let xCoordinate;
        let xyCoordinate;
        console.log('textWidth: ', textWidth);
        if (textWidth > 75) {
          xCoordinate = (canvas.width - textWidth) / 2.7;
        } else {
          xCoordinate = (canvas.width - textWidth) / 2.4;
        }

        xyCoordinate = (canvas.width - textWidthSerial) / 3;
        let finalcord;
        console.log(String(data.attributes.serialNumber).length,"asaaaa")

        if(String(data.attributes.serialNumber).length > 6) {

          ctx.drawImage(whiteBackgroundImg,isQRcode ?  0 : 0,0);
        }else if(String(data.attributes.serialNumber).length >2 ){
          console.log("end matvh",isQRcode)
          ctx.drawImage(whiteBackgroundImg,isQRcode ?  0 : 0,0);
        }else{
          ctx.drawImage(whiteBackgroundImg,isQRcode ?  0 :  5,0);
        }
        // Draw the SVG (QR code with white background and padding) onto the canvas
        ctx.drawImage(whiteBackgroundImg,isQRcode ?  52 : 10, 0);
        ctx.font = '500 16px Roboto'; // Set font size and style for the name paragraph
        ctx.fillStyle = '#000';
        const marginRight = -50; // Set font color to black // Set font color to black
        const namePosY = img.height * 2 + padding + 50;
        console.log('namePosY: ', namePosY);
        const maxWidth = isQRcode ? 530 : 505;
        const drawText = (text, x, y, maxWidth, maxLines) => {
          const words = text.split(' ');
          let line = '';
          let testLine = '';
          let lineArray = [];
          let testWidth = 0;
          ctx.font = '500 28px Roboto';

          for (let n = 0; n < words.length; n++) {
            testLine += words[n] + ' ';
            testWidth = ctx.measureText(testLine).width;

            if (testWidth > maxWidth && n > 0) {
              lineArray.push(line);
              testLine = words[n] + ' ';
              line = testLine;

              if (lineArray.length === maxLines - 1) {
                // Add remaining words with "..." if text exceeds maxLines
                let remainingWords = words.slice(n).join(' ');
                while (
                  ctx.measureText(remainingWords + '...').width > maxWidth
                ) {
                  remainingWords = remainingWords
                    .split(' ')
                    .slice(0, -1)
                    .join(' ');
                }
                lineArray.push(remainingWords + '...');
                break;
              }
            } else {
              line = testLine;
            }
          }

          if (lineArray.length < maxLines) {
            lineArray.push(line);
          }

          if(textWidth > 650){
             isQRcode ? x=x+80 : x = x-30
          }else if(textWidth > 400){
            isQRcode ? x=x : x = x-100
          }
          else if (textWidth > 75) {
            isQRcode ? x=x - 100 : x = x-200
          } else if (textWidth > 60) {
            isQRcode ? x = x - 70: x = x-170;
          } else {
            
            isQRcode ?   x = x - 50  : x = x-150
          }

          for (let k = 0; k < lineArray.length; k++) {
         
            ctx.fillText(
              lineArray[k],
              x,
              y + k * 30
            );

           
              finalcord = y + k * 30;
            
          }
        };
        // ctx.font = '400 16px Roboto'; // Set font size and style for the name paragraph
        // ctx.fillStyle = '#000';
        // const marginRight = -50; // Set font color to black // Set font color to black
        // const namePosY = img.height * 2 + padding + 30;

        if (this.props.titleName == 'Name') {
          drawText(
            `${this.props.titleName} : ${data.attributes.name}`,
            isQRcode ? xCoordinate : xCoordinate + 130,
            namePosY,
            maxWidth,
            isQRcode ? 3 : 5
            // lineHeight
          );
          // ctx.fillText(
          //   `${this.props.titleName} : ${data.attributes.name}`,
          //   xCoordinate,
          //   namePosY
          // );
        } else {
          drawText(
            `${data.attributes.name} : ${this.props.titleName}`,
            isQRcode ? xCoordinate : xCoordinate + 150,
            namePosY,
            maxWidth,
            isQRcode ? 3 : 5
            // lineHeight
          );
          // ctx.fillText(
          //   `${data.attributes.name} : ${this.props.titleName}`,
          //   xCoordinate,
          //   namePosY
          // );
        }
        let xCoordinate12 = xyCoordinate;

        if (String(data.attributes.serialNumber).length > 6) {
          xCoordinate12 = xCoordinate12 - 60;
          console.log("Condition 1 met: Serial number length > 6, xCoordinate set to", xCoordinate);
        } else if (String(data.attributes.serialNumber).length > 3) {
          xCoordinate12 = xCoordinate12 +5;
          console.log("Condition 2 met: Serial number length > 3, xCoordinate set to", xCoordinate);
        } else {
          xCoordinate12 = xCoordinate12 + 10;
          console.log("Condition 3 met: Serial number length <= 3, xCoordinate set to", xCoordinate);
        }
        // Adjust the vertical position for the name paragraph
        // Position and draw the name paragraph
        ctx.fillText(
          `${this.props.titleData} : ${data.attributes.serialNumber}`,
          xCoordinate12,
          finalcord + 60
); // Position and draw the name paragraph

        const pngFile = canvas.toDataURL('image/png');
        const downloadLink = document.createElement('a');
        downloadLink.download = `${data.attributes.name}-${data.attributes.serialNumber}`;
        downloadLink.href = `${pngFile}`;
        downloadLink.click();
      };
    };

    img.src = `data:image/svg+xml;base64,${btoa(svgData)}`;
  };

  exposeDownloadFunction = () => {
    // Expose the download function externally
    return this.downloadThisQR();
  };
  render() {
    const { data, titleData, titleName, isQRcode } = this.props;
    const truncatedText = truncateText(data?.attributes?.name, 115);
    const truncatedBarcodeText = truncateText(data?.attributes?.name, 280);
    return (
      <div className="qr-code-container">
        <div>
          {isQRcode ? (
            <>
              <QRCode
                id={'QRCodeDownloadableAsset' + data.id}
                title={data.attributes.name}
                value={'PT-' + String(data.attributes.serialNumber)}
                size={144}
                onClick={this.downloadThisQR}
                style={{ cursor: 'pointer' }}
              />
              <p style={{ display: 'none',fontSize:"24px",marginTop:"50px",maxWidth:"480px"   }}>
                {titleName == 'Name'
                  ? `${titleName} : ${truncatedText}`
                  : `${truncatedText} : ${titleName}`}
              </p>
              <p style={{ display: 'none',fontSize:"24px" }}>
                {titleData} : {data.attributes.serialNumber}
              </p>
            </>
          ) : (
            <div id={'QRCodeDownloadableAsset' + data.id}>
              <Barcode
                value={'PT-' + String(data.attributes.serialNumber)}
                format="CODE128"
                 width="2"
                height="65"
                displayValue={false}
                onClick={this.downloadThisQR}
                style={{ cursor: 'pointer' }}
              />

{/* <p style={{ display: 'none' }}>
                {titleName == 'Name'
                  ? `${titleName} : ${data.attributes.name}`
                  : `${data.attributes.name} : ${titleName}`}
              </p>
              <p style={{ display: 'none' }}>
                {titleData} : {data.attributes.serialNumber}
              </p> */}

              <p
                style={{
                  display: 'none',
                  marginTop: '-60px',
                  fontWeight: '500',
                  fontSize: '14px',
                }}
              >
                <p style={{ display: 'none', fontWeight: '500',
                  fontSize: '14px',maxWidth:"230px",  marginInline:"30px",  }}>
                  {titleName == 'Name'
                    ? `${titleName} : ${data.attributes.name}`
                    : `${data.attributes.name} : ${titleName}`}
                </p>
              </p>
              <p
                style={{
                  display: 'none',
                  marginTop: '8px',
                  fontWeight: '500',
                  fontSize: '14px',
                }}
              >
                {titleData} : {data.attributes.serialNumber}
              </p>
            </div>
          )}
        </div>
      </div>
    );
  }
}

const Barcode = ({ value, format, width, height, displayValue }) => {
  const { inputRef } = useBarcode({
    value: value,
    options: {
      // background: '#ccffff',
      format: format,
      width,
      height,
      displayValue,
    },
  });

  return <svg ref={inputRef} />;
};

QRComponentToPrint.defaultProps = {
  data: {
    attributes: {},
  },
  titleData: 'Serial Number',
  titleName: 'Name',
  isQRcode: true,
};

export default QRComponentToPrint;