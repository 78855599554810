import React, { useEffect, useState, createRef } from 'react';
import {
  Checkbox,
  Table,
  Button,
  Header,
  Icon,
  Placeholder,
  Label,
  Image,
  Grid,
  Menu,
  Loader,
} from 'semantic-ui-react';
import Pagination from '../../components/common/CommonPagination';
import { useDebounce } from 'use-debounce';
import ProgressBar from '@ramonak/react-progress-bar';
import Add from './add';
import Moment from 'react-moment';
import moment from 'moment';
import DataTable from 'react-data-table-component';
import Tooltip from 'react-power-tooltip';

import { NavLink } from 'react-router-dom';

import { useLocation } from 'react-router';
import { toAbsoluteUrl, checkIsActive } from '../../../_metronic/_helpers';
import UserProfilePlaceholder, {
  LetteredAvatarImageUrl,
} from '../../components/common/userProfilePlaceholder';
import md5 from 'md5';

import { Footer } from '../../../_metronic/layout/components/footer/Footer';
import {
  getAllWorkOrders,
  getUserProfileByID,
  humanize,
  IMAGES_URL,
} from '../../config/functions';

import Edit from './edit';
import Preview from './preview/index';
import Filters from './listingFilter';

import { useDispatch, useSelector } from 'react-redux';
import { selectTranslations } from '../../config/i18n/slice';
import { usePermissionsSimplified } from '../../../hooks/usePermissionsSimplified';
import { useHistory } from 'react-router-dom';
import useDisplayFormattedText from '../../../hooks/useDisplayFormattedText';
import {
  ArrowLeft,
  ArrowRight,
  AssetIcon,
  CloseIcon,
  ProcedurePlusIcon,
  SortIcon,
  WorkOrderIcon,
  WorkOrderIconBlue,
} from '../../../Assets/Icons/svg';
import {
  fetchAllWOData,
  fetchWOInitData,
  fetchWOWithFilter,
} from '../../../redux/reducers/workOrderReducer';
import Animation from '../../components/common/CustomLoader';

const customStyles = {
  rows: {
    style: {
      height: '72px',
      // override the row height
    },
  },
  headCells: {
    style: {
      paddingTop: '11px', // override the cell padding for head cells
      // paddingBottom: '4px',
    },
  },
  cells: {
    style: {
      paddingLeft: '16px', // override the cell padding for data cells
      paddingRight: '16px',
      paddingTop: '19px',
      paddingBottom: '19px',
    },
  },
};
export const WorkOrders = () => {
  const { userPermissions } = usePermissionsSimplified();
  const lang = useSelector((state) => state.i18n.lang);
  const history = useHistory();
  const { DisplayFormattedText } = useDisplayFormattedText();
  const dispatch = useDispatch();

  const [selectedDates, setSelectedDates] = useState([]);
  const [sortData, setSortData] = useState();

  const [filterOptions, setFilterOptions] = useState({
    serialNumber: '',
    people: [],
    teams: [],
    customers: [],
    locations: [],
    createdByUser: [],
    status: [],
    priority: [],
    categories: [],
  });

  const [modalWidth, setModalWidth] = useState(Math.max(window.innerWidth / 2, 514));
  const [searchTitle, setSearchTitle] = useState('');
  const [debounceValue] = useDebounce(searchTitle, 500);
  const [loader, setloader] = useState(false);
  const [seriesEdit, setSeriesEdit] = useState(false);

  const t = useSelector(selectTranslations);
  const organisationId = useSelector((state) => {
    if (state?.auth?.user?.organisation) {
      return state.auth.user.organisation.id;
    } else {
      return null;
    }
  });
  const selectedLanguage = useSelector((state) => {
    if (state?.i18n?.lang) {
      return state.i18n.lang;
    } else {
      return 'en';
    }
  });

  const currentUserLocations = useSelector((state) => {
    if (state?.auth?.user?.id) {
      return state.auth.user.locations;
    } else {
      return null;
    }
  });

  const role = useSelector((state) => {
    if (state?.auth?.user?.id) {
      return state.auth.user.role.type;
    } else {
      return null;
    }
  });

  const allIds = [];

  currentUserLocations?.length &&  currentUserLocations.forEach(location => {
  allIds.push(location?.id);
  location.subLocations.forEach(subLocation => {
    allIds.push(subLocation?.id);
  });
});



  

  const {
    data,
    allVendorData,
    isLoading: loading,
    error,
    initData,
    page,
    pageSize,
    total,
    initLoading,
  } = useSelector((state) => ({
    data: state.workOrder.WOData,
    allVendorData: state.workOrder.allWOData,
    isLoading: state.workOrder.isLoading,
    error: state.workOrder.error,
    initData: state.workOrder.WOInitData,
    page: state.workOrder.page,
    pageSize: state.workOrder.pageSize,
    total: state.workOrder.total,
    initLoading: state.workOrder.initLoading,
  }));

  /* The function `handleSort` sorts data based on a specified column and sort direction */
  const handleSort = async (column, sortDirection) => {
    setloader(true);
    const sort = [`${column.sortField}:${sortDirection}`];
    setSortData(sort);
    const sortedData = await dispatch(
      fetchWOWithFilter({
        organisationId,
        searchName: debounceValue,
        page: page,
        pageSize: pageSize,
        assignee: filterOptions.people,
        status: filterOptions.status,
        priority: filterOptions.priority,
        category: filterOptions.categories,
        customers: filterOptions.customers,
        locations: filterOptions.locations,
        dueDate: selectedDates,
        serialNumber: filterOptions.serialNumber,
        teams: filterOptions.teams,
        sort,
        role:role,
        allIds:allIds,

      })
    );

    setloader(false);
  };

  /* The function `handleRemoveSorting` for remove sorts data */
  const handleRemoveSorting = async () => {
    setloader(true);

    // Assuming the select or represents the field to sort
    const sort = undefined;
    setSortData(sort);
    const sortedData = await dispatch(
      fetchWOWithFilter({
        organisationId,
        searchName: debounceValue,
        page: page,
        pageSize: pageSize,
        assignee: filterOptions.people,
        status: filterOptions.status,
        priority: filterOptions.priority,
        category: filterOptions.categories,
        customers: filterOptions.customers,
        locations: filterOptions.locations,
        dueDate: selectedDates,
        serialNumber: filterOptions.serialNumber,
        teams: filterOptions.teams,
        sort,
        role:role,
        allIds:allIds,
      })
    );

    setloader(false);
  };

  /*
   * Table Fields
   */
  const columns = [
    {
      name: t.workOrders.table.ID,
      sortField: 'countNumId',
      width: selectedLanguage === 'en' ? '113px' : '107px',
      sortable: true,
      cell: (row) => (
        <span
          onClick={() => {
            setTargetedData(row);
            setTogglePreviw(true);
          }}
          className="id-text-style"
        >
          {row?.attributes?.countId}
        </span>
      ),
    },
    {
      name: t.workOrders.table.title,
      // selector: (row) => row?.attributes?.title,
      sortField: 'title',
      sortable: true,
      minWidth: '134px',
      cell: (row) => (
        <span
          onClick={() => {
            setTargetedData(row);
            setTogglePreviw(true);
          }}
          className="id-text-style"
        >
          {DisplayFormattedText(row?.attributes?.title, 8)}
        </span>
      ),
    },
    {
      minWidth: '150px',
      sortField: 'status',
      name: t.workOrders.table.status,
      // selector: (row) => row?.attributes?.status,
      sortable: true,
      cell: (row) => {
        const workOrderStatus = statusOptions.find(
          (status) => status.value === row.attributes.status
        );

        return (
          <div
            onClick={() => {
              setTargetedData(row);
              setTogglePreviw(true);
            }}
            className="d-flex align-items-center"
          >
            <Label
              circular
              empty
              color={workOrderStatus?.color || '#788ca5'}
              style={{ width: '12px', height: '12px' }}
            />
            <div>
              <span className="ml-2 mr-2">
                {workOrderStatus?.label || t.common.na}
              </span>
            </div>
          </div>
        );
      },
    },
    {
      name: t.workOrders.table.priority,
      // selector: (row) => row?.attributes?.priority,
      sortField: 'priority',
      sortable: true,
      minWidth: '122px',
      cell: (row) => {
        const PriorityStatus = priorityOptions.find(
          (status) => status.value === row.attributes.priority
        );

        return (
          <>
            <span
              onClick={() => {
                setTargetedData(row);
                setTogglePreviw(true);
              }}
              className={`pill ${row.attributes.priority}`}
            >
              {PriorityStatus?.label || t.common.na}
            </span>
          </>
        );
      },
    },
    {
      name: t.workOrders.table.assignees,
      minWidth: '128px',
      // selector: (row) =>
      //   row?.attributes?.people?.data
      //     ? row?.attributes?.people?.data?.length
      //     : 0,
      // sortable: true,
      cell: (row) => {
        let assigneePeople = row?.attributes?.people?.data;
        let totalLength = row?.attributes?.people?.data
          ? row?.attributes?.people?.data.length
          : 0;

        if (assigneePeople?.length) {
          if (assigneePeople?.length < 3) {
            assigneePeople = assigneePeople.map((people, index) => {
              if (people?.attributes?.profileImage?.data?.attributes?.url) {
                {
                  return (
                    <Image
                      onClick={() => {
                        setTargetedData(row);
                        setTogglePreviw(true);
                      }}
                      avatar
                      title={
                        people.attributes.firstName +
                        ' ' +
                        people.attributes.lastName
                      }
                      src={`${IMAGES_URL}${people?.attributes?.profileImage?.data?.attributes?.url}`}
                      style={
                        index != 0
                          ? {
                              left: '-7px',
                              width: '34px',
                              height: '34px',
                            }
                          : { width: '34px', height: '34px' }
                      }
                      className={index == 0 ? 'img-first' : ''}
                    />
                  );
                }
              } else {
                return (
                 
                    <UserProfilePlaceholder
                      // onClick={()=>handleClick(row)}
                      name={`${
                        people?.attributes?.firstName
                          ? people?.attributes?.firstName
                          : ''
                      } ${
                        people?.attributes?.lastName
                          ? people?.attributes?.lastName
                          : ''
                      }`}
                      width={'34px'}
                      height={'34px'}
                      right={index !== 0 ? '7px' : ''}
                      className={index == 0 ? 'img-first' : ''}
                    />
             
                );
              }
            });
          } else {
            assigneePeople = assigneePeople.slice(0, 2).map((people, index) => {
              if (people?.attributes?.profileImage?.data?.attributes?.url) {
                {
                  return (
                    <Image
                      onClick={() => {
                        setTargetedData(row);
                        setTogglePreviw(true);
                      }}
                      avatar
                      title={
                        people.attributes.firstName +
                        ' ' +
                        people.attributes.lastName
                      }
                      src={`${IMAGES_URL}${people?.attributes?.profileImage?.data?.attributes?.url}`}
                      style={
                        index != 0
                          ? {
                              left: '7px',
                              width: '34px',
                              height: '34px',
                            }
                          : { width: '34px', height: '34px' }
                      }
                    />
                  );
                }
              } else {
                return (
                  
                    <UserProfilePlaceholder
                      // click={handleClick(row)}
                      name={`${
                        people?.attributes?.firstName
                          ? people?.attributes?.firstName
                          : ''
                      } ${
                        people?.attributes?.lastName
                          ? people?.attributes?.lastName
                          : ''
                      }`}
                      width={'34px'}
                      height={'34px'}
                      right={
                        selectedLanguage === 'ar' && index !== 0
                          ? '0px'
                          : index === 0 && selectedLanguage === 'en'
                          ? '-15px'
                          : '-9px'
                      }
                    />
                
                );
              }
            });
            assigneePeople.push(
              <Image
             
                avatar
                title={`${totalLength - 2}`}
                src={`https://ui-avatars.com/api/?name=${totalLength -
                  2}%2B&background=8CCA9D&bold=true&color=FFFFFF`}
                style={{
                  width: '34px',
                  height: '34px',
                  right: '-3px',
                }}
              />
            );
          }
          return (
            <div  onClick={() => {
              setTargetedData(row);
              setTogglePreviw(true);
            }}
              className={`multi-profile-container ${
                lang == 'en' ? '' : 'rtl-body'
              }`}
            >
              {assigneePeople}
            </div>
          );
        } else {
          return t.common.na;
        }
      },
    },
    {
      sortField: 'category',
      name: t.workOrders.table.category,
      // selector: (row) => row?.attributes?.category,
      sortable: true,
      minWidth: '135px',
      cell: (row) => {
        const categoryStatus = categoryOptions.find(
          (one) => one.value == row.attributes.category
        );

        return (
          <div>
            <span
              onClick={() => {
                setTargetedData(row);
                setTogglePreviw(true);
              }}
              className=""
            >
              {categoryStatus?.label || t.common.na}
            </span>
          </div>
        );
      },
    },
    {
      name: t.workOrders.table.customer,
      minWidth: '152px',
      sortField: 'customer.name',
      // selector: (row) =>
      //   row?.attributes?.customer?.data
      //     ? row?.attributes?.customer?.data.attributes?.name
      //     : t.common.na,
      sortable: true,
      cell: (row) => (
        <span
          onClick={() => {
            setTargetedData(row);
            setTogglePreviw(true);
          }}
          className=""
        >
          {DisplayFormattedText(
            row?.attributes?.customer?.data?.attributes?.name,
            13
          ) || t.common.na}
        </span>
      ),
    },
    {
      sortField: 'location.name',
      name: t.workOrders.table.location,
      minWidth: '175px',
      // selector: (row) =>
      //   row?.attributes?.location?.data
      //     ? row?.attributes?.location?.data?.attributes.name
      //     : t.common.na,
      sortable: true,
      cell: (row) => (
        <span
          onClick={() => {
            setTargetedData(row);
            setTogglePreviw(true);
          }}
          className=""
        >
          {DisplayFormattedText(
            row?.attributes?.location?.data?.attributes.name,
            12
          ) || t.common.na}
        </span>
      ),
    },
    {
      sortField: 'dueDate',
      // width: '161px',
      name: t.workOrders.table.dueDate,
      minWidth: '144px',
      // selector: (row) => row?.attributes?.dueDate,
      sortable: true,
      cell: (row) => (
        <div
          onClick={() => {
            setTargetedData(row);
            setTogglePreviw(true);
          }}
        >
          <div>
            <Moment format="DD-MM-YYYY" date={row?.attributes?.dueDate} />
          </div>
          <div className="time-text-style text-left">
            <Moment
              format="h a"
              date={
                row?.attributes?.dueDate +
                ' ' +
                `${row?.attributes?.dueTime || '08:00:00'}`
              }
            />
          </div>
        </div>
      ),
    },

    {
      name: t.workOrders.table.createdByUser,
      minWidth: '142px',
      sortField: 'createdByUser.firstName',
      // selector: (row) =>
      //   row?.attributes?.createdByUser?.data?.attributes
      //     ? row?.attributes?.createdByUser?.data?.attributes?.firstName +
      //       ' ' +
      //       row?.attributes?.createdByUser?.data?.attributes?.lastName
      //     : t.common.na,
      sortable: true,
      cell: (row) => {
        let createdByUser = row?.attributes?.createdByUser?.data ? (
          <div
            onClick={() => {
              setTargetedData(row);
              setTogglePreviw(true);
            }}
          >
            <Image
              avatar
              title={
                row.attributes.createdByUser.data.attributes.firstName +
                ' ' +
                row.attributes.createdByUser.data.attributes.lastName
              }
              src={
                row?.attributes?.createdByUser?.data?.attributes?.profileImage
                  ?.data?.attributes?.url
                  ? IMAGES_URL +
                    row?.attributes?.createdByUser?.data?.attributes
                      ?.profileImage?.data?.attributes?.url
                  : LetteredAvatarImageUrl(
                      row?.attributes?.createdByUser?.data?.attributes
                        .firstName +
                        ' ' +
                        row?.attributes?.createdByUser?.data?.attributes
                          ?.lastName
                    )
              }
              style={{ width: '34px', height: '34px' }}
            />
            <span
              style={{ marginLeft: '4px', marginRight: '4px' }}
              className="id-text-style"
            >
              {row.attributes.createdByUser.data.attributes.firstName}
            </span>
          </div>
        ) : (
          t.common.na
        );
        return createdByUser;
      },
    },

    {
      name: t.workOrders.form.progress,
      sortField: 'report.procedureCompletion',
      minWidth: '166px',

      sortable: true,
      cell: (row) => (
        <>
          <ProgressBar
            completed={
              row?.attributes?.report?.data?.attributes?.procedureCompletion
                ? row?.attributes?.report?.data?.attributes?.procedureCompletion
                : 0
            }
            bgColor="#4FAB68"
            isLabelVisible={false}
            height="6px"
            width="64px"
            baseBgColor="#E4E6EA"
            labelColor="#E4E6EA"
          />

          <span
            onClick={() => {
              setTargetedData(row);
              setTogglePreviw(true);
            }}
            className="ml-3 mr-3"
          >
            {row?.attributes?.report?.data?.attributes?.procedureCompletion
              ? row?.attributes?.report?.data?.attributes?.procedureCompletion
              : 0}
            %
          </span>
        </>
      ),
    },
  ];
  const curentUrl = useLocation();

  const queryParams = new URLSearchParams(curentUrl.search);
  const workOrderId = queryParams.get('id');

  const [toggleAsideModal, setToggleAsideModal] = useState(false);
  const [fetching, setFetchLoading] = useState(true);
  const [showTooltip, setShowTooltip] = useState(false);

  const [expandedRow, setExpandedRow] = useState();
  const [targetedData, setTargetedData] = useState();
  const [togglePreview, setTogglePreviw] = useState(false);
  const [toggleEditModal, setToggleEditModal] = useState(false);

  const [currentPage, setCurrentPage] = useState(1);

  const [activeChecklistReport, setActiveChecklistReport] = useState({
    report: '',
    isOpen: false,
  });

  const [activeInspectionReport, setActiveInspectionReport] = useState({
    report: '',
    isOpen: false,
  });

  const [refresh, setRefresh] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      const fetchData = await dispatch(fetchWOInitData({organisationId,   role,
        allIds}));
      if (fetchData) {
        setFetchLoading(false);
        if (workOrderId) {
          const fetchAllData = await dispatch(fetchAllWOData({organisationId,   role,
            allIds}));

          let selectedWorkOrder = fetchAllData.payload.response.find(
            (each) => each?.attributes?.countId == workOrderId
          );
          if (selectedWorkOrder) {
            setTargetedData(selectedWorkOrder);
            setTogglePreviw(true);
          }
        }
      }
    };

    fetchData();
  }, [workOrderId]);

  const priorityOptions = [
    {
      value: 'none',
      label: t.workOrders.priority_status.none,
      color: null,
    },
    {
      value: 'low',
      label: t.workOrders.priority_status.low,

      color: 'yellow',
    },
    {
      value: 'medium',
      label: t.workOrders.priority_status.medium,

      color: 'orange',
    },
    {
      value: 'high',
      label: t.workOrders.priority_status.high,

      color: 'red',
    },
  ];

  const statusOptions = [
    {
      value: 'open',
      color: 'blue',
      label: t.workOrders.work_order_status.open,
    },
    {
      value: 'inProgress',
      color: 'yellow',
      label: t.workOrders.work_order_status.inProgress,
    },
    {
      value: 'onHold',
      color: 'red',
      label: t.workOrders.work_order_status.onHold,
    },
    {
      value: 'inReview',
      color: 'black',
      label: t.workOrders.work_order_status.inReview,
    },
    {
      value: 'completed',
      color: 'green',
      label: t.workOrders.work_order_status.completed,
    },
    {
      value: 'cancelled',
      color: 'darkgrey',
      label: t.workOrders.work_order_status.cancel,
    },
  ];
  const categoryOptions = [
    {
      value: 'damage',
      label: t.workOrders.form.category_options[0],
    },
    {
      value: 'corrective action Downtime',
      label: t.workOrders.form.category_options[1],
    },
    {
      value: 'planned Downtime',
      label: t.workOrders.form.category_options[2],
    },
    {
      value: 'unplanned Downtime',
      label: t.workOrders.form.category_options[3],
    },
    {
      value: 'inspection',
      label: t.workOrders.form.category_options[4],
    },
    {
      value: 'electrical',
      label: t.workOrders.form.category_options[5],
    },
    {
      value: 'mechanical',
      label: t.workOrders.work_order_category.Mechanical,
    },
    {
      value: 'HVAC',
      label: t.workOrders.work_order_category.HVAC,
    },
    {
      value: 'preventive maintenance',
      label: t.workOrders.form.category_options[8],
    },
    {
      value: 'project',
      label: t.workOrders.form.category_options[9],
    },
    {
      value: 'safety',
      label: t.workOrders.work_order_category.Safety,
    },
    {
      value: 'sop',
      label: t.workOrders.form.category_options[11],
    },
  ];
  const handleSetDataByFilter = (newData) => {};

  const isPastDue = (date) => {
    var date = moment(date).format('YYYY-MM-DD');
    var now = moment().format('YYYY-MM-DD');

    return now > date;
  };

  const conditionalRowStyles = [
    {
      when: (row) =>
        isPastDue(
          row?.attributes?.dueDate +
            'T' +
            `${row?.attributes?.dueTime || '08:00:00'}`
        ), // Condition to check
      style: {
        backgroundColor: '#fff6f6', // Apply red color to the row
      },
    },
  ];

  return (
    <div className="new-listing-container">
      <div className="table-header">
        <div className="header-actions">
          <Header className="procedure-title" as="h2">
            {' '}
            <WorkOrderIcon />
            {t.workOrders.title}
          </Header>
          <div></div>

          {userPermissions?.['work-order']?.create && initData.length > 0 ? (
            <Button
              icon="add"
              primary
              onClick={() => {
                setToggleAsideModal(true);
              }}
            >
              {t.workOrders.create_WO}
              {/* <ProcedurePlusIcon /> */}
            </Button>
          ) : null}
        </div>
      </div>
      {initLoading ? (
         <div className='d-flex justify-content-center align-items-center' style={{minHeight:"500px"}}>
         <Animation/>
         {/* <Placeholder fluid>
           <Placeholder.Line />
           <Placeholder.Line />
           <Placeholder.Line />
           <Placeholder.Line />
           <Placeholder.Line />
         </Placeholder> */}
       </div>
        // <div>
        //   <Placeholder fluid>
        //     <Placeholder.Line />
        //     <Placeholder.Line />
        //     <Placeholder.Line />
        //     <Placeholder.Line />
        //     <Placeholder.Line />
        //   </Placeholder>
        // </div>
      ) : (
        <div>
          <div className="new-listing-filter">
            {initData.length > 0 ? (
              <>
                <Filters
                  data={initData}
                  roleData={role}
                  allIds={allIds}
                  setDataByFilter={handleSetDataByFilter}
                  hasStatus={false}
                  fileData={data}
                  setRefresh={setRefresh}
                  refresh={refresh}
                  setloader={setloader}
                  loader={loader}
                  setSearchTitle={setSearchTitle}
                  searchTitle={searchTitle}
                  filterOptions={filterOptions}
                  debounceValue={debounceValue}
                  setFilterOptions={setFilterOptions}
                  setSelectedDates={setSelectedDates}
                  selectedDates={selectedDates}
                  setSortData={setSortData}
                  sortData={sortData}
                />
                <DataTable
                  className="datatable-div"
                  style={{ borderRadius: '0px' }}
                  columns={columns}
                  fixedHeader
                  onSort={handleSort}
                  conditionalRowStyles={conditionalRowStyles}
                  data={data}
                  noDataComponent={
                    initData.length > 0 ? (
                      <div className="no-data-container">
                        <div className="">
                          <p className="no-data-text">
                            {t.common.no_result_found}
                          </p>
                        </div>
                      </div>
                    ) : null
                  }
                  customStyles={customStyles}
                  direction={selectedLanguage === 'en' ? 'ltr' : 'rtl'}
                  onRowClicked={(row) => {
                    setTargetedData(row);
                    setTogglePreviw(true);
                  }}
                  sortIcon={
                    sortData ? (
                      <div>
                        <span>
                          <SortIcon />
                        </span>
                        <span
                          onClick={(e) => {
                            e.stopPropagation();
                            handleRemoveSorting();
                          }}
                        >
                          <CloseIcon />
                        </span>
                      </div>
                    ) : (
                      <span></span>
                    )
                  }
                  persistTableHead={true}
                  pointerOnHover={true}
                  highlightOnHover={true}
                  progressPending={loader}
                  progressComponent={
                    <div className="mt-8 mb-8 ">
      { t.common.loading}  
                    </div>
                  }
                />

                <div className="d-flex justify-content-end">
                  <Pagination
                    ofString={t.common.of}
                    total={total}
                    prevItem={
                      selectedLanguage === 'en' ? <ArrowLeft /> : <ArrowRight />
                    }
                    nextItem={
                      selectedLanguage === 'en' ? <ArrowRight /> : <ArrowLeft />
                    }
                    page={page}
                    limit={pageSize}
                    handleNext={async () => {
                      setloader(true);
                      await dispatch(
                        fetchWOWithFilter({
                          organisationId,
                          searchName: debounceValue,
                          page: page + 1,
                          pageSize: pageSize,
                          assignee: filterOptions.people,
                          status: filterOptions.status,
                          priority: filterOptions.priority,
                          category: filterOptions.categories,
                          customers: filterOptions.customers,
                          locations: filterOptions.locations,
                          dueDate: selectedDates,
                          serialNumber: filterOptions.serialNumber,
                          teams: filterOptions.teams,
                          sort: sortData,
                          role:role,
                          allIds:allIds,
                        })
                      );
                      setloader(false);
                    }}
                    handlePrevious={async () => {
                      setloader(true);
                      await dispatch(
                        fetchWOWithFilter({
                          organisationId,
                          searchName: debounceValue,
                          page: page - 1,
                          pageSize: pageSize,
                          assignee: filterOptions.people,
                          status: filterOptions.status,
                          priority: filterOptions.priority,
                          category: filterOptions.categories,
                          customers: filterOptions.customers,
                          locations: filterOptions.locations,
                          dueDate: selectedDates,
                          serialNumber: filterOptions.serialNumber,
                          teams: filterOptions.teams,
                          sort: sortData,
                          role:role,
                          allIds:allIds,
                        })
                      );
                      setloader(false);
                    }}
                  />
                </div>
              </>
            ) : null}

            {initData.length == 0 ? (
              <div className="no-data-container">
                <div className="no-work-orders pb-15">
                  <WorkOrderIconBlue />
                  <p className="no-data-text">
                    {t.common.there_no}{' '}
                    <span
                      className="link-text"
                      style={{ position: 'relative', cursor: 'pointer' }}
                      onMouseOver={() => setShowTooltip(true)}
                      onMouseLeave={() => setShowTooltip(false)}
                    >
                      {/* <Tooltip
                        color="#fff"
                        backgroundColor="#172B4D"
                        position="top left"
                        show={showTooltip}
                        textBoxWidth="160px"
                        arrowAlign="start"
                        fontSize="14px"
                        fontWeight="400"
                        fontFamily="Roboto"
                        padding="0px 0px 0px 8px"
                        borderRadius="5px"
                        static
                        moveRight="120px"
                      >
                        <span className="work-order-tooltip">
                          {t.workOrders.how_to_add_WO}
                        </span>
                      </Tooltip>       */}

                      <Tooltip
                        color="#fff"
                        backgroundColor="#172B4D"
                        position={
                          selectedLanguage == 'ar' ? 'top right' : 'top left'
                        }
                        show={showTooltip}
                        textBoxWidth={
                          selectedLanguage == 'ar' ? '53px' : '165px'
                        }
                        arrowAlign={
                          selectedLanguage == 'ar' ? 'center' : 'start'
                        }
                        fontSize="14px"
                        fontWeight="400"
                        fontFamily={
                          selectedLanguage == 'ar'
                            ? 'Noto Kufi Arabic'
                            : 'Roboto'
                        }
                        padding="0px 0px 0px 8px"
                        borderRadius="5px"
                        static
                        moveRight="120px"
                        moveUp={selectedLanguage == 'ar' ? '-1px' : '0px'}
                      >
                        <span className="work-order-tooltip">
                          {t.workOrders.how_to_add_WO}
                        </span>
                      </Tooltip>

                      {' ' + t.workOrders.title_small + ' '}
                    </span>{' '}
                    {t.common.added}
                  </p>
                  {userPermissions?.['work-order']?.create ? (
                    <button
                      className="work-order-button-transparent"
                      onClick={() => {
                        setToggleAsideModal(true);
                      }}
                    >
                      {t.workOrders.add_work_orders}
                    </button>
                  ) : null}
                </div>
              </div>
            ) : null}
          </div>
        </div>
      )}

      {/*  WO add modal */}
      <Add
        toggled={toggleAsideModal}
        untoggle={() => {
          setToggleAsideModal(false);
          setModalWidth(Math.max(window.innerWidth / 2, 514));
        }}
        setModalWidth={setModalWidth}
        modalWidth={modalWidth}
        roleData={role}
        allIds={allIds}
        setRefresh={setRefresh}
        refresh={refresh}
        setloader={setloader}
        loader={loader}
        setSearchTitle={setSearchTitle}
        searchTitle={searchTitle}
        filterOptions={filterOptions}
        debounceValue={debounceValue}
        setFilterOptions={setFilterOptions}
        setSelectedDates={setSelectedDates}
        selectedDates={selectedDates}
        setSortData={setSortData}
        sortData={sortData}
      />

      {/*  WO edit modal */}
      <Edit
        toggled={toggleEditModal}
        untoggle={() => {
          setToggleEditModal(false);
          setTargetedData();
          setModalWidth(Math.max(window.innerWidth / 2, 514));
          history.push('/work-orders');
        }}
        setModalWidth={setModalWidth}
        modalWidth={modalWidth}
        roleData={role}
        allIds={allIds}
        setRefresh={setRefresh}
        refresh={refresh}
        targetedData={targetedData}
        setSeriesEdit={setSeriesEdit}
        seriesEdit={seriesEdit}
        setloader={setloader}
        loader={loader}
        setSearchTitle={setSearchTitle}
        searchTitle={searchTitle}
        filterOptions={filterOptions}
        debounceValue={debounceValue}
        setFilterOptions={setFilterOptions}
        setSelectedDates={setSelectedDates}
        selectedDates={selectedDates}
        setSortData={setSortData}
        sortData={sortData}
      />

      {/*  WO view modal */}
      <Preview
        targetedData={targetedData}
        edit={() => {
          setTogglePreviw(false);
          setToggleEditModal(true);
        }}
        setModalWidth={setModalWidth}
        modalWidth={modalWidth}
        toggled={togglePreview}
        untoggle={() => {
          setTargetedData();

          setTogglePreviw(false);
          history.push('/work-orders');
        }}
        negative={isPastDue(
          targetedData?.attributes?.dueDate +
            'T' +
            `${targetedData?.attributes?.dueTime || '08:00:00'}`
        )}
        setRefresh={setRefresh}
        refresh={refresh}
        setSeriesEdit={setSeriesEdit}
        seriesEdit={seriesEdit}
        setloader={setloader}
        loader={loader}
        setSearchTitle={setSearchTitle}
        searchTitle={searchTitle}
        filterOptions={filterOptions}
        debounceValue={debounceValue}
        setFilterOptions={setFilterOptions}
        setSelectedDates={setSelectedDates}
        selectedDates={selectedDates}
        setSortData={setSortData}
        sortData={sortData}
      />
    </div>
  );
};
