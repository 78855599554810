import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { getAllParentLocations, getAllTeams } from '../../app/config/functions';
const initialState = {
  teamsData: [], 
  allTeamsData: [],
  isLoading: false,
  initLoading: false,
  error: null, 
  teamsInitData: [], 
  page: 1,
  pageSize: 25,
  total: 25,
};


/*
 * Fetch teams initial data
 */
export const fetchTeamsInitData = createAsyncThunk(
  'teams/initData',
  async ({organisationId,userRole,currentUserId}, { dispatch, rejectWithValue }) => {
    try {
      const data = await getAllTeams(organisationId, '', 1, 25,[],[] ,[],[],[],userRole,currentUserId);

      if (data.status == 200) {
        const dataObj = {
          response: data.response,
          pagination: data.pagination,
        };

        return dataObj;
       
      } else if (data.error) {
        return rejectWithValue(data.error.message);
      }
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);


/*
 * Fetch all teams data
 */
export const fetchAllTeamsData = createAsyncThunk(
  'teams/allData',
  async ({organisationId,userRole,currentUserId}, { dispatch, rejectWithValue }) => {
    try {
      const data = await getAllTeams(organisationId, '', 1, 99999,[],[] ,[],[],[],userRole,currentUserId);

      if (data.status == 200) {
        const dataObj = {
          response: data.response,
          pagination: data.pagination,
        };

        return dataObj;
       
      } else if (data.error) {
        return rejectWithValue(data.error.message);
      }
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

/*
 * Fetch filter teams data
 */
export const fetchTeamsWithFilter = createAsyncThunk(
  'teams/filter',
  async (
    {
      organisationId,
      searchName,
      page,
      pageSize,
      customer = [],
      superior = [],
      team = [],
      location = [],
      sort = [],
      userRole='',
      currentUserId=[]
    },
    { dispatch, rejectWithValue }
  ) => {

    try {
      const data = await getAllTeams(
        organisationId,
        searchName,
        page,
        pageSize,
        customer,
        superior,
        team,
        location,
        sort,
        userRole,
        currentUserId
      );

      if (data.status == 200) {
        const dataObj = {
          response: data.response,
          pagination: data.pagination,
        };
        return dataObj;
       
      } else if (data.error) {
        return rejectWithValue(data.error.message);
      }
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export const teamsSlice = createSlice({
  name: 'teams',
  initialState,
  reducers: {
    setData: (state, action) => {
      state.teamsData = action.payload;
    },
    setInitData: (state, action) => {
      state.teamsInitData = action.payload;
    },
    setLoading: (state, action) => {
      state.isLoading = action.payload;
    },
    setError: (state, action) => {
      state.error = action.payload;
    },
    setPage: (state, action) => {
      state.page = action.payload;
    },
    setPageSize: (state, action) => {
      state.pageSize = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
     
      .addCase(fetchTeamsInitData.pending, (state) => {
        state.initLoading = true;
        state.error = null;
      })
      .addCase(fetchTeamsInitData.fulfilled, (state, action) => {
        state.initLoading = false;
        state.teamsInitData = action.payload.response;
        state.error = null; 
      })
      .addCase(fetchTeamsInitData.rejected, (state, action) => {
        state.initLoading = false;
        state.error = action.payload;
      })
      .addCase(fetchTeamsWithFilter.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(fetchTeamsWithFilter.fulfilled, (state, action) => {
        state.isLoading = false;
        state.teamsData = action.payload.response;
        state.allTeamsData = action.payload.response;
        state.page = action.payload.pagination.page;
        state.pageSize = action.payload.pagination.pageSize;
        state.total = action.payload.pagination.total;
        state.error = null; 
      
      })
      .addCase(fetchTeamsWithFilter.rejected, (state, action) => {
        state.isLoading = false;
        
      })
  
      .addCase(fetchAllTeamsData.pending, (state) => {
       
      })
      .addCase(fetchAllTeamsData.fulfilled, (state, action) => {
        state.allTeamsData = action.payload.response;
      })
      .addCase(fetchAllTeamsData.rejected, (state, action) => {
        
      });
  },
});

export const {
  setData,
  setLoading,
  setError,
  setInitData,
} = teamsSlice.actions;

export default teamsSlice.reducer;
