import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { getAllParentLocations } from '../../app/config/functions';
const initialState = {
  locationData: [], 
  allLocationData: [],
  isLoading: false,
  initLoading: false,
  error: null, 
  locationInitData: [], 
  pageSize: 25,
  total: 25, 
};

/*
 * Fetch location data
 */
export const fetchLocations = createAsyncThunk(
  'location/list',
  async (organisationId, { dispatch, rejectWithValue }) => {
    try {
      const data = await getAllParentLocations(organisationId);

      if (data.status == 200) {
        const dataObj = {
          response: data.response,
          pagination: data.pagination,
        };

        return dataObj;
       
      } else if (data.error) {
        return rejectWithValue(data.error.message);
      }
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

/*
 * Fetch initial location data
 */
export const fetchLocationsInitData = createAsyncThunk(
  'location/initData',
  async (organisationId, { dispatch, rejectWithValue }) => {
    try {
      const data = await getAllParentLocations(organisationId, '', 1, 25);

      if (data.status == 200) {
        const dataObj = {
          response: data.response,
          pagination: data.pagination,
        };

        return dataObj;
        // dispatch(setData(data.response));
        // dispatch(setInitData(data.response));
      } else if (data.error) {
        return rejectWithValue(data.error.message);
      }
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

/*
 * Fetch all location data
 */
export const fetchAllLoctionData = createAsyncThunk(
  'location/allData',
  async (organisationId, { dispatch, rejectWithValue }) => {
    try {
      const data = await getAllParentLocations(organisationId, '', 1, 99999);

      if (data.status == 200) {
        const dataObj = {
          response: data.response,
          pagination: data.pagination,
        };

        return dataObj;
        // dispatch(setData(data.response));
        // dispatch(setInitData(data.response));
      } else if (data.error) {
        return rejectWithValue(data.error.message);
      }
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

/*
 * Fetch filter location data
 */
export const fetchLocationsWithFilter = createAsyncThunk(
  'location/filter',
  async (
    {
      organisationId,
      searchName,
      page,
      pageSize,
      customer = [],
      country = [],
      city = [],
      sort = [],
    },
    { dispatch, rejectWithValue }
  ) => {
    try {
      const data = await getAllParentLocations(
        organisationId,
        searchName,
        page,
        pageSize,
        customer,
        country,
        city,
        sort
      );

      if (data.status == 200) {
        const dataObj = {
          response: data.response,
          pagination: data.pagination,
        };
        return dataObj;
        // dispatch(setData(data.response));
        // dispatch(setInitData(data.response));
      } else if (data.error) {
        return rejectWithValue(data.error.message);
      }
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export const locationSlice = createSlice({
  name: 'location',
  initialState,
  reducers: {
    setData: (state, action) => {
      state.locationData = action.payload;
    },
    setInitData: (state, action) => {
      state.locationInitData = action.payload;
    },
    setLoading: (state, action) => {
      state.isLoading = action.payload;
    },
    setError: (state, action) => {
      state.error = action.payload;
    },
    setPage: (state, action) => {
      state.page = action.payload;
    },
    setPageSize: (state, action) => {
      state.pageSize = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchLocations.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(fetchLocations.fulfilled, (state, action) => {
        state.isLoading = false;
        state.locationInitData = action.payload.response;
        state.locationData = action.payload.response;
        state.page = action.payload.pagination.page;
        state.pageSize = action.payload.pagination.pageSize;
        state.total = action.payload.pagination.total;
        state.error = null; 
      })
      .addCase(fetchLocations.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload;
      })
      .addCase(fetchLocationsInitData.pending, (state) => {
        state.initLoading = true;
        state.error = null;
      })
      .addCase(fetchLocationsInitData.fulfilled, (state, action) => {
        state.initLoading = false;
        state.locationInitData = action.payload.response;
        state.error = null; 
      })
      .addCase(fetchLocationsInitData.rejected, (state, action) => {
        state.initLoading = false;
        state.error = action.payload;
      })
      .addCase(fetchLocationsWithFilter.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(fetchLocationsWithFilter.fulfilled, (state, action) => {
        state.isLoading = false;
        state.locationData = action.payload.response;
        state.allLocationData = action.payload.response;
        state.page = action.payload.pagination.page;
        state.pageSize = action.payload.pagination.pageSize;
        state.total = action.payload.pagination.total;
        state.error = null; 
        if (state.locationInitData && state.locationInitData.length <= 0) {
          state.locationInitData = action.payload.response;
        }
      })
      .addCase(fetchLocationsWithFilter.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload;
      })
      .addCase(fetchAllLoctionData.pending, (state) => {
      
      })
      .addCase(fetchAllLoctionData.fulfilled, (state, action) => {
        state.allLocationData = action.payload.response;
      })
      .addCase(fetchAllLoctionData.rejected, (state, action) => {
       
      });
  },
});

export const {
  setData,
  setLoading,
  setError,
  setInitData,
} = locationSlice.actions;

export default locationSlice.reducer;
