import React, { useEffect, useState, createRef } from 'react';
import {
  Image,
  Table,
  Button,
  Header,
  Icon,
  Placeholder,
  Loader,
} from 'semantic-ui-react';

import Moment from 'react-moment';

import md5 from 'md5';
import { NavLink } from 'react-router-dom';

import { Country, State, City } from 'country-state-city';

import { useLocation, useHistory } from 'react-router';
import { toAbsoluteUrl, checkIsActive } from '../../../_metronic/_helpers';

import { getAllCustomers, humanize, IMAGES_URL } from '../../config/functions';

import Add from './add';
import Edit from './edit';

import Filters from './filters';
import Preview from './preview';
import { useDispatch, useSelector } from 'react-redux';
import { usePermissionsSimplified } from '../../../hooks/usePermissionsSimplified';
import UserProfilePlaceholder, {
  LetteredAvatarImageUrl,
} from '../../components/common/userProfilePlaceholder';
import { selectTranslations } from '../../config/i18n/slice';
import useDisplayFormattedText from '../../../hooks/useDisplayFormattedText';
import useGetCurrentTimeAccZone from '../../../hooks/useGetCurrentTimeAccZone';
import moment from 'moment';
import DateConvert from '../../../utilFunctions/DateFormat';
import {
  ArrowLeft,
  ArrowRight,
  CloseIcon,
  CustomerIcon,
  ProcedurePlusIcon,
  SortIcon,
  VendorBlueIcon,
} from '../../../Assets/Icons/svg';
import DataTable from 'react-data-table-component';
import Pagination from '../../components/common/CommonPagination';
import ToolTip from 'react-power-tooltip';
import { useDebounce } from 'use-debounce';
import {
  fetchAllCustomerData,
  fetchCustomerInitData,
  fetchCustomerWithFilter,
} from '../../../redux/reducers/customerReducer';
import EmptyFilter from './emptyFilter';
import Animation from '../../components/common/CustomLoader';

const customStyles = {
  // rows: {
  //   style: {
  //     height: '72px',
  //     // override the row height
  //   },
  // },
  headCells: {
    style: {
      paddingTop: '11px', // override the cell padding for head cells
      // paddingBottom: '4px',
    },
  },
  cells: {
    style: {
      paddingLeft: '16px', // override the cell padding for data cells
      paddingRight: '16px',
      paddingTop: '19px',
      paddingBottom: '19px',
    },
  },
};

export const Customers = () => {
  const { userPermissions } = usePermissionsSimplified();
  const t = useSelector(selectTranslations);
  const { DisplayFormattedText } = useDisplayFormattedText();
  const dispatch = useDispatch();
  const [modalWidth, setModalWidth] = useState(
    Math.max(window.innerWidth / 2, 514)
  );

  const organisationId = useSelector((state) => {
    if (state?.auth?.user?.organisation) {
      return state.auth.user.organisation.id;
    } else {
      return null;
    }
  });
  const selectedLanguage = useSelector((state) => {
    if (state?.i18n?.lang) {
      return state.i18n.lang;
    } else {
      return 'en';
    }
  });

  const {
    data,
    allCustomerData,
    isLoading: loading,
    error,
    initData,
    page,
    pageSize,
    total,
    initLoading,
  } = useSelector((state) => ({
    data: state.customer.customerData,
    allCustomerData: state.customer.allCustomerData,
    isLoading: state.customer.isLoading,
    error: state.customer.error,
    initData: state.customer.customerInitData,
    page: state.customer.page,
    pageSize: state.customer.pageSize,
    total: state.customer.total,
    initLoading: state.customer.initLoading,
  }));

  const location = useLocation();
  const history = useHistory();
  const queryParams = new URLSearchParams(location.search);
  const customerId = queryParams.get('id');
  const [currentPage, setCurrentPage] = useState(1);
  // const [pageSize, setPageSize] = useState(25);
  const [toggleAddModal, setToggleAddModal] = useState(false);
  const [showTooltip, setShowTooltip] = useState(false);
  const [toggleEditModal, setToggleEditModal] = useState(false);
  const [togglePreviewModal, setTogglePreviewModal] = useState(false);
  const [fetching, setFetchLoading] = useState(true);
  const [refresh, setRefresh] = useState(true);
  const [loader, setloader] = useState(false);
  const [searchTitle, setSearchTitle] = useState('');
  const [sortData, setSortData] = useState([]);
  const [debounceValue] = useDebounce(searchTitle, 500);

  const [filterOptions, setFilterOptions] = useState({
    countries: [],
    cities: [],
    locations: [],
    createdAt: {
      value: 0,
      text: t.customers.filter.created_at,
    },
    rate: {
      from: null,
      to: null,
    },
    updatedAt: {
      value: 0,
      text: t.customers.filter.updated_at,
    },
    dueDate: {
      value: 'any_day',
      text: 'Any Date',
    },
  });

  const [debounceRate] = useDebounce(filterOptions.rate, 500);

  // const [data, setData] = useState([]);
  const [targetedData, setTargetedData] = useState({});
  // const [initData, setInitData] = useState([]);
  const { currentDate, organisationTimezone } = useGetCurrentTimeAccZone();

  const getMenuItemActive = (url, hasSubmenu = false) => {
    return checkIsActive(location, url);
  };

  const rowStyle = { cursor: 'pointer' };

  useEffect(() => {
    const fetchData = async () => {
      const fetchData = await dispatch(fetchCustomerInitData(organisationId));
      // console.log("fetchData", fetchData);
      if (fetchData) {
        // setData(fetchData);
        // setInitData(fetchData);

        setFetchLoading(false);
        if (customerId) {
          const fetchAllData = await dispatch(
            fetchAllCustomerData(organisationId)
          );

          let selectedCustomer = fetchAllData.payload.response.find(
            (each) => each?.attributes?.countId == customerId
          );
          if (selectedCustomer) {
            setTargetedData(selectedCustomer);
            setTogglePreviewModal(true);
          }
        }
      }
    };

    fetchData();
  }, [customerId]);

  const handleSetDataByFilter = (newData) => {
    // setData(newData);
  };

/* The above code is defining an array of column configurations for a table. Each object in the
`columns` array represents a column in the table with specific properties such as `name`,
`sortField`, `sortable`, `minWidth`, and `cell` function to render the content of the cell. */
  const columns = [
    {
      width: selectedLanguage === 'en' ? '133px' : '150px',
      name: t.assets.table.ID,
      sortField: 'countNumId',
      sortable: true,
      cell: (row) => <span onClick={()=>{
        setTargetedData(row);
        setTogglePreviewModal(true);
      }} className="id-text-style">{row?.attributes?.countId}</span>,
    },
    {
      name: t.customers.table.name,
      sortField: 'name',
      sortable: true,
      minWidth: '180px',
      cell: (row) => {
        if (row?.attributes?.logo?.data) {
          // return <span>{role?.text || humanize(row.role.type)}</span>;
          return (
            <div onClick={()=>{
              setTargetedData(row);
              setTogglePreviewModal(true);
            }} className="d-flex align-items-center">
              <div className="text-center">
                <Image
                  avatar
                  title={row?.attributes?.name}
                  src={`${IMAGES_URL}${row?.attributes?.logo?.data.attributes.url}`}
                  style={
                    selectedLanguage === 'en'
                      ? { width: '34px', height: '34px', right: '6px' }
                      : { width: '34px', height: '34px', right: '-6px' }
                  }

                  // style={
                  //   index != 0
                  //     ? {
                  //         marginLeft: '-6px',
                  //         width: '34px',
                  //         height: '34px',
                  //       }
                  //     : { width: '34px', height: '34px' }
                  // }
                />
              </div>
              <div
                className="username-container ml-1"
                // onClick={() => history.push(`/people?id=${row?.id}`)}
              >
                <p className="user-text">
                  {DisplayFormattedText(humanize(row?.attributes?.name), 8)}
                </p>
              </div>
            </div>
          );
        } else {
          return (
            <div onClick={()=>{
              setTargetedData(row);
              setTogglePreviewModal(true);
            }} className="d-flex align-items-center">
              <div className="text-center">
                <UserProfilePlaceholder
                  name={row?.attributes?.name}
                  width={'34px'}
                  height={'34px'}
                  right={selectedLanguage === 'en' ? '6px' : '-6px'}
                />
              </div>
              <div
                className="username-container ml-1"
                // onClick={() => history.push(`/people?id=${row?.id}`)}
              >
                <p className="user-text">
                  {DisplayFormattedText(humanize(row?.attributes?.name), 8)}
                </p>
              </div>
            </div>
          );
        }
      },
    },

    {
      minWidth: '175px',
      name: t.customers.contact_person,
      sortField: 'contactInformation.name',
      // selector: (row) =>
      //   row?.attributes?.contactInformation?.name
      //     ? row?.attributes?.contactInformation?.name
      //     : '',
      sortable: true,
      cell: (row) =>
        row?.attributes?.contactInformation?.name
          ? <span onClick={()=>{
            setTargetedData(row);
            setTogglePreviewModal(true);
          }}>{DisplayFormattedText(row?.attributes?.contactInformation?.name, 10)} </span>
          : t.common.na,
    },

    {
      minWidth: '160px',
      name: t.customers.table.phone,
      sortField: 'phone',
      // selector: (row) => row?.attributes?.phone,
      sortable: true,
      cell: (row) =>
        row.attributes?.phone
          ? `${selectedLanguage == 'en' ? '+' : ''}${row.attributes?.phone}${
              selectedLanguage == 'ar' ? '+' : ''
            }`
          : t.common.na,
    },

    {
      minWidth: '150px',
      name: t.customers.table.email,
      sortField: 'email',
      // selector: (row) =>
      //   row?.attributes?.email ? row?.attributes?.email?.length : 0,
      sortable: true,
      cell: (row) =>
        row?.attributes?.email
          ? <span onClick={()=>{
            setTargetedData(row);
            setTogglePreviewModal(true);
          }}>{DisplayFormattedText(row?.attributes?.email, 10)}</span>
          : t.common.na,
    },

    // {
    //   name: t.customers.table.website,
    //   selector: (row) => row?.attributes?.website,
    //   sortable: true,
    //   minWidth: '150px',
    //   cell: (row) =>
    //     row?.attributes?.website
    //       ? DisplayFormattedText(row?.attributes?.website, 10)
    //       : t.common.na,
    // },

    {
      name: t.workOrders.table.location,
      minWidth: '150px',
      sortField: 'location.name',
      // selector: (row) =>
      //   row?.attributes?.location?.data
      //     ? row?.attributes?.location?.data?.attributes.name
      //     : '',
      sortable: true,
      cell: (row) => (
        <span onClick={()=>{
          setTargetedData(row);
          setTogglePreviewModal(true);
        }} className="">
          {DisplayFormattedText(
            row?.attributes?.location?.data?.attributes.name,
            12
          ) || t.common.na}
        </span>
      ),
    },

    {
      name: t.locations.table.country,
      sortField: 'billingInformation.country',
      // selector: (row) => row?.attributes?.billingInformation?.country,
      sortable: true,
      minWidth: '156px',
      cell: (row) => {
        const the_city = row?.attributes?.billingInformation?.country
          ? Country.getCountryByCode(
              row?.attributes?.billingInformation?.country
            )?.name
          : t.common.na;

        const formatted_address = DisplayFormattedText(the_city, 10);

        return <span onClick={()=>{
          setTargetedData(row);
          setTogglePreviewModal(true);
        }}>{formatted_address}</span>;
      },
    },

    {
      name: t.locations.table.city,
      // selector: (row) => row?.attributes?.billingInformation?.city,
      sortField: 'billingInformation.city',
      sortable: true,
      minWidth: '156px',
      cell: (row) => {
        const the_city = row?.attributes?.billingInformation?.city
          ? State.getStateByCodeAndCountry(
              row?.attributes?.billingInformation?.city,
              row.attributes?.billingInformation?.country
            )?.name
          : t.common.na;

        const formatted_address = DisplayFormattedText(the_city, 10);

        return <span onClick={()=>{
          setTargetedData(row);
          setTogglePreviewModal(true);
        }}>{formatted_address}</span>;
      },
    },

    {
      name: t.customers.table.service_type,
      sortField: 'serviceType',
      // selector: (row) => row?.attributes?.serviceType,

      sortable: true,
      minWidth: '155px',
      cell: (row) =>
        row?.attributes?.serviceType
          ? DisplayFormattedText(row?.attributes?.serviceType,15)
          : t.common.na,
    },

    {
      name: t.customers.table.hourly_rate,
      sortField: 'billingInformation.rate',
      // selector: (row) => row?.attributes?.billingInformation?.rate,
      sortable: true,
      minWidth: '150px',
      cell: (row) =>
        row.attributes?.billingInformation?.rate
          ? row?.attributes?.billingInformation?.rate +
            ' ' +
            (row.attributes?.billingInformation?.currency
              ? row.attributes?.billingInformation?.currency
              : '')
          : t.common.na,
    },
  ];

  const getPaginatedData = (dataToPaginate) => {
    const startIndex = (currentPage - 1) * pageSize;
    const endIndex = startIndex + pageSize;
    return dataToPaginate.slice(startIndex, endIndex);
  };

/*
 * The handleSort function sorts customer data based on a specified column and sort direction.
 */
  const handleSort = async (column, sortDirection) => {
    setloader(true);

    // Assuming the selector represents the field to sort
    const sort = [`${column.sortField}:${sortDirection}`];
    setSortData(sort);
    const sortedData = await dispatch(
      fetchCustomerWithFilter({
        organisationId,
        searchName: debounceValue,
        page: page,
        pageSize: pageSize,
        location: filterOptions.locations,
        country: filterOptions.countries,
        city: filterOptions.cities,
        rate: filterOptions.rate,
        sort,
      })
    );

    setloader(false);

    // Update state or data variable with sortedData
    // setData(sortedData); // Assuming you have state or variable named 'data'
  };

/*
 * The function `handleRemoveSorting` sets a sorting parameter to undefined, fetches customer data with specified filters, and then disables the loader.
 */
  const handleRemoveSorting = async () => {
    setloader(true);

    // Assuming the select or represents the field to sort
    const sort = undefined;
    setSortData(sort);
    const sortedData = await dispatch(
      fetchCustomerWithFilter({
        organisationId,
        searchName: debounceValue,
        page: page,
        pageSize: pageSize,
        location: filterOptions.locations,
        country: filterOptions.countries,
        city: filterOptions.cities,
        rate: filterOptions.rate,
        sort,
      })
    );

    setloader(false);
  };

  const totalFilteredPages = Math.ceil(data.length / pageSize);

  return (
    <div className="new-listing-container">
      <div className="table-header">
        <div className="header-actions">
          <Header className="procedure-title" as="h2">
            {' '}
            <CustomerIcon />
            {t.aside.customersVendors}
          </Header>
          <div></div>
          {/* <Button.Group basic>
          <NavLink to="/customers">
            <Button
              color={getMenuItemActive('/customers', false) ? 'teal' : ''}
            >
              {t.customers.customers}
            </Button>
          </NavLink>
          <NavLink to="/vendors">
            <Button color={getMenuItemActive('/vendors', false) ? 'teal' : ''}>
              {t.vendors.vendors}
            </Button>
          </NavLink>
        </Button.Group> */}
          {userPermissions?.customer?.create && initData.length > 0 ? (
            <Button
              icon="add"
              primary
              onClick={() => {
                setToggleAddModal(true);
              }}
            >
              {t.customers.add_customer}
              {/* <ProcedurePlusIcon /> */}
            </Button>
          ) : null}
        </div>
      </div>
      {initLoading ? (
        <div className='d-flex justify-content-center align-items-center' style={{minHeight:"500px"}}>
        <Animation/>
        {/* <Placeholder fluid>
          <Placeholder.Line />
          <Placeholder.Line />
          <Placeholder.Line />
          <Placeholder.Line />
          <Placeholder.Line />
        </Placeholder> */}
      </div>
        // <div>
        //   <Placeholder fluid>
        //     <Placeholder.Line />
        //     <Placeholder.Line />
        //     <Placeholder.Line />
        //     <Placeholder.Line />
        //     <Placeholder.Line />
        //   </Placeholder>
        // </div>
      ) : (
        <div className="new-listing-filter">
          {initData.length > 0 ? (
            <>
              <Filters
                debounceRate={debounceRate}
                data={initData}
                setDataByFilter={handleSetDataByFilter}
                hasStatus={false}
                fileData={data}
                setFilterOptions={setFilterOptions}
                filterOptions={filterOptions}
                debounceValue={debounceValue}
                setSearchTitle={setSearchTitle}
                searchTitle={searchTitle}
                setloader={setloader}
                loader={loader}
                sortData={sortData}
              />
              {error ? (
                <div className="mt-8 mb-8 ">
                <Animation/>
                </div>
              ) : (
                <DataTable
                  className="datatable-div"
                  columns={columns}
                  fixedHeader
                  data={data}
                  onSort={handleSort}
                  customStyles={customStyles}
                  noDataComponent={
                    initData.length > 0 ? (
                      <div className="no-data-container">
                        <div className="">
                          <p className="no-data-text">
                            {t.common.no_result_found}
                          </p>
                        </div>
                      </div>
                    ) : null
                  }
                  sortIcon={
                    sortData ? (
                      <div>
                        <span>
                          <SortIcon />
                        </span>
                        <span
                          onClick={(e) => {
                            e.stopPropagation();
                            handleRemoveSorting();
                          }}
                        >
                          <CloseIcon />
                        </span>
                      </div>
                    ) : (
                      <span></span>
                    )
                  }
                  direction={selectedLanguage === 'en' ? 'ltr' : 'rtl'}
                  onRowClicked={(row) => {
                    setTargetedData(row);
                    setTogglePreviewModal(true);
                  }}
                  persistTableHead={true}
                  pointerOnHover={true}
                  highlightOnHover={true}
                  progressPending={loader}
                  progressComponent={
                    <div className="mt-8 mb-8 ">
                     { t.common.loading}
                    </div>
                  }
                />
              )}

              <div className="d-flex justify-content-end">
                <Pagination
                  ofString={t.common.of}
                  total={total}
                  prevItem={
                    selectedLanguage === 'en' ? <ArrowLeft /> : <ArrowRight />
                  }
                  nextItem={
                    selectedLanguage === 'en' ? <ArrowRight /> : <ArrowLeft />
                  }
                  page={page}
                  limit={pageSize}
                  handleNext={async () => {
                    setloader(true);
                    await dispatch(
                      fetchCustomerWithFilter({
                        organisationId,
                        searchName: '',
                        page: page + 1,
                        pageSize: pageSize,
                        location: filterOptions.locations,
                        country: filterOptions.countries,
                        city: filterOptions.cities,
                        rate: filterOptions.rate,
                        sort: sortData,
                      })
                    );
                    setloader(false);
                  }}
                  handlePrevious={async () => {
                    setloader(true);
                    await dispatch(
                      fetchCustomerWithFilter({
                        organisationId,
                        searchName: '',
                        page: page - 1,
                        pageSize: pageSize,
                        location: filterOptions.locations,
                        country: filterOptions.countries,
                        city: filterOptions.cities,
                        rate: filterOptions.rate,
                        sort: sortData,
                      })
                    );
                    setloader(false);
                  }}
                />
              </div>
            </>
          ) : (
            <div className="">
              <EmptyFilter />
              <div className="no-data-container">
                <div className="no-work-orders pb-15">
                  <VendorBlueIcon />
                  <p className="no-data-text">
                    {t.common.there_no}{' '}
                    <span
                      className="link-text"
                      style={{ position: 'relative', cursor: 'pointer' }}
                      onMouseOver={() => setShowTooltip(true)}
                      onMouseLeave={() => setShowTooltip(false)}
                    >
                      <ToolTip
                        color="#fff"
                        backgroundColor="#172B4D"
                        position="top left"
                        show={showTooltip}
                        textBoxWidth={
                          selectedLanguage == 'ar' ? '72px' : '48px'
                        }
                        arrowAlign="start"
                        fontSize="14px"
                        fontWeight="400"
                        fontFamily={
                          selectedLanguage == 'ar'
                            ? 'Noto Kufi Arabic'
                            : 'Roboto'
                        }
                        padding="0px 0px 0px 8px"
                        borderRadius="5px"
                        static
                        moveRight={selectedLanguage == 'ar' ? '70px' : '105px'}
                        moveUp="-1px"
                      >
                        <span className="work-order-tooltip">
                          {t.customers.how_to_add_customer}
                        </span>
                      </ToolTip>
                      {' ' + t.customers.title_small + ' '}
                    </span>{' '}
                    {t.common.added}
                  </p>
                  {userPermissions?.customer?.create ? (
                    <button
                      className="work-order-button-transparent"
                      onClick={() => {
                        setToggleAddModal(true);
                      }}
                    >
                      {t.customers.no_customer_added}
                    </button>
                  ) : null}
                </div>
              </div>
            </div>
          )}
        </div>
      )}

      <Preview
        toggled={togglePreviewModal}
        setModalWidth={setModalWidth}
        modalWidth={modalWidth}
        untoggle={() => {
          setTogglePreviewModal(false);
          setTargetedData();
          setModalWidth(Math.max(window.innerWidth / 2, 514));
          history.push('/customers');
        }}
        edit={() => {
          setTogglePreviewModal(false);
          setToggleEditModal(true);
        }}
        targetedData={targetedData}
        refresh={refresh}
        setRefresh={setRefresh}
        setFilterOptions={setFilterOptions}
        setSearchTitle={setSearchTitle}
      />

      <Edit
        targetedData={targetedData}
        toggled={toggleEditModal}
        untoggle={() => {
          setToggleEditModal(false);
          setModalWidth(Math.max(window.innerWidth / 2, 514));
        }}
        refresh={refresh}
        setRefresh={setRefresh}
        setFilterOptions={setFilterOptions}
        setSearchTitle={setSearchTitle}
      />

      <Add
        toggled={toggleAddModal}
        untoggle={() => {
          setToggleAddModal(false);
          setModalWidth(Math.max(window.innerWidth / 2, 514));
        }}
        refresh={refresh}
        setRefresh={setRefresh}
        setFilterOptions={setFilterOptions}
        setSearchTitle={setSearchTitle}
      />
    </div>
  );
};
