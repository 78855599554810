import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import {  getAllSets } from '../../app/config/functions';
const initialState = {
  data: [], // Initial empty array for category data
  isLoading: false,
  initLoading: false,
  error: null, //
  initData: [], //
  page: 1, //
  pageSize: 25, //
  total: 25, //
};

/*
 * Fetch categoty data
 */
export const fetchSets = createAsyncThunk(
  'sets/list',
  async ({organisationId,role,allIds}, { dispatch, rejectWithValue }) => {
    try {
      const data = await getAllSets(organisationId);

      if (data.status == 200) {
        const dataObj = {
          response: data.response,
          pagination: data.pagination,
        };

        return dataObj;
      } else if (data.error) {
        return rejectWithValue(data.error.message);
      }
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

/*
 * Fetch initial categoty data
 */
export const fetchSetsInitData = createAsyncThunk(
  'sets/initData',
  async ({organisationId,role,allIds}, { dispatch, rejectWithValue }) => {
    console.log('role: ', role);
    try {
      const data = await getAllSets(organisationId, '', 1, 25,'','',role,allIds);

      if (data.status == 200) {
        const dataObj = {
          response: data.response,
          pagination: data.pagination,
        };

        return dataObj;
      } else if (data.error) {
        return rejectWithValue(data.error.message);
      }
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

/*
 * Fetch all categoty data
 */
export const fetchAllSetsData = createAsyncThunk(
  'sets/allData',
  async ({organisationId,role,allIds}, { dispatch, rejectWithValue }) => {
    console.log('role: ', role);
    try {
      const data = await getAllSets(organisationId, '', 1, 99999,'','',role,allIds);

      if (data.status == 200) {
        const dataObj = {
          response: data.response,
          pagination: data.pagination,
        };

        return dataObj;
      } else if (data.error) {
        return rejectWithValue(data.error.message);
      }
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

/*
 * Fetch filter categoty data
 */
export const fetchSetWithFilter = createAsyncThunk(
  'sets/filter',
  async (
    { organisationId, searchName, page, pageSize, sort,        role = '',
      allIds = []},
    { dispatch, rejectWithValue }
  ) => {
    try {
      const data = await getAllSets(
        organisationId,
        searchName,
        page ,
        pageSize,
        sort,
        '',
        '',
        role,
        allIds
      );

      if (data.status == 200) {
        const dataObj = {
          response: data.response,
          pagination: data.pagination,
        };
        return dataObj;
      } else if (data.error) {
        return rejectWithValue(data.error.message);
      }
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export const setsSlice = createSlice({
  name: 'sets',
  initialState,
  reducers: {
    setData: (state, action) => {
      state.data = action.payload;
    },
    setInitData: (state, action) => {
      state.initData = action.payload;
    },
    setLoading: (state, action) => {
      state.isLoading = action.payload;
    },
    setError: (state, action) => {
      state.error = action.payload;
    },
    setPage: (state, action) => {
      state.page = action.payload;
    },
    setPageSize: (state, action) => {
      state.pageSize = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchSets.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(fetchSets.fulfilled, (state, action) => {
        state.isLoading = false;
        state.initData = action.payload.response;
        state.data = action.payload.response;
        state.page = action.payload.pagination.page;
        state.pageSize = action.payload.pagination.pageSize;
        state.total = action.payload.pagination.total;
        state.error = null;
      })
      .addCase(fetchSets.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload;
      })
      .addCase(fetchSetsInitData.pending, (state) => {
        state.initLoading = true;
        state.error = null;
      })
      .addCase(fetchSetsInitData.fulfilled, (state, action) => {
        state.initLoading = false;
        state.initData = action.payload.response;
        state.error = null;
      })
      .addCase(fetchSetsInitData.rejected, (state, action) => {
        state.initLoading = false;
        state.error = action.payload;
      })
      .addCase(fetchSetWithFilter.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(fetchSetWithFilter.fulfilled, (state, action) => {
        state.isLoading = false;
        state.data = action.payload.response;
        state.page = action.payload.pagination.page;
        state.pageSize = action.payload.pagination.pageSize;
        state.total = action.payload.pagination.total;
        state.error = null;
        if (state.initData && state.initData.length <= 0) {
          state.initData = action.payload.response;
        }
      })
      .addCase(fetchSetWithFilter.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload;
      })
      .addCase(fetchAllSetsData.pending, (state) => {})
      .addCase(fetchAllSetsData.fulfilled, (state, action) => {})
      .addCase(fetchAllSetsData.rejected, (state, action) => {});
  },
});

export const {
  setData,
  setLoading,
  setError,
  setInitData,
} = setsSlice.actions;


export default setsSlice.reducer;
