export default {
  title: "لوحة التحكم",
  tabs: {
    WO_management: "إدارة أوامر العمل",
    assets_management: "إدارة الممتلكات",
    inventory_management: "Inventory Management",
    recource_planner: "Recource Planner",
    timesheet: "Time sheet",
    cost: "Cost",
  },

  box: {
    unassign: "أوامر العمل الغير مكلفة ",
    overDue: "أوامر العمل المتأخرة عن الوقت المحدد",
    ontime: "أوامر العمل المنجزة في الوقت المحدد",
    increase: "ارتفاع عن الشهر الماضي",
    decrease: "اتخفاض عن الشهر الماضي",
  },

  cards: {
    status: "حالة أمر العمل",
    assignee: "أمر العمل حسب الشخص المكلف",
    total: "الرقم الإجمالي",
    response: "متوسط ​​وقت الاستجابة لامر العمل حسب الأولوية",
    response_time_label: "متوسط ​​وقت الاستجابة (دقيقة)",
    date: "التاريخ",
    technician_res: "متوسط وقت الاستجابة لامر العمل حسب الفني",
    technician_name: "اسم الفني",
    no_of_WO: "عدد أوامر العمل",
    avg_time: "متوسط ​​الوقت (دقيقة)",
    high: "عالية",
    low: "منخفضة",
    med: "متوسطة",
    resolution: "متوسط ​​وقت حل امر العمل حسب الأولوية",
    resolution_time_label: "متوسط ​​وقت الحل (دقيقة)",
    resolution_technician: "متوسط وقت حل امر العمل حسب الفني",
    cost: "التكلفة المتوقعة مقابل التكلفة الفعلية",
    avg_cost: "متوسط ​​تقدير التكلفة",
    avg_actual: "متوسط الكلفة الفعلية",
    days: "الأيام",
    cost_currency: "التكلفة (العملة)",
    time: "Time Estimate Vs. Actual",
    avg_time_data: "Avg. Estimate Time",
    avg_actual_time: "Avg. Actual Time",
    top10Wo: "أفضل 10 أوامر عمل",
    category: "الفئة",
    received: "مستلم",
    comp: "مكتمل",
    overdueWO: "متأخر",
    utilisation_rate: "معدل الاستغلال الزمني",
    avail_hr: "ساعات العمل المتاحة",
    incurred_hr: "ساعات العمل المستغلة",
    actual_hr: "الساعات الفعلية",
    uti: "الاستغلال",
    other_filters: "Other Filters",
    refresh:"Refresh",
  },
  filter: {
    period: "الفترة",
    time_period: "Time period",
    today: "Today",
    yesterday: "Yesterday",
    week: "Last week",
    month: "Last month",
    month_6: "Last 6 month",
    year: "Last year",
    date: "Select date",
    last_7: "Last 7 days",
    last_14: "Last 14 days",
    last_28: "Last 28 days",
    last_30: "Last 30 days",
    last_90: "Last 90 days",
    last_180: "Last 180 days",
    last_365: "Last 365 days",
    year_to_date: "Year to date",
    this_week: "This week",
    this_month: "This month",
    this_quarter: "This quarter",
    this_year: "This year",
    previous_week: "Previous Week",
    previous_month: "Previous Month",
    previous_quarter: "Previous Quarter",
    previous_year: "Previous Year",
  },

  dateOptions: {
    day: "Day",
    week: "Week",
    month: "Month",
    presets:"Presets",
    custom:"Custom"
  },

  percantage: "Percentage",
  number: "Number",
  expand_full: "Expand to full screen",
  add_customized: "Add to customized dashboard",
  tooltip: {
    unAssgin:
      "نسبة أوامرالعمل التي تم إصدارها ولم يتم تحديد المكلف بها. ",
    overDue:
      "نسبة أوامر العمل المتأخرة / التي تخطت موعد التسليم ولم تنتهي بعد. ",
    onTime:
      " نسبة أوامر العمل التي تم تسليمها ضمن الإطار الزمني المحدد.",
    WOstatus:
      "العدد والنسبة لأوامر العمل: المنجزة: أوامر العمل المنجزة، والمغلقة. قيد المراجعة: أوامر العمل المنجزة لكنها بانتظار موافقة المشرف. قيد التنفيذ: أوامر العمل التي ما زالت قيد التنفيذ قيد الانتظار: أوامر العمل المعلقة لأسباب معينة. المتاحة: أوامر العمل الجديدة التي لم يتم البدء بتنفيذها.",
    assignee: "عدد ونسبة أوامر العمل التي تم تعيينها لكل عامل.",
    response_prioprity:
      "متوسط الوقت المستغرق لبدء العمل على أمر العمل من لحظة استلامه مصنف حسب مستوى الأولوية.",
    resolution_prioprity:
      "الوقت الإجمالي المستغرق من إنشاء أمر العمل حتى الانتهاء منه، مصنف حسب مستوى الأولوية.",
    resolution_technician:
      "الوقت الإجمالي المستغرق من إنشاء أمر العمل حتى الانتهاء منه، مصنف حسب الفني المعين.",
    response_technician:
      "متوسط الوقت المستغرق لبدء العمل على أمر العمل من لحظة استلامه، مصنف حسب الفني المعين.",
    cost:
      "مقارنة بين التكاليف المقدرة لأوامر العمل مقابل التكاليف الفعلية اللي تم دفعها أثناء حلها.",
    time:
      "مقارنة عدد الساعات المقدّرة لإنجاز أوامر العمل مقابل عدد الساعات الفعلية المقضية في إنجازها.",
    top10: "أنواع وأعداد أوامر العمل المستلمة، المنجزة، والمتأخرة.",
    utilisation:
      "نسبة ساعات العمل الإجمالية للموظف التي يتم قضاءها بشكل منتج / بفاعلية على أوامر العمل.",
  },
};
