import React, { useEffect, useState, createRef } from 'react';
import {
  Checkbox,
  Table,
  Button,
  Header,
  Icon,
  Placeholder,
  Label,
  Dropdown,
  Grid,
  Menu,
  Image,
  Loader,
} from 'semantic-ui-react';

import Moment from 'react-moment';
import { useHistory } from 'react-router';

import { NavLink } from 'react-router-dom';

import { useLocation } from 'react-router';
import { toAbsoluteUrl, checkIsActive } from '../../../_metronic/_helpers';

import {
  IMAGES_URL,
  getNewWorkRequests,
  humanize,
  updateWorkRequestStatus,
} from '../../config/functions';

import AddRequest from './add';
import Preview from './preview/index';
import Filters from './filters';

import AddOrder from '../WorkOrders/add';
import { usePermissionsSimplified } from '../../../hooks/usePermissionsSimplified';
import { useDispatch, useSelector } from 'react-redux';
import useDisplayFormattedText from '../../../hooks/useDisplayFormattedText';
import { selectTranslations } from '../../config/i18n/slice';
import {
  fetchAllWRData,
  fetchWRInitData,
  fetchWRWithFilter,
} from '../../../redux/reducers/workRequestReducer';
import {
  AssetProfilePlaceholder,
  LetteredAvatarImageUrl,
} from '../../components/common/userProfilePlaceholder';
import {
  ArrowLeft,
  ArrowRight,
  CloseIcon,
  FlagIcon,
  FlagWhiteIcon,
  ProcedurePlusIcon,
  SortIcon,
  WRBlueIcon,
  WRIcon,
} from '../../../Assets/Icons/svg';
import DataTable from 'react-data-table-component';
import Pagination from '../../components/common/CommonPagination';
import ToolTip from 'react-power-tooltip';
import { useDebounce } from 'use-debounce';
import Edit from './edit';
import Animation from '../../components/common/CustomLoader';

const customStyles = {
  rows: {
    style: {
      height: '72px',
    },
  },
  headCells: {
    style: {
      paddingTop: '11px',
      // paddingBottom: '4px',
    },
  },
  cells: {
    style: {
      paddingLeft: '16px',
      paddingRight: '16px',
      paddingTop: '19px',
      paddingBottom: '19px',
    },
  },
};

/*
 * The `WorkRequests` component handles the display and management of WorkRequest data, including filtering, sorting, pagination, and modals for adding, editing, and previewing assets.
 */
export const WorkRequests = () => {
  const { userPermissions } = usePermissionsSimplified();
  const { DisplayFormattedText } = useDisplayFormattedText();
  const dispatch = useDispatch();
  const [toggleAsideModal, setToggleAsideModal] = useState(false);
  const [toggleAsideOrder, setToggleAsideOrder] = useState(false);
  const [fetching, setFetchLoading] = useState(true);
  const t = useSelector(selectTranslations);
  // const [data, setData] = useState([]);
  // const [initData, setInitData] = useState([]);
  const [sortData, setSortData] = useState();
  const [loader, setloader] = useState(false);
  const [buttonsLoading, setButtonsLoading] = useState({
    approved: false,
    declined: false,
  });
  const [modalWidth, setModalWidth] = useState(
    Math.max(window.innerWidth / 2, 514)
  );
  const [searchTitle, setSearchTitle] = useState('');
  const [debounceValue] = useDebounce(searchTitle, 500);
  const [showATooltip, setShowATooltip] = useState({});
  const [showTooltip, setShowTooltip] = useState(false);
  const [selectedDates, setSelectedDates] = useState([]);
  const [targetedRequest, setTargetedRequest] = useState();
  const [togglePreview, setTogglePreviw] = useState(false);
  const [toggleEditModal, setToggleEditModal] = useState(false);

  const currentUserLocations = useSelector((state) => {
    if (state?.auth?.user?.id) {
      return state.auth.user.locations;
    } else {
      return null;
    }
  });







  const [filterOptions, setFilterOptions] = useState({
    requestedBy: [],
    locations: [],
    assets: [],
    priority: [],
    status: [],
    dueDate: {
      value: 'any_day',
      text: 'Any Date',
    },
    priority: [],
    status: [],
  });

  const organisationId = useSelector((state) => {
    if (state?.auth?.user?.organisation) {
      return state.auth.user.organisation.id;
    } else {
      return null;
    }
  });

  const handleMouseEnter = (index) => {
    setShowATooltip({
      ...showTooltip,
      [index]: true,
    });
  };

  const handleMouseLeave = (index) => {
    setShowATooltip({
      ...showTooltip,
      [index]: false,
    });
  };

  const selectedLanguage = useSelector((state) => {
    if (state?.i18n?.lang) {
      return state.i18n.lang;
    } else {
      return 'en';
    }
  });

  const {
    data,
    isLoading: loading,
    error,
    initData,
    page,
    pageSize,
    total,
    initLoading,
  } = useSelector((state) => ({
    data: state.workRequest.WRData,
    isLoading: state.workRequest.isLoading,
    error: state.workRequest.error,
    initData: state.workRequest.WRInitData,
    page: state.workRequest.page,
    pageSize: state.workRequest.pageSize,
    total: state.workRequest.total,
    initLoading: state.workRequest.initLoading,
  }));

  const role = useSelector((state) => {
    if (state?.auth?.user?.role?.id) {
      return state?.auth?.user?.role?.type;
    } else {
      return null;
    }
  });

  const currentUserId = useSelector((state) => {
    if (state?.auth?.user?.id) {
      return state.auth.user.id;
    } else {
      return null;
    }
  });

  const curentUrl = useLocation();
  const queryParams = new URLSearchParams(curentUrl.search);
  const WRid = queryParams.get('id');
  const history = useHistory();

  const priorityOptions = [
    {
      value: 'none',
      label: t.workOrders.priority_status.none,
      color: 'darkgrey',
    },
    {
      value: 'low',
      label: t.workOrders.priority_status.low,

      color: 'yellow',
    },
    {
      value: 'medium',
      label: t.workOrders.priority_status.medium,

      color: 'orange',
    },
    {
      value: 'high',
      label: t.workOrders.priority_status.high,
      color: 'red',
    },
  ];

  const statusOptions = [
    {
      value: 'open',
      color: 'blue',
      label: t.workOrders.work_order_status.open,
    },
    {
      value: 'inProgress',
      color: 'yellow',
      label: t.workOrders.work_order_status.inProgress,
    },
    {
      value: 'onHold',
      color: 'red',
      label: t.workOrders.work_order_status.onHold,
    },
    {
      value: 'inReview',
      color: 'black',
      label: t.workOrders.work_order_status.inReview,
    },
    {
      value: 'completed',
      color: 'green',
      label: t.workOrders.work_order_status.completed,
    },
    {
      value: 'cancelled',
      color: 'darkgrey',
      label: t.workOrders.work_order_status.cancel,
    },
  ];

  const WRstatusOptions = [
    {
      value: 'approved',
      color: 'green',
      label: t.workRequests.status.approved,
    },
    {
      value: 'pending',
      color: 'orange',
      label: t.workRequests.status.pending,
    },
    {
      value: 'declined',
      color: 'red',
      label: t.workRequests.status.declined,
    },
  ];
  const request = useLocation();

  useEffect(() => {
    const fetchData = async () => {
      const fetchData = await dispatch(
        fetchWRInitData({ organisationId, role, currentUserId })
      );

      if (fetchData) {
        if (WRid) {
          let fetchAllData = await dispatch(
            fetchAllWRData({ organisationId, role, currentUserId })
          );
          let selectedWR = fetchAllData.payload.response.find(
            (each) => each?.attributes?.countId == WRid
          );
          if (selectedWR) {
            setTargetedRequest(selectedWR);
            setTogglePreviw(true);
          }
        }
      }
    };

    fetchData();
  }, [WRid]);

  /* The function `handleSort` sorts data based on a specified column and sort direction */
  const handleSort = async (column, sortDirection) => {
    setloader(true);
    const sort = [`${column.sortField}:${sortDirection}`];
    setSortData(sort);
    const sortedData = await dispatch(
      fetchWRWithFilter({
        organisationId,
        searchName: debounceValue,
        page: page,
        pageSize: pageSize,
        role,
        currentUserId,
        people: filterOptions.requestedBy,
        status: filterOptions.status,
        priority: filterOptions.priority,
        location: filterOptions.locations,
        assets: filterOptions.assets,
        submissionDate: selectedDates,
        sort: sort,
      })
    );

    setloader(false);
  };

  /* The function `handleRemoveSorting` for remove sorts data */
  const handleRemoveSorting = async () => {
    setloader(true);

    // Assuming the select or represents the field to sort
    const sort = undefined;
    setSortData(sort);
    const sortedData = await dispatch(
      fetchWRWithFilter({
        organisationId,
        searchName: debounceValue,
        page: page,
        pageSize: pageSize,
        role,
        currentUserId,
        people: filterOptions.requestedBy,
        status: filterOptions.status,
        priority: filterOptions.priority,
        location: filterOptions.locations,
        assets: filterOptions.assets,
        submissionDate: selectedDates,
        sort: sort,
      })
    );

    setloader(false);
  };

  const columns = [
    {
      
      name: t.workOrders.table.ID,
      sortField: 'countNumId',
      width: selectedLanguage === 'en' ? '113px' : '107px',
      sortable: true,
      cell: (row) => (
        <span
          onClick={() => {
            setTargetedRequest(row);
            setTogglePreviw(true);
          }}
          className="id-text-style"
        >
          {row?.attributes?.countId}
        </span>
      ),
    },
    {
      name: t.workOrders.table.title,
      // selector: (row) => row?.attributes?.title,
      sortField: 'title',
      sortable: true,
      minWidth: '250px',
      cell: (row) => (
        <span
          onClick={() => {
            setTargetedRequest(row);
            setTogglePreviw(true);
          }}
          className="id-text-style"
        >
          {DisplayFormattedText(row?.attributes?.title, 30)}
        </span>
      ),
    },
    {
      minWidth: selectedLanguage === 'en' ? '134px' : '170px',
      sortField: 'status',
      name: t.workRequests.table.wr_status,
      // selector: (row) => row?.attributes?.status,
      sortable: true,
      cell: (row) => {
        const workOrderStatus = WRstatusOptions.find(
          (status) => status.value === row.attributes.status
        );

        return (
          <div
            onClick={() => {
              setTargetedRequest(row);
              setTogglePreviw(true);
            }}
            className="d-flex align-items-center"
          >
            <Label
              circular
              empty
              color={workOrderStatus?.color || '#788ca5'}
              style={{ width: '12px', height: '12px' }}
            />
            <div>
              <span className="ml-2 mr-2">
                {workOrderStatus?.label || t.common.na}
              </span>
            </div>
          </div>
        );
      },
    },
    {
      minWidth: selectedLanguage === 'en' ? '150px' : '170px',
      sortField: 'workOrder.status',
      name: t.workRequests.table.wo_status,
      // selector: (row) => row?.attributes?.status,
      sortable: true,
      cell: (row) => {
        const workOrderStatus = statusOptions.find(
          (status) =>
            status.value ===
            row?.attributes?.workOrder?.data?.attributes?.status
        );

        return (
          <>
            {workOrderStatus?.label ? (
              <div
                onClick={() => {
                  setTargetedRequest(row);
                  setTogglePreviw(true);
                }}
                className="d-flex align-items-center"
              >
                <Label
                  circular
                  empty
                  color={workOrderStatus?.color || '#788ca5'}
                  style={{ width: '12px', height: '12px' }}
                />
                <div>
                  <span className="ml-2 mr-2">
                    {workOrderStatus?.label || t.common.na}
                  </span>
                </div>
              </div>
            ) : null}
          </>
        );
      },
    },
    {
      name: t.workOrders.table.priority,
      // selector: (row) => row?.attributes?.priority,
      sortField: 'priority',
      sortable: true,
      minWidth: '122px',
      cell: (row) => {
        const PriorityStatus = priorityOptions.find(
          (status) => status.value === row.attributes.priority
        );

        return (
          <>
            <span
              onClick={() => {
                setTargetedRequest(row);
                setTogglePreviw(true);
              }}
              className={`pill ${row.attributes.priority}`}
            >
              <FlagWhiteIcon /> {PriorityStatus?.label || t.common.na}
            </span>
          </>
        );
      },
    },
    {
      sortField: 'location.name',
      name: t.workOrders.table.location,
      minWidth: '200px',
      // selector: (row) =>
      //   row?.attributes?.location?.data
      //     ? row?.attributes?.location?.data?.attributes.name
      //     : t.common.na,
      sortable: true,
      cell: (row) => (
        <span
          onClick={() => {
            setTargetedRequest(row);
            setTogglePreviw(true);
          }}
        >
          {DisplayFormattedText(
            row?.attributes?.location?.data?.attributes?.name,
            20
          ) || t.common.na}
        </span>
      ),
    },

    {
      name: t.workRequests.table.asset,
      sortField: 'assets.name',
      minWidth: '150px',

      sortable: true,
      cell: (row) => {
        const locationData = row?.attributes?.assets?.data;
        if (locationData?.length > 0) {
          const firstLocation = locationData[0]?.attributes?.name;
          const remainingCount = locationData.length - 1;
          const remainingLocations = locationData.slice(1);
          const displayText =
            remainingCount > 0 ? <>{firstLocation} </> : firstLocation;
          return (
            <>
              <span
                onClick={() => {
                  setTargetedRequest(row);
                  setTogglePreviw(true);
                }}
                className="id-text-style"
              >
                {displayText}
                <span
                  className="id-text-style ml-2 mr-2"
                  style={{
                    width: 'fitContent',
                    color: '#788CA5',
                    position: 'relative',
                  }}
                  onMouseEnter={() =>
                    setShowATooltip({ ...showATooltip, [row.id]: true })
                  }
                  onMouseLeave={() =>
                    setShowATooltip({ ...showATooltip, [row.id]: false })
                  }
                >
                  {remainingCount == 0 ? '' : remainingCount}

                  {showATooltip[row?.id] &&
                    (remainingCount > 0 ? (
                      <div className="custom-tooltip custom-tooltip-assets">
                        <div className="wrapper-assets-tooltip">
                          {remainingLocations.map((location) => (
                            <p className="mb-1">{location.attributes.name}</p>
                          ))}
                        </div>
                      </div>
                    ) : null)}
                </span>
              </span>
            </>
          );
        } else {
          return t.common.na;
        }
      },
    },
    {
      name: t.workRequests.table.wo_id,
      sortField: 'workOrder.countNumId',
      width: selectedLanguage === 'en' ? '110px' : '162px',
      sortable: true,
      cell: (row) => {
        // Check if the role is 'requester'
        if (role !== 'requester') {
          return (
            <span
              onClick={() =>
                history.push(
                  `/work-orders?id=${row?.attributes?.workOrder?.data?.attributes?.countId}`
                )
              }
              className="id-text-style work-request"
            >
              {row?.attributes?.workOrder?.data?.id
                ? `#${row?.attributes?.workOrder?.data?.attributes?.countId}`
                : null}
            </span>
          );
        } else {
          return (
            <span className="id-text-style">
              {row?.attributes?.workOrder?.data?.attributes?.countId}
            </span>
          ); // Return null if the role is not 'requester'
        }
      },
    },

    {
      sortField: 'createdAt',
      // width: '161px',
      name: t.workRequests.table.sub_date,
      minWidth: '182px',
      // selector: (row) => row?.attributes?.dueDate,
      sortable: true,
      cell: (row) => (
        <div
          onClick={() => {
            setTargetedRequest(row);
            setTogglePreviw(true);
          }}
        >
          <div>
            <Moment format="DD-MM-YYYY" date={row?.attributes?.createdAt} />
          </div>
          <div className="time-text-style text-left">
            <Moment format="h a" date={row?.attributes?.createdAt} />
          </div>
        </div>
      ),
    },

    {
      name: t.workRequests.table.sub_by,
      minWidth: '193px',
      sortField: 'requestedBy.firstName',
      sortable: true,
      cell: (row) => {
        let createdByUser = row?.attributes?.submittedBy?.data ? (
          <div>
            <Image
              avatar
              title={
                row.attributes.submittedBy?.data?.attributes?.firstName +
                ' ' +
                row.attributes.submittedBy?.data?.attributes?.lastName
              }
              src={
                row?.attributes?.submittedBy?.data?.attributes?.profileImage
                  ?.data?.attributes?.url
                  ? row?.attributes?.submittedBy?.data?.attributes?.profileImage
                      ?.data?.attributes?.url
                  : LetteredAvatarImageUrl(
                      row?.attributes?.submittedBy?.data?.attributes.firstName +
                        ' ' +
                        row?.attributes?.submittedBy?.data?.attributes?.lastName
                    )
              }
              style={
                selectedLanguage == 'en'
                  ? { width: '34px', height: '34px', right: '5px' }
                  : { width: '34px', height: '34px', left: '5px' }
              }
            />
            <span className="id-text-style">
              {row.attributes.submittedBy.data.attributes.firstName}
            </span>
          </div>
        ) : (
          t.common.na
        );
        return (
          <span
            onClick={() => {
              setTargetedRequest(row);
              setTogglePreviw(true);
            }}
            className="ml-1 mr-1"
          >
            {createdByUser}
          </span>
        );
      },
    },
  ];

  // useEffect(() => {
  //   const fetchData = async () => {
  //     const fetchData = await getNewWorkRequests();
  //     // console.log(fetchData);
  //     if (fetchData) {
  //       // setData(fetchData);
  //       // setInitData(fetchData);
  //       setFetchLoading(false);
  //     }
  //   };

  //   fetchData();
  // }, []);

  const handleSetDataByFilter = (newData) => {
    // setData(newData);
  };

  const approveOrder = async () => {
    setButtonsLoading({
      ...buttonsLoading,
      approved: true,
    });

    setTimeout(() => {
      setButtonsLoading({
        ...buttonsLoading,
        approved: false,
      });
      setTogglePreviw(false);
      setTargetedRequest(targetedRequest);
      setToggleAsideOrder(true);
    }, 1000);
  };

  const declineOrder = async (declinedReason) => {
    const declineOrderResponse = await updateWorkRequestStatus(
      targetedRequest.id,
      'declined',
      '',
      declinedReason
    );

    // console.log(declineOrderResponse);
    setButtonsLoading({
      ...buttonsLoading,
      declined: true,
    });
    if (declineOrderResponse.status == 200)
      setTimeout(() => {
        setButtonsLoading({
          ...buttonsLoading,
          declined: false,
        });
      }, 1000);
  };

  return (
    <div className="new-listing-container">
      <div className="table-header">
        <div className="header-actions">
          <Header className="procedure-title" as="h2">
            {' '}
            <WRIcon />
            {t.workRequests.title}
          </Header>
          <div></div>

          {userPermissions?.['work-request']?.create && initData.length > 0 ? (
            <Button
              icon="add"
              primary
              onClick={() => {
                setToggleAsideModal(true);
              }}
            >
              {t.workRequests.create_WR}
              {/* <ProcedurePlusIcon /> */}
            </Button>
          ) : null}
        </div>
      </div>

      {initLoading ? (
        <div
          className="d-flex justify-content-center align-items-center"
          style={{ minHeight: '500px' }}
        >
          <Animation />
        </div>
      ) : (
        <div className="new-listing-filter">
          {initData.length > 0 ? (
            <>
              <Filters
              currentUserId={currentUserId}
                data={initData}
                fileData={data}
                setSortData={setSortData}
                sortData={sortData}
                setDataByFilter={handleSetDataByFilter}
                hasStatus={false}
                setFilterOptions={setFilterOptions}
                filterOptions={filterOptions}
                setSearchTitle={setSearchTitle}
                searchTitle={searchTitle}
                debounceValue={debounceValue}
                setSelectedDates={setSelectedDates}
                selectedDates={selectedDates}
                setloader={setloader}
                loader={loader}
              />

              {error ? (
                <div className="mt-8 mb-8 ">
                  <Animation />
                </div>
              ) : (
                <DataTable
                  className="datatable-div"
                  columns={columns}
                  data={data}
                  onSort={handleSort}
                  fixedHeader
                  customStyles={customStyles}
                  noDataComponent={
                    initData.length > 0 ? (
                      <div className="no-data-container">
                        <div className="">
                          <p className="no-data-text">
                            {t.common.no_result_found}
                          </p>
                        </div>
                      </div>
                    ) : null
                  }
                  sortIcon={
                    sortData ? (
                      <div>
                        <span>
                          <SortIcon />
                        </span>
                        <span
                          onClick={(e) => {
                            e.stopPropagation();
                            handleRemoveSorting();
                          }}
                        >
                          <CloseIcon />
                        </span>
                      </div>
                    ) : (
                      <span></span>
                    )
                  }
                  direction={selectedLanguage === 'en' ? 'ltr' : 'rtl'}
                  onRowClicked={(row) => {
                    setTargetedRequest(row);
                    setTogglePreviw(true);
                  }}
                  persistTableHead={true}
                  pointerOnHover={true}
                  highlightOnHover={true}
                  progressPending={loader}
                  progressComponent={
                    <div className="mt-8 mb-8 ">{t.common.loading}</div>
                  }
                />
              )}

              <div className="d-flex justify-content-end">
                <Pagination
                  ofString={t.common.of}
                  total={total}
                  prevItem={
                    selectedLanguage === 'en' ? <ArrowLeft /> : <ArrowRight />
                  }
                  nextItem={
                    selectedLanguage === 'en' ? <ArrowRight /> : <ArrowLeft />
                  }
                  page={page}
                  limit={pageSize}
                  handleNext={async () => {
                    setloader(true);
                    await dispatch(
                      fetchWRWithFilter({
                        organisationId,
                        searchName: debounceValue,
                        page: page + 1,
                        pageSize: pageSize,
                        role,
                        currentUserId,
                        people: filterOptions.requestedBy,
                        status: filterOptions.status,
                        priority: filterOptions.priority,
                        location: filterOptions.locations,
                        assets: filterOptions.assets,
                        submissionDate: selectedDates,
                        sort: sortData,
                      })
                    );
                    setloader(false);
                  }}
                  handlePrevious={async () => {
                    setloader(true);
                    await dispatch(
                      fetchWRWithFilter({
                        organisationId,
                        searchName: debounceValue,
                        page: page - 1,
                        pageSize: pageSize,
                        role,
                        currentUserId,
                        people: filterOptions.requestedBy,
                        status: filterOptions.status,
                        priority: filterOptions.priority,
                        location: filterOptions.locations,
                        assets: filterOptions.assets,
                        submissionDate: selectedDates,
                        sort: sortData,
                      })
                    );
                    setloader(false);
                  }}
                />
              </div>
            </>
          ) : (
            <div className="">
              {/* <EmptyFilter/> */}
              <div className="no-data-container">
                <div className="no-work-orders pb-15">
                  <WRBlueIcon />
                  <p className="no-data-text">
                    {t.common.there_no}{' '}
                    <span
                      className="link-text"
                      style={{ position: 'relative', cursor: 'pointer' }}
                      onMouseOver={() => setShowTooltip(true)}
                      onMouseLeave={() => setShowTooltip(false)}
                    >
                      <ToolTip
                        color="#fff"
                        backgroundColor="#172B4D"
                        position="top left"
                        show={showTooltip}
                        textBoxWidth={
                          selectedLanguage == 'ar' ? '72px' : '58px'
                        }
                        arrowAlign="start"
                        fontSize="14px"
                        fontWeight="400"
                        fontFamily={
                          selectedLanguage == 'ar'
                            ? 'Noto Kufi Arabic'
                            : 'Roboto'
                        }
                        padding="0px 0px 0px 8px"
                        borderRadius="5px"
                        static
                        moveRight={selectedLanguage == 'ar' ? '110px' : '120px'}
                        moveUp="-1px"
                      >
                        <span className="work-order-tooltip">
                          {t.workRequests.how_to_add_wr}
                        </span>
                      </ToolTip>
                      {' ' + t.workRequests.title_small + ' '}
                    </span>{' '}
                    {t.common.added}
                  </p>
                  {userPermissions?.['work-request']?.create ? (
                    <button
                      className="work-order-button-transparent"
                      onClick={() => {
                        setToggleAsideModal(true);
                      }}
                    >
                      {t.workRequests.no_wr_added}
                    </button>
                  ) : null}
                </div>
              </div>
            </div>
          )}
        </div>
      )}

      <AddRequest
        toggled={toggleAsideModal}
        currentUserId={currentUserId}
        userRole={role}
     
        untoggle={() => {
          setToggleAsideModal(false);
          setModalWidth(Math.max(window.innerWidth / 2, 514));
        }}
        setFilterOptions={setFilterOptions}
        setSearchTitle={setSearchTitle}
        setSelectedDates={setSelectedDates}
        sortData={sortData}
        filterOptions={filterOptions}
        selectedDates={selectedDates}
        debounceValue={debounceValue}
      />


      <Edit
       userRole={role}
       currentUserId={currentUserId}
        targetedData={targetedRequest}
        toggled={toggleEditModal}
        setModalWidth={setModalWidth}
        modalWidth={modalWidth}
        untoggle={() => {
          setModalWidth(Math.max(window.innerWidth / 2, 514));
          // setTargetedRequest()
          setToggleEditModal(false);
        }}
        setFilterOptions={setFilterOptions}
        setSearchTitle={setSearchTitle}
        setSelectedDates={setSelectedDates}
      />

      <AddOrder
        toggled={toggleAsideOrder}
        untoggle={() => {
          setModalWidth(Math.max(window.innerWidth / 2, 514));
          setToggleAsideOrder(false);
        }}
        request={targetedRequest}
      />

      <Preview
        targetedData={targetedRequest}
        toggled={togglePreview}
        setModalWidth={setModalWidth}
        modalWidth={modalWidth}
        approveOrder={approveOrder}
        edit={() => {
          setTogglePreviw(false);
          setToggleEditModal(true);
        }}
        setFilterOptions={setFilterOptions}
        setSearchTitle={setSearchTitle}
        setSelectedDates={setSelectedDates}
        declineOrder={declineOrder}
        approveLoading={buttonsLoading.approved}
        declineLoading={buttonsLoading.declined}
        untoggle={() => {
          setTargetedRequest();

          setTogglePreviw(false);
          history.push('/work-requests');
        }}
      />
    </div>
  );
};
