import React, { useState, useEffect, createRef, useCallback } from 'react';
import {
  Segment,
  Header,
  Modal,
  Checkbox,
  Dropdown,
  Grid,
  Input,
  Form,
  Button,
  Divider,
  Icon,
  Dimmer,
  Loader,
} from 'semantic-ui-react';

import MapMarker from './map-marker';
import CustomMarkerIcon from '../../../Assets/Icons/marker.svg';
import search_icon from '../../../Assets/Icons/search-icon.svg';

import GeofenceMarker from './geofence-marker';

import Autocomplete from 'react-google-autocomplete';
import { toast } from 'react-toastify';
import { toAbsoluteUrl } from '../../../_metronic/_helpers';
import {
  getUsersByRole,
  getAllParentLocations,
  updateLocation,
  getAllTeams,
  getAllCustomers,
  getAllVendros,
  updateMainLocation,
  updateGeofencingCoords,
  getAllProcedures,
  GMAPAPIKEY,
  IMAGES_URL,
  uploadFile,
  urlToFile,
  DeleteArea,
  UpdateArea,
} from '../../config/functions';
import { selectTranslations } from '../../config/i18n/slice';
import { useCancelModal } from '../../../hooks/Messages/useCancelModal';

import { Country, State, City } from 'country-state-city';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { LetteredAvatarImageUrl } from '../../components/common/userProfilePlaceholder';
import { scrollToError } from '../../../utilFunctions/scrollToError';
import Asterisk from '../../components/common/Asterisk';
import {
  AddIconWithCircle,
  AddImage,
  CloseIcon,
  CustomerPlusIcon,
  DeleteIcon,
  GreenLocationIcon,
  Success,
  TextSnippet,
} from '../../../Assets/Icons/svg';
import { cloneDeep, debounce } from 'lodash';
import { Map, Marker } from 'google-maps-react';
import { extractAddress } from '../../../services/extractAddress';
import { useDropzone } from 'react-dropzone';
import ToolTip from 'react-power-tooltip';
import {
  fetchAllLoctionData,
  fetchLocations,
} from '../../../redux/reducers/locationReducer';
import { Resizable } from 're-resizable';
import useNewCancelModal from '../../../hooks/Messages/useNewCncelModal';
import Animation from '../../components/common/CustomLoader';

const FileUpload = ({
  onFileChange,
  name = '',
  attachments = {},
  setAttachments = () => {},
}) => {
  const { getRootProps, getInputProps } = useDropzone({
    onDrop: (acceptedFiles) => {
      onFileChange(acceptedFiles, name);
    },
  });
  const t = useSelector(selectTranslations);
  //TO DO : Customize and Style this Drag and Drop to Upload box as you want🧑‍💻😊
  return (
    <div {...getRootProps()}>
      <input {...getInputProps()} />
      {name === 'image' ? (
        attachments?.image?.name ? (
          <div className="image-dropzone text-center mb-5">
            <label>
              {attachments?.image?.name}
              <span
                className="ml-3 mr-3 c-pointer"
                onClick={(e) => {
                  e.stopPropagation();
                  setAttachments({
                    ...attachments,
                    image: null,
                  });
                }}
              >
                <DeleteIcon />
              </span>
            </label>
          </div>
        ) : (
          <div className="image-dropzone text-center mb-5">
            <div>
              <p className="p1">
                <span className="mr-2 ml-2">
                  <AddImage />
                </span>
                <span className="mt-1">{t.workOrders.drag_img}</span>
                <span className="mt-1" style={{ color: '#0C66E4' }}>
                  {' '}
                  {t.workOrders.upload_a_file}
                </span>{' '}
              </p>
            </div>
          </div>
        )
      ) : attachments?.warrantyFile?.name ? (
        <div className="warrenty-file-dropzone text-center">
          <label>
            {attachments?.warrantyFile?.name}
            <span
              className="ml-3 mr-3 c-pointer"
              onClick={(e) => {
                e.stopPropagation();
                setAttachments({
                  ...attachments,
                  warrantyFile: null,
                });
              }}
            >
              <DeleteIcon />
            </span>
          </label>
        </div>
      ) : (
        <div className="warrenty-file-dropzone d-flex align-items-center justify-content-between">
          <div className="d-flex ml-4 mr-4">
            <TextSnippet />
            <div className="pl-4 pr-4">
              <p className="p1 m-0">Warranty file</p>
              <p className="p2 m-0">Attach a file to this asset</p>
            </div>
          </div>
          <div>
            <AddIconWithCircle />
          </div>
        </div>
      )}
    </div>
  );
};

function Edit({ toggled, untoggle, refresh, setRefresh, targetedData }) {
  const t = useSelector(selectTranslations);
  const selectedLanguage = useSelector((state) => {
    if (state?.i18n?.lang) {
      return state.i18n.lang;
    } else {
      return 'en';
    }
  });
  const history = useHistory();
  const organisationId = useSelector((state) => {
    if (state?.auth?.user?.organisation) {
      return state.auth.user.organisation.id;
    } else {
      return null;
    }
  });

  const dispatch = useDispatch();

  const [showCancleTooltip, setCancleTooltip] = useState(false);
  const [modalWidth, setModalWidth] = useState(
    Math.max(window.innerWidth / 2, 514)
  );
  const containerStyle = {
    position: 'relative',
    width: '100%',
    height: '250px',
  };
  const style = {
    width: '100%',
    height: '100%',
  };
  const [initChange, setInitChange] = useState(false);
  const [attachments, setAttachments] = useState({
    image: null,
    warrantyFile: null,
  });
  const [cancelModal, toggleCancelModal] = useState(false);
  const [buttonStatus, setButtonStatus] = useState(0);
  const [successModal, toggleSuccessModal] = useState(false);
  const { setNewCancelModal, NewCancelModal } = useNewCancelModal();
  const [mapModal, toggleMapModal] = useState(false);
  const [locationAddedModal, toggleLocationAddedModal] = useState(false);
  const [geofencingModal, toggleGeofencingModal] = useState(false);
  const [defaultCenter, setDefaultCenter] = useState({
    lat: 0,
    lng: 0,
  });

  const [geofencingSuccessModal, toggleGeofencingSuccessModal] = useState(
    false
  );

  const [responseData, setResponseData] = useState({});

  const getLocationData = useCallback(debounce(getData, 800), []);

  const [fetchingCountries, setFetchingCountries] = useState(true);
  const [fetchingCities, setFetchingCities] = useState(true);
  const [fetchingCustomers, setFetchingCustomers] = useState(true);
  const [fetchingVendors, setFetchingVendros] = useState(true);
  const [fetchingUsers, setFetchingUsers] = useState(true);
  const [fetchingTeams, setFetchingTeams] = useState(true);
  const [fetchingParentLocations, setFetchingParentLocations] = useState(true);

  const [dropdownOptions, setDropdownOptions] = useState({
    propertyType: [
      {
        key: 0,
        value: 'Office Buildings',
        text: t.locations.propertyType.officeBuildings,
      },
      {
        key: 1,
        value: 'Industrial Facilities',
        text: t.locations.propertyType.industrialFacilities,
      },
      {
        key: 2,
        value: 'Retail Spaces',
        text: t.locations.propertyType.retailSpaces,
      },
      {
        key: 3,
        value: 'Educational Institutions',
        text: t.locations.propertyType.educationalInstitutions,
      },
      {
        key: 4,
        value: 'Healthcare Facilities',
        text: t.locations.propertyType.healthcareFacilities,
      },
      {
        key: 5,
        value: 'Residential Buildings',
        text: t.locations.propertyType.residentialBuildings,
      },
      {
        key: 6,
        value: 'Hotels and Hospitality',
        text: t.locations.propertyType.hotelsAndHospitality,
      },
      {
        key: 7,
        value: 'Government Buildings',
        text: t.locations.propertyType.governmentBuildings,
      },
      {
        key: 8,
        value: 'Data Centers',
        text: t.locations.propertyType.dataCenters,
      },
      {
        key: 9,
        value: 'Sports and Recreation Facilities',
        text: t.locations.propertyType.sportsAndRecreationFacilities,
      },
      {
        key: 10,
        value: 'Cultural Institutions',
        text: t.locations.propertyType.culturalInstitutions,
      },
      {
        key: 11,
        value: 'Transportation Hubs',
        text: t.locations.propertyType.transportationHubs,
      },
      {
        key: 12,
        value: 'Laboratories',
        text: t.locations.propertyType.laboratories,
      },
      {
        key: 13,
        value: 'Agricultural Facilities',
        text: t.locations.propertyType.agriculturalFacilities,
      },
      {
        key: 14,
        value: 'Correctional Facilities',
        text: t.locations.propertyType.correctionalFacilities,
      },
      {
        key: 15,
        value: 'Utilities and Energy Facilities',
        text: t.locations.propertyType.utilitiesAndEnergyFacilities,
      },
      {
        key: 16,
        value: 'Commercial Parking Structures',
        text: t.locations.propertyType.commercialParkingStructures,
      },
      {
        key: 17,
        value: 'Mixed-Use Developments',
        text: t.locations.propertyType.mixedUseDevelopments,
      },
      {
        key: 18,
        value: 'Telecommunication Facilities',
        text: t.locations.propertyType.telecommunicationFacilities,
      },
      {
        key: 19,
        value: 'Financial Institutions',
        text: t.locations.propertyType.financialInstitutions,
      },
      {
        key: 20,
        value: 'Government Housing',
        text: t.locations.propertyType.governmentHousing,
      },
      {
        key: 21,
        value: 'Religious Buildings',
        text: t.locations.propertyType.religiousBuildings,
      },
      {
        key: 22,
        value: 'Shopping Centers',
        text: t.locations.propertyType.shoppingCenters,
      },
      {
        key: 23,
        value: 'Technology and Innovation Hubs',
        text: t.locations.propertyType.technologyAndInnovationHubs,
      },
      {
        key: 24,
        value: 'Entertainment Venues',
        text: t.locations.propertyType.entertainmentVenues,
      },
    ],
    parentLocations: [],
    procedures: [],
    countries: [],
    cities: [],
    customers: [],
    vendors: [],
    users: [],
    teams: [],
  });

  const [data, setData] = useState({
    name: '',
    removed_area:[],
    address: '',
    isParent: false,
    parentLocation: '',
    country: '',
    city: '',
    customer: [],
    vendors: [],
    teams: [],
    users: [],
    mainLocation: {},
    geofencingCoords: [],
    procedure: '',
    propertyType: [],
    area: [{ name: '', isStore: false }],
  });
  const [customFields, setCustomData] = useState([]);

  const [mapMarker, setMapMarker] = useState({});
  const [geofenceMarkers, setGeofencingCoords] = useState([]);

  const [validation, setValidation] = useState({
    name: {
      error: false,
      pattern: (name) => name != '',
      skipped: false,
      message: t.common.required_field,
    },
    address: {
      error: false,
      pattern: (address) => address != '',
      skipped: false,
      message: t.common.required_field,
    },
    parentLocation: {
      error: false,
      pattern: (parentLocation) => parentLocation != '',
      skipped: false,
      message: t.common.required_field,
    },
    country: {
      error: false,
      pattern: (country) => country != '',
      skipped: false,
      message: t.common.required_field,
    },
    city: {
      error: false,
      pattern: (city) => city != '',
      skipped: false,
      message: t.common.required_field,
    },
    customer: {
      error: false,
      pattern: (customer) => true,
      skipped: false,
      message: t.common.required_field,
    },
  });

  const [validForm, setValidForm] = useState(false);

  const resetForm = () => {
    setData({
      name: '',
      removed_area:[],
      area: [{ name: '', isStore: false }],
      address: '',
      isParent: false,
      parentLocation: '',
      country: '',
      city: '',
      customer: [],
      vendors: [],
      teams: [],
      users: [],
      mainLocation: {},
      geofencingCoords: [],
      procedure: '',
      propertyType: [],
    });

    setCustomData([]);

    setDropdownOptions({
      ...dropdownOptions,
      parentLocations: [],
      countries: [],
      cities: [],
      customers: [],
      vendors: [],
      users: [],
      teams: [],
    });
    setModalWidth(Math.max(window.innerWidth / 2, 514));


    setFetchingCountries(true);
    setFetchingCities(true);
    setFetchingCustomers(true);
    setFetchingVendros(true);
    setFetchingUsers(true);
    setFetchingTeams(true);
    setFetchingParentLocations(true);

    setValidation({
      name: {
        error: false,
        pattern: (name) => name != '',
        skipped: false,
        message: t.common.required_field,
      },
      address: {
        error: false,
        pattern: (address) => address != '',
        skipped: false,
        message: t.common.required_field,
      },
      parentLocation: {
        error: false,
        pattern: (parentLocation) => parentLocation != '',
        skipped: false,
        message: t.common.required_field,
      },
      country: {
        error: false,
        pattern: (country) => country != '',
        skipped: false,
        message: t.common.required_field,
      },
      city: {
        error: false,
        pattern: (city) => city != '',
        skipped: false,
        message: t.common.required_field,
      },
      customer: {
        error: false,
        pattern: (customer) => true,
        skipped: false,
        message: t.common.required_field,
      },
    });

    setValidForm(false);
    setInitChange(false);
  };

  function getData(lat, lng) {
    return new Promise((resolve, reject) => {
      fetch(
        `https://maps.googleapis.com/maps/api/geocode/json?latlng=${lat},${lng}&key=AIzaSyDmQtEQpQEGsCaIEgA558XVrMT_bOQFKKM`
      )
        .then(async (response) => {
          return response.json();
        })
        .then(async (result) => {
          setData({
            ...data,
            address: result?.results?.[0]?.formatted_address || '',
          });

          const address = result?.results?.[0]?.formatted_address || '';

          resolve(address);

          // let data = data_from_fetched.results;
        });
    });
  }

  const onMarkerDragEnd = async (t, map, coord) => {
    const { latLng } = coord;
    const lat = latLng.lat();
    const lng = latLng.lng();

    // setMarkerPosition({ lat, lng });
    setDefaultCenter({ lat, lng });

    let ans = await getData(lat, lng);

    const geocoder = new window.google.maps.Geocoder();
    geocoder.geocode({ location: { lat, lng } }, (results, status) => {
      if (status === 'OK') {
        if (results[0]) {
          const addressComponents = results[0].address_components;
          const address = extractAddress(results[0]);

          let the_options = [];

          let city = '';

          if (address?.stateCode?.length > 2) {
            const fetchData = State.getStatesOfCountry(address.countryCode);

            if (fetchData.length > 0) {
              // console.log(fetchData);
              the_options = fetchData.map((el) => {
                return {
                  key: el.isoCode,
                  value: el.isoCode,
                  text: el.name,
                };
              });
            } else {
              the_options = [];
            }

            city = the_options?.find((city) =>
              city?.text?.startsWith(address.stateCode)
            );

            //     cityAddress = city?.value
            //     console.log(cityAddress,"dsdssdsdsd")
          }

          let validcity = city?.value
            ? city.value
            : address.stateCode
            ? address.stateCode
            : '';

          if (validcity.length > 3) {
            setValidation({
              ...validation,
              city: {
                ...validation.city,
                error: true,
              },
            });
            setData((prev) => {
              return {
                ...prev,

                city: '',
              };
            });
          } else {
            setData((prev) => {
              return {
                ...prev,
                address: address.address3,
                city: city?.value
                  ? city.value
                  : address.stateCode
                  ? address.stateCode
                  : '',
              };
            });
          }
          if (address.countryCode == undefined) {
            setValidation({
              ...validation,
              country: {
                ...validation.country,
                error: true,
              },
            });
          } else {
            setData((prev) => {
              return {
                ...prev,
                country: address.countryCode ? address.countryCode : '',
              };
            });
          }

          // console.log(results[0],"kdddd")

          // console.log(address,"my address:")
          // console.log(addressComponents,"addressComponents:")
          // const countryComponent = addressComponents.find(component =>
          //   component.types.includes('country')
          // );
          // const countryCode = countryComponent?.short_name;

          // const stateComponent = addressComponents.find(component =>
          //   component.types.includes('administrative_area_level_1')
          // );
          // const stateCode = stateComponent?.short_name;

          // setData({
          //   ...data,
          //   city: stateCode ? stateCode : "",
          //   country: countryCode ? countryCode : "",
          // });

          // console.log('Country code:', countryCode);
          // console.log('State code:', stateCode);
          // You can use countryCode and stateCode as needed
        } else {
          console.error('No results found');
        }
      } else {
        console.error('Geocoder failed due to: ' + status);
      }
    });

    // setData({
    //   ...data,
    //   address: e.target.value,
    // });
  };

  const getLocation = () => {
    return new Promise((resolve, reject) => {
      navigator.geolocation.getCurrentPosition(
        async (position) => {
          // setLang(position.coords.longitude);
          // setLat(position.coords.latitude);
          resolve(position.coords);
        },
        (error) => {
          // if (error.code === 1) {
          //   Toast.error(t('generalValidation.locationPermission'));
          // }
          // if (error.code === 2) {
          //   Toast.error(t('generalValidation.locationPermission'));
          // }
          resolve({});
        }
      );
    });
  };

  useEffect(() => {
    let ele = document.getElementById(`location_12`);
    const AutoAddress = new window.google.maps.places.Autocomplete(ele, {});
    window.google.maps.event.addDomListener(ele, 'focus', (e) => {
      e.target.setAttribute('autocomplete', 'new-password');
    });

    const handlePlaceSelect = async (e) => {
      const place = AutoAddress.getPlace();
      if (!place.address_components) {
        return;
      }
      const address = extractAddress(place);

      let longitudeData = address.lng;
      let latitudeData = address.lat;

      setDefaultCenter({ lat: latitudeData, lng: longitudeData });
      let the_options = [];

      let city = '';

      if (address?.stateCode?.length > 3) {
        const fetchData = State.getStatesOfCountry(address.countryCode);

        if (fetchData.length > 0) {
          // console.log(fetchData);
          the_options = fetchData.map((el) => {
            return {
              key: el.isoCode,
              value: el.isoCode,
              text: el.name,
            };
          });
        } else {
          the_options = [];
        }

        city = the_options?.find((city) =>
          city?.text?.startsWith(address.stateCode)
        );

        //     cityAddress = city?.value
        //     console.log(cityAddress,"dsdssdsdsd")
      }

      setData((prev) => {
        return {
          ...prev,
          address: address.address3,
          city: city?.value
            ? city.value
            : address.stateCode
            ? address.stateCode
            : '',
          country: address.countryCode ? address.countryCode : '',
        };
      });

      let validcity = city?.value
        ? city.value
        : address.stateCode
        ? address.stateCode
        : '';

      if (validcity.length > 3) {
        setValidation({
          ...validation,
          city: {
            ...validation.city,
            error: true,
          },
        });

        setData((prev) => {
          return {
            ...prev,

            city: '',
          };
        });
      }
      let currentValidationData = cloneDeep(validation);
      if (currentValidationData.address.pattern(address.address1)) {
        currentValidationData.address = {
          ...currentValidationData.address,
          error: false,
          skipped: true,
        };
      } else {
        currentValidationData.address = {
          ...currentValidationData.address,
          error: true,
          skipped: true,
        };
      }

      // if (currentValidationData.city.pattern(address.city)) {
      //   currentValidationData.city = {
      //     ...currentValidationData.city,
      //     error: false,
      //     skipped: true,
      //   };
      // } else {
      //   currentValidationData.city = {
      //     ...currentValidationData.city,
      //     error: true,
      //     skipped: true,
      //   };
      // }
      if (address.countryCode == undefined) {
        currentValidationData.country = {
          ...currentValidationData.country,
          error: true,
          skipped: true,
        };
      } else {
        currentValidationData.country = {
          ...currentValidationData.country,
          error: false,
          skipped: false,
        };
      }

      if (validcity.length > 2) {
        currentValidationData.city = {
          ...currentValidationData.city,
          error: true,
          skipped: true,
        };
      } else {
        currentValidationData.city = {
          ...currentValidationData.country,
          error: false,
          skipped: false,
        };
      }

      setValidation(currentValidationData);
    };
    AutoAddress.addListener('place_changed', handlePlaceSelect);
  }, []);

  useEffect(() => {
    if (toggled) {
      fetchOptions();

      if (targetedData) {
        setData({
          ...data,
          name: targetedData?.attributes.name,

          area:
            targetedData?.attributes?.areas?.data?.length > 0
              ? targetedData.attributes.areas?.data
              : [{ attributes: { name: '', isStore: false } }],
          address: targetedData?.attributes?.address,
          isParent: !targetedData?.attributes?.isParent,
          mainLocation: targetedData?.attributes?.mainLocation,
          geofencingCoords: targetedData?.attributes?.geofencingCoords,
          // country: targetedData?.attributes.country,
          // city: targetedData?.attributes.city,
          users: targetedData?.attributes.users?.data?.map((el) => el.id),
          teams: targetedData?.attributes.teams?.data?.map((el) => el.id),
          customer: targetedData?.attributes?.customers?.data?.map(
            (el) => el.id
          ),
          procedure: targetedData?.attributes?.procedure?.data?.id || '',
          vendors: targetedData?.attributes.vendors.data?.map((el) => el.id),
          parentLocation: targetedData?.attributes?.parentLocation?.data?.id,
          propertyType: targetedData?.attributes?.propertyType?.map(
            (item) => item.property
          ),
        });

        setDefaultCenter({
          lat: targetedData?.attributes?.mainLocation?.lat
            ? targetedData?.attributes?.mainLocation?.lat
            : 0,
          lng: targetedData?.attributes?.mainLocation?.lng
            ? targetedData?.attributes?.mainLocation?.lng
            : 0,
        });

        setAttachments({
          ...attachments,
          image: targetedData?.attributes.image?.data
            ? {
                id: targetedData?.attributes.image?.data?.id,
                file: urlToFile(
                  targetedData?.attributes.image?.data?.attributes.url,
                  targetedData?.attributes.image?.data?.attributes.name
                ),
                name: targetedData?.attributes.image?.data?.attributes.name,
                type: targetedData?.attributes.image?.data?.attributes.mime,
                cancelPopup: false,
                isChanged: false,
              }
            : null,
        });

        if (targetedData?.attributes.customFields.length > 0) {
          setCustomData(targetedData?.attributes.customFields);
        }
      }
    }
  }, [targetedData, toggled]);

  const fetchOptions = async () => {
    const [
      countries,
      cities,
      users,
      teams,
      customers,
      vendors,
      parent_locations,
      procedures,
    ] = await Promise.all([
      Country.getAllCountries(),
      State.getStatesOfCountry(targetedData?.attributes.country),
      getUsersByRole(
        [
          'user',
          'supervisor',
          'super_admin',
          'view_only',
          'limited_admin',
          'admin',
          'requester',
        ],
        organisationId
      ),
      getAllTeams(organisationId, '', 1, 99999),
      getAllCustomers(organisationId),
      getAllVendros(organisationId),
      getAllParentLocations(organisationId, '', 1, 99999),
      getAllProcedures(false, organisationId, '', 1, 99999),
    ]);

    if (countries) {
      setFetchingCountries(false);
    }

    if (cities) {
      setFetchingCities(false);
    }

    if (users) {
      setFetchingUsers(false);
    }

    if (teams.response) {
      setFetchingTeams(false);
    }

    if (customers.response) {
      setFetchingCustomers(false);
    }

    if (vendors.response) {
      setFetchingVendros(false);
    }

    if (parent_locations) {
      setFetchingParentLocations(false);
    }
    let procedures_options = [];
    if (procedures.response) {
      procedures_options = procedures.response.map((el) => {
        return {
          key: el.id,
          value: el.id,
          text: el.attributes.title,
        };
      });
      procedures_options.unshift({
        key: '',
        value: '',
        text: 'Select Procedure',
      });
    }

    setDropdownOptions({
      ...dropdownOptions,
      countries:
        countries?.length > 0
          ? countries.map((el) => {
              return {
                key: el?.isoCode?.toLowerCase(),
                value: el?.isoCode,
                text: el.name,
                flag: el?.isoCode?.toLowerCase(),
              };
            })
          : [],
      cities:
        cities?.length > 0
          ? cities.map((el) => {
              return {
                key: el.isoCode,
                value: el.isoCode,
                text: el.name,
              };
            })
          : [],
      users:
        users.response?.length > 0
          ? users.response.map((el) => {
              const the_image = el.profileImage?.url
                ? IMAGES_URL + el.profileImage?.url
                : LetteredAvatarImageUrl(el?.firstName + ' ' + el.lastName);
              return {
                key: el.id,
                value: el.id,
                text: el.firstName,
                image: { avatar: true, src: the_image, size: 'tiny' },
              };
            })
          : [],
      teams:
        teams.response?.length > 0
          ? teams.response.map((el) => {
              const the_image = LetteredAvatarImageUrl(el?.attributes?.name);

              return {
                key: el.id,
                value: el.id,
                text: el.attributes.name,
                image: { avatar: true, src: the_image, size: 'tiny' },
              };
            })
          : [],
      customers:
        customers.response?.length > 0
          ? customers.response.map((el) => {
              const the_image = el?.attributes?.logo?.data?.attributes?.url
                ? IMAGES_URL + el?.attributes?.logo?.data?.attributes?.url
                : LetteredAvatarImageUrl(el.attributes.name);
              return {
                key: el.id,
                value: el.id,
                text: el.attributes.name,
                image: { avatar: true, src: the_image, size: 'tiny' },
              };
            })
          : [],
      vendors:
        vendors.response?.length > 0
          ? vendors.response.map((el) => {
              const the_image = el?.attributes?.logo?.data?.attributes?.url
                ? IMAGES_URL + el?.attributes?.logo?.data?.attributes?.url
                : LetteredAvatarImageUrl(el.attributes.name);
              return {
                key: el.id,
                value: el.id,
                text: el.attributes.name,
                image: { avatar: true, src: the_image, size: 'tiny' },
              };
            })
          : [],
      procedures: procedures_options,
      parentLocations:
        parent_locations.response?.length > 0
          ? parent_locations.response.map((el) => {
              return {
                key: el.id,
                value: el.id,
                text: el.attributes.name,
              };
            })
          : [],
    });

    if (countries.length > 0) {
      let country;
      let the_options = [];
      let city;
      country = countries?.find((city) =>
        city?.name?.startsWith(targetedData?.attributes?.countryName)
      );

      const fetchData = State.getStatesOfCountry(country?.isoCode);

      if (fetchData?.length > 0) {
        // console.log(fetchData);
        the_options = fetchData?.map((el) => {
          return {
            key: el?.isoCode,
            value: el?.isoCode,
            text: el?.name,
          };
        });
      } else {
        the_options = [];
      }

      city = the_options?.find((city) =>
        city?.text?.startsWith(targetedData.attributes.cityName)
      );

      setData((prev) => {
        return {
          ...prev,

          city: city?.value ? city.value : '',
          country: country?.isoCode ? country?.isoCode : '',
        };
      });
    }
  };

  const fetchProceduresOptions = async () => {
    const fetchData = await getAllProcedures(false, organisationId);

    if (fetchData) {
      let the_options = fetchData.map((el) => {
        return {
          key: el.id,
          value: el.id,
          text: el.attributes.title,
        };
      });
      the_options.unshift({
        key: '',
        value: '',
        text: 'Select Procedure',
      });

      setDropdownOptions({
        ...dropdownOptions,
        procedures: the_options,
      });

      return the_options;
    }
  };

  const onFileChange = (files, name) => {
    setInitChange(true);

    var randomnumber = Math.floor(Math.random() * (0 - 100 + 1)) + 100;
    setAttachments({
      ...attachments,
      [name]: {
        file: files[0],
        id: randomnumber,
        name: files[0].name,
        type: files[0].type,
        cancelPopup: false,
        isChanged: true,
      },
    });
  };

  const addNewArea = () => {
    let areaData = [...data.area];
    areaData.push({ attributes: { name: '', isStore: false } });
    setData({
      ...data,
      area: areaData,
    });
  };

  // const handleAreaChange = (value, index) => {
  //   let areaData = [...data.area];
  //   areaData[index].attributes.name = value;

  //   if (!areaData[index].name) {
  //     areaData[index].attributes.isStore = false;
  //   }
  //   setData({
  //     ...data,
  //     area: areaData,
  //   });
  // };

  const handleAreaChange = (value, index) => {
    // Clone the area data deeply
    const areaData = data.area.map((item, i) => {
      if (i === index) {
        return {
          ...item,
          attributes: {
            ...item.attributes,
            name: value,
          },
          // Ensure isStore is updated only if name is empty
          isStore: value ? item.attributes.isStore : false,
        };
      }
      return item;
    });

    // Update the state with the new data
    setData({
      ...data,
      area: areaData,
    });
  };

  // const handleAreaStoreChange = (e, index, value) => {
  //   let areaData = [...data.area];
  //   areaData[index].attributes.isStore = value.checked;
  //   setData({
  //     ...data,
  //     area: areaData,
  //   });
  // };

  const handleAreaStoreChange = async(e, index, value) => {
    let areaData = [...data.area];

    if(value.checked == false && areaData[index]?.id){
      const response = await UpdateArea(areaData[index]?.id, organisationId,true);
      if (response.status == 400) {
        return toast.error(t.assets.delete_store);
      }
    }
  
    areaData[index] = {
      ...areaData[index],
      attributes: {
        ...areaData[index].attributes,
        isStore: value.checked,
      },
    };
    setData({
      ...data,
      area: areaData,
    });
  };

  const fetchCities = async () => {
    const fetchData = State.getStatesOfCountry(data.country);

    if (fetchData.length > 0) {
      // console.log(fetchData);
      let the_options = fetchData.map((el) => {
        return {
          key: el.isoCode,
          value: el.isoCode,
          text: el.name,
        };
      });

      setDropdownOptions({
        ...dropdownOptions,
        cities: the_options,
      });
    } else {
      setDropdownOptions({
        ...dropdownOptions,
        cities: [],
      });
    }
  };

  useEffect(() => {
    fetchCities();
  }, [data.country]);

  const removeArea = async (index) => {
    let areaData = [...data.area];
    let removeAres= data?.removed_area;

    if(areaData[index]?.id){
      const response = await UpdateArea(areaData[index]?.id, organisationId);
      if (response.status == 400) {
        return toast.error(t.assets.delete_area);
      }
      removeAres.push(areaData[index]?.id)
    }

    // const response = await DeleteArea(areaData[index]?.id);
    // if (response.status == 400) {
    //   return toast.error(t.assets.delete_area);
    // }




    areaData.splice(index, 1);
    setData({
      ...data,
      area: areaData,
      removed_area:removeAres
    });

  };
  const onValuesChange = (name) => (event, value) => {
    setInitChange(true);
    const dropdown_fields = [
      'parentLocation',
      'country',
      'city',
      'users',
      'teams',
      'customer',
      'procedure',
      'vendors',
      'propertyType',
    ];
    const boolean_fields = ['isParent'];

    if (!dropdown_fields.includes(name) && !boolean_fields.includes(name)) {
      setData({
        ...data,
        [name]: event.target.value,
      });
    }

    if (dropdown_fields.includes(name)) {
      // console.log(value);
      if (name === 'country') {
        setData({
          ...data,
          [name]: value.value,
          city: '',
        });
      } else {
        setData({
          ...data,
          [name]: value.value,
        });
      }
    }

    if (boolean_fields.includes(name)) {
      setData({
        ...data,
        [name]: value.checked,
      });
    }
  };

  const generatedCustomDataFields = () => {
    return customFields.map((field, index) => {
      return (
        <>
          <div className="custom-container mb-3">
            <label className="custom-label mr-3">
              {t.locations.form.custom_data}
            </label>
          </div>
          <Form.Field>
            <label className="label-item">{t.locations.form.label}</label>
            <Input
              autoComplete="new-password"
              fluid
              placeholder={t.locations.form.label}
              value={field.label}
              onChange={handleCustomDataChange(index, 'label')}
            />
          </Form.Field>

          <Form.Field>
            <label className="label-item">{t.locations.form.value}</label>
            <Input
              autoComplete="new-password"
              fluid
              placeholder={t.locations.form.field_value}
              value={field.value}
              onChange={handleCustomDataChange(index, 'value')}
            />
          </Form.Field>

          <div
            className="buttun-type-link mb-5"
            basic
            onClick={removeThisData(field.id)}
          >
            <Icon name="trash alternate outline" />

            <p className="ml-2 mr-5 ">{t.common.remove}</p>
          </div>
        </>
      );
    });
  };

  const addCustomData = () => {
    // console.log("clicked");
    setCustomData([
      ...customFields,
      {
        label: '',
        value: '',
      },
    ]);
  };

  const removeThisData = (id) => (e) => {
    e.stopPropagation();
    const index = id;
    let newfields = [...customFields];
    newfields.splice(index, 1);

    setCustomData(newfields);
  };

  const handleCustomDataChange = (id, type) => (event) => {
    // 1. Make a shallow copy of the items
    let newfields = [...customFields];
    // 2. Make a shallow copy of the item you want to mutate
    let item = { ...customFields[id] };
    // 3. Replace the property you're intested in

    if (type == 'label') item.label = event.target.value;
    if (type == 'value') item.value = event.target.value;
    // 4. Put it back into our array. N.B. we *are* mutating the array here, but that's why we made a copy first
    newfields[id] = item;
    // 5. Set the state to our new copy
    setCustomData(newfields);
  };

  const validationHandler = () => {
    let currentValidation = {
      ...validation,
      name: {
        ...validation.name,
        error: validation.name.pattern(data.name) ? false : true,
        skipped: true,
      },
      address: {
        ...validation.address,
        error: validation.address.pattern(data.address) ? false : true,
        skipped: true,
      },
      parentLocation: {
        ...validation.parentLocation,
        error: data.isParent
          ? validation.parentLocation.pattern(data.parentLocation)
            ? false
            : true
          : false,
        skipped: true,
      },
      country: {
        ...validation.country,
        error: validation.country.pattern(data.country) ? false : true,
        skipped: true,
      },
      city: {
        ...validation.city,
        error: validation.city.pattern(data.city) ? false : true,
        skipped: true,
      },
      customer: {
        ...validation.customer,
        error: validation.customer.pattern(data.customer) ? false : true,
        skipped: true,
      },
    };
    if (!defaultCenter.lat && !defaultCenter.lng) {
      currentValidation = {
        ...currentValidation,
        address: {
          ...currentValidation.address,
          error: true,
          skipped: true,
          message: t.locations.location_map,
        },
      };
    }
    setValidation(currentValidation);
    const validationToArray = Object.entries(currentValidation);
    const cond = validationToArray.some((el) => el[1].error);
    return cond;
  };

  useEffect(() => {
    const validationToArray = Object.entries(validation);
    const cond = validationToArray.some((el) => el[1].error && el[1].skipped);
    // console.log("cond", cond);

    setValidForm(!cond);
  }, [validation, initChange]);

  const updateLocationHandler = async () => {
    let errorsInData = validationHandler();
    if (!errorsInData) {
      setButtonStatus(1);

      const isParentHandler = !data.isParent ? true : false;

      const propertyType = data?.propertyType?.map((e) => ({
        property: e,
      }));

      // console.log(response);
      const locationImageReponse =
        attachments.image && attachments.image.isChanged
          ? await uploadFile([attachments.image.file])
          : null;

      await updateLocationHandler2(
        isParentHandler,
        propertyType,
        locationImageReponse?.status == 200
          ? locationImageReponse.data[0].id
          : attachments?.image?.id
          ? attachments?.image.id
          : null
      );
    } else {
      scrollToError();
    }
  };

  const updateLocationHandler2 = async (
    isParentHandler,
    propertyType,
    image
  ) => {
    const response = await updateLocation(
      targetedData?.id,
      String(data.name),
      String(data.address),
      Boolean(isParentHandler),
      data.isParent ? Number(data.parentLocation) : null,
      String(data.country),
      String(data.city),
      data.customer,
      data.vendors,
      data.teams,
      data.users,
      defaultCenter,
      data.geofencingCoords,
      customFields,
      data.procedure ? data.procedure : null,
      propertyType ? propertyType : [],
      data.area ? data.area : [],
      image,
      data?.removed_area ?  data?.removed_area  : []
    );
    // console.log(response);

    if (response.status == 200) {
      setButtonStatus(0);
      untoggle();
      resetForm();
      setResponseData({
        id: response.data.data.id,
        name: response.data.data.attributes.name,
        isParent: response.data.data.attributes.isParent,
        mainLocation: response.data.data.attributes.mainLocation,
        geofencingCoords: response.data.data.attributes.geofencingCoords,
      });
      // const response1 = await updateMainLocation(
      //   response.data.data.id,
      //   defaultCenter,
      //   organisationId
      // );
      toast.success(
        response.data.data.attributes.isParent
          ? t.locations.location_updated_success
          : t.locations.sub_location_updated_success
      );
      // setRefresh(!refresh);
      const currentPathname = window.location.pathname;
      if (currentPathname == '/locations-map-view') {
        dispatch(fetchAllLoctionData(organisationId));
      } else {
        dispatch(fetchLocations(organisationId));
      }

      // toggleSuccessModal(true);
    } else if (response.status == 400) {
      if (response.error && response.error.message) {
        toast.error(response.error.message);
        // untoggle();
      } else {
        toast.error(t.common.something_wrong);
        untoggle();
        resetForm();
      }
    } else {
      toast.error(t.common.something_wrong);
      untoggle();
    }
    setButtonStatus(0);
  };

  useEffect(() => {
    validationHandler();
  }, [data]);

  const submitLocationMarkerHandler = async () => {
    setButtonStatus(1);
    const response = await updateMainLocation(
      responseData.id,
      mapMarker,
      organisationId
    );
    // console.log(response);
    if (response.status == 200) {
      setButtonStatus(0);

      // toggleMapModal(false);
      // toggleLocationAddedModal(true);

      setResponseData({
        ...responseData,
        id: response.data.data.id,
        mainLocation: response.data.data.mainLocation,
      });
    }
  };

  const submitGeofencingCoordsHandler = async () => {
    setButtonStatus(1);
    const response = await updateGeofencingCoords(
      responseData.id,
      geofenceMarkers
    );
    // console.log(response);
    if (response.status == 200) {
      setButtonStatus(0);

      toggleGeofencingModal(false);
      toggleGeofencingSuccessModal(true);
    }
  };

  const cancelHandler = () => {
    resetForm();
    setModalWidth(Math.max(window.innerWidth / 2, 514));
    untoggle();
    setNewCancelModal(false);
    toggleCancelModal(false);
    setInitChange(false);
  };

  return (
    <>
      <div
        clearing
        style={{ maxWidth: `${modalWidth}px` }}
        className={
          toggled ? 'aside-modal active procedure' : 'aside-modal procedure'
        }
      >
        <Resizable
          size={{ width: modalWidth, height: 'auto' }}
          minWidth={'514px'}
          onResize={(e, direction, ref, d) => {
            setModalWidth(ref.offsetWidth);
          }}
        >
          <div className="modal-header">
            <div className="flexbox justify-between">
              <div className="name">
                {targetedData?.attributes.isParent
                  ? `${t.locations.edit_location} #${targetedData?.attributes?.countId}`
                  : `${t.locations.edit_sublocation}  #${targetedData?.attributes?.countId}`}
              </div>
              <div>
                <span
                  className="c-pointer"
                  style={{ position: 'relative' }}
                  onMouseOver={() => setCancleTooltip(true)}
                  onMouseLeave={() => setCancleTooltip(false)}
                  onClick={() =>
                    initChange
                      ? setNewCancelModal(initChange && true)
                      : cancelHandler()
                  }
                >
                  <CloseIcon />
                  <ToolTip
                    color="#fff"
                    backgroundColor="#172B4D"
                    position={
                      selectedLanguage == 'en'
                        ? 'bottom center'
                        : 'bottom center'
                    }
                    arrowAlign="center"
                    show={showCancleTooltip}
                    textBoxWidth="36px"
                    padding="5px 5px 5px 7px"
                    borderRadius="5px"
                    static
                  >
                    <span className="work-order-preview-tooltip">
                      {t.common.close}
                    </span>
                  </ToolTip>
                </span>
              </div>
            </div>
          </div>
          <div className="body">
            {buttonStatus == 1 ? (
              <Dimmer active inverted>
                <Loader inverted content={t.common.loading} />
              </Dimmer>
            ) : (
              ''
            )}
            <Form.Field>
              <FileUpload
                onFileChange={onFileChange}
                name="image"
                attachments={attachments}
                setAttachments={setAttachments}
              />
            </Form.Field>
            <Form.Field>
              <label className="label-item">
                {t.locations.form.location_name}
                <Asterisk color="#172B4D" />
              </label>
              <Input
                autoComplete="new-password"
                fluid
                placeholder={t.locations.form.enter_location_name}
                value={data.name}
                onChange={onValuesChange('name')}
                error={validation.name.error && validation.name.skipped}
                onBlur={(e) => {
                  if (validation.name.pattern(data.name)) {
                    setValidation({
                      ...validation,
                      name: {
                        ...validation.name,
                        error: false,
                        skipped: true,
                      },
                    });
                  } else {
                    setValidation({
                      ...validation,
                      name: {
                        ...validation.name,
                        error: true,
                        skipped: true,
                      },
                    });
                  }
                }}
              />
              {validation.name.error && validation.name.skipped ? (
                <div className="label-error">{validation.name.message}</div>
              ) : (
                ''
              )}
            </Form.Field>
            {/* {console.log(data)} */}
            <Form.Field>
              <label className="label-item">
                {t.locations.table.address}
                <Asterisk color="#172B4D" />
              </label>
              <div>
                <Input
                  type="text"
                  id="location_12"
                  autoComplete="new-password"
                  className={
                    selectedLanguage == 'en'
                      ? 'location-address'
                      : 'location-address-rtl'
                  }
                  fluid
                  placeholder={t.locations.form.address_placeholder}
                  value={data.address}
                  onChange={(e) => {
                    setDefaultCenter({ lat: 0, lng: 0 });
                    setData({
                      ...data,
                      address: e.target.value,
                    });
                  }}
                  error={validation.address.error && validation.address.skipped}
                  onBlur={(e) => {
                    if (validation.address.pattern(data.address)) {
                      setValidation({
                        ...validation,
                        address: {
                          ...validation.address,
                          error: false,
                          skipped: true,
                        },
                      });
                    } else {
                      setValidation({
                        ...validation,
                        address: {
                          ...validation.address,
                          error: true,
                          skipped: true,
                        },
                      });
                    }
                  }}
                />
                {!data.address ? (
                  <img
                    className="date-picker-image location"
                    style={{ top: '-26px', left: '8px' }}
                    src={search_icon}
                  />
                ) : null}
              </div>
              {validation.address.error && validation.address.skipped ? (
                <div
                  style={!data?.address ? { marginTop: '-15px' } : {}}
                  className="label-error"
                >
                  {validation.address.message}
                </div>
              ) : (
                ''
              )}
              {/* <div className="ui fluid input">
              <Autocomplete
                apiKey={GMAPAPIKEY}
                onPlaceSelected={onPlaceSelected}
                componentRestrictions={{ country: "us" }}
                options={{
                  types: ["geocode", "establishment"],
                }}
              />
            </div> */}
            </Form.Field>
            {defaultCenter.lat && defaultCenter.lng ? (
              <div>
                <Map
                  google={window.google}
                  style={style}
                  containerStyle={containerStyle}
                  center={defaultCenter}
                  initialCenter={defaultCenter}
                  // zoom={editingLocationMode ? 12 : 10}
                  version="weekly"
                >
                  <Marker
                    position={defaultCenter}
                    draggable={true}
                    icon={{
                      url: CustomMarkerIcon,
                      scaledSize: new window.google.maps.Size(50, 50),
                    }}
                    onDragend={(t, map, coord) =>
                      onMarkerDragEnd(t, map, coord)
                    }
                  />
                </Map>
              </div>
            ) : null}

            {/* {targetedData?.attributes.isParent ?  <Form.Field style={{ minHeight: '0px' }}>
            <Checkbox
              value={data.isParent}
              checked={data.isParent}
              label={t.locations.form.is_sub_location}
              onChange={onValuesChange('isParent')}
              className={` ${'mt-2 mb-3'}`}
            />
          </Form.Field> : null} */}

            {data.isParent ? (
              <Form.Field>
                <label className="label-item mt-5">
                  {t.locations.form.parent_location}
                  <Asterisk color="#172B4D" />
                </label>
                <Dropdown
                  placeholder={t.locations.form.choose_from_existing_location}
                  noResultsMessage={t.common.no_results_found}
                  fluid
                  search
                  selection
                  clearable
                  options={dropdownOptions.parentLocations}
                  value={data.parentLocation}
                  onChange={onValuesChange('parentLocation')}
                  disabled={!targetedData?.attributes.isParent}
                  loading={fetchingParentLocations}
                  error={
                    validation.parentLocation.error &&
                    validation.parentLocation.skipped
                  }
                  onBlur={(e) => {
                    if (data.isParent) {
                      if (
                        validation.parentLocation.pattern(data.parentLocation)
                      ) {
                        setValidation({
                          ...validation,
                          parentLocation: {
                            ...validation.parentLocation,
                            error: false,
                            skipped: true,
                          },
                        });
                      } else {
                        setValidation({
                          ...validation,
                          parentLocation: {
                            ...validation.parentLocation,
                            error: true,
                            skipped: true,
                          },
                        });
                      }
                    }
                  }}
                />
                {validation.parentLocation.error &&
                validation.parentLocation.skipped ? (
                  <div className="label-error">
                    {validation.parentLocation.message}
                  </div>
                ) : (
                  ''
                )}
              </Form.Field>
            ) : (
              ''
            )}

            <Form.Field autoComplete="off">
              <label className={`label-item ${data.isParent ? '' : 'mt-5'} `}>
                {t.locations.table.country}
                <Asterisk color="#172B4D" />
              </label>
              <Dropdown
                placeholder={t.locations.form.country_add}
                noResultsMessage={t.common.no_results_found}
                fluid
                search
                selection
                options={dropdownOptions.countries}
                value={data.country}
                disabled={
                  data.country !== 'AX' && (data?.country || fetchingCountries)
                    ? true
                    : false
                }
                onChange={onValuesChange('country')}
                loading={fetchingCountries}
                error={validation.country.error && validation.country.skipped}
                onBlur={(e) => {
                  if (validation.country.pattern(data.country)) {
                    setValidation({
                      ...validation,
                      country: {
                        ...validation.country,
                        error: false,
                        skipped: true,
                      },
                    });
                  } else {
                    setValidation({
                      ...validation,
                      country: {
                        ...validation.country,
                        error: true,
                        skipped: true,
                      },
                    });
                  }
                }}
              />
              {validation.country.error && validation.country.skipped ? (
                <div className="label-error">{validation.country.message}</div>
              ) : (
                ''
              )}
            </Form.Field>

            <Form.Field autoComplete="off">
              <label className="label-item">
                {t.locations.table.city}
                <Asterisk color="#172B4D" />
              </label>
              <Dropdown
                placeholder={t.locations.form.city_add}
                noResultsMessage={t.common.no_results_found}
                fluid
                search
                selection
                // disabled={dropdownOptions.cities.length < 1}
                options={dropdownOptions.cities}
                value={data.city}
                disabled={data?.city ? true : false}
                onOpen={fetchCities}
                onChange={onValuesChange('city')}
                error={validation.city.error && validation.city.skipped}
                onBlur={(e) => {
                  if (validation.city.pattern(data.city)) {
                    setValidation({
                      ...validation,
                      city: {
                        ...validation.city,
                        error: false,
                        skipped: true,
                      },
                    });
                  } else {
                    setValidation({
                      ...validation,
                      city: {
                        ...validation.city,
                        error: true,
                        skipped: true,
                      },
                    });
                  }
                }}
              />
              {validation.city.error && validation.city.skipped ? (
                <div className="label-error">{validation.city.message}</div>
              ) : (
                ''
              )}
            </Form.Field>

            {data.area.length > 0 ? (
              <label className="label-item">{t.assets.form.area}</label>
            ) : null}

            {data.area.map((one, index) => {
              return (
                <>
                  <div className="flexbox mt-3">
                    <Input
                      style={{ flex: 1 }}
                      className=" area-input-div"
                      placeholder={t.assets.form.add_area}
                      type="text"
                      fluid
                      selection
                      value={one?.attributes?.name}
                      onChange={(e) => handleAreaChange(e.target.value, index)}
                    />

                    {
                      <span
                        className="ml-2 mr-2 p-1 d-flex align-items-center justify-content-center c-pointer"
                        onClick={() => {
                          removeArea(index);
                        }}
                        style={{ backgroundColor: '#E9F2FF' }}
                      >
                        <DeleteIcon fill={'#0C66E4'} />
                      </span>
                    }
                  </div>

                  <div className="mt-3">
                    <Checkbox
                      disabled={!one?.attributes?.name}
                      checked={one?.attributes?.isStore}
                      label={t.locations.store}
                      onChange={(e, value) =>
                        handleAreaStoreChange(e, index, value)
                      }
                    />
                  </div>
                </>
              );
            })}

            <div
              className={`buttun-type-link ${
                data.area.length > 0 ? 'mt-5' : ' '
              }`}
              basic
              onClick={addNewArea}
            >
              <span className="customer-add-btn">
                {' '}
                <CustomerPlusIcon />{' '}
              </span>

              <p className="ml-2 mr-2">{t.assets.form.add_area}</p>
            </div>

            <Form.Field autoComplete="off">
              <label className="label-item mt-5">
                {t.locations.form.propertyType}
              </label>
              <Dropdown
                placeholder={t.locations.form.select_propertyType}
                noResultsMessage={t.common.no_results_found}
                fluid
                search
                selection
                multiple
                // disabled={dropdownOptions.propertyType}
                options={dropdownOptions.propertyType}
                value={data.propertyType}
                onChange={onValuesChange('propertyType')}
                // error={validation.city.error && validation.city.skipped}
                // onBlur={(e) => {
                //   if (validation.city.pattern(data.city)) {
                //     setValidation({
                //       ...validation,
                //       city: {
                //         ...validation.city,
                //         error: false,
                //         skipped: true,
                //       },
                //     });
                //   } else {
                //     setValidation({
                //       ...validation,
                //       city: {
                //         ...validation.city,
                //         error: true,
                //         skipped: true,
                //       },
                //     });
                //   }
                // }}
              />
              {/* {validation.city.error && validation.city.skipped ? (
              <div className="label-error">{validation.city.message}</div>
            ) : (
              ''
            )} */}
            </Form.Field>
            {/* <Form.Field>
            <label className="label-item">{t.locations.form.people}</label>
            <Dropdown
              placeholder={t.locations.form.select_users}
              fluid
              multiple
              search
              selection
              options={dropdownOptions.users}
              value={data.users}
              onChange={onValuesChange('users')}
              disabled={fetchingUsers}
              loading={fetchingUsers}
            />
          </Form.Field>

          <Form.Field>
            <label className="label-item">{t.locations.form.teams}</label>
            <Dropdown
              placeholder={t.locations.form.select_teams}
              fluid
              multiple
              search
              selection
              options={dropdownOptions.teams}
              value={data.teams}
              onChange={onValuesChange('teams')}
              disabled={fetchingTeams}
              loading={fetchingTeams}
            />
          </Form.Field> */}

            <Form.Field>
              <label className="label-item">
                {t.locations.table.customer}
                {/* <Icon name="asterisk" color="red" size="small" /> */}
              </label>
              <Dropdown
                placeholder={t.locations.form.select_customer}
                noResultsMessage={t.common.no_results_found}
                fluid
                search
                multiple
                selection
                options={dropdownOptions.customers}
                value={data.customer}
                onChange={onValuesChange('customer')}
                disabled={fetchingCustomers}
                loading={fetchingCustomers}
                error={validation.customer.error && validation.customer.skipped}
                onBlur={(e) => {
                  if (validation.customer.pattern(data.customer)) {
                    setValidation({
                      ...validation,
                      customer: {
                        ...validation.customer,
                        error: false,
                        skipped: true,
                      },
                    });
                  } else {
                    setValidation({
                      ...validation,
                      customer: {
                        ...validation.customer,
                        error: true,
                        skipped: true,
                      },
                    });
                  }
                }}
              />
              {validation.customer.error && validation.customer.skipped ? (
                <div className="label-error">{validation.customer.message}</div>
              ) : (
                ''
              )}
            </Form.Field>

            <Form.Field>
              <label className="label-item">{t.locations.form.vendors}</label>
              <Dropdown
                placeholder={t.locations.form.select_vendors}
                noResultsMessage={t.common.no_results_found}
                fluid
                search
                multiple
                selection
                options={dropdownOptions.vendors}
                value={data.vendors}
                onChange={onValuesChange('vendors')}
                disabled={fetchingVendors}
                loading={fetchingVendors}
              />
            </Form.Field>

            {/* <Grid columns="equal">
            <Grid.Column>
              <Form.Field>
                <label className="label-item">{'Procedure'}</label>
                <Dropdown
                  placeholder={'Select Procedure'}
                  fluid
                  selection
                  search
                  options={dropdownOptions.procedures}
                  value={data.procedure}
                  onChange={onValuesChange('procedure')}
                  onOpen={fetchProceduresOptions}
                />
              </Form.Field>
            </Grid.Column>
            <Grid.Column></Grid.Column>
          </Grid> */}

            {generatedCustomDataFields()}
            <div className="buttun-type-link" basic onClick={addCustomData}>
              <span className="customer-add-btn">
                {' '}
                <CustomerPlusIcon />{' '}
              </span>

              <p className="ml-2 mr-2">{t.locations.form.custom_data}</p>
            </div>

            <div className="form-button-container">
              <button
                // content={t.procedures.form.cancel_button}
                // basic
                onClick={() =>
                  initChange
                    ? setNewCancelModal(initChange && true)
                    : cancelHandler()
                }
                className="form-action-button"
              >
                {t.common.cancel}
              </button>

              <button
                // content={t.procedures.form.submit.add}
                // primary
                onClick={updateLocationHandler}
                // loading={buttonStatus == 1}
                className="form-action-button blue"
                disabled={buttonStatus == 1}
              >
                {t.common.edit}
              </button>
            </div>
          </div>
        </Resizable>
      </div>
      <div
        className={
          toggled ? 'aside-modal-background active' : 'aside-modal-background'
        }
        onClick={() =>
          initChange ? setNewCancelModal(initChange && true) : cancelHandler()
        }
      ></div>
      {/* success modal */}
      <Modal
        size="tiny"
        open={successModal}
        className="assets-modal"
        onClose={() => {
          toggleSuccessModal(false);
          setRefresh(!refresh);
          history.push('/locations');
        }}
      >
        <div className="text-right mt-5 mr-5">
          <span
            className="c-pointer"
            onClick={() => {
              toggleSuccessModal(false);
              setRefresh(!refresh);
              history.push('/locations');
            }}
          >
            <CloseIcon />
          </span>
        </div>
        <Modal.Content>
          <div style={{ textAlign: 'center' }}>
            <div className="mb-5">
              <Success />
            </div>
            <p className="status-modal-popup-message">
              {t.locations.location_updated_success}
            </p>
            <p className="status-modal-popup-message">
              {t.locations.add_location_on_map_statement}
            </p>
          </div>
        </Modal.Content>
        <div className="modal-button-container justify-content-center">
          {responseData.isParent ? (
            <>
              <button
                onClick={() => {
                  toggleSuccessModal(false);
                  setRefresh(!refresh);
                  history.push('/locations');
                }}
                className="modal-form-action-button "
              >
                {t.common.later}
              </button>
              <button
                onClick={() => {
                  toggleSuccessModal(false);
                  toggleMapModal(true);
                }}
                className="modal-form-action-button blue"
              >
                {t.locations.update_on_map}
              </button>
              {/* <Button
              color="black"
              onClick={() => {
                toggleSuccessModal(false);
                setRefresh(!refresh);
                history.push('/locations');
              }}
            >
              {t.common.later}
            </Button>
            <Button
              content={t.locations.update_on_map}
              labelPosition="right"
              icon="add"
              positive
              onClick={() => {
                toggleSuccessModal(false);
                toggleMapModal(true);
              }}
            /> */}
            </>
          ) : (
            <Modal.Actions>
              <Button
                color="black"
                className="button-transparent"
                onClick={() => {
                  toggleSuccessModal(false);
                  setRefresh(!refresh);
                  history.push('/locations');
                }}
              >
                {t.common.ok}
              </Button>
            </Modal.Actions>
          )}
        </div>
      </Modal>

      {/* ask if you want to add location on map modal */}
      <Modal
        size="tiny"
        open={mapModal}
        className="assets-modal"
        onClose={() => {
          toggleMapModal(false);
          setRefresh(!refresh);
        }}
      >
        <div className="header-container">
          <span className="assets-header">
            {t.locations.update_location_on_map}
          </span>

          <div>
            <span
              className="c-pointer text-right"
              onClick={() => {
                toggleMapModal(false);
                setRefresh(!refresh);
              }}
            >
              <CloseIcon />
            </span>
          </div>
        </div>
        {/* <Modal.Header>{t.locations.update_location_on_map}</Modal.Header> */}
        <Modal.Content>
          <p>{t.locations.drag_marker_statement}</p>
          <MapMarker
            locationName={responseData.name}
            submitMarker={setMapMarker}
            mainLocation={responseData.mainLocation}
          />
        </Modal.Content>
        <div className="modal-button-container justify-content-center">
          <button
            onClick={() => {
              toggleMapModal(false);
              setRefresh(!refresh);
            }}
            className="modal-form-action-button "
          >
            {t.common.later}
          </button>

          <button
            onClick={() => {
              submitLocationMarkerHandler();
            }}
            className="modal-form-action-button blue"
          >
            {t.locations.update_on_map}
          </button>
          {/* <Button
            color="black"
            onClick={() => {
              toggleMapModal(false);
              setRefresh(!refresh);
            }}
          >
            {t.common.later}
          </Button>
          <Button
            content={t.locations.update_on_map}
            labelPosition="right"
            icon="add"
            positive
            loading={buttonStatus == 1}
            onClick={() => {
              submitLocationMarkerHandler();
            }}
          /> */}
        </div>
      </Modal>

      {/* success add location on map modal */}
      <Modal
        size="tiny"
        open={locationAddedModal}
        className="assets-modal"
        onClose={() => {
          toggleLocationAddedModal(false);
          setRefresh(!refresh);
        }}
      >
        <div className="text-right mt-5 mr-5">
          <span
            className="c-pointer"
            onClick={() => {
              toggleLocationAddedModal(false);
              setRefresh(!refresh);
            }}
          >
            <CloseIcon />
          </span>
        </div>
        <Modal.Content>
          <div style={{ textAlign: 'center' }}>
            <div className="mb-5">
              <GreenLocationIcon />
            </div>
            <p className="status-modal-popup-message">
              {t.locations.location_added_on_map_success}
            </p>
            <p className="status-modal-popup-message">
              {t.locations.add_geo_coord_statement}
            </p>
          </div>
        </Modal.Content>
        <div className="modal-button-container justify-content-center">
          <button
            onClick={() => {
              toggleLocationAddedModal(false);
              setRefresh(!refresh);
            }}
            className="modal-form-action-button "
          >
            {t.common.later}
          </button>

          <button
            onClick={() => {
              toggleLocationAddedModal(false);
              toggleGeofencingModal(true);
            }}
            className="modal-form-action-button blue"
          >
            {t.locations.update_on_map}
          </button>
          {/* <Button
            color="black"
            onClick={() => {
              toggleLocationAddedModal(false);
              setRefresh(!refresh);
            }}
          >
            {t.common.later}
          </Button>
          <Button
            content={t.locations.update_on_map}
            labelPosition="right"
            icon="add"
            positive
            onClick={() => {
              toggleLocationAddedModal(false);
              toggleGeofencingModal(true);
            }}
          /> */}
        </div>
      </Modal>

      {/* ask if you want to add geofencing coords on map modal */}
      <Modal
        size="tiny"
        open={geofencingModal}
        className="assets-modal"
        onClose={() => {
          toggleGeofencingModal(false);
          setRefresh(!refresh);
        }}
      >
        <div className="header-container">
          <span className="assets-header">{t.locations.update_geo_coord}</span>

          <div>
            <span
              className="c-pointer text-right"
              onClose={() => {
                toggleGeofencingModal(false);
                setRefresh(!refresh);
              }}
            >
              <CloseIcon />
            </span>
          </div>
        </div>
        {/* <Modal.Header>{t.locations.update_geo_coord}</Modal.Header> */}
        <Modal.Content>
          <p>{t.locations.click_to_add_coord}</p>
          {geofencingModal ? (
            <GeofenceMarker
              center_map={responseData.mainLocation}
              submitMarker={setGeofencingCoords}
              geofencingCoords={responseData.geofencingCoords}
            />
          ) : (
            ''
          )}
        </Modal.Content>
        <div className="modal-button-container justify-content-center">
          <button
            onClick={() => {
              toggleGeofencingModal(false);
              setRefresh(!refresh);
            }}
            className="modal-form-action-button "
          >
            {t.common.later}
          </button>

          <button
            onClick={() => {
              submitGeofencingCoordsHandler();
            }}
            className="modal-form-action-button blue"
          >
            {t.locations.update_on_map}
          </button>
          {/* <Button
            color="black"
            onClick={() => {
              toggleGeofencingModal(false);
              setRefresh(!refresh);
            }}
          >
            {t.common.later}
          </Button>
          <Button
            content={t.locations.update_on_map}
            labelPosition="right"
            icon="add"
            positive
            loading={buttonStatus == 1}
            onClick={() => {
              submitGeofencingCoordsHandler();
            }}
          /> */}
        </div>
      </Modal>

      {/* success add geofencing coords on map modal */}
      <Modal
        size="tiny"
        open={geofencingSuccessModal}
        className="assets-modal"
        onClose={() => {
          toggleGeofencingSuccessModal(false);
          setRefresh(!refresh);
        }}
      >
        <div className="text-right mt-5 mr-5">
          <span
            className="c-pointer"
            onClick={() => {
              toggleGeofencingSuccessModal(false);
              setRefresh(!refresh);
            }}
          >
            <CloseIcon />
          </span>
        </div>
        <Modal.Content className="pt-0">
          <div style={{ textAlign: 'center' }}>
            <div className="mb-5">
              <GreenLocationIcon />
            </div>
            <p className="status-modal-popup-message">
              {t.locations.geo_add_success}
            </p>
          </div>
        </Modal.Content>
        <Modal.Actions>
          <Button
            color="black"
            className="button-transparent"
            onClick={() => {
              toggleGeofencingSuccessModal(false);
              setRefresh(!refresh);
            }}
          >
            {t.common.ok}
          </Button>
        </Modal.Actions>
      </Modal>

      {/* cancel modal */}

      <NewCancelModal
        yesCallback={() => {
          cancelHandler();
        }}
        title={t.parts.form.changes}
      >
        <p style={{ lineHeight: '24px' }}> {t.common.cancel_2statement}</p>
      </NewCancelModal>
      {/* <Modal
        size="tiny"
        open={cancelModal && initChange}
        onClose={() => toggleCancelModal(false)}
      >
        <Modal.Content className="cancel-text">
          <p>{t.common.cancel_statement}</p>
        </Modal.Content>
        <Modal.Actions>
          <Button color="black" onClick={() => toggleCancelModal(false)}>
            {t.common.keep}
          </Button>
          <Button negative onClick={() => cancelHandler()}>
            {t.common.yes_cancel}
          </Button>
        </Modal.Actions>
      </Modal> */}
    </>
  );
}

export default Edit;
